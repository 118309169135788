export function chunk_split(body: { match: (arg0: RegExp) => any[] }, chunklen: any, end: string) {
	chunklen = parseInt(chunklen, 10) || 76;
	end = end || '\r\n';

	if (chunklen < 1) {
		return false;
	}

	return body.match(new RegExp('.{0,' + chunklen + '}', 'g')).join(end);
}

export const generateDownloadLink = ({ content, fileName, mimeType }) => {
	const file = new Blob([content], { type: mimeType });
	const link = document.createElement('a');
	link.download = fileName;
	const url = window.URL.createObjectURL(file);
	link.href = url;
	link.style.display = 'none';
	return link;
};
