/* eslint-disable no-console */
import Dexie from 'dexie';
let db;

export function getDatabase() {
	if (!db) {
		db = new Dexie('SCM_UI_Database');
		db.version(1).stores({
			vulnerability: '++id, category, cve, host, name, severity, vendor, version, details',
			phoneHome: '++id, category, name, severity, version, details',
		});
	}
	return db;
}

export async function initializeDatabase() {
	const database = getDatabase();
	if (!database.isOpen()) {
		await database.open();
		console.log('Database initialized successfully');
	} else {
		console.log('Database already initialized');
	}
}

// Function to delete a specific table
export async function deleteTable(tableName: string) {
	await db.table(tableName).clear();
	console.log(`Table ${tableName} cleared successfully`);
}
