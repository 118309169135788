import React, { ReactElement } from 'react';

export const DeviceIcon = (): ReactElement => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M13 6C12.448 6 12 6.447 12 7C12 7.553 12.448 8 13 8C13.553 8 14 7.553 14 7C14 6.447 13.553 6 13 6Z'
			fill='currentColor'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10 6C9.448 6 9 6.447 9 7C9 7.553 9.448 8 10 8C10.553 8 11 7.553 11 7C11 6.447 10.553 6 10 6Z'
			fill='currentColor'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M15 10.5176C15 10.7836 14.775 10.9996 14.498 10.9996H1.502C1.225 10.9996 1 10.7836 1 10.5176V5.48263C1 5.21663 1.225 4.99963 1.502 4.99963H14.498C14.775 4.99963 15 5.21663 15 5.48263V10.5176ZM14.498 3.99963H1.502C0.673 3.99963 0 4.66363 0 5.48263V10.5176C0 11.3356 0.673 11.9996 1.502 11.9996H14.498C15.327 11.9996 16 11.3356 16 10.5176V5.48263C16 4.66363 15.327 3.99963 14.498 3.99963Z'
			fill='currentColor'
		/>
	</svg>
);
export const HADeviceIcon = (): ReactElement => (
	<svg width='17' height='16' viewBox='0 0 17 16' fill='none'>
		<g clipPath='url(#clip0_232_10994)'>
			<path
				d='M11 7C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5C10.4477 5 10 5.44772 10 6C10 6.55228 10.4477 7 11 7Z'
				fill='currentColor'
			/>
			<path
				d='M8 7C8.55228 7 9 6.55228 9 6C9 5.44772 8.55228 5 8 5C7.44772 5 7 5.44772 7 6C7 6.55228 7.44772 7 8 7Z'
				fill='currentColor'
			/>
			<path
				d='M12.5 4C12.77 4 13 4.22 13 4.48V8.52C13 8.78 12.77 9 12.5 9H1.5C1.23 9 1 8.78 1 8.52V4.48C1 4.22 1.23 4 1.5 4H12.5ZM12.5 3H1.5C0.67 3 0 3.66 0 4.48V8.52C0 9.34 0.67 10 1.5 10H12.5C13.33 10 14 9.34 14 8.52V4.48C14 3.66 13.33 3 12.5 3Z'
				fill='currentColor'
			/>
			<path
				d='M14.56 6.06H13.64V7.06H14.56C14.84 7.06 15.06 7.28 15.06 7.54V11.58C15.06 11.85 14.83 12.06 14.56 12.06H3.56C3.28 12.06 3.06 11.84 3.06 11.58V9.51H2.06V11.58C2.06 12.4 2.73 13.06 3.56 13.06H14.56C15.39 13.06 16.06 12.4 16.06 11.58V7.54C16.06 6.73 15.39 6.06 14.56 6.06Z'
				fill='currentColor'
			/>
		</g>
		<defs>
			<clipPath id='clip0_232_10994'>
				<rect width='16.06' height='10.06' fill='white' transform='translate(0 3)' />
			</clipPath>
		</defs>
	</svg>
);
export const CloudNGFWIcon = (): ReactElement => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M14.5 9C14.77 9 15 9.22 15 9.48V13.52C15 13.78 14.77 14 14.5 14H3.5C3.23 14 3 13.78 3 13.52V9.48C3 9.22 3.23 9 3.5 9H14.5ZM14.5 8H3.5C2.67 8 2 8.66 2 9.48V13.52C2 14.34 2.67 15 3.5 15H14.5C15.33 15 16 14.34 16 13.52V9.48C16 8.66 15.33 8 14.5 8Z'
			fill='currentColor'
		/>
		<path
			d='M10 12C10.5523 12 11 11.5523 11 11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11C9 11.5523 9.44772 12 10 12Z'
			fill='currentColor'
		/>
		<path
			d='M13 12C13.5523 12 14 11.5523 14 11C14 10.4477 13.5523 10 13 10C12.4477 10 12 10.4477 12 11C12 11.5523 12.4477 12 13 12Z'
			fill='currentColor'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M13 10.5H12.343C12.4128 10.4376 12.5014 10.3953 12.6 10.3844C12.7398 10.3671 12.8738 10.3301 13 10.2759V10.5ZM15 8.5C15 7.73125 14.7 7 14.175 6.46562C14.1 4.99375 12.9375 3.8125 11.5125 3.8125C11.2969 3.8125 11.0813 3.84062 10.8562 3.89687C10.4531 3.38125 9.89062 3.03437 9.2625 2.92188C8.63437 1.75 7.45313 1 6.17813 1C4.22813 1 2.63437 2.67812 2.63437 4.75C2.63437 4.78172 2.63656 4.81124 2.63883 4.84176C2.64125 4.87438 2.64375 4.90812 2.64375 4.94688C2.2875 5.14375 2.00625 5.45312 1.81875 5.82812C0.75 6.19375 0 7.28125 0 8.5C0 9.88618 1.03738 10.978 2.5 11.2481V10.2873C1.57582 10.051 0.9375 9.36063 0.9375 8.5C0.9375 7.61875 1.5 6.85 2.26875 6.6625C2.42813 6.63438 2.55 6.52187 2.60625 6.37187C2.72813 6.03437 2.98125 5.77187 3.3 5.66875C3.50625 5.59375 3.62812 5.30312 3.6 5.07812C3.59676 5.03929 3.59241 5.00156 3.5881 4.96419C3.57991 4.8933 3.57187 4.82366 3.57187 4.75C3.57187 3.20312 4.74375 1.9375 6.1875 1.9375C7.17188 1.9375 8.1 2.56562 8.53125 3.55C8.59688 3.70937 8.75625 3.8125 8.925 3.83125C9.47813 3.86875 9.98438 4.1875 10.275 4.69375C10.3969 4.9 10.65 4.98438 10.8656 4.89062C11.0719 4.79688 11.2969 4.75 11.5125 4.75C12.4688 4.75 13.2469 5.59375 13.2469 6.67188C13.2469 6.8125 13.3031 6.93437 13.4062 7.02812C13.8188 7.38437 14.0625 7.92812 14.0625 8.5H15Z'
			fill='currentColor'
		/>
	</svg>
);
export const FolderIcon = (): ReactElement => (
	<svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.528 0H7.5H1.708C0.764 0 0 0.788 0 1.761V12.239C0 13.212 0.764 14 1.708 14H14.293C15.237 14 16 13.212 16 12.239V4.311C16 3.338 15.047 2 13.5 2C13.5 2 12.177 2.001 11.289 2.001C10.843 2.001 10.507 2.001 10.5 2C10.489 1.999 10.476 1.998 10.461 1.998C10.422 1.998 10.369 2.001 10.306 2.001C9.972 2.001 9.346 1.928 9 1C8.642 0.045 7.704 0 7.528 0ZM7.531 1C7.654 1 7.946 1.036 8.063 1.35C8.461 2.415 9.257 3.001 10.306 3.001L10.453 2.998C10.532 3.001 10.713 3.001 11.289 3.001L13.5 3C14.47 3 15 3.865 15 4.311V12.239C15 12.659 14.682 13 14.293 13H1.708C1.317 13 1 12.659 1 12.239V1.761C1 1.341 1.317 1 1.708 1H7.5H7.531Z'
			fill='currentColor'
		/>
	</svg>
);

export const SnippetIcon = ({
	height = 16,
	width = 16,
	className = '',
}: {
	height?: number;
	width?: number;
	className?: string;
}): ReactElement => (
	<svg
		className={className}
		width={width}
		height={height}
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M6.44 10.8622L3.68 8L6.44 5.13777L5.6 4.26666L2 8L5.6 11.7333L6.44 10.8622ZM9.56 10.8622L12.32 8L9.56 5.13777L10.4 4.26666L14 8L10.4 11.7333L9.56 10.8622Z'
			fill='#354252'
		/>
		<rect x='0.5' y='0.5' width='15' height='15' rx='2.5' stroke='#354252' />
	</svg>
);
