import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { _T } from 'src/utils/i18n-utils';
import {
	PRISMA_ACCESS_CONTAINER,
	SASE_PRISMA_ACCESS_BRAND,
	ALL,
	MU_EP_CONTAINER,
	MU_EP_KEY,
	MU_GP_CONTAINER,
	MU_GP_KEY,
	MU_JP_KEY,
	RN_CONTAINER,
	SC_CONTAINER,
} from 'service-lib/services/constants';
import { getStoreState } from 'ui-lib/core/utils/storeRegistry';
import { getLocationReverseKeyMap } from 'service-lib/services/licenseManager';
import { getFolderNameByUUID } from 'ui-lib/core/autorender/widgets/util';
import { getAgentDisplayLabel } from 'src/containers/config/services';
import { ICellRendererParams, RowEvent } from 'ag-grid-community';
import { CloudNGFWIcon, DeviceIcon, HADeviceIcon } from 'src/components/ScopeIcons';
import ReactDOM from 'react-dom';

export const getScopeNameMap: any = () => {
	const tenantName = _.get(getStoreState(), 'main.tenant.info.tenant_instance_name', '');
	const folderDisplayNameMap = _.get(getStoreState(), 'main.scopeManage.folderDisplayNameMap', {});
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return {
		...folderDisplayNameMap,
		Shared: `${_T('Global')}${tenantName ? ` (${tenantName})` : ''}`,
		[ALL]: _T(`Global`),
		'ngfw-shared': _T('All Firewalls'),
		[MU_GP_KEY]: _T(`{displayStr}`, { displayStr: getAgentDisplayLabel() }),
		[MU_JP_KEY]: _T(`{displayStr}`, { displayStr: getAgentDisplayLabel() }),
		[MU_EP_KEY]: _T('Explicit Proxy'),
		[MU_EP_CONTAINER]: _T('Explicit Proxy'),
		[MU_GP_CONTAINER]: _T(`{displayStr}`, { displayStr: getAgentDisplayLabel() }),
		[PRISMA_ACCESS_CONTAINER]: _T(`{displayStr}`, { displayStr: SASE_PRISMA_ACCESS_BRAND }),
		[RN_CONTAINER]: _T('Remote Networks'),
		[SC_CONTAINER]: _T('Service Connections'),
	};
};

export const getSnippetNameMap: any = () => {
	const snippetDisplayNameMap = _.get(getStoreState(), 'main.snippetManage.snippetDisplayMap', {});
	return {
		...snippetDisplayNameMap,
	};
};

export const getScopeNameByDisplayName: any = (displayName: string) => {
	const folderDisplayNameMap = getScopeNameMap();
	const matched = Object.keys(folderDisplayNameMap).filter((key: any) => folderDisplayNameMap[key] === displayName);

	return matched.length ? matched[0] : displayName;
};

const getFolderIcon = (params: any) => {
	const type = _.get(params, 'data.type');
	const displayType = _.get(params, 'data.displayType');
	if (displayType === 'cngfw') {
		return <CloudNGFWIcon />;
	}
	const IconMap: any = {
		'on-prem': <DeviceIcon />,
		ha: <HADeviceIcon />,
	};
	const iconType = displayType === 'ha' ? displayType : type;
	if (iconType !== 'container' && iconType !== 'root') {
		return IconMap[iconType];
	} else {
		return '';
	}
};

const getDisplayElement = (params: any) => {
	const displayName = _.get(params, 'data.displayName');
	const type = _.get(params, 'data.type');
	const count = _.get(params, 'data.childDevicesCount');
	const scopeNameMap = getScopeNameMap();
	const name = scopeNameMap[displayName] || displayName;
	const countDisplay = count && type === 'container' ? `(${count})` : '';
	return `<span>
			${name} <span class='child-count'>${countDisplay}</span>
		</span>`;
};

export const FolderHierarchyRenderer = () => {
	function FolderHierarchyRenderer() {}
	FolderHierarchyRenderer.prototype.init = function (params: any) {
		this.eGui = document.createElement('div');
		this.eGui.innerHTML = getDisplayElement(params);
		// params.api.forEachNodeAfterFilter((node:any ) => {
		// 	if(_.get(node,'data.displayName') === _.get(params, 'data.displayName')){
		//add logic for updating count after filter
		// 		console.log(node);
		// 	}
		// });
		const type = _.get(params, 'data.type');
		if (
			(type === 'container' &&
				_.get(params, 'data.displayName') !== 'All Firewalls' &&
				!getLocationReverseKeyMap()[_.get(params, 'data.displayName')]) ||
			type === 'root' ||
			type === 'on-prem'
		) {
			this.imgEle = document.createElement('span');
			this.imgEle.className = 'folder-icon';
			ReactDOM.render(getFolderIcon(params), this.imgEle);
			this.eGui.insertBefore(this.imgEle, this.eGui.firstChild);
		}
	};
	FolderHierarchyRenderer.prototype.getGui = function () {
		return this.eGui;
	};
	FolderHierarchyRenderer.prototype.getValue = function () {
		return this.eGui;
	};
	return FolderHierarchyRenderer;
};

export const FolderHierarchyRendererReact = (props: ICellRendererParams) => {
	const { node, value } = props;
	const [expanded, setExpanded] = useState(node.expanded);

	useEffect(() => {
		const expandListener = (event: RowEvent) => setExpanded(event.node.expanded);

		node.addEventListener('expandedChanged', expandListener);

		return () => {
			node.removeEventListener('expandedChanged', expandListener);
		};
	}, []);

	const onClick = useCallback(() => node.setExpanded(!node.expanded), [node]);

	return (
		<div
			style={{
				paddingLeft: `${node.level * 15}px`,
			}}
		>
			{node.group && (
				<div
					style={{
						cursor: 'pointer',
						transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
						display: 'inline-block',
					}}
					onClick={onClick}
				>
					&rarr;
				</div>
			)}
			&nbsp;
			{value}
		</div>
	);
};

export const getDisplayNameByUUID: any = (uuid: string) => {
	const snippetUUIDMap = _.get(getStoreState(), 'main.snippetManage.snippetUUIDMap', {});
	const name = getFolderNameByUUID(uuid);
	const scopeNameMap = getScopeNameMap();
	return scopeNameMap[name] || snippetUUIDMap[uuid]?.displayName;
};
