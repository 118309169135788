import { deleteTable, getDatabase, initializeDatabase } from 'src/db/Database';
import { ANTISPYWARE_TABLE, IThreat1, IThreat2, VULNERABILITY_TABLE } from 'src/db/dbSchema';

export async function insertVulnerabilty(data = [], clear) {
	const threats: Array<IThreat1> = data;
	try {
		await initializeDatabase();
		if (clear) {
			await deleteTable(VULNERABILITY_TABLE);
		}
		const db = getDatabase();
		await db.table(VULNERABILITY_TABLE).bulkAdd(threats);
		console.info('Successfully insert data');
	} catch (error) {
		throw new Error('Error inserting vulnerability data');
	}
}
export async function insertPhoneHome(data = [], clear) {
	const threats: Array<IThreat2> = data;
	try {
		await initializeDatabase();
		if (clear) {
			await deleteTable(ANTISPYWARE_TABLE);
		}
		const db = getDatabase();
		await db.table(ANTISPYWARE_TABLE).bulkAdd(threats);
		console.info('Successfully insert data');
	} catch (error) {
		throw new Error('Error inserting phoneHome data');
	}
}

export function getVulnerabilityTableQuery(sqlPromise, searchParams, db) {
	const { count, id, name, category, severity, cve, vendor, host, action, partialMatch } = searchParams;
	if (id?.length) {
		const rangeList = id
			.filter((d) => d.indexOf('-') >= 0)
			.map((d) =>
				d
					.split('-')
					.map((e) => parseInt(e))
					.sort(),
			);
		const inList = id.filter((d) => d.indexOf('-') === -1).map((d) => parseInt(d));

		if (inList.length > 0 && rangeList.length > 0) {
			sqlPromise = db
				.table(VULNERABILITY_TABLE)
				.where('id')
				.anyOf(inList)
				.or('id')
				.inAnyRange(
					rangeList.map((d) => [d[0], d[1]]),
					{ includeUppers: true },
				);
		} else if (inList.length > 0) {
			sqlPromise = db.table(VULNERABILITY_TABLE).where('id').anyOf(inList);
		} else if (rangeList.length > 0) {
			sqlPromise = db
				.table(VULNERABILITY_TABLE)
				.where('id')
				.inAnyRange(
					rangeList.map((d) => [d[0], d[1]]),
					{ includeUppers: true },
				);
		}
	}
	if (name) {
		sqlPromise = sqlPromise.filter((item) => {
			return item.name.toLowerCase().includes(name.toLowerCase());
		});
	}
	if (category) {
		sqlPromise = sqlPromise.filter((item) => {
			return category.includes(item.category);
		});
	}
	if (severity) {
		sqlPromise = sqlPromise.filter((item) => {
			return severity.includes(item.severity);
		});
	}
	if (cve) {
		sqlPromise = sqlPromise.filter((item) => {
			return cve.some((c) => item.cve.includes(c));
		});
	}
	if (vendor) {
		sqlPromise = sqlPromise.filter((item) => {
			return vendor.includes(item.vendor);
		});
	}
	if (host?.length === 1) {
		sqlPromise = sqlPromise.filter((item) => {
			return host[0] === item.host;
		});
	}

	if (action) {
		const negative = action?.[0] === '!';
		sqlPromise = sqlPromise.filter((item) => {
			if (typeof item.details !== 'string') return false;
			return negative
				? JSON.parse(item.details)?.['default-action'] !== action.substring(1)
				: JSON.parse(item.details)?.['default-action'] === action;
		});
	}
	if (partialMatch?.length > 0) {
		sqlPromise = sqlPromise.filter((item) => {
			for (const match of partialMatch) {
				if (item.name.toLowerCase().includes(match.toLowerCase())) {
					return true;
				}
				if (item.cve?.toLowerCase().includes(match.toLowerCase())) {
					return true;
				}
				if (item.id === parseInt(match)) {
					return true;
				}
				if (item.vendor?.toLowerCase().includes(match.toLowerCase())) {
					return true;
				}
			}
			return false;
		});
	}

	if (count > 0) {
		sqlPromise = sqlPromise.limit(count);
	}
	return sqlPromise;
}

export function getAntiSpywareTableQuery(sqlPromise, searchParams, db) {
	const { count, id, name, category, severity, action, partialMatch } = searchParams;
	if (id?.length) {
		const rangeList = id
			.filter((d) => d.indexOf('-') >= 0)
			.map((d) =>
				d
					.split('-')
					.map((e) => parseInt(e))
					.sort(),
			);
		const inList = id.filter((d) => d.indexOf('-') === -1).map((d) => parseInt(d));
		if (inList.length > 0 && rangeList.length > 0) {
			sqlPromise = db
				.table(ANTISPYWARE_TABLE)
				.where('id')
				.anyOf(inList)
				.or('id')
				.inAnyRange(
					rangeList.map((d) => [d[0], d[1]]),
					{ includeUppers: true },
				);
		} else if (inList.length > 0) {
			sqlPromise = db.table(ANTISPYWARE_TABLE).where('id').anyOf(inList);
		} else if (rangeList.length > 0) {
			sqlPromise = db
				.table(ANTISPYWARE_TABLE)
				.where('id')
				.inAnyRange(
					rangeList.map((d) => [d[0], d[1]]),
					{ includeUppers: true },
				);
		}
	}
	if (name) {
		sqlPromise = sqlPromise.filter((item) => {
			return item.name.toLowerCase().includes(name.toLowerCase());
		});
	}
	if (category) {
		sqlPromise = sqlPromise.filter((item) => {
			return category.includes(item.category);
		});
	}
	if (severity) {
		sqlPromise = sqlPromise.filter((item) => {
			return severity.includes(item.severity);
		});
	}

	if (action) {
		const negative = action?.[0] === '!';
		sqlPromise = sqlPromise.filter((item) => {
			if (typeof item.details !== 'string') return false;
			return negative
				? JSON.parse(item.details)?.['default-action'] !== action.substring(1)
				: JSON.parse(item.details)?.['default-action'] === action;
		});
	}
	if (partialMatch?.length > 0) {
		sqlPromise = sqlPromise.filter((item) => {
			for (const match of partialMatch) {
				if (item.name.toLowerCase().includes(match.toLowerCase())) {
					return true;
				}
				if (item.id === parseInt(match)) {
					return true;
				}
			}
			return false;
		});
	}

	if (count > 0) {
		sqlPromise = sqlPromise.limit(count);
	}
	return sqlPromise;
}

export async function search(tableName, searchParams) {
	try {
		await initializeDatabase();
		const db = getDatabase();
		let sqlPromise = db.table(tableName).where('id').above(0);
		if (tableName === VULNERABILITY_TABLE) {
			sqlPromise = getVulnerabilityTableQuery(sqlPromise, searchParams, db);
		} else if (tableName === ANTISPYWARE_TABLE) {
			sqlPromise = getAntiSpywareTableQuery(sqlPromise, searchParams, db);
		}
		const result = await sqlPromise.toArray();
		console.info(`Search params ${JSON.stringify(searchParams)} result for ${tableName}: ${result.length}`);
		return result;
	} catch (error) {
		console.error('Error clearing all data:', error);
	}
}
export async function getCount(tableName) {
	try {
		await initializeDatabase();
		const db = getDatabase();
		return await db.table(tableName).count();
	} catch (error) {
		console.error('Error clearing all data:', error);
	}
}
