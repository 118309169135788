import { getAuthHeaders } from 'ui-lib/auth/lib';
import { isMockService } from 'src/ui-lib/core/services/CommonServices';

// this will give nice auto-completion in the editor and also ensures we pass
// in only these strings to the second param
export type METHOD = 'GET' | 'POST' | 'PUT' | 'DELETE';

export const prepareRequestOptions = (url: string, method: METHOD, payload?: any) => {
	const includeCredentials = !isMockService(); // include credentials for all requests which aren't mocked
	const requestOptions: Record<string, any> = {
		withCredentials: includeCredentials,
		method: method,
		responseType: 'json',
		url: url,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
	if (payload !== undefined && payload !== null) {
		requestOptions.body = JSON.stringify(payload);
	}

	return requestOptions;
};
