const b64DecodeUnicode = function (str: string) {
	return decodeURIComponent(
		atob(str).replace(/(.)/g, function (m, p) {
			var code = p.charCodeAt(0).toString(16).toUpperCase();
			if (code.length < 2) {
				code = '0' + code;
			}
			return '%' + code;
		}),
	);
};

const decode = function (str: string) {
	var output = str.replace(/-/g, '+').replace(/_/g, '/');
	switch (output.length % 4) {
		case 0:
			break;
		case 2:
			output += '==';
			break;
		case 3:
			output += '=';
			break;
		default:
			throw new Error('Illegal base64url string!');
	}

	try {
		return b64DecodeUnicode(output);
	} catch (err) {
		return atob(output);
	}
};

export const jwt_decode = function (token: string, options: any) {
	if (typeof token !== 'string') {
		throw new Error('Invalid token specified');
	}

	options = options || {};
	var pos = options.header === true ? 0 : 1;
	try {
		return JSON.parse(decode(token.split('.')[pos]));
	} catch (e: any) {
		throw new Error('Invalid token specified: ' + e.message);
	}
};
