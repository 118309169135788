export const ALLOW_REDIRECT_ROUTES = ['/'];
export const PREFERENCES = {
	HIDE_WELCOME_POPUP: 'hideWelcomePopup',
	HIDE_SCOPE_POPUP: 'hideScopePopup',
	HIDE_POLICY_OPTMIZER_POPUP: 'hidePolicyOptmizerPopup',
};
export const GUIDES = {
	LOCAL_ZONE: 'https://docs.paloaltonetworks.com/whats-new/august-2023/local-zone-additions',
};
export const PAGINATION_QUERY_PARAM = `pagination=false`;
