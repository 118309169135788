import { v4 as uuidv4 } from 'uuid';
import { fawkesUtils } from 'sparky-framework';
import _ from 'lodash';
import { getStoreState } from 'src/ui-lib/core/utils/storeRegistry';

/*eslint-disable import/no-cycle */
declare global {
	interface Window {
		Cypress: any;
		csrfSalt: any;
	}
}

/* eslint-disable @typescript-eslint/no-var-requires */
const md5 = require('md5');
const sha1 = require('sha1');

let md5Salt = '';
let sha1Salt = '';
export function initAuth(getUserResponse: any) {
	const isGov = _.get(getStoreState(), 'main.loggedInUser.tenant.is_gov', false);
	if (getUserResponse && getUserResponse.xhr && getUserResponse.xhr.getResponseHeader('x-csrf-salt')) {
		md5Salt = getUserResponse.xhr.getResponseHeader('x-csrf-salt');
		sha1Salt = getUserResponse.xhr.getResponseHeader('x-csrf-salt');
		if (window.Cypress) {
			window.csrfSalt = isGov ? sha1Salt : md5Salt;
		}
	} else {
		console.log('auth'); // As little as possible
	}
}

export function getAuthHeaders(): any {
	const now = new Date().getTime();
	const isGov = _.get(getStoreState(), 'main.loggedInUser.tenant.is_gov', false);
	return {
		// salt should have been sent by Admin node already.
		'x-csrf-token': isGov ? now + ':' + sha1(`${now}:${sha1Salt}`) : now + ':' + md5(`${now}:${md5Salt}`),
		// by this time, session value must be already set.
		'x-auth-jwt': fawkesUtils?.getSession(),
		'x-trace-id': uuidv4(),
	};
}

export function setMd5Salt(salt: string) {
	md5Salt = salt;
}

export function getCSRFToken() {
	const now = new Date().getTime();
	return now + ':' + md5(`${now}:${md5Salt}`);
}

export function getIAMAuthHeaders(): any {
	return {
		authorization: `Bearer ${fawkesUtils?.getSession()}`,
	};
}
