/* eslint-disable no-prototype-builtins */
/* eslint-disable no-useless-escape */
/*eslint-disable import/no-cycle */
import React from 'react';
import { Label } from 'reactstrap';
import { getPathValue, getRecordPathWithFilters } from 'src/ui-lib/core/utils/json';
import Pan from 'src/ui-lib/core/autorender/schema/Pan';
import { get, isArray, isString, each, escape } from 'lodash';
import { FormFeedbackWidget } from 'src/ui-lib/core/autorender/widgets/FormFeedbackWidget';
import {
	MU_CONTAINER,
	PRISMA_ACCESS_CONTAINER,
	CONTAINER,
	CONTAINER_CLOUD,
	CONTAINER_ONPREM,
	SWG_CONTAINER_TYPE,
	SNIPPET,
} from 'service-lib/services/constants';

import { _T } from 'src/utils/i18n-utils';
import { FAWKES } from 'src/typings/type';
import _ from 'lodash';
import { hideModal, showModal } from 'src/ui-lib/core/services/actions';

export function capitalizeLetter(str: any) {
	if (!Pan.isDefined(str)) {
		return '';
	}

	str = str.replace(/-/g, ' ');
	str = str.split(' ');

	for (let i = 0, x = str.length; i < x; i++) {
		if (str[i]) {
			str[i] = str[i][0].toUpperCase() + str[i].substr(1);
		}
	}

	return str.join(' ');
}

export function getFieldLabel(field: FAWKES.FieldType, formData?: any) {
	const { uiHint } = field;
	if (uiHint) {
		const label = Pan.isFunction(uiHint.fieldLabel) ? uiHint.fieldLabel(formData) : uiHint.fieldLabel;
		const hideLabel = uiHint.hideLabel;
		if (hideLabel) {
			return false;
		}
		if (label === '@name') {
			return 'Name';
		}

		if (label !== uiHint.fieldLabelAutoGen) {
			return label;
		}

		if (label) {
			return capitalizeLetter(label);
		}
	}
	return false;
}

export function getTooltip(field: FAWKES.FieldType) {
	const tooltip = field?.uiHint?.tooltip;
	if (!tooltip) return null;
	const id = `tooltip-${field?.attrPath}`;
	return (
		<>
			<i id={id} className='ml-1 far fa-question-circle' />
			<FormFeedbackWidget target={id} feedback={tooltip} placement='right' />
		</>
	);
}

export function getFieldLabelComp(
	field: FAWKES.FieldType,
	name: string,
	isEditorGridColumnEditor?: boolean,
	containerLabelWidthSize?: any,
	customField: any = undefined,
	alignTop = false,
	formData?: any,
) {
	const fieldLabel = customField ? customField : getFieldLabel(field, formData);
	const tooltip = getTooltip(field);
	const showLabelInGridEditor = getPathValue(field, 'uiHint.showLabelInGridEditor');
	const showLabel = fieldLabel && (!isEditorGridColumnEditor || showLabelInGridEditor);
	const labelWidthSize = getPathValue(field, 'uiHint.labelWidthSize');
	const labelClassName = labelWidthSize
		? 'col-' + labelWidthSize
		: containerLabelWidthSize
		? 'col-' + containerLabelWidthSize
		: '';
	const alignTopClass = alignTop ? 'align-self-start ' : '';
	return (
		showLabel && (
			<Label className={'flex-grow-0 flex-shrink-0 col-form-label ' + alignTopClass + labelClassName} for={name}>
				{fieldLabel} {tooltip}
			</Label>
		)
	);
}

export function getFieldWidthClass(field: FAWKES.FieldType, isEditorGridColumnEditor?: boolean) {
	const labelWidthSize = getPathValue(field, 'uiHint.labelWidthSize');
	const fieldWidthSize = getPathValue(field, 'uiHint.fieldWidthSize');
	const colWidth = fieldWidthSize || (labelWidthSize ? 12 - labelWidthSize : 9);
	const fieldClassName = isEditorGridColumnEditor ? 'col-12' : `col-${colWidth}`;
	return fieldClassName;
}

export function getFieldHelpString(field: FAWKES.FieldType, formData: any) {
	const showHelpString = getPathValue(field, 'uiHint.showHelpString');
	const fieldHelpString = Pan.isFunction(showHelpString)
		? showHelpString(field, formData)
		: showHelpString === true
		? getPathValue(field, 'uiHint.helpstring')
		: showHelpString;
	return showHelpString && fieldHelpString;
}

export function getFieldWidthClassForSingleLineLayout(field: FAWKES.FieldType, isEditorGridColumnEditor?: boolean) {
	const labelWidthSize = getPathValue(field, 'uiHint.labelWidthSize');
	const fieldWidthSize = getPathValue(field, 'uiHint.fieldWidthSize');
	let colWidth = fieldWidthSize || (labelWidthSize ? 12 - labelWidthSize : 9);
	colWidth = Math.round(colWidth / 2);
	const fieldClassName = isEditorGridColumnEditor ? 'col-12' : `col-${colWidth}`;
	return fieldClassName;
}

export function emptyTextFormat(field: FAWKES.FieldType) {
	let rv = '';
	const defaultValue = field.defaultValue;
	const minValue = getPathValue(field, 'uiHint.minValue');
	const min = !Pan.isEmpty(minValue) ? minValue : undefined;
	const max = getPathValue(field, 'uiHint.maxValue') || undefined;

	if (Pan.isDefined(defaultValue)) {
		// the ' ' is necessary because combo box emptyText cannot be the same as one of its value.
		rv = defaultValue + ' ';
	}

	if (Pan.isDefined(min) && Pan.isDefined(max)) {
		rv += '[' + min + ' - ' + max + ']';
	} else if (Pan.isDefined(min)) {
		rv += '[>= ' + min + ']';
	} else if (Pan.isDefined(max)) {
		rv += '[<= ' + max + ']';
	}

	return rv;
}

export function getCompletionLoadConfig(field: FAWKES.FieldType) {
	const { uiHint } = field;
	if (uiHint && uiHint.completionConfig) {
		return uiHint.completionConfig;
	}
	return;
}

export function isFieldEndsWithMember(field: FAWKES.FieldType) {
	return field && field.attrPath && field.attrPath.endsWith('.member');
}

export function isFieldEndsWithMemberStar(field: FAWKES.FieldType) {
	return field && field.attrPath && field.attrPath.endsWith('.member.*');
}

export function getMemberCompletionField(field: FAWKES.FieldType, fields: Array<FAWKES.FieldType>) {
	const fieldPath = field.attrPath + '.*';
	const index = fields.findIndex((f) => {
		return f.attrPath === fieldPath;
	});
	return index >= 0 ? fields[index] : null;
}

export function getEnumsFromMemberCompletionField(field: FAWKES.FieldType) {
	let ret = undefined;
	if (isFieldEndsWithMemberStar(field)) {
		const enums = get(field, 'multitypes.enum');
		if (enums) {
			ret = enums.map((val: string[]) => {
				return {
					value: val[0],
					label: val[1],
				};
			});
		}
	}
	return ret;
}

export function getCompletionRecordConfigPathFromComposite(
	field: FAWKES.FieldType,
	compositeRecordConfigPath?: FAWKES.I_OBJECT,
) {
	if (!compositeRecordConfigPath) {
		return;
	}

	if (field && field.name) {
		let name = field.name;
		name = name.replace(/^\$\./, '');
		const key = name.slice(0, name.indexOf('.'));
		if (compositeRecordConfigPath[key]) return compositeRecordConfigPath[key];
	}

	if (field && field.attrPath) {
		let attrPath = field.attrPath;
		attrPath = attrPath.replace(/^\$\./, '');
		const key = attrPath.slice(0, attrPath.indexOf('.'));
		if (compositeRecordConfigPath[key]) return compositeRecordConfigPath[key];
	}
}

export function getCompositeFieldAttrPath(field: FAWKES.FieldType, compositeRecordConfigPath?: FAWKES.I_OBJECT) {
	if (field && field.attrPath && compositeRecordConfigPath) {
		let attrPath = field.attrPath;
		attrPath = attrPath.replace(/^\$\./, '');
		const key = attrPath.slice(0, attrPath.indexOf('.'));
		if (compositeRecordConfigPath[key]) return '$' + attrPath.slice(key.length);
	}
}

export function getLabelWidth(field: FAWKES.FieldType) {
	const { uiHint } = field;
	if (uiHint) {
		const label = uiHint.fieldLabel;
		const labelWidth = uiHint.labelWidth;
		if (label) {
			// default labelWidth to 250px
			return labelWidth || 250;
		}
	}
	return false;
}

/**
 * Return the schema object for itemId
 * @param {Object} schema
 * @param {string} itemId
 */
export function getSchemaNode(schema: FAWKES.I_OBJECT, name: string) {
	const schemaNode = getPathValue(schema, name);
	return schemaNode;
}

/**
 * Return the uiSchema object for itemId
 * @param {Array} fields
 * @param {string} itemId
 */
export function getField(fields: Array<FAWKES.FieldType>, name?: string) {
	const found = fields.find((item) => {
		return item.name === name || item.itemId === name;
	});
	return found || {};
}

/**
 * Return the errors for all leaf nodes
 * @param {Array} fields
 * @param {string} itemId
 */
export function getChildError(fieldNames: any, fields: Array<FAWKES.FieldType>, errors: any) {
	//get all the leaf nodes for the given fieldNames
	//verify if there are any errors for those fields
	const errorMap: FAWKES.I_OBJECT = {},
		fieldErrorMap: FAWKES.I_OBJECT = {};
	const leafNodesMap: any = getLeafNodesMap(fieldNames, fields, true);
	if (leafNodesMap && !Pan.isEmpty(leafNodesMap) && errors && !Pan.isEmpty(errors)) {
		for (const err in errors) {
			if (err) {
				//replace the key in err path
				const str = err.replace(/\[\?\(\@\.[^?]*\)\]/g, '.*');
				errorMap[str] = errors[err][0];
			}
		}
		for (const fieldName of fieldNames) {
			const fieldLeafNodes = leafNodesMap[fieldName];
			if (fieldLeafNodes && !Pan.isEmpty(fieldLeafNodes)) {
				let errorResult = null;
				for (const node of fieldLeafNodes) {
					if (errorMap[node]) {
						//this child has error
						errorResult = errorMap[node];
						break;
					}
				}
				fieldErrorMap[fieldName] = errorResult;
			}
		}
	}
	return fieldErrorMap;
}

/**
 * Recursively merge deeply nested objects, optionally concat array elements
 * @param {Object} obj1
 * @param {Object} obj2
 * @param {boolean} concatArrays
 */
export function mergeObjects(obj1: FAWKES.I_OBJECT, obj2: FAWKES.I_OBJECT, concatArrays = false) {
	// Recursively merge deeply nested objects.
	const acc = Object.assign({}, obj1); // Prevent mutation of source object.
	return Object.keys(obj2).reduce((acc, key) => {
		const left = obj1[key],
			right = obj2[key];
		if (obj1.hasOwnProperty(key) && isObject(right)) {
			acc[key] = mergeObjects(left, right, concatArrays);
		} else if (concatArrays && Array.isArray(left) && Array.isArray(right)) {
			acc[key] = left.concat(right);
		} else {
			acc[key] = right;
		}
		return acc;
	}, acc);
}

/**
 * This function checks if the given thing is an object
 * @param {Object} thing
 */
export function isObject(thing: unknown) {
	return typeof thing === 'object' && thing !== null && !Array.isArray(thing);
}

/**
 * Convert value to number
 * @param {Object} value
 */
export function asNumber(value: any) {
	if (value === '') {
		return undefined;
	}
	if (/\.$/.test(value)) {
		// "3." can't really be considered a number even if it parses in js. The
		// user is most likely entering a float.
		return value;
	}
	if (/\.0$/.test(value)) {
		// we need to return this as a string here, to allow for input like 3.07
		return value;
	}
	const n = Number(value);
	const valid = typeof n === 'number' && !Number.isNaN(n);

	if (/\.\d*0$/.test(value)) {
		// It's a number, that's cool - but we need it as a string so it doesn't screw
		// with the user when entering dollar amounts or other values (such as those with
		// specific precision or number of significant digits)
		return value;
	}

	return valid ? n : value;
}

function isArguments(object: unknown) {
	return Object.prototype.toString.call(object) === '[object Arguments]';
}

export function deepEquals(a: any, b: any, ca: any[] = [], cb: any[] = []): boolean {
	// Partially extracted from node-deeper and adapted to exclude comparison
	// checks for functions.
	// https://github.com/othiym23/node-deeper
	if (a === b) {
		return true;
	} else if (typeof a === 'function' || typeof b === 'function') {
		// Assume all functions are equivalent
		// see https://github.com/mozilla-services/react-jsonschema-form/issues/255
		return true;
	} else if (typeof a !== 'object' || typeof b !== 'object') {
		return false;
	} else if (a === null || b === null) {
		return false;
	} else if (a instanceof Date && b instanceof Date) {
		return a.getTime() === b.getTime();
	} else if (a instanceof RegExp && b instanceof RegExp) {
		return (
			a.source === b.source &&
			a.global === b.global &&
			a.multiline === b.multiline &&
			a.lastIndex === b.lastIndex &&
			a.ignoreCase === b.ignoreCase
		);
	} else if (isArguments(a) || isArguments(b)) {
		if (!(isArguments(a) && isArguments(b))) {
			return false;
		}
		const slice = Array.prototype.slice;
		return deepEquals(slice.call(a), slice.call(b), ca, cb);
	} else {
		if (a.constructor !== b.constructor) {
			return false;
		}

		const ka = Object.keys(a);
		const kb = Object.keys(b);
		// don't bother with stack acrobatics if there's nothing there
		if (ka.length === 0 && kb.length === 0) {
			return true;
		}
		if (ka.length !== kb.length) {
			return false;
		}

		let cal = ca.length;
		while (cal--) {
			if (ca[cal] === a) {
				return cb[cal] === b;
			}
		}
		ca.push(a);
		cb.push(b);

		ka.sort();
		kb.sort();
		for (let j = ka.length - 1; j >= 0; j--) {
			if (ka[j] !== kb[j]) {
				return false;
			}
		}

		let key;
		for (let k = ka.length - 1; k >= 0; k--) {
			key = ka[k];
			if (!deepEquals(a[key], b[key], ca, cb)) {
				return false;
			}
		}

		ca.pop();
		cb.pop();

		return true;
	}
}

export function shouldRender(comp: { props: any; state: any }, nextProps: any, nextState: any) {
	const { props, state } = comp;
	return !deepEquals(props, nextProps) || !deepEquals(state, nextState);
}

export function pad(num: any, size: number) {
	let s = String(num);
	while (s.length < size) {
		s = '0' + s;
	}
	return s;
}

export function setState(instance: any, state: any, callback: any) {
	const { safeRenderCompletion } = instance.props;
	if (safeRenderCompletion) {
		instance.setState(state, callback);
	} else {
		instance.setState(state);
		setImmediate(callback);
	}
}

export function dataURItoBlob(dataURI: string) {
	// Split metadata from data
	const splitted = dataURI.split(',');
	// Split params
	const params = splitted[0].split(';');
	// Get mime-type from params
	const type = params[0].replace('data:', '');
	// Filter the name property from params
	const properties = params.filter((param) => {
		return param.split('=')[0] === 'name';
	});
	// Look for the name and use unknown if no name property.
	let name;
	if (properties.length !== 1) {
		name = 'unknown';
	} else {
		// Because we filtered out the other property,
		// we only have the name case here.
		name = properties[0].split('=')[1];
	}

	// Built the Uint8Array Blob parameter from the base64 string.
	const binary = atob(splitted[1]);
	const array = [];
	for (let i = 0; i < binary.length; i++) {
		array.push(binary.charCodeAt(i));
	}
	// Create the blob object
	const blob = new window.Blob([new Uint8Array(array)], { type });

	return { blob, name };
}

/**
 * This function gives all the leaf nodes  map for each of the given fieldNames using fieldsMap
 */
export function getLeafNodes(fieldNames: any, fieldsMap: FAWKES.I_OBJECT, includeArray: any) {
	const resultMap: FAWKES.I_OBJECT = {};

	for (const fieldName of fieldNames) {
		const result: any[] = [];
		getLeafNodeFromMap(fieldName, fieldsMap, result, includeArray);
		resultMap[fieldName] = result;
	}
	return resultMap;
}

/**
 * This function gives all the leaf nodes map for each of the given fieldNames using fieldsList
 */
export function getLeafNodesMap(fieldNames: any, fieldsList: any[], includeArray: any) {
	const fieldsMap: FAWKES.I_OBJECT = {};
	fieldsList.forEach((item) => {
		fieldsMap[item.attrPath || item.name] = item;
	});
	return getLeafNodes(fieldNames, fieldsMap, includeArray);
}

/**
 * This function gives list of leaf nodes for the given fieldName using fieldsMap
 */
export function getLeafNodeFromMap(fieldName: string, fieldsMap: FAWKES.I_OBJECT, result: any[], includeArray: any) {
	if (!result.includes(fieldName)) {
		result.push(fieldName);
	}
	if (fieldsMap && fieldsMap[fieldName] && fieldsMap[fieldName]['childrenNames']) {
		for (const child of fieldsMap[fieldName]['childrenNames']) {
			getLeafNodeFromMap(child, fieldsMap, result, includeArray);
		}
	}
}

/**
 * This function gives all the leaf nodes (first child for choice) map for each of the given fieldNames using fieldsList
 */
export function getLeafNodesMapForDefaults(fieldNames: string[], fieldsList: any[], parentAllowBlank: any) {
	const fieldsMap: FAWKES.I_OBJECT = {};
	fieldsList.forEach((item) => {
		if (item.mapping) {
			fieldsMap[item.name] = item;
		} else {
			fieldsMap[item.attrPath || item.name] = item;
		}
	});
	return getLeafNodesForDefaults(fieldNames, fieldsMap, parentAllowBlank);
}

/**
 * This function gives all the leaf nodes(first child for choice)  map for each of the given fieldNames using fieldsMap
 */
export function getLeafNodesForDefaults(fieldNames: string[], fieldsMap: FAWKES.I_OBJECT, parentAllowBlank: any) {
	const resultMap: FAWKES.I_OBJECT = {};

	for (const fieldName of fieldNames) {
		const result: any[] = [];
		getLeafNodeFromMapForDefaults(fieldName, fieldsMap, result, parentAllowBlank);
		resultMap[fieldName] = result;
	}
	return resultMap;
}

export function getLeafNodeFromMapForDefaults(
	fieldName: string,
	fieldsMap: FAWKES.I_OBJECT,
	result: any[],
	parentAllowBlank?: any,
) {
	const field = fieldsMap[fieldName];
	if (
		field &&
		field['childrenNames'] &&
		(!field.attrPath || !field.attrPath.endsWith('.entry')) &&
		(!field.uiHint.allowBlank || field.uiHint.fieldRequired || parentAllowBlank)
	) {
		let children = [];
		if (field.type === 'choice' || field.type === 'union') {
			if (field.defaultValue || field.uiHint.fieldRequiredValue) {
				//set default value
				const attrPath = field.choiceParentAttr ? field.choiceParentAttr.attrPath : field.attrPath;
				const childValue = attrPath + '.' + (field.defaultValue || field.uiHint.fieldRequiredValue);
				if (field['childrenNames'].indexOf(childValue) >= 0) {
					children.push(childValue);
				} else {
					//fallback on first child
					children.push(field['childrenNames'][0]);
				}
			} else {
				//set first child as default value
				children.push(field['childrenNames'][0]);
			}
		} else if (field.uiHint.checkboxToggle !== true) {
			//FieldSetBuilder with checkbox
			children = field['childrenNames'];
		}
		for (const child of children) {
			getLeafNodeFromMapForDefaults(child, fieldsMap, result);
		}
	} else if (
		field &&
		(!field.uiHint.allowBlank || field.uiHint.fieldRequired) &&
		(!field.attrPath || !field.attrPath.endsWith('.entry') || field.uiHint.fieldRequired)
	) {
		result.push(fieldName);
	}
}

export function getCompletionXpath(fieldAttrPath: string, filters: any, formData = {}, recordConfigPath: string) {
	// let {field, filters} = props;
	// let {formData, recordConfigPath} = context;
	let completionXpath = '';
	//go over the field attrPath and replace any entry.* with entry[@name] using the form data and filters
	const completionPath = fieldAttrPath;
	if (completionPath) {
		let currentpath = '';
		const attrPathSplit = completionPath.split('.entry.*.');
		for (let index = 0; index < attrPathSplit.length; index++) {
			currentpath += attrPathSplit[index];
			completionXpath += attrPathSplit[index];
			if (index !== attrPathSplit.length - 1) {
				currentpath += '.entry.*';
				const pathObj = getPathValue(formData, currentpath, filters);
				if (pathObj && pathObj['@name']) {
					//object name exist. Append name
					let objName = pathObj['@name'];
					//we want to preserve dot in object names. We will replace this with . when we convert to exact xpath
					objName = objName.replace(/\./g, '%dot%');
					completionXpath += ".entry[@name='" + escape(objName) + "'].";
				} else {
					//append noname
					completionXpath += ".entry[@name='__noname__'].";
				}
				currentpath += '.';
			}
		}
	}
	let recName = getPathValue(formData, '$.@name') || '__noname__';
	recName = `${recName}`.replace(/\./g, '%dot%');
	completionXpath = completionXpath.replace(/^\$\./, '');
	completionXpath = recordConfigPath.endsWith('.entry')
		? recordConfigPath + "[@name='" + escape(recName) + "']." + completionXpath
		: recordConfigPath + '.' + completionXpath;
	completionXpath = completionXpath.replace(/\.\*$/, '');
	return completionXpath;
}
export function getWhereUsedXpath(recordConfigPath: string, location: any) {
	let xpath = recordConfigPath.replace(/\./g, '/');
	xpath = xpath.replace(/%dot%/g, '.');
	xpath = xpath.replace(/\/config\/devices\/entry/g, "/config/devices/entry[@name='localhost.localdomain']");
	xpath = xpath.replace(/\/devices\/entry\//g, "/devices/entry[@name='localhost.localdomain']/");
	xpath = xpath.replace('vsys/entry', `vsys/entry[@name='${location.vsys}']`);

	// container
	if (location) {
		if (location?.loc === 'predefined' || location?.loc === 'predefined-snippet') {
			xpath = xpath.replace('snippet/entry', "snippet/entry[@name='predefined-snippet']/predefined");
			xpath = xpath.replace('device/cloud/entry', "snippet/entry[@name='predefined-snippet']/predefined");
			xpath = xpath.replace('device/on-prem/entry', "snippet/entry[@name='predefined-snippet']/predefined");
			xpath = xpath.replace('container/entry', "snippet/entry[@name='predefined-snippet']/predefined");
		} else if (location.type) {
			const map: any = {
				container: `container/entry[@name='${location.loc}']`,
				cloud: `device/cloud/entry[@name='${location.loc}']`,
				'on-prem': `device/on-prem/entry[@name='${location.loc}']`,
				snippet: `snippet/entry[@name='${location.loc}']`,
			};
			const replaceString = map[location.type];
			if (replaceString) {
				if (xpath.includes('container/entry')) {
					xpath = xpath.replace(`container/entry`, replaceString);
				} else if (xpath.includes('device/cloud/entry')) {
					xpath = xpath.replace(`device/cloud/entry`, replaceString);
				} else if (xpath.includes('device/on-prem/entry')) {
					xpath = xpath.replace(`device/on-prem/entry`, replaceString);
				} else if (xpath.includes('snippet/entry')) {
					xpath = xpath.replace(`snippet/entry`, replaceString);
				}
			}
		} else {
			if (location.loc === PRISMA_ACCESS_CONTAINER || location.loc === MU_CONTAINER) {
				xpath = xpath.replace('device/cloud', 'container');
			}
			xpath = xpath.replace(`container/entry`, `container/entry[@name='${location.loc}']`);
			xpath = xpath.replace(`cloud/entry`, `cloud/entry[@name='${location.loc}']`);
			xpath = xpath.replace(`on-prem/entry`, `on-prem/entry[@name='${location.loc}']`);
			xpath = xpath.replace(`snippet/entry`, `snippet/entry[@name='${location.loc}']`);
		}
	}

	xpath = xpath.replace(/\/@name$/g, '');
	xpath = xpath.replace(/\/entry$/g, '');
	xpath = xpath.replace(/\/entry\[@name='[ 0-9a-zA-Z.,_-]*'\]$/, '');
	xpath = xpath.replace(/\/choice/g, '');
	xpath = xpath.startsWith('$/') ? xpath.slice(1) : xpath;
	return xpath;
}

export function getFieldXpath(fieldpath: string, location: any) {
	const managedDeviceOverridePath = [
		'/entry/variable/',
		'/entry/object-variable/interface/',
		"/entry/devices/entry[@name='localhost.localdomain']/network/interface/",
	];
	let xpath = fieldpath.replace(/\./g, '/');
	xpath = xpath.replace(/%dot%/g, '.');
	xpath = xpath.replace(/\/config\/devices\/entry/g, "/config/devices/entry[@name='localhost.localdomain']");
	xpath = xpath.replace(/\/devices\/entry\//g, "/devices/entry[@name='localhost.localdomain']/");

	// container
	if (location && location[CONTAINER]) {
		const locationType = (location && location[CONTAINER] && location[CONTAINER].type) || CONTAINER;
		const locationName =
			location && location[CONTAINER] && location[CONTAINER].name ? location[CONTAINER].name : '';
		if (
			locationType === CONTAINER_ONPREM &&
			(xpath.includes(managedDeviceOverridePath[0]) ||
				xpath.includes(managedDeviceOverridePath[1]) ||
				xpath.includes(managedDeviceOverridePath[2]))
		) {
			if (xpath.includes('managed-devices/entry')) {
				xpath = xpath.replace(`managed-devices/entry`, `managed-devices/entry[@name='${locationName}']`);
			} else {
				xpath = xpath.replace(`device/${locationType}/entry`, `managed-devices/entry[@name='${locationName}']`);
			}
			xpath = xpath.replace(`device/${locationType}/entry`, `managed-devices/entry[@name='${locationName}']`);
		} else {
			xpath = xpath.replace(`${locationType}/entry`, `${locationType}/entry[@name='${locationName}']`);
		}
	}

	//on-prem

	if (location && location.container && location.container[CONTAINER_ONPREM]) {
		const locationType = location[CONTAINER]?.type;
		const locationName = location[CONTAINER]?.name;
		if (
			xpath.includes(managedDeviceOverridePath[0]) ||
			xpath.includes(managedDeviceOverridePath[1]) ||
			xpath.includes(managedDeviceOverridePath[2])
		) {
			if (xpath.includes('managed-devices/entry')) {
				xpath = xpath.replace(`managed-devices/entry`, `managed-devices/entry[@name='${locationName}']`);
			} else {
				xpath = xpath.replace(`device/${locationType}/entry`, `managed-devices/entry[@name='${locationName}']`);
			}
		} else {
			xpath = xpath.replace(`${locationType}/entry`, `${locationType}/entry[@name='${locationName}']`);
		}
	}

	if (location && location.vsys) {
		xpath = xpath.replace(/\/vsys\/entry\//g, `/vsys/entry[@name='${location.vsys}']/`);
	}

	xpath = xpath.replace(/\/@name$/g, '');
	xpath = xpath.replace(/\/entry$/g, '');
	xpath = xpath.replace(/\/entry\[@name='[ 0-9a-zA-Z.,_-]*'\]$/, '');
	xpath = xpath.replace(/\/choice/g, '');
	xpath = xpath.startsWith('$/') ? xpath.slice(1) : xpath;
	return xpath;
}
export function trimErrorDetailmsg(msg = '') {
	const regexSwg = new RegExp(
		'(device -> cloud -> )|(devices -> localhost.localdomain -> )|(enforcement.*?-> )',
		'g',
	);
	const regex = new RegExp(
		'(container -> )|(device -> cloud -> )|(devices -> localhost.localdomain -> )|(enforcement.*?-> )',
		'g',
	);
	return msg.replace(msg.includes('swg-container') ? regexSwg : regex, '');
}

export function parseServerError(title: string, serverErrorMessage: FAWKES.I_OBJECT) {
	const msgBody = [];
	let key = 1;

	if (serverErrorMessage.productizedError) {
		msgBody.push(<div key={key++}>{serverErrorMessage.productizedError.errorMessage}</div>);
		if (serverErrorMessage.productizedError.errorDetails) {
			msgBody.push(<div key={key++}>{_T('Detail error(s):')}</div>);
			const msgRes = (
				<div key={key++}>
					<ul>
						{serverErrorMessage.productizedError.errorDetails.map((errorMessage: any) => (
							<li>{errorMessage}</li>
						))}
					</ul>
				</div>
			);
			msgBody.push(msgRes);
		}

		return { title: serverErrorMessage.title, msgBody };
	}

	if (serverErrorMessage.newErrorMsg) {
		serverErrorMessage.newErrorMsg.forEach((msg: string) => {
			msgBody.push(<div key={key++}>{msg}</div>);
		});
	} else {
		if (serverErrorMessage.errMsgArr && Pan.isArray(serverErrorMessage.errMsgArr)) {
			const msgRes = (
				<div key={key++}>
					<ul>
						{serverErrorMessage.errMsgArr.map((errorMessage: any) => {
							return (
								<>
									<li>{errorMessage}</li>
								</>
							);
						})}
					</ul>
				</div>
			);
			msgBody.push(msgRes);
		} else {
			msgBody.push(<div key={key++}>{serverErrorMessage.msg}</div>);
		}
	}
	const detailMsg = get(serverErrorMessage, 'extra.message');
	const hasExtraError = Array.isArray(_.get(serverErrorMessage, 'extra.errors'));
	const nonZeroRefs = hasExtraError
		? _.get(serverErrorMessage, 'extra.errors')?.filter((err: any) => err['@type'] === 'NON_ZERO_REFS')
		: null;
	const extraErrors = _.get(nonZeroRefs?.[0], 'references.entry');
	if (extraErrors) {
		msgBody.push(<div key={key++}>{_T('Detail error(s):')}</div>);
		if (nonZeroRefs) msgBody.push(<div key={key++}>{nonZeroRefs?.[0]?.msg}</div>);
		each(extraErrors, (ref) => {
			msgBody.push(<div key={key++}>&nbsp;&nbsp;&nbsp;&nbsp;{trimErrorDetailmsg(ref?.['prefix'])}</div>);
		});
	} else {
		if (detailMsg) {
			msgBody.push(<div key={key++}>{_T('Detail error(s):')}</div>);
			if (isString(detailMsg)) {
				msgBody.push(<div key={key++}>&nbsp;&nbsp;&nbsp;&nbsp;{trimErrorDetailmsg(detailMsg)}</div>);
			} else if (isArray(detailMsg)) {
				each(detailMsg, (msg) => {
					msgBody.push(<div key={key++}>&nbsp;&nbsp;&nbsp;&nbsp;{trimErrorDetailmsg(msg)}</div>);
				});
			} else {
				msgBody.push(
					<div key={key++}>&nbsp;&nbsp;&nbsp;&nbsp;{trimErrorDetailmsg(JSON.stringify(detailMsg))}</div>,
				);
			}
		}
	}
	if (get(serverErrorMessage, 'extra.errorType')) {
		title += ` - ${serverErrorMessage.extra.errorType}`;
	}
	if (serverErrorMessage.title) {
		title = _T(`Error - {serverErrorMessage}`, { serverErrorMessage: serverErrorMessage.title });
	}
	return { title, msgBody };
}

export function getFieldValueFromType(field: FAWKES.FieldType) {
	const stringTypes = [
		'string',
		'auto',
		'date',
		'multiple',
		'password',
		'ipspec',
		'iprangespec',
		'mac',
		'rangelistspec',
		'enum',
	];
	const boolTypes = ['bool', 'reverseBool'];
	const fieldType = field.type;
	if (stringTypes.indexOf(fieldType) >= 0) {
		return '';
	} else if (boolTypes.indexOf(fieldType) >= 0) {
		return false;
	}
	return {};
}

export function xmlStringToDocument(str: unknown) {
	if (!isString(str)) {
		return null;
	}
	return new DOMParser().parseFromString(str, 'text/xml');
}

export function xmlToJson(xml: any) {
	// Create the return object
	let obj: FAWKES.I_OBJECT = {};
	if (xml.nodeType === 1) {
		// element
		// do attributes
		if (xml.attributes.length > 0) {
			obj['@attributes'] = {};
			for (let j = 0; j < xml.attributes.length; j++) {
				const attribute = xml.attributes.item(j);
				obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
			}
		}
	} else if (xml.nodeType === 3) {
		// text
		obj = xml.nodeValue;
	}

	// do children
	// If just one text node inside
	if (xml.hasChildNodes() && xml.childNodes.length === 1 && xml.childNodes[0].nodeType === 3) {
		obj = xml.childNodes[0].nodeValue;
	} else if (xml.hasChildNodes()) {
		for (let i = 0; i < xml.childNodes.length; i++) {
			const item = xml.childNodes.item(i);
			const nodeName = item.nodeName;
			if (typeof obj[nodeName] == 'undefined') {
				obj[nodeName] = xmlToJson(item);
			} else {
				if (typeof obj[nodeName].push == 'undefined') {
					const old = obj[nodeName];
					obj[nodeName] = [];
					obj[nodeName].push(old);
				}
				obj[nodeName].push(xmlToJson(item));
			}
		}
	}
	return obj;
}

export function getFieldErrors(field: FAWKES.FieldType, filters: any, formData: any, errors: any, CURRENT_KEY: any) {
	//check if any child has errors
	const recPath = getRecordPathWithFilters(formData, field.attrPath, filters, CURRENT_KEY);
	for (const key in errors) {
		if (key.indexOf(recPath) === 0) {
			//child has error
			return { [recPath]: errors[key] };
		}
	}
}

const CONTAINER_PREFIX = '$.config.devices.entry.container.entry';
const CLOUD_PREFIX = '$.config.devices.entry.device.cloud.entry';
const ONPREM_PREFIX = '$.config.devices.entry.device.on-prem.entry';
const SNIPPET_PREFIX = '$.config.devices.entry.snippet.entry';

export const RECORD_CONFIG_PATH_PREFIX_MAP: any = {
	[CONTAINER]: CONTAINER_PREFIX,
	[CONTAINER_CLOUD]: CLOUD_PREFIX,
	[CONTAINER_ONPREM]: ONPREM_PREFIX,
	[SNIPPET]: SNIPPET_PREFIX,
};

/**
 * Use this function to get recordConfig paths for container, cloud, on-prem by giving subpath after the prefix
 * @param {*} path
 */
export const getRecordConfigPathMap = (path: string) => {
	return {
		[CONTAINER]: `${CONTAINER_PREFIX}.${path}`,
		[CONTAINER_CLOUD]: `${CLOUD_PREFIX}.${path}`,
		[CONTAINER_ONPREM]: `${ONPREM_PREFIX}.${path}`,
		[SNIPPET]: `${SNIPPET_PREFIX}.${path}`,
	};
};

export const getPluginConfigPath = (path: string) => {
	return '$.config.devices.entry.plugins.' + path;
};

export const getContainerConfigPath = (path: string) => {
	return getRecordConfigPathMap(path)[CONTAINER];
};

export const getCloudConfigPath = (path: string) => {
	return getRecordConfigPathMap(path)[CONTAINER_CLOUD];
};

export const getOnpremConfigPath = (path: string) => {
	return getRecordConfigPathMap(path)[CONTAINER_ONPREM];
};

export const getDevicesPrefix = (prefix: string) => {
	const devices = "devices/entry[@name='localhost.localdomain']/";
	return devices + prefix;
};

export const getVsysPrefix = (prefix: string) => {
	const vsys = "devices/entry[@name='localhost.localdomain']/container/entry[@name='Prisma Access']/";
	return prefix;
};

export const getNetworkPrefix = (prefix: string) => {
	const network = "devices/entry[@name='localhost.localdomain']/network/";
	return network + prefix;
};

export const getDeviceConfigPrefix = (prefix: string) => {
	const deviceConfig = "devices/entry[@name='localhost.localdomain']/deviceconfig/";
	return deviceConfig + prefix;
};

export const getRecordConfigPathFromConfigLocation = (
	recordConfigPath: any,
	configLocation: FAWKES.configLocation | undefined,
) => {
	let type = (configLocation && configLocation[CONTAINER] && configLocation[CONTAINER].type) || CONTAINER;
	if (type === SWG_CONTAINER_TYPE) {
		type = CONTAINER;
	} else if (type === SNIPPET) {
		type = SNIPPET;
	}
	const replacement =
		type === CONTAINER
			? `.${CONTAINER}.`
			: type === CONTAINER_CLOUD
			? `.device.${CONTAINER_CLOUD}.`
			: type === CONTAINER_ONPREM
			? `.device.${CONTAINER_ONPREM}.`
			: type === SNIPPET
			? `.${SNIPPET}.`
			: `.${CONTAINER}.`;
	return recordConfigPath && recordConfigPath[type]
		? recordConfigPath[type]
		: recordConfigPath && String(recordConfigPath).indexOf(CONTAINER) > 0
		? recordConfigPath.replace(`.${CONTAINER}.`, replacement)
		: null;
};

export const getCompositeRecordConfigPathFromConfigLocation = (
	compositeRecordConfigPath: any[],
	configLocation: FAWKES.configLocation,
) => {
	const result: FAWKES.I_OBJECT = {};
	_.forEach(compositeRecordConfigPath, (val, key) => {
		if (val && typeof val === 'object') {
			result[key] = getRecordConfigPathFromConfigLocation(val, configLocation);
		} else if (val && typeof val === 'string') {
			result[key] = val;
		}
	});
	return result;
};

export const showWarningModal = (message: string, cb?: FAWKES.func, cancelCb?: FAWKES.func, cancelable?: boolean) => {
	const modalId = Pan.id();
	const actions = [];
	if (cb) {
		actions.push({
			text: _T('Yes'),
			action: () => {
				hideModal(modalId);
				cb();
			},
		});
	}
	if (cancelable) {
		actions.push({
			text: _T('No'),
			color: 'secondary',
			action: () => {
				cancelCb?.();
				hideModal(modalId);
			},
		});
	}
	const modal = {
		id: modalId,
		title: _T('Warning'),
		type: 'Warning',
		toggle: () => {
			hideModal(modalId);
		},
		message,
		actions,
	};
	return showModal(modal);
};

export const getVariableInUsedMessage = (cluster: string) =>
	_T(`Interface variable is used in VPN Cluster-{cluster}, Do you want to continue?`, { cluster: cluster });
