/* eslint-disable import/no-duplicates */
import { _T } from 'src/utils/i18n-utils';
import { history, dispatch as sparkyDispatch } from 'sparky-framework';
import _, { isArray } from 'lodash';
import { FAWKES } from 'src/typings/type';
import { getStoreState } from 'ui-lib/core/utils/storeRegistry';
import { MU_GP_CONTAINER, RN_CONTAINER, SC_CONTAINER, SNIPPET } from 'service-lib/services/constants';
import moment from 'moment';
import LightNoData from 'ui-lib/images/light_no_data_icon.svg';
import DarkNoData from 'ui-lib/images/dark_no_data_icon.svg';
import LightGlobeSrc from 'src/images/policy-form/globe-source-icon.svg';
import DarkGlobeSrc from 'src/images/policy-form/dark-globe-source-icon.svg';
import LightTableIcon from 'src/images/policy-form/table-icon.svg';
import DarkTableIcon from 'src/images/policy-form/dark-table-icon.svg';
import LightShieldIcon from 'src/images/policy-form/shield-globe-icon.svg';
import DarkShieldIcon from 'src/images/policy-form/dark-shield-globe-icon.svg';
import LightPhoneIcon from 'ui-lib/images/phone-icon.svg';
import DarkPhoneIcon from 'ui-lib/images/dark-phone-icon.svg';
import LightAntiSpyware from 'src/images/policy-form/anti-spyware.svg';
import DarkAntiSpyware from 'src/images/policy-form/dark-anti-spyware.svg';
import LightAntivirus from 'src/images/policy-form/antivirus.svg';
import DarkAntivirus from 'src/images/policy-form/dark-antivirus.svg';
import LightDnsSecurity from 'src/images/policy-form/dns-security.svg';
import DarkDnsSecurity from 'src/images/policy-form/dark-dns-security.svg';
import LightFileBlocking from 'src/images/policy-form/file-blocking.svg';
import DarkFileBlocking from 'src/images/policy-form/dark-file-blocking.svg';
import LightUrlFiltering from 'src/images/policy-form/url-filtering.svg';
import DarkUrlFiltering from 'src/images/policy-form/dark-url-filtering.svg';
import LightVulnerabilityProtection from 'src/images/policy-form/vulnerability-protection.svg';
import DarkVulnerabilityProtection from 'src/images/policy-form/dark-vulnerability-protection.svg';
import LightHTTPHeaderInsertion from 'src/images/policy-form/http-header-insertion.svg';
import DarkHTTPHeaderInsertion from 'src/images/policy-form/dark-http-header-insertion.svg';
import LightAiSecurity from 'src/images/policy-form/ai-security.svg';
import DarkAiSecurity from 'src/images/policy-form/dark-ai-security.svg';
import LightDataLossPrevention from 'src/images/policy-form/data-loss-prevention.svg';
import DarkDataLossPrevention from 'src/images/policy-form/dark-data-loss-prevention.svg';
import LightDevicesIcon from 'ui-lib/images/devices-icon.svg';
import DarkDevicesIcon from 'ui-lib/images/dark-devices-icon.svg';
import lightYellowAlertIcon from 'ui-lib/images/light_yellow_alert_icon.svg';
import lightRedAlertIcon from 'ui-lib/images/light_red_alert_icon.svg';
import lightBlueInfoIcon from 'ui-lib/images/light_blue_info_icon.svg';
import LightDeviceIcon from 'ui-lib/images/device-icon2.svg';
import DarkDeviceIcon from 'ui-lib/images/dark-device-icon2.svg';
import LightByFunctionTemplate from 'src/images/discovery/by_function.svg';
import DarkByFunctionTemplate from 'src/images/discovery/by_function_dark.svg';
import LightByRegionTemplate from 'src/images/discovery/by_region.svg';
import DarkByRegionTemplate from 'src/images/discovery/by_region_dark.svg';
import LightFunctionRegionMixedTemplate from 'src/images/discovery/by_function_and_region.svg';
import DarkFunctionRegionMixedTemplate from 'src/images/discovery/by_function_and_region_dark.svg';
import LightCustomTemplate from 'src/images/discovery/custom.svg';
import DarkCustomTemplate from 'src/images/discovery/custom_dark.svg';
import LightCloudOffIcon from 'src/images/discovery/cloud_off.svg';
import DarkCloudOffIcon from 'src/images/discovery/cloud_off_dark.svg';
import LightSelectedFolderIcon from 'src/images/discovery/SelectedFolder.svg';
import DarkSelectedFolderIcon from 'src/images/discovery/SelectedFolderDark.svg';
import LightPolicyIcon from 'src/images/discovery/policy.svg';
import DarkPolicyIcon from 'src/images/discovery/policy_dark.svg';
import LightConfigurationIcon from 'src/images/discovery/configuration.svg';
import DarkConfigurationIcon from 'src/images/discovery/configuration_dark.svg';
import LightFolderCreationGif from 'src/images/discovery/folder_creation_tip.gif';
import DarkFolderCreationGif from 'src/images/discovery/folder_creation_tip_dark.gif';
import LightFolderCreation from 'src/images/discovery/folder_creation_tip.png';
import DarkFolderCreation from 'src/images/discovery/folder_creation_tip_dark.png';
import LightSelectFirewallsGif from 'src/images/discovery/select_firewalls_tip.gif';
import DarkSelectFirewallsGif from 'src/images/discovery/select_firewalls_tip_dark.gif';
import LightSelectFirewalls from 'src/images/discovery/select_firewalls_tip.png';
import DarkSelectFirewalls from 'src/images/discovery/select_firewalls_tip_dark.png';
import LightCyanSquare from 'ui-lib/images/square-icon-cyan.svg';
import LightSkyBlueSquare from 'ui-lib/images/square-icon-sky-blue.svg';
import LightIpOptimization from 'ui-lib/images/ip-optimization.svg';
import DarkIpOptimization from 'ui-lib/images/ip-optimization.svg';
import { isPBAEnabled } from 'service-lib/services/licenseManager';
import BlueDotDark from 'src/ui-lib/images/blue-dot-dark.svg';
import BlueDotLight from 'src/ui-lib/images/blue-dot-light.svg';
import GreyDotDark from 'src/ui-lib/images/grey-dot-dark.svg';
import GreyDotLight from 'src/ui-lib/images/grey-dot-light.svg';
import PurpleDot from 'src/ui-lib/images/purple-dot.svg';
import { isDisabledForDeviceWithoutDeviceContainer } from 'src/ui-lib/utils';
import { checkIsFeatureEnabled } from 'src/utils/featureFlagUtils';
import { isObjectLocal } from 'src/containers/localConfig/utils/utils';
import LightShieldURL from 'src/ui-lib/images/shield-url-light.svg';
import DarkShieldURL from 'src/ui-lib/images/shield-url-dark.svg';
import LightCorrectIcon from 'src/ui-lib/images/correct-light.svg';
import DarkCorrectIcon from 'src/ui-lib/images/correct-dark.svg';
import DarkCaretRight from 'src/ui-lib/images/arrow-right-dark.svg';
import DarkCaretDown from 'src/ui-lib/images/arrow-down-dark.svg';
import LightCaretRight from 'src/ui-lib/images/arrow-right-light.svg';
import LightCaretDown from 'src/ui-lib/images/arrow-down-light.svg';
import DarkClipboardCheckList from 'src/ui-lib/images/clipboard-list-check-dark.svg';
import LightClipboardCheckList from 'src/ui-lib/images/clipboard-list-check-light.svg';
import LightHintIcon from 'src/ui-lib/images/hint-light.svg';
import DarkHintIcon from 'src/ui-lib/images/hint-dark.svg';
import DarkGearIcon from 'src/ui-lib/images/gear-dark.svg';
import LightGearIcon from 'src/ui-lib/images/gear-light.svg';
import DarkComputerIcon from 'src/ui-lib/images/computer-dark.svg';
import LightComputerIcon from 'src/ui-lib/images/computer-light.svg';
import LightFPIcon from 'src/ui-lib/images/fp-light.svg';
import DarkFPIcon from 'src/ui-lib/images/fp-dark.svg';
import LightBasicInfo from 'src/ui-lib/images/basic-info-light.svg';
import DarkBasicInfo from 'src/ui-lib/images/basic-info-dark.svg';
import LightPALocation from 'src/ui-lib/images/pa-location-light.svg';
import DarkPALocation from 'src/ui-lib/images/pa-location-dark.svg';
import DeployAgentBigDark from 'src/ui-lib/images/deploy-agent-big-dark.svg';
import DeployAgentBigLight from 'src/ui-lib/images/deploy-agent-big-light.svg';
import DownloadInstallAgentBigDark from 'src/ui-lib/images/download-install-agent-big-dark.svg';
import DownloadInstallAgentBigLight from 'src/ui-lib/images/download-install-agent-big-light.svg';
import PushConfigDark from 'src/ui-lib/images/push-config-dark.svg';
import SelectGPVersionDark from 'src/ui-lib/images/select-gp-version-dark.svg';
import DeployAgentSmallDark from 'src/ui-lib/images/deploy-agent-small-dark.svg';
import BroadcastTowerDark from 'src/ui-lib/images/broadcast-tower-dark.svg';
import UserLockDark from 'src/ui-lib/images/user-lock-dark.svg';
import AuthKeyDark from 'src/ui-lib/images/auth-key-dark.svg';
import PushConfigurationProgressDark from 'src/ui-lib/images/push-configuration-progress-dark.svg';
import PushConfigurationProgressLight from 'src/ui-lib/images/push-configuration-progress-light.svg';
import OnboardUsersDark from 'src/ui-lib/images/onboard-users-dark.svg';
import OnboardPrivateAppsDark from 'src/ui-lib/images/onboard-private-app-dark.svg';
import OnboardRemoteSitesDark from 'src/ui-lib/images/onboard-remote-sites-dark.svg';
import OnboardUsersLight from 'src/ui-lib/images/onboard-users-light.svg';
import OnboardPrivateAppsLight from 'src/ui-lib/images/onboard-private-app-light.svg';
import OnboardRemoteSitesLight from 'src/ui-lib/images/onboard-remote-sites-light.svg';
import AgentBasedAccessBigDark from 'src/ui-lib/images/agent-based-access-big-dark.svg';
import AgentBasedAccessBigLight from 'src/ui-lib/images/agent-based-access-big-light.svg';
import ExplicitProxyBigDark from 'src/ui-lib/images/explicit-proxy-big-dark.svg';
import EnterpriseBrowserBigDark from 'src/ui-lib/images/enterprise-browser-big-dark.svg';
import ExplicitProxyBigLight from 'src/ui-lib/images/explicit-proxy-big-light.svg';
import EnterpriseBrowserBigLight from 'src/ui-lib/images/enterprise-browser-big-light.svg';
import SDWANDataCenterDark from 'src/ui-lib/images/prisma-sd-wan-data-centers-dark.svg';
import SDWANDataCenterLight from 'src/ui-lib/images/prisma-sd-wan-data-centers-light.svg';
import JamfProSmallDark from 'src/ui-lib/images/jamf-pro-small-dark.svg';
import MicrosoftIntuneSmallDark from 'src/ui-lib/images/microsoft-intune-small-dark.svg';
import WorkspaceOneSmallDark from 'src/ui-lib/images/workspace-one-small-dark.svg';
import WebLight from 'src/images/policy-form/web.svg';
import WebDark from 'src/images/policy-form/dark-web.svg';
import DataControlLight from 'src/images/policy-form/data-control.svg';
import DataControlDark from 'src/images/policy-form/dark-data-control.svg';
import UploadLight from 'src/images/policy-form/upload.svg';
import UploadDark from 'src/images/policy-form/dark-upload.svg';
import DownloadLight from 'src/images/policy-form/download.svg';
import DownloadDark from 'src/images/policy-form/dark-download.svg';
import MatchIcon from 'src/images/policy-form/match.svg';
import MatchIconDark from 'src/images/policy-form/dark-match.svg';
import { validationRulesMap } from 'src/ui-lib/core/utils/validationRules';

const validUserPreferenceAttributes = ['insights', 'explore', 'config'];
export const getValidUserPreference = (userPreference: any) => {
	const preference: FAWKES.I_OBJECT = {};
	for (const key of Object.keys(userPreference)) {
		if (_.includes(validUserPreferenceAttributes, key)) {
			preference[key] = userPreference[key];
		}
	}
	return preference;
};

export const navigateToURL = (url: string) => {
	history.push(url);
};

export const arrayToString = (arr: any[]) => {
	if (arr.length === 1) return arr[0];
	const firsts = arr.slice(0, arr.length - 1);
	const last = arr[arr.length - 1];
	return _T('{elements} or {lastElement}', { elements: firsts.join(', '), lastElement: last });
};

export const isJSONString = (str: string) => {
	try {
		const json = JSON.parse(str);
		return typeof json === 'object';
	} catch (e) {
		return false;
	}
};
export const base64ToArrayBuffer = (base64: any) => {
	const binaryString = atob(base64);
	const bytes = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes.buffer;
};

export const refreshGlobalMainState = () => sparkyDispatch({ type: 'set-main-values', values: {} });

export const FAWKES_EVENTS = {
	bpaOpen: 'fawkes_bpa_open',
	bpaClose: 'fawkes_bpa_close',
	coveoOpen: 'coveo_open',
	coveoClose: 'coveo_close',
};

export const dfsHelper = (ele: any, parentArray: any, cb: any, childDevicesCallBack?: any) => {
	const currParentArray = [...parentArray, ele?.name];
	let childDevicesCount = ele?.type === 'on-prem' ? 1 : 0;
	cb(ele, currParentArray);
	if (ele?.children) {
		ele.children.forEach((child: any) => {
			childDevicesCount += dfsHelper(child, currParentArray, cb, childDevicesCallBack);
		});
	}
	_.isFunction(childDevicesCallBack) && childDevicesCallBack(ele, childDevicesCount);
	return childDevicesCount;
};

export const getFolderOptions = (root: any): string[] => {
	const FOLDER_OPTIONS: string[] = [];
	dfsHelper(root, [], (element: any) => {
		FOLDER_OPTIONS.push(element.name);
	});
	return FOLDER_OPTIONS;
};

export const getFolderOptionsWithoutDevice = (root: any): string[] => {
	const FOLDER_OPTIONS: string[] = [];
	dfsHelper(root, [], (element: any) => {
		if (element.type !== 'on-prem') {
			FOLDER_OPTIONS.push(element.name);
		}
	});
	return FOLDER_OPTIONS;
};

export const isFromSnippetScope = (record: any) => {
	if (record) {
		if (
			record.snippet ||
			record['snippet_ref'] === 'yes' ||
			record['@snippet-ref'] === 'yes' ||
			record['@local-ref'] === 'yes' ||
			record['@snippet-loc'] ||
			record['snippet_location'] ||
			record['@type'] === 'snippet'
		) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const isFromSameScope = (record: FAWKES.IFormData, configLocation: FAWKES.configLocation) => {
	const { container: { name: configLocationName = '', type: configLocationType = '' } = {} }: any = configLocation;
	const { '@loc': loc, folder: folderLoc, device, snippet: snippetLoc } = record;

	const recordLoc = loc || folderLoc || device;

	if (recordLoc && configLocationName) {
		if (configLocationName === recordLoc) {
			if (isFromSnippetScope(record) && configLocationType !== 'snippet') {
				return false;
			}
			return true;
		}
		return false;
	} else if (!recordLoc && configLocationName && configLocationType === 'snippet') {
		if (snippetLoc) {
			return configLocationName === snippetLoc;
		}
		return true;
	} else {
		return false;
	}
};

export const hasScope = (record: any) => {
	const loc =
		_.get(record, '@loc') || _.get(record, 'folder') || _.get(record, 'device') || _.get(record, 'location');
	const snippet = _.get(record, '@snippet-loc') || _.get(record, 'snippet') || _.get(record, 'snippet_location');
	return record && (loc || snippet);
};

export const isFromSnippetScopeWithoutSnippetLocationInfo = (record: any) => {
	if (record) {
		if (
			record.snippet ||
			record['snippet_ref'] === 'yes' ||
			record['@snippet-ref'] === 'yes' ||
			record['@snippet-loc'] ||
			record['snippet_location']
		) {
			return Boolean(!record['@snippet-loc'] && !record['snippet_location'] && !record.snippet);
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const isActionDisabledForSnippet = (selected: any) => {
	const { main: { configLocation: { container: { type = '' } = {} } = {} } = {} } = getStoreState();

	if (type === SNIPPET) return false;

	const isDisabled = Object.keys(selected).reduce((result: any, key: any): any => {
		return isFromSnippetScope(selected[key]) || result;
	}, false);

	return isDisabled;
};

export const isCloneActionDisabledForSnippet = (selected: any) => {
	const { main: { configLocation: { container: { type = '' } = {} } = {} } = {} } = getStoreState();

	if (type === SNIPPET) return false;

	const isDisabled = Object.keys(selected).reduce((result: any, key: any): any => {
		return isFromSnippetScopeWithoutSnippetLocationInfo(selected[key]) || result;
	}, false);

	return isDisabled;
};

export const preProcessAutoGenerateData = (gridData: any[] = [], useProductizedApi = true) => {
	if (_.isEmpty(gridData)) {
		return [];
	}
	const hasAutoGeneratedVPN = gridData?.find(
		(data: any) => data?.['autogenerated'] === 'autovpn' || data?.['@autogenerated'] === 'autovpn',
	);
	if (!hasAutoGeneratedVPN) return gridData;
	gridData?.forEach((data: any) => {
		if (useProductizedApi) {
			if (data?.['autogenerated'] === 'autovpn') {
				data['autogenerated'] = _T('Auto VPN');
			} else {
				data['autogenerated'] = _T('Normal');
			}
		} else {
			if (data?.['@autogenerated'] === 'autovpn') {
				data['@autogenerated'] = _T('Auto VPN');
			} else {
				data['@autogenerated'] = _T('Normal');
			}
		}
	});
	return gridData;
};

export const getRulesItemCount = (gridData: any = [], isSnippetScope: boolean) => {
	let count = 0;
	gridData?.forEach((data: any) => {
		if (data?.['snippet_ref'] === 'yes') {
			return;
		} else if (data?.['@local'] === 'yes') {
			return;
		} else if (data?.['@snippet-ref'] === 'yes') {
			count += Number(data?.['@count']) || Number(data?.['count']);
		} else if (data?.['@local-ref'] === 'yes') {
			// LCM TODO: not working as its counting the marker as rule as well
			count += Number(data?.['@count']) || Number(data?.['count']);
		} else if (data?.['@type'] !== 'snippet') {
			count++;
		} else if (isSnippetScope && data?.['@type'] === 'snippet') {
			count++;
		}
	});
	return count;
};

export const getRulesItemCountForProductizedAPI = (gridData: any = [], isSnippetScope: boolean) => {
	let count = 0;
	gridData?.forEach((data: any) => {
		if (data?.['snippet_ref'] === 'yes') {
			count += Number(data?.['@count']) || Number(data?.['count']);
		} else if (data?.['@snippet-ref'] === 'yes') {
			count += Number(data?.['@count']) || Number(data?.['count']);
		} else if (data?.['@local-ref'] === 'yes') {
			count += Number(data?.['@count']) || Number(data?.['count']);
		} else if (data?.['@type'] !== 'snippet' && !hasParent(data, gridData)) {
			count++;
		} else if (isSnippetScope && data?.['@type'] === 'snippet') {
			count++;
		}
	});
	return count;
};

const hasParent = (data: FAWKES.I_OBJECT, gridData: any = []) => {
	const { snippet = '' } = data;
	let hasSnippetParent = false;
	if (!_.isEmpty(snippet)) {
		hasSnippetParent = gridData.find(({ name: parentSnippet = '' }: any) => snippet === parentSnippet);
	}

	return hasSnippetParent;
};

export const processRowNumber = (gridApi: any) => {
	let rowIndex = 1;
	let preCount = 0;
	let preRowNode: any;
	gridApi.forEachNode(function (rowNode: any) {
		if (rowNode?.data?.['@skip-validate'] === 'yes') {
			preCount = Number(rowNode?.data?.['@count']) || Number(rowNode?.data?.['count']) || 0;
			rowIndex += preCount;
		} else {
			if (!rowNode.group) {
				if (rowNode?.data?.['@type'] === 'snippet' && preRowNode?.data?.['@skip-validate'] === 'yes') {
					rowIndex -= preCount;
				}
				if (rowNode?.data?.['@type'] === 'local-ref' && preRowNode?.data?.['@skip-validate'] === 'yes') {
					rowIndex -= preCount;
				}
				rowNode.rowNumber = rowIndex++;
			}
		}
		preRowNode = rowNode;
	});
};

export const isRowSelectableForRulebase = (params) => {
	if (_.get(params, "data.['@snippet-ref']", '') === 'yes') return false;
	if (_.get(params, "data.['@local-ref']", '') === 'yes') return false;
	if (isObjectLocal(params)) return false;
	if (params?.data?.af) {
		if (_.get(params, 'data.af.@local-ref', '') === 'yes') {
			return false;
		}
		if (isObjectLocal(params.data.af)) {
			return false;
		}
	}
	const currentConfigLocationType = _.get(getStoreState(), 'main.configLocation.container.type');
	return (
		!_.get(params, 'data.readonly') &&
		(_.get(params, "data.['@type']") !== 'snippet' || currentConfigLocationType === 'snippet')
	);
};
//To map colors and svgs embedded within the code to light and dark themes, import svg with light and dark prefix and preferably create a key name w/out prefix to map
export const THEME_MAP: Record<string, any> = {
	light: {
		pushSuccess: 'green', //this may need specific green? check with UX
		// ---------------
		// for bar chart:
		// from ADI-20574
		chartGreen: '#33CCB8',
		chartRed: '#E14E4E',
		chartGray: '#707070',
		chartYellow: '#FAD957',
		chartOrange: '#F38C16',
		chartBlue: '#128DF2',
		chartInactive: '#DADBDB',
		// for pie chart - Data Viz/Categorical:
		pieSky: '#4290F5',
		pieOrange: '#F38C16',
		pieTeal: '#37C8AC',
		piePurple: '#9460DC',
		pieYellow: '#FAD957',
		pieMagenta: '#BC246A',
		pieCyan: '#76D6F9',
		pieGreen: '#33CCB8',
		piePink: '#E250BB',
		pieIndigo: '#3F52D5',
		pieLime: '#A2D80E',
		pieRed: '#E14E4E',
		pieLightTeal: '#2CBEA2',
		pieBlue: '#006FCC',
		// for tree structure:
		arrowLine: '#DADBDB',
		arrowhead: '#B8B8B8',
		// ---------------
		PendingBlue: '#54a5ff',
		PendingGray: '#cfe7fc',
		barBlue: '#1F75B2',
		barDarkGray: '#333333',
		lightestGray: '#f4f5f5',
		severityCritical: '#B22D2D',
		whiteVar: '#ffffff',
		NoDataIcon: LightNoData,
		GlobeSrcDestIcon: LightGlobeSrc,
		TableIcon: LightTableIcon,
		GlobeShieldIcon: LightShieldIcon,
		PhoneIcon: LightPhoneIcon,
		AntivirusIcon: LightAntivirus,
		AntiSpywareIcon: LightAntiSpyware,
		DnsSecurityIcon: LightDnsSecurity,
		FileBlockingIcon: LightFileBlocking,
		UrlFilteringIcon: LightUrlFiltering,
		VulnerabilityProtectionIcon: LightVulnerabilityProtection,
		HTTPHeaderInsertionIcon: LightHTTPHeaderInsertion,
		AiSecurityIcon: LightAiSecurity,
		DataLossPreventionIcon: LightDataLossPrevention,
		DevicesIcon: LightDevicesIcon,
		lightYellowAlertIcon: lightYellowAlertIcon,
		lightRedAlertIcon: lightRedAlertIcon,
		lightBlueInfoIcon: lightBlueInfoIcon,
		DeviceIcon: LightDeviceIcon,
		ByFunctionTemplate: LightByFunctionTemplate,
		ByRegionTemplate: LightByRegionTemplate,
		FunctionRegionMixedTemplate: LightFunctionRegionMixedTemplate,
		CustomTemplate: LightCustomTemplate,
		CloudOffIcon: LightCloudOffIcon,
		SelectedFolderIcon: LightSelectedFolderIcon,
		PolicyIcon: LightPolicyIcon,
		ConfigurationIcon: LightConfigurationIcon,
		FolderCreationGif: LightFolderCreationGif,
		FolderCreation: LightFolderCreation,
		SelectFirewallGif: LightSelectFirewallsGif,
		SelectedFirewall: LightSelectFirewalls,
		CyanSquare: LightCyanSquare,
		SkyBlueSquare: LightSkyBlueSquare,
		IpOptimization: LightIpOptimization,
		blueDotSvg: BlueDotDark,
		greyDotSvg: GreyDotDark,
		purpleDotSvg: PurpleDot,

		//wan site bar chart styles
		barChartBackground: '#F9F9F9',
		legendTextColor: '#707070',
		seriesTextColor: '#FFFFFF',
		borderColorWithBackground: '#F9F9F9',
		borderColorWithoutBackground: '#FFFFFF',
		ShieldURL: LightShieldURL,
		CorrectIcon: LightCorrectIcon,
		CaretRight: LightCaretRight,
		CaretDown: LightCaretDown,
		ClipboardCheckList: LightClipboardCheckList,
		HintIcon: LightHintIcon,
		GearIcon: LightGearIcon,
		ComputerIcon: LightComputerIcon,
		FPIcon: LightFPIcon,
		BIIcon: LightBasicInfo,
		PALocationIcon: LightPALocation,

		AgentBasedAccessBig: AgentBasedAccessBigLight,
		ExplicitProxyBig: ExplicitProxyBigLight,
		EnterpriseBrowserBig: EnterpriseBrowserBigLight,
		PushConfigurationProgress: PushConfigurationProgressLight,
		DeployAgent: DeployAgentBigLight,
		DownloadInstallAgent: DownloadInstallAgentBigLight,
		// Need to work on the images below
		OnboardUsers: OnboardUsersLight,
		OnboardPrivateApps: OnboardPrivateAppsLight,
		OnboardRemoteSites: OnboardRemoteSitesLight,
		SDWANDataCenter: SDWANDataCenterLight,
		JamfProSmall: JamfProSmallDark,
		WorkspaceOneSmall: WorkspaceOneSmallDark,
		MicrosoftIntuneSmall: MicrosoftIntuneSmallDark,
		WebIcon: WebLight,
		DataControlIcon: DataControlLight,
		UploadIcon: UploadLight,
		DownloadIcon: DownloadLight,
		MatchIcon: MatchIcon,
	},
	dark: {
		pushSuccess: 'green',
		// ---------------
		// for bar chart:
		// from ADI-20574
		chartGreen: '#33CCB8',
		chartRed: '#E14E4E',
		chartGray: '#707070',
		chartYellow: '#FAD957',
		chartOrange: '#F38C16',
		chartBlue: '#128DF2',
		chartInactive: '#DADBDB',
		// for pie chart - Data Viz/Categorical:
		pieSky: '#4290F5',
		pieOrange: '#F38C16',
		pieTeal: '#37C8AC',
		piePurple: '#9460DC',
		pieYellow: '#FAD957',
		pieMagenta: '#BC246A',
		pieCyan: '#76D6F9',
		pieGreen: '#1AA33E',
		piePink: '#E250BB',
		pieIndigo: '#3F52D5',
		pieLime: '#A2D80E',
		pieRed: '#E14E4E',
		pieLightTeal: '#2CBEA2',
		pieBlue: '#006FCC',
		// for tree structure:
		arrowLine: '#333333',
		arrowhead: '#929191',
		// ---------------
		PendingBlue: '#54a5ff',
		PendingGray: '#cfe7fc',
		barBlue: '#1F75B2',
		barDarkGray: '#333333',
		lightestGray: '#0b1117',
		severityCritical: '#B22D2D',
		whiteVar: '#000',
		NoDataIcon: DarkNoData,
		GlobeSrcDestIcon: DarkGlobeSrc,
		TableIcon: DarkTableIcon,
		GlobeShieldIcon: DarkShieldIcon,
		PhoneIcon: DarkPhoneIcon,
		AntivirusIcon: DarkAntivirus,
		AntiSpywareIcon: DarkAntiSpyware,
		DnsSecurityIcon: DarkDnsSecurity,
		FileBlockingIcon: DarkFileBlocking,
		UrlFilteringIcon: DarkUrlFiltering,
		VulnerabilityProtectionIcon: DarkVulnerabilityProtection,
		HTTPHeaderInsertionIcon: DarkHTTPHeaderInsertion,
		AiSecurityIcon: DarkAiSecurity,
		DataLossPreventionIcon: DarkDataLossPrevention,
		DevicesIcon: DarkDevicesIcon,
		DeviceIcon: DarkDeviceIcon,
		ByFunctionTemplate: DarkByFunctionTemplate,
		ByRegionTemplate: DarkByRegionTemplate,
		FunctionRegionMixedTemplate: DarkFunctionRegionMixedTemplate,
		CustomTemplate: DarkCustomTemplate,
		CloudOffIcon: DarkCloudOffIcon,
		SelectedFolderIcon: DarkSelectedFolderIcon,
		PolicyIcon: DarkPolicyIcon,
		ConfigurationIcon: DarkConfigurationIcon,
		FolderCreationGif: DarkFolderCreationGif,
		FolderCreation: DarkFolderCreation,
		SelectFirewallGif: DarkSelectFirewallsGif,
		SelectedFirewall: DarkSelectFirewalls,
		lightYellowAlertIcon: lightYellowAlertIcon,
		lightRedAlertIcon: lightRedAlertIcon,
		lightBlueInfoIcon: lightBlueInfoIcon,
		CyanSquare: LightCyanSquare,
		SkyBlueSquare: LightSkyBlueSquare,
		IpOptimization: DarkIpOptimization,
		blueDotSvg: BlueDotLight,
		greyDotSvg: GreyDotLight,
		purpleDotSvg: PurpleDot,

		//wan site bar chart styles
		barChartBackground: '#1A1A1A',
		legendTextColor: '#DADBDB',
		seriesTextColor: '#EFF8FF',
		borderColorWithBackground: '#1A1A1A',
		borderColorWithoutBackground: '#242424',
		ShieldURL: DarkShieldURL,
		CorrectIcon: DarkCorrectIcon,
		CaretRight: DarkCaretRight,
		CaretDown: DarkCaretDown,
		ClipboardCheckList: DarkClipboardCheckList,
		HintIcon: DarkHintIcon,
		GearIcon: DarkGearIcon,
		ComputerIcon: DarkComputerIcon,
		FPIcon: DarkFPIcon,
		BIIcon: DarkBasicInfo,
		PALocationIcon: DarkPALocation,
		DeployAgent: DeployAgentBigDark,
		DownloadInstallAgent: DownloadInstallAgentBigDark,
		PushConfig: PushConfigDark,
		SelectGPVersion: SelectGPVersionDark,
		DeployAgentSmall: DeployAgentSmallDark,
		BroadcastTower: BroadcastTowerDark,
		UserLock: UserLockDark,
		AuthKey: AuthKeyDark,
		PushConfigurationProgress: PushConfigurationProgressDark,
		OnboardUsers: OnboardUsersDark,
		OnboardPrivateApps: OnboardPrivateAppsDark,
		OnboardRemoteSites: OnboardRemoteSitesDark,
		AgentBasedAccessBig: AgentBasedAccessBigDark,
		ExplicitProxyBig: ExplicitProxyBigDark,
		EnterpriseBrowserBig: EnterpriseBrowserBigDark,
		SDWANDataCenter: SDWANDataCenterDark,
		JamfProSmall: JamfProSmallDark,
		WorkspaceOneSmall: WorkspaceOneSmallDark,
		MicrosoftIntuneSmall: MicrosoftIntuneSmallDark,
		WebIcon: WebDark,
		DataControlIcon: DataControlDark,
		UploadIcon: UploadDark,
		DownloadIcon: DownloadDark,
		MatchIcon: MatchIconDark,
	},
};

export const getColor = (key: string) => {
	return THEME_MAP[getStoreState()?.main?.theme || 'light'][key];
};

//Old format to productized api format
export const transformOut = (oldFormData: FAWKES.IFormData | string) => {
	if (!_.isObject(oldFormData)) return oldFormData;
	let formData: FAWKES.I_OBJECT = {};
	for (const prop in oldFormData) {
		const newProp = prop.replaceAll('-', '_').replace('@', '');
		const value = oldFormData[prop];

		// Member string transform eg: member: "any"
		if (prop === 'member' && typeof value === 'string') {
			formData = [value];
			continue;
		}

		if (_.isArray(value)) {
			const arr = [];
			for (let i = 0; i < value.length; i++) {
				arr.push(transformOut(value[i]));
			}
			if (prop === 'entry' || prop === 'member') {
				formData = arr;
			} else {
				formData[newProp] = arr;
			}
		} else if (_.isObject(value)) {
			formData[newProp] = transformOut(value);
		} else {
			formData[newProp] = value;
		}
	}
	return formData;
};

//Productized api format to old format
export const transformIn = (newFormData: FAWKES.IFormData | string) => {
	const formData: any = {};
	if (!_.isObject(newFormData)) {
		return newFormData;
	}
	for (const prop in newFormData) {
		let newProp = prop.replaceAll('_', '-');
		if (newProp !== '@name' && newProp === 'name') {
			newProp = newProp.replace('name', '@name');
		}
		if (_.isArray(newFormData[prop])) {
			const arr = [];
			for (let i = 0; i < newFormData[prop].length; i++) {
				arr.push(transformIn(newFormData[prop][i]));
			}
			const isStringArray = (arr: any) => arr.every((i: any) => typeof i === 'string');
			if (isStringArray(arr)) {
				formData[newProp] = { member: arr };
			} else {
				formData[newProp] = newProp !== 'entry' ? { entry: arr } : newFormData[prop];
			}
		} else if (_.isObject(newFormData[prop])) {
			formData[newProp] = transformIn(_.get(newFormData, prop));
		} else if (_.isBoolean(newFormData[prop])) {
			const toggle = newFormData[prop] ? 'yes' : 'no';
			formData[newProp] = toggle;
		} else {
			formData[newProp] = newFormData[prop];
		}
	}
	return formData;
};

// Productized api location format to old format
export const transformLocation = (formData: FAWKES.IFormData) => {
	const newFormData = _.cloneDeep(formData);
	const device = _.get(newFormData, 'device', '');
	const folder = _.get(newFormData, 'folder', '');
	const snippet = _.get(newFormData, 'snippet', '');
	if (device) {
		_.set(newFormData, '@loc', device);
		_.set(newFormData, '@type', 'on-prem');
		_.unset(newFormData, 'device');
	} else if (folder) {
		let locType = 'container';
		if (folder === MU_GP_CONTAINER || folder === RN_CONTAINER || folder === SC_CONTAINER) locType = 'cloud';
		_.set(newFormData, '@loc', folder);
		_.set(newFormData, '@type', locType);
		_.unset(newFormData, 'folder');
	}
	if (snippet) {
		if (folder || device) {
			_.set(newFormData, '@snippet-loc', snippet);
		} else {
			_.set(newFormData, '@loc', snippet);
			_.set(newFormData, '@type', 'snippet');
		}
		_.unset(newFormData, 'snippet');
	}
	return newFormData;
};

export const getDateFromEpoch = (epochDate: number) => {
	const date = new Date(epochDate * 1000);
	return formatTime(date);
};

export const formatTime = (time: any) => {
	return moment.tz(time, moment.tz.guess()).format('YYYY-MMM-DD HH:mm:ss z');
};

export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const rbaPathMap = {
	Variable: 'variable',
	Zone: 'zones',
	Address: 'address',
	AddressGroup: 'address_groups',
	Region: 'regions',
	LocalUser: 'local_users_groups',
	LocalUserGroup: 'local_users_groups',
	ExternalDynamicList: 'external_dynamic_lists',
	URLCategory: 'objects',
	Application: 'applications',
	ApplicationGroup: 'application_groups',
	ApplicationFilter: 'application_filters',
	Services: 'services',
	ServiceGroup: 'service_groups',
	ApplicationGroupWithControl: 'web_security',
};
const ipToInt = (ip: string) => {
	return ip.split('.').reduce((int, octet) => (int << 8) + parseInt(octet), 0) >>> 0;
};

export const checkSubnetOverlap = (subnet1: string, subnet2: string) => {
	const [subnet1IP, subnet1Mask] = subnet1.split('/');
	const [subnet2IP, subnet2Mask] = subnet2.split('/');

	const subnet1MaskInt = parseInt(subnet1Mask);
	const subnet2MaskInt = parseInt(subnet2Mask);

	// Convert the IP addresses to 32-bit integers
	const subnet1Int = ipToInt(subnet1IP);
	const subnet2Int = ipToInt(subnet2IP);

	// Calculate the subnet addresses by applying the subnet masks
	const subnet1Address = subnet1Int & (0xffffffff << (32 - subnet1MaskInt));
	const subnet2Address = subnet2Int & (0xffffffff << (32 - subnet2MaskInt));

	// Check if one subnet is entirely contained within the other
	if (subnet1Address === subnet2Address && subnet1MaskInt <= subnet2MaskInt) {
		return true;
	}
	if (subnet1Address === subnet2Address && subnet1MaskInt >= subnet2MaskInt) {
		return true;
	}

	// Check if the subnets share at least one IP address
	const subnet1EndAddress = subnet1Address + (1 << (32 - subnet1MaskInt)) - 1;
	const subnet2EndAddress = subnet2Address + (1 << (32 - subnet2MaskInt)) - 1;
	if (subnet1Address <= subnet2Address && subnet1EndAddress >= subnet2Address) {
		return true;
	}
	if (subnet2Address <= subnet1Address && subnet2EndAddress >= subnet1Address) {
		return true;
	}

	return false;
};

export const isRfc6598IpRange = (ip: any) => {
	// 100.64.0.0/15 - (100.64.0.1 - 100.65.255.254)
	const pattern1 =
		/^100\.(64|65)\.((1?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))\.([1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4])$/;

	// 100.88.0.0/15  - (100.88.0.1 - 100.89.255.254)
	const pattern2 =
		/^100\.(88|89)\.((1?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))\.([1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4])$/;

	// 100.72.0.0/15 - (100.72.0.1 - 100.73.255.254)
	const pattern3 =
		/^100\.(72|73)\.((1?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))\.([1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4])$/;

	if (pattern1.test(ip) || pattern2.test(ip) || pattern3.test(ip)) {
		return true;
	} else {
		return false;
	}
};

const ipToIntHelper = (ip) => {
	const parts = ip.split('.');
	return (parts[0] << 24) | (parts[1] << 16) | (parts[2] << 8) | parts[3];
};

const cidrToMask = (cidr) => {
	return (0xffffffff << (32 - cidr)) >>> 0;
};

const subnetIntersects = (subnet1, subnet2) => {
	const [ip1, cidr1] = subnet1.split('/');
	const [ip2, cidr2] = subnet2.split('/');
	const ipInt1 = ipToIntHelper(ip1);
	const mask1 = cidrToMask(parseInt(cidr1, 10));
	const network1 = ipInt1 & mask1;
	const ipInt2 = ipToIntHelper(ip2);
	const mask2 = cidrToMask(parseInt(cidr2, 10));
	const network2 = ipInt2 & mask2;
	const broadcast1 = network1 | ~mask1;
	const broadcast2 = network2 | ~mask2;
	return network1 <= broadcast2 && network2 <= broadcast1;
};

export const isIntersectingWithRfcSubnets = (ipAddr) => {
	if (
		subnetIntersects('100.64.0.0/15', ipAddr) ||
		subnetIntersects('100.88.0.0/15', ipAddr) ||
		subnetIntersects('100.72.0.0/15', ipAddr)
	) {
		return true;
	}
	return false;
};

export const isColoConnectEnabled = () => {
	const state = getStoreState();
	const featuresInfo = _.get(state, 'main.featuresInfo');
	return checkIsFeatureEnabled(featuresInfo, 'colo-connect');
};

export const isNGPAGreenFieldEnabled = () => {
	const state = getStoreState();
	const featuresInfo = _.get(state, 'main.featuresInfo');
	return checkIsFeatureEnabled(featuresInfo, 'ngpa-greenfield');
};

export const isMRAIEnabled = () => {
	const state = getStoreState();
	const featuresInfo = _.get(state, 'main.featuresInfo');
	return checkIsFeatureEnabled(featuresInfo, 'ebgp-mrai');
};

export const mapAppDisplayLabel = (str = '') => {
	let label: any = str;
	label = label.replace(/-/g, ' ');
	label = label.split(' ');
	for (let i = 0, x = label.length; i < x; i++) {
		if (label[i]) {
			label[i] = label[i] === 'and' ? label[i] : label[i][0].toUpperCase() + label[i].substr(1);
		}
	}
	return label.join(' ');
};

export const supportPrefix = (configLocation: FAWKES.configLocation, fieldsMap?: Map<string, FAWKES.FieldType>) => {
	if (!configLocation) return false;
	const { container: { name: configLocationName = '', type: configLocationType = '' } = {} }: any = configLocation;
	if (!isPBAEnabled()) {
		return false;
	}
	if (configLocationType !== 'snippet') {
		return false;
	}
	if (fieldsMap?.get('$.snippet-prefix-needed')) {
		return false;
	}
	const currentSnippet = _.get(getStoreState(), 'main.snippetManage.currentSnippet');
	if (currentSnippet) {
		return currentSnippet.enable_prefix;
	}
	if (configLocationType === 'snippet') {
		const allSnippets = _.get(getStoreState(), 'main.snippetManage.allSnippets', {});
		const snippet = allSnippets[configLocationName];
		return snippet.enable_prefix;
	}
	return false;
};

export const getPrefixFromConfigLocation = (configLocation: FAWKES.configLocation) => {
	const currentSnippet = _.get(getStoreState(), 'main.snippetManage.currentSnippet');
	if (currentSnippet) {
		return currentSnippet.prefix;
	}
	const { container: { name: configLocationName = '', type: configLocationType = '' } = {} }: any = configLocation;
	if (configLocationType === 'snippet') {
		const allSnippets = _.get(getStoreState(), 'main.snippetManage.allSnippets', {});
		const snippet = allSnippets[configLocationName];
		return snippet.prefix;
	}
	return '';
};

export const addPrefixToRecord = (configLocation: FAWKES.configLocation, formData: FAWKES.IFormData) => {
	const prefix = getPrefixFromConfigLocation(configLocation);
	let name = _.get(formData, '@name') || _.get(formData, 'name');
	if (typeof name !== 'string') {
		return;
	}
	if (!name?.startsWith(`${prefix}-`)) {
		name = `${prefix}-${name}`;
	}
	if (_.has(formData, '@name')) {
		_.set(formData, '@name', name);
	}
	if (_.has(formData, 'name')) {
		_.set(formData, 'name', name);
	}
};

export const removePrefixFromRecord = (configLocation: FAWKES.configLocation, formData: FAWKES.IFormData) => {
	const prefix = getPrefixFromConfigLocation(configLocation);
	let name = _.get(formData, '@name') || _.get(formData, 'name');
	if (name?.startsWith(`${prefix}-`)) {
		name = name.replace(`${prefix}-`, '');
	}
	if (_.has(formData, '@name')) {
		_.set(formData, '@name', name);
	}
	if (_.has(formData, 'name')) {
		_.set(formData, 'name', name);
	}
};

export const getDeviceContainerParentLocation = () => {
	const configLocation = _.get(getStoreState(), 'main.configLocation', {});
	const name = _.get(configLocation, 'container.name', '');
	const folderScopeMap = _.get(getStoreState(), 'main.scopeManage.folderScopeMap', {});
	const deviceParentName = folderScopeMap[name]?.parent?.name || '';
	const locationName = isDisabledForDeviceWithoutDeviceContainer() ? deviceParentName : name;
	return locationName;
};

export const deepConvertKeysToCamelCase = (obj) => {
	if (_.isArray(obj)) {
		return obj.map((item) => deepConvertKeysToCamelCase(item));
	} else if (_.isObject(obj) && obj !== null) {
		return _.mapValues(
			_.mapKeys(obj, (value, key) => _.camelCase(key)),
			(value) => deepConvertKeysToCamelCase(value),
		);
	}
	return obj;
};

export const getPendoEventMetaData = ({ name, metaData }) => {
	if (!name || typeof name !== 'string') {
		return;
	}
	const metaDataWithStrValues = Object.keys(metaData).reduce((acc, key) => {
		acc[key] = metaData[key].toLocaleString();
		return acc;
	}, {});
	//we need to convert the meta data object into camelcase since kebab-case props are not accepted by Pendo
	const camelCaseMetaData = deepConvertKeysToCamelCase(metaDataWithStrValues);
	const finalMetaData = { ...camelCaseMetaData, ts: moment.utc().format('YYYY-MM-DD HH:mm:ss') };
	return finalMetaData;
};

export const processPAVersionData = (paDisplay: string, runningContent: string) => {
	const relType = paDisplay?.split('-')[1]?.split('(')[0] || 'N/A';
	const wholePanosVersion = paDisplay?.split('(')[1]?.slice(0, -1) || 'N/A';
	const panosVer = wholePanosVersion?.includes('PAN-OS') ? wholePanosVersion.split('PAN-OS')[1] : wholePanosVersion;
	const appThreatParts =
		runningContent !== 'N/A' && runningContent !== '' ? runningContent?.split('.')[0]?.split('-') : 'N/A';
	let appThreat = 'N/A';
	if (Array.isArray(appThreatParts)) {
		const contentNums = appThreatParts.slice(-2);
		appThreat = !isNaN(Number(contentNums[0])) ? contentNums.join('-') : contentNums[1];
	}
	return {
		relType,
		panosVer,
		appThreat,
	};
};

/**
 * A util function to convert regular arrays into an array that matches the schema node structure for multiconfigure requests
 * @param preprocessArray Array that needs to be converted to multiconfigure-type payload (matching the schema nodes)
 * @returns Returns an object that can be directly assigned to { payload: {...}} in the request
 */
export const preprocessArraysForMulticonfigure = (preprocessArray) => {
	preprocessArray.map((item) => {
		item['@name'] = item?.name; // Change name -> @name
		Object.keys(item).forEach((key) => {
			if (isArray(item[key])) {
				item[key] = preprocessArraysForMulticonfigure(item[key]);
			}
		});
		delete item?.name; // Remove the name key
		if (item?.['__KEY__']) delete item?.['__KEY__']; // Remove autogen __KEY__
	});
	return { entry: preprocessArray };
};

// This function is to remove any node which has undefined, null or '' assinged to it
export const removeEmptyNodes = (obj: any): any => {
	return _.transform(obj, (result: any, value: any, key: string) => {
		if (_.isObject(value)) {
			const cleaned = removeEmptyNodes(value);
			if (cleaned !== undefined && cleaned !== null && cleaned !== '') {
				result[key] = cleaned;
			}
		} else if (value !== undefined && value !== null && value !== '') {
			result[key] = value;
		}
	});
};

export const isFQDN = (str): boolean => {
	const fqdnRegex = /^(?!:\/\/)(?=.{1,255}$)((.{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.?)$/i;
	return fqdnRegex.test(str);
};

export const isIPorFQDN = (strin): boolean => {
	if (typeof strin !== 'string') {
		return false;
	}
	return !validationRulesMap.isIpAddressMask(strin) || isFQDN(strin);
};
