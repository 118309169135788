import { _T } from 'src/utils/i18n-utils';
import { getCustomServiceURL } from 'ui-lib/core/services/CommonServices';

export const LOCAL_CONFIG_API = {
	FETCH_PRUNED_OBJECTS_FOR_DEVICE: (device: string) => {
		return getCustomServiceURL(`api/sase/config/v1/pruned-objects?device=${device}`).SERVICE_URL;
	},
	FETCH_CONFLICTS_FOR_DEVICE: (device: string) => {
		return getCustomServiceURL(
			`api/sase/config/v1/device-config/device-conflicts?device=${device}&pagination=false`,
		).SERVICE_URL;
	},
	FETCH_CONFLICTS_FOR_FOLDER: (folder: string) => {
		return getCustomServiceURL(
			`api/sase/config/v1/device-config/folder-conflicts?folder=${folder}&pagination=false`,
		).SERVICE_URL;
	},
	IMPORT_SNIPPETS: () => {
		return getCustomServiceURL(`api/sase/config/v1/device-config/snippet-imports?&pagination=false`).SERVICE_URL;
	},
};

export const LOCAL_CONFIG_INCLUDE_SET = new Set([
	'Network/Zones',
	'Network/EthernetInterfaces',
	'networks/vlan-interfaces',
	'networks/virtual-wires',
	'networks/bgp-auth-profiles',
	'networks/bgp-address-family-profiles',
	'networks/bgp-redistribution-profiles',
	'networks/bgp-filtering-profiles',
	'networks/ospf-auth-profiles',
	'Network/IPSecTunnels',
	'networks/dns-proxies',
	'zone-protection-profiles',
	'Device/AuthenticationProfile',
	'Device/AuthenticationSequence',
	'Network/IKEGatewayNetworkProfiles',
	// auth server profiles
	'Device/SAMLIdPProfiles',
	'Device/LDAPProfiles',
	'Device/TACPlusProfiles',
	'Device/KerberosProfiles',
	'Device/RadiusProfiles',
	'local-users',
	'local-user-groups',
	'Device/CertificateProfiles',
	'Device/OCSPResponder',
	'Device/SSLTLSServiceProfiles',
	'Device/SCEP',
	'Device/CertificateManagement/CertificatesInfo',
	'Device/AuthenticationProfile',
	'Device/MFAServer',
	'networks/route-access-lists',
	'networks/route-prefix-lists',
	'networks/route-community-lists',
	'networks/bgp-route-maps',
	'networks/bgp-route-map-redistributions',
	'networks/route-path-access-lists',
	'Policies/SecurityRules',
	'Policies/DecryptionRules',
	'Policies/NatRules',
	'Policies/ApplicationOverrideRules',
	'Policies/PolicyBasedForwardingRules',
	'Policies/DoSRules',
	'Policies/AuthenticationRules',
	// Objects
	'Objects/Regions',
	'Objects/Addresses',
	'Objects/AddressGroups',
	'Objects/Services',
	'Objects/ServiceGroups',
	'dos-protection-profiles',
	'Objects/Tags',
	'Objects/ApplicationGroups',
	'Objects/AntiSpywareSecurityProfiles',
	'Objects/GlobalProtectHIPObjects',
	'Objects/FileBlockingSecurityProfiles',
	'Objects/ExternalDynamicLists',
	'Objects/GlobalProtectHIPProfiles',
	'Objects/SecurityProfileGroups',
	'Objects/VulnerabilityProtectionSecurityProfiles',
	'Objects/DynamicUserGroup',
	'Objects/Schedules',
	'Objects/WildFireAndAntivirusSecurityProfiles',
	'Objects/DecryptionProfiles',
	'Objects/DnsSecurityProfiles',
	'Objects/AutoTag',
	'Objects/URLFilteringSecurityProfiles',
	'networks/log-setting-profiles',
	// add syslog and http server profile
	'networks/httplogs',
	'networks/syslogs',
	'devices',
	'Device/AuthenticationProfile',
	'Objects/CustomURLCategories',
]);

export const IS_LOCAL_QUERY_PARAM = 'isLocal';
export const INCLUDE_LOCAL_QUERY_PARAM = 'include-local';
export const INCLUDE_LOCAL_QUERY_PARAM_OBJ = {
	[INCLUDE_LOCAL_QUERY_PARAM]: 'true',
};

export const EXTRA_PARAMS_WITHOUT_INCLUDE_LOCAL = {
	[INCLUDE_LOCAL_QUERY_PARAM]: 'false',
};

export const INCLUDE_LOCAL_LEGACY_PARAM = `${INCLUDE_LOCAL_QUERY_PARAM}=true`;

export const LOCAL_CONFIG_TOOLTIP_TEXT = _T('Conflicts with local device configs');
