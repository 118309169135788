/* eslint-disable import/no-cycle */
import { CHAR_SET, ID_LENGTH, IGNORE_KEY, INJECTED_REC_ID } from 'src/ui-lib/constants';
import _ from 'lodash';
import Pan from 'src/ui-lib/core/autorender/schema/Pan';
import * as yup from 'yup';
import { FAWKES } from 'src/typings/type';
import {
	CONTAINER,
	CONTAINER_CLOUD,
	CONTAINER_ONPREM,
	GPCS_CONTAINERS,
	PRISMA_ACCESS_CONTAINER,
	PRISMA_ACCESS_KEY,
	NGFW_SHARED,
	SNIPPET,
	LOCATION_KEY_MAP,
	DEFAULT_SNIPPET,
	ALL,
	LOCATION_REVERSE_KEY_MAP,
	MU_EP_KEY,
	SC_KEY,
} from 'service-lib/services/constants';
import { getFieldName, getValidationSchemaByField } from 'src/ui-lib/core/utils/validationRuleSchema';
import { useLocation } from 'react-router';
import React, { useContext } from 'react';
import { getStore, getStoreState } from 'src/ui-lib/core/utils/storeRegistry';
import { SlidingPaneContext } from 'src/ui-lib/core/autorender/widgets/SlidingPaneWidget/SlidingPaneContext';
import { FormContext } from 'src/ui-lib/core/autorender/FormContext';
import { getAddonLicenseByAppId, getLocationReverseKeyMap, isLicenseActive } from 'service-lib/services/licenseManager';
import { checkIsFeatureEnabled } from 'src/utils/featureFlagUtils';
import { INPROGRESS } from 'src/containers/panoramaMigration/constant';
import { parseError, SERVER_ERROR } from 'src/ui-lib/core/services/CommonServices';
import { history } from 'sparky-framework';
import { _T } from 'src/utils/i18n-utils';
import { isNonEmptyString } from 'src/containers/deviceSettings/Interfaces/common/generalUtils';
import { getFawkesMainFromSparkyStore, isBulkConfigEnabled } from 'src/appUtils';
import { CONTAINER_PARAM, CONTAINER_TYPE_PARAM } from 'src/constants';

export const EXT_GPCS_EXPIRE_TIME = 1800000;

function generateGridRecId(): string {
	let id = '';
	for (let i = 0; i < ID_LENGTH; i++) {
		id += CHAR_SET.charAt(Math.floor(Math.random() * CHAR_SET.length));
	}
	return id;
}

export function compareTwoArrays(arr1: any[], arr2: any[]): boolean {
	if (Array.isArray(arr1) && Array.isArray(arr2)) {
		arr1.forEach((item, index) => {
			arr1[index] = item[IGNORE_KEY] || item;
		});
		return _.isEqual(arr1.sort(), arr2.sort());
	}
	return false;
}

function findSelectionFromOptions(value: string, options: Array<FAWKES.OptionValue>): FAWKES.OptionValue | void {
	if (options && value) {
		for (let i = 0; i < options.length; i++) {
			const item: FAWKES.OptionValue = options[i];
			if (item.value === value) {
				return item;
			}
			if (_.isArray(item.options)) {
				const find = findSelectionFromOptions(value, item.options);
				if (find) {
					return find;
				}
			}
			if (Pan.isObject(item.value)) {
				if (_.keys(item.value)[0] === value) {
					return item;
				}
			}
		}
	}
}

export function convertToSelectionValue(
	valueObj: FAWKES.OptionValue | Array<FAWKES.OptionValue> | string,
	options: Array<FAWKES.OptionValue>,
): any {
	const dValue = [];
	if (valueObj && Array.isArray(valueObj) && valueObj.length > 0) {
		for (let index = 0; index < valueObj.length; index++) {
			const v: FAWKES.OptionValue = (valueObj as FAWKES.OptionValue[])[index];
			const value = _.isObject(v) ? v.value : v;
			const key = _.isObject(v) ? v['__KEY__'] : generateGridRecId();
			const selection = findSelectionFromOptions(value as string, options);
			const label = selection && selection.label ? selection.label : value;
			dValue.push({
				value: value,
				label: label,
				[INJECTED_REC_ID]: key,
			});
		}
		return dValue;
	} else if (typeof valueObj === 'string' && !_.isEmpty(valueObj)) {
		let label: string = valueObj;
		if (options && options.length > 0) {
			const selection = findSelectionFromOptions(valueObj, options);
			if (!selection) {
				return null;
			}
			label = selection && selection.label ? selection.label : label;
		}

		return {
			value: valueObj,
			label: label,
			[INJECTED_REC_ID]: generateGridRecId(),
		};
	} else if (_.isObject(valueObj) && !Array.isArray(valueObj)) {
		const value: string = (valueObj as FAWKES.OptionValue).value || '';
		const selection = findSelectionFromOptions(value, options);
		const label = selection && selection.label ? selection.label : (valueObj as FAWKES.OptionValue).label || value;
		return {
			value,
			label: label,
			[INJECTED_REC_ID]: (valueObj as FAWKES.OptionValue)['__KEY__'] || generateGridRecId(),
		};
	}
	return null;
}

export function processMultiSelectValue(valueObj: any): any {
	const dValue: any = [];
	valueObj.forEach((obj: any) => {
		dValue.push({
			value: obj.value,
			label: obj.label,
			serial_number: obj.serial_number,
			type: obj.type,
			[INJECTED_REC_ID]: generateGridRecId(),
		});
	});
	return dValue;
}

export function processSelectionValueObject(
	valueObj: FAWKES.OptionValue | Array<FAWKES.OptionValue>,
	multiple: boolean,
): any {
	if (!valueObj || (valueObj as Array<FAWKES.OptionValue>).length === 0) {
		return undefined;
	} else if (multiple && (valueObj as Array<FAWKES.OptionValue>).length > 0 && Array.isArray(valueObj)) {
		return (valueObj as Array<FAWKES.OptionValue>).map((obj) => {
			return obj.value;
		});
	} else if ((valueObj as FAWKES.OptionValue).value || (valueObj as FAWKES.OptionValue).value === '') {
		return (valueObj as FAWKES.OptionValue).value;
	} else if (Array.isArray(valueObj) && valueObj.length > 0) {
		return valueObj[0].value;
	} else {
		return valueObj;
	}
}

export function groupOptions(
	optionItems: Array<FAWKES.OptionValue>,
	groupOrder: () => FAWKES.I_OBJECT | FAWKES.I_OBJECT,
	duplicateOptions: Array<{ value: any }>,
	selectedValue: string,
) {
	const optionsMap: { [k: string]: any[] } = { _empty: [] },
		groupOptions = [];
	const duplicates = duplicateOptions || [];
	let expandedGroup = '';

	if (optionItems && optionItems.length > 0) {
		optionItems.forEach((item) => {
			if (item.value === selectedValue) expandedGroup = item.type;
			const found = duplicates.find((obj) => obj.value === item.value);
			if (!found) {
				if (groupOrder && item.type) {
					if (optionsMap[item.type]) {
						// existing type
						optionsMap[item.type].push(item);
					} else {
						// new type
						optionsMap[item.type] = [item];
					}
				} else {
					// no type
					optionsMap._empty.push(item);
				}
			}
		});
	}
	if (Object.keys(optionsMap).length === 1) {
		// only empty label
		return optionsMap['_empty'];
	}
	if (groupOrder) {
		const _groupOrder = Pan.isFunction(groupOrder) ? groupOrder() : groupOrder;
		// group order defined
		for (const key in _groupOrder) {
			const options = optionsMap[key];
			if (options && options.length > 0) {
				groupOptions.push({
					label: capitalizeLetter(key),
					options: options,
					expanded: expandedGroup === key ? true : false,
				});
				delete optionsMap[key];
			}
		}
	}

	for (const optionKey in optionsMap) {
		const options = optionsMap[optionKey];
		if (optionKey !== '_empty') {
			groupOptions.push({
				label: capitalizeLetter(optionKey),
				options: options,
				expanded: expandedGroup === optionKey ? true : false,
			});
		} else if (options.length > 0) {
			groupOptions.push({ label: '', options: options });
		}
	}

	return groupOptions;
}

export function capitalizeLetter(str: string | undefined): string {
	if (!Pan.isDefined(str)) {
		return '';
	}
	str = str.replace(/-/g, ' ');
	const str1: string[] = str.split(' ');

	for (let i = 0, x = str1.length; i < x; i++) {
		if (str1[i]) {
			str1[i] = str1[i][0].toUpperCase() + str1[i].substr(1);
		}
	}

	return str1.join(' ');
}

export const formatStringTemplate = (template: string | undefined, ...args: Array<any>): string => {
	const regex = new RegExp('{-?[0-9]+}', 'g');
	return template.replace(regex, (item) => {
		const intVal = parseInt(item.substring(1, item.length - 1));
		let replace;
		if (intVal >= 0) {
			replace = args[intVal];
		} else if (intVal === -1) {
			replace = '{';
		} else if (intVal === -2) {
			replace = '}';
		} else {
			replace = '';
		}
		return replace;
	});
};

export const completeUrl = (url: string): string => {
	return url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
};

export const exportTextFile = ({
	content,
	fileName,
	extension = 'txt',
}: {
	content: string;
	fileName: string;
	extension?: string;
}) => {
	let textFile: string | null = null;
	const makeTextFile = function (text: string) {
		const data = new Blob([text], { type: 'text/plain' });

		if (textFile !== null) {
			window.URL.revokeObjectURL(textFile);
		}

		textFile = window.URL.createObjectURL(data);

		return textFile;
	};

	const link = document.createElement('a');
	link.setAttribute('download', `${fileName}.${extension}`);
	link.href = makeTextFile(content);
	document.body.appendChild(link);

	// wait for the link to be added to the document
	window.requestAnimationFrame(function () {
		const event = new MouseEvent('click');
		link.dispatchEvent(event);
		document.body.removeChild(link);
	});
};

export function getArrayFromString(str = '', separator: string | null = null) {
	let result: Array<string> = [];
	if (separator) {
		result = str.split(separator);
	} else if (str.indexOf('\n')) {
		result = str.indexOf('\r') ? str.replace(/[\r]+/gm, '').split('\n') : str.split('\n');
	} else if (str.indexOf(',')) {
		result = str.split(',');
	} else if (str.indexOf('\r')) {
		result = str.split('\r');
	}
	return result.filter((item) => item.trim());
}

export function arrayMoveMutable(array: Array<any>, fromIndex: number, toIndex: number) {
	const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

	if (startIndex >= 0 && startIndex < array.length) {
		const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

		const [item] = array.splice(fromIndex, 1);
		array.splice(endIndex, 0, item);
	}
}

export function arrayMoveImmutable(array: Array<any>, fromIndex: number, toIndex: number) {
	array = [...array];
	arrayMoveMutable(array, fromIndex, toIndex);
	return array;
}

export const generateValidateSchema = (
	field: FAWKES.FieldType | undefined,
	fieldsMap: Map<string, FAWKES.FieldType>,
): yup.AnyObjectSchema | undefined => {
	if (!field || !field.name) return;
	let schema: any;
	if (field.childrenNames && field.childrenNames.length > 0) {
		schema = yup.object();
		field.childrenNames.forEach((subFieldName: string) => {
			const subField = fieldsMap.get(subFieldName);
			const name: string = getFieldName(subField);
			const subSchema = generateValidateSchema(fieldsMap.get(subFieldName), fieldsMap);
			if (subSchema) {
				if (name === 'choice' && subSchema.fields) {
					Object.keys(subSchema.fields).forEach((k) => {
						schema = (schema as yup.ObjectSchema<any>).shape({
							[k]: subSchema.fields[k],
						});
					});
				} else {
					schema = (schema as yup.ObjectSchema<any>).shape({
						[name]: subSchema,
					});
				}
			}
		});
	} else {
		const validateSchema = getValidationSchemaByField(field);
		if (validateSchema) {
			schema = validateSchema;
		}
	}
	return schema;
};

export const getRecordConfigPathMap = (path: string) => {
	const container_prefix = '$.config.devices.entry.container.entry.';
	const cloud_prefix = '$.config.devices.entry.device.cloud.entry.';
	const onprem_prefix = '$.config.devices.entry.device.on-prem.entry.';
	const snippet_prefix = '$.config.devices.entry.snippet.entry.';
	return {
		[CONTAINER]: container_prefix + path,
		[CONTAINER_CLOUD]: cloud_prefix + path,
		[CONTAINER_ONPREM]: onprem_prefix + path,
		[SNIPPET]: snippet_prefix + path,
	};
};

export const invalidParams = (url: string | undefined): boolean => {
	if (!url) return false;
	return url.indexOf('/') >= 0;
};

//clean the empty data
//clean confirm password
export const pruneFormData = (formData: FAWKES.I_OBJECT): FAWKES.I_OBJECT => {
	if (!_.isObject(formData) && !_.isFunction(formData) && !_.isArray(formData)) {
		return formData;
	}
	const obj: FAWKES.IFormData = {};
	for (const prop in formData) {
		if (!prop.endsWith('-confirm')) {
			const o: any = (formData as FAWKES.IFormData)[prop];
			if (!_.isEmpty(o)) {
				if (_.isArray(o)) {
					obj[prop] = new Array(o.length);
					for (let i = 0; i < o.length; i++) {
						const target = pruneFormData(o[i]);
						if (!_.isEmpty(target)) {
							obj[prop][i] = target;
						}
					}
				} else if (_.isObject(o)) {
					const target = pruneFormData(o);
					if (!_.isEmpty(target)) {
						obj[prop] = target;
					}
				} else {
					obj[prop] = o;
				}
			}
		}
	}
	return obj;
};

export const useQuery = () => {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const changeParams = (history: any, params: any) => {
	const pathname = window.location.pathname;
	history.push({
		pathname,
		search: '?' + new URLSearchParams(params).toString(),
	});
};

export const generateLinkForContainer = (name: string, type?: string, search?: URLSearchParams, state?: any): any => {
	const containerObj: any = {
		[CONTAINER_PARAM]: name,
	};
	if (type) {
		containerObj[CONTAINER_TYPE_PARAM] = type;
	}

	if (search) {
		Object.keys(containerObj).forEach((key: string) => {
			search.set(key, containerObj[key]);
		});
		if (LOCATION_KEY_MAP[containerObj[CONTAINER_PARAM]]) {
			search.delete(CONTAINER_TYPE_PARAM);
		}
	}

	const historyObject: any = {
		pathname: window.location.pathname,
		search: search ? `?${search.toString()}` : `?${new URLSearchParams(containerObj).toString()}`,
	};

	if (state && !Pan.isEmpty(state)) {
		historyObject.state = state;
	}

	return historyObject;
};

export const generateContainerParams = (container: any) => {
	const { name } = container;
	const containerObj: any = {
		[CONTAINER_PARAM]: getLocationReverseKeyMap()[name] || PRISMA_ACCESS_KEY,
	};
	return `?${new URLSearchParams(containerObj).toString()}`;
};

export const getLegacyTypeParamForRecord = (record: any) => {
	const { folder, device, snippet } = record;
	if (!(folder || device || snippet)) return '';
	if (snippet) return 'snippet';

	if (device) return 'on-prem';

	const PA_CONTAINER_MAP = getLocationReverseKeyMap();
	const cloudContainerKeyMatched = PA_CONTAINER_MAP[folder];

	if (cloudContainerKeyMatched) {
		const { type } = GPCS_CONTAINERS[cloudContainerKeyMatched];

		return type;
	}

	return 'container';
};

export const generateContainerParamsByName = (
	url: string,
	name: string,
	configLocationContainer: any,
	search?: URLSearchParams,
	state?: any,
) => {
	const paDisabled = isPADisabled();
	const isMSP = isBulkConfigEnabled();
	let containerObj: any = isMSP
		? {
				name: DEFAULT_SNIPPET,
				type: SNIPPET,
				locationPath: DEFAULT_SNIPPET,
		  }
		: {
				name: ALL,
				type: 'container',
				locationPath: ALL,
		  };

	if (name) {
		const PA_CONTAINER_MAP = paDisabled ? {} : getLocationReverseKeyMap();
		if (PA_CONTAINER_MAP[name]) {
			containerObj = {
				[CONTAINER_PARAM]: PA_CONTAINER_MAP[name],
			};
		} else if (!PA_CONTAINER_MAP[name] && configLocationContainer) {
			containerObj = {
				[CONTAINER_PARAM]: name,
				[CONTAINER_TYPE_PARAM]: configLocationContainer?.container?.type || configLocationContainer?.type,
			};
		}
	}

	if (search) {
		Object.keys(containerObj).forEach((key: string) => {
			search.set(key, containerObj[key]);
		});
		if (LOCATION_KEY_MAP[containerObj[CONTAINER_PARAM]]) {
			search.delete(CONTAINER_TYPE_PARAM);
		}
	}

	const historyObject: any = {
		pathname: url,
		search: search ? `?${search.toString()}` : `?${new URLSearchParams(containerObj).toString()}`,
	};

	if (state && !Pan.isEmpty(state)) {
		historyObject.state = state;
	}

	return historyObject;
};

export const isURLIncludeConfigLocation = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const path = window.location.pathname;
	const [, , containerKey] = path.split('/');
	const containerKeyFromParam = search.get(CONTAINER_PARAM);

	const paDisabled = isPADisabled();
	const PA_CONATINER = paDisabled ? {} : GPCS_CONTAINERS;

	if (containerKeyFromParam || _.has(PA_CONATINER, containerKey)) {
		return true;
	} else {
		return false;
	}
};

export const getConfigLocationFromURL = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const path = window.location.pathname;
	const [, , containerKey] = path.split('/');
	const containerKeyFromParam = search.get(CONTAINER_PARAM);
	const containerTypeFromParam = search.get(CONTAINER_TYPE_PARAM);
	return getConfigLocation(containerKeyFromParam, containerTypeFromParam, containerKey);
};

export const getConfigLocation = (
	containerKeyFromParam: string,
	containerTypeFromParam: string,
	containerKey: string,
) => {
	const paDisabled = isPADisabled();
	const isMSP = isBulkConfigEnabled();
	const defaultContainer = isMSP
		? {
				name: DEFAULT_SNIPPET,
				type: SNIPPET,
				locationPath: DEFAULT_SNIPPET,
		  }
		: !paDisabled && isNGFWDisabled()
		? GPCS_CONTAINERS[PRISMA_ACCESS_KEY]
		: {
				name: ALL,
				type: 'container',
				locationPath: ALL,
		  };

	const defaultContainerKey = isMSP ? DEFAULT_SNIPPET : ALL;
	const PA_CONATINER = paDisabled ? {} : GPCS_CONTAINERS;
	if (_.isEmpty(containerKeyFromParam)) {
		if (_.has(PA_CONATINER, containerKey)) {
			return {
				container: PA_CONATINER[containerKey],
			};
		}
		return {
			container: defaultContainer,
		};
	}
	const key = containerKeyFromParam || defaultContainerKey;

	if (PA_CONATINER[key]) {
		return {
			container: PA_CONATINER[key],
		};
	} else {
		const container = {
			name: containerKeyFromParam,
			type: containerTypeFromParam,
			locationPath: containerKeyFromParam,
		};
		return {
			container: container,
		};
	}
};
export const needChangeScope = (path: string, hideScope: any) => {
	const [, , containerKey] = path.split('/');
	if (Pan.isFunction(hideScope)) {
		return !hideScope();
	} else {
		return hideScope ? !hideScope : !_.has(GPCS_CONTAINERS, containerKey);
	}
};

export const isNGFWScope = () => {
	// const url = new URL(document.URL);
	// const search = url.searchParams;
	// return search.has(CONTAINER_TYPE_PARAM);
	const { main: { configLocation: { container: { name: currentScope = '' } = {} } = {} } = {} } = getStoreState();

	const matchedFromFawkesContainer = LOCATION_REVERSE_KEY_MAP[currentScope];

	return !matchedFromFawkesContainer;
};
export const isAdemAIOPSEnabled = () => {
	const adem = _.get(getStoreState(), 'main.licenseInfo.features_enabled.add_adem_aiops') || false;
	return _.get(adem, 'enabled_for', []).indexOf('remote_networks') !== -1;
};

export const isAdemAIOPSActive = () => {
	const addonLicense = getAddonLicenseByAppId('add_adem_aiops');
	return isLicenseActive(addonLicense?.license_expiration);
};

export const isScmProEnabled = () => {
	const isScmProTenant = _.get(getStoreState(), 'main.loggedInUser.scmFlagsFromUser.is_scm_premium_tenant');
	return isScmProTenant;
};
export const isScmProActive = () => {
	const addonLicense = getAddonLicenseByAppId('strata_cloud_manager');
	return isLicenseActive(addonLicense?.license_expiration);
};

export const isScmProExpired = (gridData: any) => {
	const adem = _.get(getStoreState(), 'main.licenseInfo.features_enabled.strata_cloud_manager') || false;
	const isAdemAlreadyEnable = gridData.filter((loc) => loc['enable-adem'] === 'yes');
	return _.get(adem, 'enabled_for', []).indexOf('remote_networks') === -1 && isAdemAlreadyEnable.length > 0;
};

export const isMigrationInProgress = () => {
	const migrationStatus = _.get(getStoreState(), 'main.migrationStatus.state');
	return migrationStatus === INPROGRESS;
};

export const isPADisabled = () => {
	// const url = new URL(document.URL);
	// const search = url.searchParams;
	// return search.has(CONTAINER_TYPE_PARAM);

	// to decouple
	const fawkesMain = getFawkesMainFromSparkyStore();
	const tenantInfo = _.get(getStoreState(), 'main.tenant') || fawkesMain.tenant || {};
	const { pa_enabled: isPAEnabled = false } = tenantInfo;
	return !isPAEnabled;
};

export const isNGFWDisabled = () => {
	// to decouple
	const fawkesMain = getFawkesMainFromSparkyStore();
	const tenantInfo = _.get(getStoreState(), 'main.tenant') || fawkesMain.tenant || {};
	const featuresInfo =
		_.get(getStoreState(), 'main.featuresInfo', [])?.length > 0
			? _.get(getStoreState(), 'main.featuresInfo', [])
			: fawkesMain?.featuresInfo;
	const isNGFWDisabledByFeatureFlag = checkIsFeatureEnabled(featuresInfo, 'isNGFWDisabled');
	const { ngfw_enabled: isNGFWEnabled = false } = tenantInfo;
	return isNGFWDisabledByFeatureFlag || !isNGFWEnabled;
};

export const isGlobalScope = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const containerTypeFromParam = search.get(CONTAINER_TYPE_PARAM);
	const container = search.get(CONTAINER_PARAM);
	return containerTypeFromParam === CONTAINER && container === ALL;
};

export const isSnippetScope = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const containerTypeFromParam = search.get(CONTAINER_TYPE_PARAM);
	return containerTypeFromParam === SNIPPET;
};

export const isCloudContainer = (locationName?: string) => {
	const containerName = locationName || _.get(getStoreState(), 'main.configLocation.container.name');
	return (
		_.keys(GPCS_CONTAINERS)
			.map((key) => {
				return GPCS_CONTAINERS[key].name;
			})
			.indexOf(containerName) >= 0
	);
};

export const isNotNGFWContainer = (params?: URLSearchParams) => {
	const url = new URL(document.URL);
	const search = params || url.searchParams;
	const containerTypeFromParam = search.get(CONTAINER_TYPE_PARAM);
	if (
		!containerTypeFromParam ||
		containerTypeFromParam === SNIPPET ||
		(containerTypeFromParam === CONTAINER && search.get(CONTAINER_PARAM) === ALL)
	) {
		return true;
	} else if (containerTypeFromParam === 'on-prem') {
		return false;
	}
	return isCloudContainer();
};
export const isEpScope = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const conatinerTypeFromParam = search.get(CONTAINER_PARAM);
	return conatinerTypeFromParam === MU_EP_KEY;
};

export const isScScope = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const conatinerTypeFromParam = search.get(CONTAINER_PARAM);
	return conatinerTypeFromParam === SC_KEY;
};

export const isFolderScope = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const conatinerTypeFromParam = search.get(CONTAINER_TYPE_PARAM);
	return conatinerTypeFromParam === CONTAINER;
};

/**
 * Util function that returns true if the current folder is ngfw-shared
 * @param name
 * @returns boolean
 */
export const isFolderNGFWShared = (name): boolean => {
	return name === NGFW_SHARED;
};

export const isDeviceScope = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const conatinerTypeFromParam = search.get(CONTAINER_TYPE_PARAM);
	return (
		conatinerTypeFromParam === CONTAINER_ONPREM ||
		_.get(getStoreState(), 'main.configLocation.container.type') === CONTAINER_ONPREM
	);
};

export const isCNGFWDevice = (deviceName: any) => {
	const { main: { scopeManage: { cloudNGFWNameMap = {} } = {} } = {} } = getStoreState();

	return Boolean(cloudNGFWNameMap[deviceName]);
};

export const isCNGFWDeviceAWS = (deviceName: any) => {
	const { main: { scopeManage: { cloudNGFWNameMap = {} } = {} } = {} } = getStoreState();
	const deviceDetail = cloudNGFWNameMap[deviceName];
	return deviceDetail?.model?.toLowerCase() === 'cngfw-aws';
};

export const isCNGFWDeviceAzure = (deviceName: any) => {
	const { main: { scopeManage: { cloudNGFWNameMap = {} } = {} } = {} } = getStoreState();
	const deviceDetail = cloudNGFWNameMap[deviceName];
	return deviceDetail?.model?.toLowerCase() === 'cngfw-azure';
};

export const getChildrenFromFolder = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const conatinerNameFromParam = search.get(CONTAINER_PARAM);
	const { main: { scopeManage: { folderScopeMap = {} } = {} } = {} } = getStoreState();
	const childrenArray = _.get(folderScopeMap[conatinerNameFromParam], 'deviceSerialNumberArray', []);
	return childrenArray;
};
export const isCNGFWDeviceScope = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const conatinerNameFromParam = search.get(CONTAINER_PARAM);
	return isDeviceScope() && isCNGFWDevice(conatinerNameFromParam);
};

// Folder with CNGFW device - Hybrid folder
export const isFolderScopeWithCngfwDevice = () => {
	if (isFolderScope()) {
		const childrenFromFolder = getChildrenFromFolder();
		const cngfwDeviceList = childrenFromFolder.filter((device: string) => isCNGFWDevice(device));
		return cngfwDeviceList.length > 0 && cngfwDeviceList.length !== childrenFromFolder.length;
	}
	return false;
};

// Folder with CNGFW device - Hybrid or CNGFW only folder
export const isFolderHasCngfwDevice = () => {
	if (isFolderScope()) {
		const hasNgfwDevice = getChildrenFromFolder().some((device: string) => isCNGFWDevice(device));
		return hasNgfwDevice;
	}
	return false;
};

// CNGFW only folder
export const isFolderScopeWithOnlyCngfwDevice = () => {
	if (isFolderScope()) {
		const onlyNgfwDevice = getChildrenFromFolder().every((device: string) => isCNGFWDevice(device));
		return onlyNgfwDevice;
	}

	return false;
};

export const isGlobalScopeHasCngfwDevice = () => {
	const { main: { scopeManage: { folderScopeWithDevicesData = [] } = {} } = {} } = getStoreState();
	const childrenArray = _.get(folderScopeWithDevicesData[0], 'deviceSerialNumberArray', []);
	const hasNgfwDevice = childrenArray.some((device: string) => isCNGFWDevice(device));
	return hasNgfwDevice;
};

export const isGlobalScopeWithCngfwDevice = () => {
	return isGlobalScope() && isGlobalScopeHasCngfwDevice();
};

export const isGlobalZoneMapping = () => {
	if (isGlobalScope()) {
		if (!isPADisabled() || isGlobalScopeHasCngfwDevice()) {
			return true;
		}
	}
	return false;
};

export const enableCloudCertForSnippet = () => {
	return isSnippetScope() && isGlobalScopeHasCngfwDevice();
};

export const enableCloudCert = () => {
	return (
		isCNGFWDeviceScope() ||
		isFolderScopeWithCngfwDevice() ||
		isFolderScopeWithOnlyCngfwDevice() ||
		isGlobalScopeWithCngfwDevice() ||
		enableCloudCertForSnippet()
	);
};

export const isDeviceScopeWithDeviceContainer = (deviceName: any) => {
	const { main: { scopeManage: { deviceContainerNameMap = {} } = {} } = {} } = getStoreState();

	return Pan.isBoolean(deviceContainerNameMap[deviceName]) ? deviceContainerNameMap[deviceName] : true;
};

export const isDisabledForDeviceWithoutDeviceContainer = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const conatinerNameFromParam = search.get(CONTAINER_PARAM);
	return isDeviceScope() && !isDeviceScopeWithDeviceContainer(conatinerNameFromParam);
};

export const excludeIfUrlIncludes = (str: string) => {
	const url = new URL(document.URL);
	const pathname = url.pathname;
	return pathname.includes(str);
};

export const searchInFolder = (item: any, containerName: string): any => {
	if (item.children) {
		let find;
		for (let i = 0; i < item.children.length; i++) {
			find = searchInFolder(item.children[i], containerName);
			if (find) {
				return find;
			}
		}
	}
	return item.name === containerName || LOCATION_KEY_MAP[item.name] === containerName ? item : null;
};

export const createFolderHierarchyList = (containerName: string | undefined, allFolders: any) => {
	if (!containerName) return [];
	let folder;
	const _allFolders = _.cloneDeep(allFolders);
	for (let i = 0; i < _allFolders.length; i++) {
		folder = searchInFolder(_allFolders[i], containerName);
		if (folder) {
			break;
		}
	}
	if (folder) {
		const res = [];
		while (folder) {
			res.push(folder);
			folder = folder.parent;
		}
		return res;
	}
	return [];
};
export const getBreadCrumbList = () => {
	return document.getElementsByClassName('breadcrumb-item');
};

export const isVariable = (name: string): boolean => {
	if (isNonEmptyString(name)) {
		return name?.startsWith('$');
	}
	return false;
};

export const isContainerDevice = (configLocation: FAWKES.configLocation | undefined | FAWKES.I_OBJECT): boolean => {
	configLocation = Pan.isEmpty(configLocation) ? getConfigLocationFromURL() : configLocation;
	const containerType = _.get(configLocation, 'container.type');
	return containerType === 'on-prem';
};

export const preProcess = (
	formData: FAWKES.IFormData,
	fieldsMap?: Map<string, FAWKES.FieldType>,
	isEdit?: boolean,
	path = '$',
	useProductizedApi?: boolean,
): FAWKES.IFormData => {
	if (!_.isObject(formData) && !_.isFunction(formData) && !_.isArray(formData)) {
		return formData;
	}
	const obj: FAWKES.IFormData = {};
	for (const prop in formData) {
		if (!prop.endsWith('-confirm') && !prop.startsWith('fake_')) {
			const o: any = (formData as FAWKES.IFormData)[prop];
			const fieldPath = useProductizedApi ? `${path}.${prop}`.replaceAll('_', '-') : `${path}.${prop}`;
			const field = fieldsMap?.get(fieldPath);
			if (_.isArray(o)) {
				if (o.length === 0 && field?.type === 'sequence' && field?.schemaAllowBlank && useProductizedApi) {
					continue;
				}
				obj[prop] = new Array(o.length);
				for (let i = 0; i < o.length; i++) {
					const target =
						prop === 'entry'
							? preProcess(o[i], fieldsMap, isEdit, `${path}.${prop}.*`, useProductizedApi)
							: preProcess(o[i], fieldsMap, isEdit, `${path}.${prop}`, useProductizedApi);
					obj[prop][i] = target;
				}
			} else if (_.isObject(o)) {
				const target = preProcess(o, fieldsMap, isEdit, `${path}.${prop}`, useProductizedApi);
				if (!_.isEmpty(target) || field?.type !== 'sequence' || !field?.schemaAllowBlank || isEdit) {
					obj[prop] = target;
				}
			} else {
				if (o || typeof o === 'boolean' || typeof o === 'number') {
					if (useProductizedApi && (field?.type === 'rangedint' || field?.type === 'float')) {
						//use number
						obj[prop] = Number(o);
					} else {
						obj[prop] = o;
					}
				}
			}
		}
	}
	return obj;
};

export const getSerialNumberByName = (deviceName: string) => {
	const { folderScopeMap = {} } = getStoreState()?.main?.scopeManage;
	const sn = folderScopeMap?.[deviceName]?.serial_number;
	return sn || deviceName;
};

export const isInsideSlidingPane = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return !_.isEmpty(useContext(SlidingPaneContext));
};

export const isReadOnlyForm = (): boolean => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const context: any = useContext(FormContext);
	return context?.readonly;
};

export const queryParamsStringToObject = (queryParamStr: string) => {
	queryParamStr = queryParamStr || '';
	queryParamStr = queryParamStr.startsWith('?') ? queryParamStr.slice(1) : queryParamStr;

	type QueryParamObject = { [key: string]: string };

	return queryParamStr.split('&').reduce((o: QueryParamObject, p: string) => {
		if (!p) {
			return o;
		}
		const [key, value] = p.split('=');
		o[key] = value;
		return o;
	}, {});
};

export const isPAandNgfwDisabled = () => {
	const currentLocation = _.get(getStoreState(), 'main.configLocation.container.name', '');
	const PAandNgfwDisabled = !(currentLocation === PRISMA_ACCESS_CONTAINER || currentLocation === NGFW_SHARED);
	return PAandNgfwDisabled;
};

export const getInterfaceType = (data: any) => {
	if (_.has(data, 'layer3')) {
		return 'layer3';
	} else if (_.has(data, 'layer2')) {
		return 'layer2';
	} else if (_.has(data, 'tap')) {
		return 'tap';
	} else if (_.has(data, 'aggregate-group')) {
		return 'aggregateEthernet';
	} else {
		return '';
	}
};

export const getCurrentDirSync = () => {
	const state = getStoreState();
	const containerName = _.get(state, 'main.configLocation.container.name');
	const containerType = _.get(state, 'main.configLocation.container.type');
	// Dirsync is enabled for 3 scopes [ALL, PA, NGFW]
	// For Global scope we should use from ALL
	if (containerName === ALL) return _.get(state, `config.${containerName}.dirSync.result.dir-sync.entry[0]`) || [];
	if (containerType === SNIPPET) return _.get(state, `config.${ALL}.dirSync.result.dir-sync.entry[0]`) || [];
	// Note: Always get the dir-sync from PA, as when calling dir-sync api the location is hardcoded as PA
	// For PA, dirSync is stored on PA level.
	// For BB, dirSync is stored on All Firewall level.
	const dirSyncContainerName = !isNGFWDisabled() && isNGFWScope() ? NGFW_SHARED : PRISMA_ACCESS_CONTAINER;
	return _.get(state, `config.${dirSyncContainerName}.dirSync.result.dir-sync.entry[0]`);
};

export const extGpcsCacheExpired = (lastUpdate = 0) => {
	if (Date.now() - lastUpdate >= EXT_GPCS_EXPIRE_TIME) {
		return true;
	}
	return false;
};

export const getFolderBySerialNumber = (serialNumber: string) => {
	const { folderScopeMap = {} } = getStoreState()?.main?.scopeManage;
	return folderScopeMap[serialNumber].parent?.name;
};

export const handleError = (error: any) => {
	getStore().dispatch({
		type: SERVER_ERROR,
		errorMessage: parseError(error),
		showMessage: true,
	});
};

export const tenantInfo = () => {
	const fawkesMain = getFawkesMainFromSparkyStore();
	const tenantInfo = _.get(getStoreState(), 'main.tenant') || fawkesMain.tenant || {};
	return tenantInfo;
};

export const isScmPremiumTenant = () => {
	return _.get(tenantInfo(), 'scmFlags.is_scm_premium_tenant');
};

export const isScmBaseTenant = () => {
	return _.get(tenantInfo(), 'scmFlags.is_scm_base_tenant');
};

export const isScmBaseTenantWithCDL = () => {
	return _.get(tenantInfo(), 'scmFlags.is_tenant_with_cdl');
};

export const cleanRecordOnSubmit = (record: FAWKES.IFormData, useProductizedApi: boolean): FAWKES.IFormData => {
	const cloneRecord = _.cloneDeep(record);
	if (useProductizedApi) {
		_.unset(cloneRecord, 'folder');
		_.unset(cloneRecord, 'snippet');
		_.unset(cloneRecord, 'device');
		_.unset(cloneRecord, 'id');
	}
	return cloneRecord;
};

export const getErrorMessageWithPrefix = (errorMessage: string) => {
	if (Pan.isEmpty(errorMessage)) return '';
	const prefix = 'During the last process run, ';
	const uncapitalizedMessage = errorMessage.toLowerCase();
	return _T(`${prefix}${uncapitalizedMessage}`);
};

export const optimizePageBannerDetails = () => {
	const { location: { pathname = '' } = {} } = history;
	const { dashboard: { optimizeSummary = {}, securityRulesToOptimize = [] } = {} } = getStoreState();
	const isPolicyOptimizerMainPage = pathname === '/manage/operation/policy-optimizer';
	const isOptimizeRoute = pathname.includes('/policy-optimizer/optimize/');
	const uniqueId = isOptimizeRoute ? pathname.split('/').pop() : '';
	const foundData = securityRulesToOptimize.find((obj: { uuid: any }) => obj.uuid === uniqueId);
	const { optimizerErrorCode = '', optimizerErrorMessage = '' } =
		isPolicyOptimizerMainPage && !Pan.isEmpty(optimizeSummary)
			? {
					optimizerErrorCode: optimizeSummary.error_code,
					optimizerErrorMessage: getErrorMessageWithPrefix(optimizeSummary.error_message),
			  }
			: isOptimizeRoute && !Pan.isEmpty(foundData)
			? {
					optimizerErrorCode: foundData.error_code,
					optimizerErrorMessage: getErrorMessageWithPrefix(foundData.error_reason),
			  }
			: {};
	const showUnexpectedError = optimizerErrorCode === 'UnexpectedError';
	const optimizerErrorClassName = showUnexpectedError ? 'policy-optimizer-general-error' : '';

	return { optimizerErrorCode, optimizerErrorMessage, showUnexpectedError, optimizerErrorClassName };
};

export const populateTagMap = (item: any) => {
	const tagMap = new Map();
	const tags = item.tags;
	tags.forEach((tag) => {
		tagMap.set(`[${tag['@name']}]`, tag);
		tagMap.set(`${tag['@name']}`, tag);
	});
	item.tagMap = tagMap;
};

export const mouseClickInSideContainer = (event, container) => {
	const x = event.clientX;
	const y = event.clientY;
	const containerRect = container.getBoundingClientRect();
	return x < containerRect.right && x > containerRect.left && containerRect.top < y && containerRect.bottom > y;
};

export const getSnippetsByFolders = (rootNodes, folders) => {
	const res = [];
	const folderNameSet = new Set(folders);
	const resultSet = new Set([]);
	const dfs = function (cur) {
		if (folderNameSet.has(cur.name) && cur.snippets?.length > 0) {
			cur.snippets.forEach((snippet) => {
				resultSet.add(snippet);
			});
		}
		cur.children?.forEach(dfs);
	};
	rootNodes.forEach(dfs);
	resultSet.forEach((item) => {
		res.push({
			label: item,
			value: item,
		});
	});
	return res;
};
