import { getStoreState } from 'ui-lib/core/utils/storeRegistry';
import _, { isFunction } from 'lodash';
import { isNextSchemaEnabled } from 'src/service-lib/services/licenseManager';
import { checkIsFeatureEnabled } from 'src/utils/featureFlagUtils';
import Pan from 'src/ui-lib/core/autorender/schema/Pan';
import { store as sparkyStore } from 'sparky-framework';
import { getFawkesMainFromSparkyStore } from 'src/appUtils';

export const getFeaturesInfoFromStore = () => sparkyStore?.getState()?.fawkesMain?.featuresInfo ?? [];

const updateFeaturesInfo = (featuresInfo: any) => {
	if (Pan.isEmpty(featuresInfo) && isFunction(getFawkesMainFromSparkyStore)) {
		// get state from sparky if the featuresInfo is empty as we can't get updated state in NatPoliciesWithSchema
		const fawkesMain = getFawkesMainFromSparkyStore();
		featuresInfo = fawkesMain?.featuresInfo;
	}
	return featuresInfo;
};

const getActualFeaturesList = () => {
	let ffList = getFeaturesInfoFromStore();
	if (Pan.isEmpty(ffList) && isFunction(getFawkesMainFromSparkyStore)) {
		// get state from sparky if the featuresInfo is empty as we can't get updated state in NatPoliciesWithSchema
		const fawkesMain = getFawkesMainFromSparkyStore();
		ffList = fawkesMain?.featuresInfo;
	}
	return ffList;
};
export function isAgentProxyAuthBypassEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'agent-proxy-auth-bypass');
}

export function isAgentProxySourceIPEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'agent-proxy-source-ip');
}

export function isForwardingProfilesEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'forwarding-profiles');
}

export function isCustomProxiesEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'fp-custom-proxies');
}

export const isLocalConfigEnabled = () => {
	// Updated flag name based on ADI-32777
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'local-config-phase-2');
};

export function isTemplateConfigsEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'notification-template-configs');
}

export function isEndpointDLPConfigsEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'endpoint-dlp-template-config');
}

export function isEndpointDLPMessageEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'endpoint-dlp-message-config');
}

export function isTrafficEnforcementEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'fp-traffic-enforcement');
}

export function isEPPrivateAppAccessEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'ep_priv_app_access');
}

export function isPrivateIpBasedPolicyEnforcementEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'mu_private_ip_based_policy_enforcement');
}

export function isEPClientDNSEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'ep_client_dns');
}

export function isCuidCompatEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'cloud_userid_ff');
}
export function isCuidSreEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'cloud_userid_sre_ff');
}
export function isHostCompCompatEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'host_compliance_ff');
}
export function isHostCompSreEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'host_compliance_sre_ff');
}

export function isGPSplitTunnelEnabled() {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'gp-split-tunnel');
}

export const isIPV6ParityEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'enable-ipv6-parity');
};

export const isBypassDecryptionServerCertCheckEnabled = () => {
	return (
		isNextSchemaEnabled() &&
		checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'decryption-server-cert-verification')
	);
};

export const isChinaEPEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'china-explicit-proxy');
};

export const isGlobalSummaryPoolEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'global_summary_pool');
};

export const isProjectSummaryPoolEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'project_summary_pool');
};

export const isDeviceDeactivationEnabled = () => {
	return checkIsFeatureEnabled(updateFeaturesInfo(getFeaturesInfoFromStore()), 'vm_auto_deactivation');
};

// LR VR flag check in one place
export const isVirtualRouterEnabledFn = () => {
	let featuresInfo = getFeaturesInfoFromStore();
	featuresInfo = updateFeaturesInfo(featuresInfo);
	return !checkIsFeatureEnabled(_.get(getStoreState(), 'main.featuresInfo', []), 'enable-frr-routing');
};

export const isOrionEnabled = () => {
	return isNextSchemaEnabled();
};

export function isAsymmetricLoadShareEnabled() {
	return (
		checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'inter_region_routing_ff') &&
		checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'inter_region_routing_ff_dev')
	);
}
export const isRoutersIPV6ParityEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'enable-ipv6-routers') && isIPV6ParityEnabled();
};

export const isMulticastRoutingEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'enable-multicast-routing');
};

export const isUnifiedPolicyEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'unified-policy-2');
};
export const isSaaSSessionTrackingEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'saas-session-tracking');
};
export const isLinuxSupportEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'paa_linux_support');
};
export const isMobileSupportEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'paa_mobile_support');
};
export const isLocalProxyPortEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'paa_proxy_port');
};
export const isPreLogonEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'paa_pre_logon');
};
export const isPasswordlessEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'paa_passwordless');
};
export const isSplitGatwayPortalAuthEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'split-gw-portal-auth');
};
export const isZonesInspectionEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'enable-zone-inspection');
};
export const isNetworkInterfaceFalloutsEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'network-interface-fallouts');
};
export const isProxyChainingEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'ep_proxy_chaining_ff');
};
export const isCloudServicesAgentAuthEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'cloud-serv-auth-cert');
};
export const isFullQoSParity = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'is-full-qos-parity');
};
export const isDelegationProfileEnabled = () => {
	const isKerberosEnabled = checkIsFeatureEnabled(getActualFeaturesList(), 'kerberos_feature_flag');
	return isKerberosEnabled && isOrionEnabled();
};

export const isUCFObjectSubscriptionEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'ucf_config_obj_subscription');
};

export const isUCFMockLicenseEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'ucf_pabranchsecurity_mocked_license');
};

export const isPOv3FeatureEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'policy-optimization-v2-users');
};

export const isTSAParityEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'enable-bb-tsa');
};

export const isRoutingProfilesParityEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'enable-bb-routing-profiles');
};
export const isDeviceSetupFullyEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'device-setup-parity-ff');
};

export const isCaptivePortalModeEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'enable-captive-portal-mode');
};

export const isPABTrafficIDForEPEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'pab_traffic_id_feature_flag');
};
export const isFedrampEPEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'fedramp-ep');
};
export const isPaginationEnabled = () => {
	// also add a check here to ensure this tenant has graph-db enabled. Set this to true for local dev
	// https://code.pan.run/fawkes/togglesvc/-/merge_requests/391/diffs
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'enable-grid-infinite-scroll');
};

export const isResponsePagesParityEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'enable-response-pages');
};

export const isSaasCharacteristicsEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'enable-saas-characteristics');
};

export const isQuarantineDeviceListEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'enable-device-quarantine-list');
};
export const isRNHPBrownFieldMigrationEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'rn_hp_brownfield_migration');
};

export const isPolicyAppDependencyEnabled = () => {
	return checkIsFeatureEnabled(getActualFeaturesList(), 'enable-policy-app-dependency');
};
export const isNewGPModelEnabled = () => {
	return checkIsFeatureEnabled(getFeaturesInfoFromStore(), 'gp_new_model');
};
