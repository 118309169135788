import * as yup from 'yup';
import { _T } from 'src/utils/i18n-utils';
import { FAWKES } from 'src/typings/type';
import _ from 'lodash';
import { validationRulesMap } from 'src/ui-lib/core/utils/validationRules';

export const getFieldName = (field: FAWKES.FieldType | undefined): string => {
	if (!field) return '';
	if (field.attrName) return field.attrName;
	return field.name.replace('$.', '');
};

export const octectsToLong = (a: number, b: number, c: number, d: number) => {
	return a * (1 << 24) + b * (1 << 16) + c * (1 << 8) + d;
};

export const validNumber = (str: string, min?: number, max?: number) => {
	var n = parseInt(str);
	if (isNaN(n)) {
		return false;
	}
	// check to make sure that there is only digits
	if (str.match(/^([0-9])+$/) == null) {
		return false;
	}
	if (min !== undefined && n < min!) {
		return false;
	}
	if (max !== undefined && n > max) {
		return false;
	}
	return true;
};

export const validNumberRangeList = (str: string, min?: number, max?: number) => {
	var tokensArray = str.split(',');
	for (var i = 0; i < tokensArray.length; i++) {
		if (tokensArray[i].charAt(tokensArray[i].length - 1) === '-') {
			// cannot end in -
			return false;
		}
		if (tokensArray[i].charAt(0) === '-') {
			// if the first char is - then it is a negative number, consider it as a number
			// instead of a range.
			if (!validNumber(tokensArray[i], min, max)) {
				return false;
			}
		} else {
			var numbersArray: string[] = tokensArray[i].split('-');
			for (var j = 0; j < numbersArray.length; j++) {
				if (!validNumber(numbersArray[j], min, max)) {
					return false;
				}
			}
			if (numbersArray.length === 2) {
				var num1 = Number(numbersArray[0]) - 0;
				var num2 = Number(numbersArray[1]) - 0;
				if (num1 > num2) {
					return false;
				}
			}
		}
	}
	return true;
};

export const inRange = (str: string, min?: number, max?: number): boolean => {
	var n = parseInt(str);
	if (isNaN(n)) return false;
	// check to make sure that there is only digits
	if (str.match(/^([0-9])+$/) == null) {
		return false;
	}
	if (min !== undefined && n < min) return false;
	return !(max !== undefined && n > max);
};

export const isIpV4Address = (str: string): any => {
	var i;
	var matches = str ? str.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)(\/[0-9]{1,3})?$/) : null;
	if (!matches) return false;
	for (i = 1; i <= 4; i++) {
		var n = parseInt(matches[i], 10);
		if (isNaN(n)) return false;
		if (!(n >= 0 && n <= 255)) false;
	}
	matches.shift(); // drop the complete string match
	let newMatches: number[] = new Array(matches.length);
	for (i = 0; i < matches.length; i++) newMatches[i] = parseInt(matches[i], 10);
	return { ip: octectsToLong(newMatches[0], newMatches[1], newMatches[2], newMatches[3]), octects: newMatches };
};

export const isIpV6Address = (str: string): boolean => {
	return !!str.match(
		/^((([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){6}:[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){5}:([0-9A-Fa-f]{1,4}:)?[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){4}:([0-9A-Fa-f]{1,4}:){0,2}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){3}:([0-9A-Fa-f]{1,4}:){0,3}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){2}:([0-9A-Fa-f]{1,4}:){0,4}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){6}((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|(([0-9A-Fa-f]{1,4}:){0,5}:((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|(::([0-9A-Fa-f]{1,4}:){0,5}((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|([0-9A-Fa-f]{1,4}::([0-9A-Fa-f]{1,4}:){0,5}[0-9A-Fa-f]{1,4})|(::([0-9A-Fa-f]{1,4}:){0,6}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){1,7}:))$/,
	);
};

export const isIpV6Netmask = (str: string): boolean => {
	return inRange(str, 3, 128);
};

export const isIpV4AddressMask = (str: string, requireMask?: boolean) => {
	/* accept both 1.1.1.1 and 1.1.1.1/30 */
	var matches = str ? str.match(/^([^\/]+)\/([^\/]+)$/) : null;
	if (!requireMask && !matches) return isIpV4Address(str);
	if (matches) {
		var n = parseInt(matches[2], 10);
		return n >= 0 && n <= 32 && isIpV4Address(matches[1]);
	}
	return isIpV4Address(str);
};

export const isIpV6AddressMask = (str: string, requireMask?: boolean): boolean => {
	// default unicast route address
	if (str === '::/0') return true;
	var arr = str.split('/');
	if (!requireMask && arr.length === 1) {
		return isIpV6Address(arr[0]);
	} else if (arr.length === 2) {
		return isIpV6Netmask(arr[1]) && isIpV6Address(arr[0]);
	}
	return true;
};

export const isIpAddressMask = (str: string, requireMask?: boolean): boolean => {
	if (!_.isEmpty(str)) {
		let ipv4 = isIpV4AddressMask(str, requireMask);
		let ipv6 = isIpV6AddressMask(str, requireMask);
		if ((ipv4 && ipv4['ip']) || ipv6) {
			return true;
		}
	}
	return false;
};

export const fieldLenRange = (min?: number, max?: number, isRequired?: boolean): yup.StringSchema => {
	let schema;
	if (!min && !max) {
		schema = yup.string();
	} else if (!min) {
		schema = yup.string().max(max!, _T(`The maximum length is ${max} characters`));
	} else if (!max) {
		schema = yup.string().min(min!, _T(`The minimal length is ${min} characters`));
	} else {
		schema = yup
			.string()
			.min(min!, _T(`The minimal length is ${min} characters`))
			.max(max!, _T(`The maximum length is ${max} characters`));
	}
	return isRequired ? schema.required(_T('Value is Required')) : schema;
};

export const fieldIntRange = (min?: number, max?: number, isRequired?: boolean): yup.NumberSchema => {
	let schema;
	if (!min && !max) {
		schema = yup.number();
	} else if (!min) {
		schema = yup.number().max(max!, _T(`Value is greater than maximum`));
	} else if (!max) {
		schema = yup.number().min(min!, _T(`Value is less than minimum`));
	} else {
		schema = yup
			.number()
			.min(min!, _T(`Value is less than minimum`))
			.max(max!, _T(`Value is greater than maximum`));
	}
	return isRequired ? schema.required(_T('Value is Required')) : schema;
};

export const fieldIpRange = (isRequired?: boolean): yup.StringSchema => {
	let schema = yup.string().test('fieldIpRange', _T('Invalid IP address range'), function (value) {
		if (!value) return false;
		let arr = value.split ? value.split('-') : '';
		// TODO: Need translation? Might be used for logic
		if (arr.length !== 2) return false;
		if (isIpAddressMask(arr[0]) || isIpAddressMask(arr[1])) {
			return false;
		}
		return true;
	});

	return isRequired ? schema.required(_T('Value is Required')) : schema;
};

export const fieldIpAndIntegerSubnetMaskV4orV6 = (isRequired?: boolean): yup.StringSchema => {
	let schema = yup.string().test('fieldIpAndIntegerSubnetMaskV4orV6', _T('Invalid IP address'), function (value) {
		if (!value) return false;
		return isIpAddressMask(value);
	});

	return isRequired ? schema.required(_T('Value is Required')) : schema;
};

export const fieldIpAndIntegerSubnetMaskV4 = (isRequired?: boolean): yup.StringSchema => {
	let schema = yup.string().test('fieldIpAndIntegerSubnetMaskV4', _T('Invalid IP address'), function (value) {
		if (!value) return false;
		let ipv4 = isIpV4AddressMask(value);
		if (ipv4 && ipv4['ip']) {
			return true;
		}
		return false;
	});

	return isRequired ? schema.required(_T('Value is Required')) : schema;
};

export const fieldIpAndIntegerSubnetMaskV4orV6SubnetMaskRequired = (isRequired?: boolean): yup.StringSchema => {
	let schema = yup
		.string()
		.test('fieldIpAndIntegerSubnetMaskV4orV6SubnetMaskRequired', _T('Invalid IP address'), function (value) {
			if (!value) return false;
			return isIpAddressMask(value, true);
		});

	return isRequired ? schema.required(_T('Value is Required')) : schema;
};

export const fieldIpAndIntegerSubnetMaskV6 = (isRequired?: boolean): yup.StringSchema => {
	let schema = yup.string().test('fieldIpAndIntegerSubnetMaskV6', _T('Invalid IP address'), function (value) {
		if (!value) return false;
		let ipv6 = isIpV6AddressMask(value);
		if (ipv6) {
			return true;
		}
		return false;
	});

	return isRequired ? schema.required(_T('Value is Required')) : schema;
};

export const fieldRangeList = (isRequired?: boolean) => {
	let schema = yup.string().test('fieldRangeList', _T('Invalid IP address'), function (value) {
		if (!value) return false;
		let ipv6 = isIpV6AddressMask(value);
		if (ipv6) {
			return true;
		}
		return false;
	});

	return isRequired ? schema.required(_T('Value is Required')) : schema;
};

export const fieldObjectName = (isRequired?: boolean) => {
	let schema = yup
		.string()
		.test(
			'fieldRangeList',
			_T(
				'A valid object name must start with an alphanumeric character and can contain zero or more alphanumeric characters, underscore "_", hyphen "-", dot "." or spaces.',
			),
			function (value) {
				if (value && !value.match(/^[0-9a-zA-Z]{1}([0-9a-zA-Z_-]|[ ]|[.])*$/)) {
					return false;
				}
				return true;
			},
		);
	return isRequired ? schema.required(_T('Value is Required')) : schema;
};

export const getValidationSchemaByField = (field: FAWKES.FieldType): yup.AnySchema<any> | undefined => {
	if (!field) return;
	let vType = _.get(field, 'uiHint.vtype');
	let isRequired = _.get(field, 'uiHint.allowBlank') === false;
	if (_.isFunction(vType)) {
		vType = vType(field);
	}
	switch (vType) {
		case 'isString':
			return yup
				.string()
				.concat(
					fieldLenRange(
						Number(_.get(field, 'uiHint.minLength')),
						Number(_.get(field, 'uiHint.maxLength')),
						isRequired,
					),
				);
		case 'rangedInt':
			return yup
				.number()
				.concat(
					fieldIntRange(
						Number(_.get(field, 'uiHint.minValue')),
						Number(_.get(field, 'uiHint.maxValue')),
						isRequired,
					),
				);
		case 'ipRange':
			return yup.string().concat(fieldIpRange(isRequired));
		case 'ipAndIntegerSubnetMaskV4orV6':
			return yup.string().concat(fieldIpAndIntegerSubnetMaskV4orV6(isRequired));
		case 'ipAndIntegerSubnetMaskV4':
			return yup.string().concat(fieldIpAndIntegerSubnetMaskV4(isRequired));
		case 'ipAndIntegerSubnetMaskV4orV6SubnetMaskRequired':
			return yup.string().concat(fieldIpAndIntegerSubnetMaskV4orV6SubnetMaskRequired(isRequired));
		case 'ipAndIntegerSubnetMaskV6':
			return yup.string().concat(fieldIpAndIntegerSubnetMaskV6(isRequired));
		case 'rangeList':
			return yup.string().concat(fieldRangeList(isRequired));
		case 'objectName':
			return yup
				.string()
				.concat(fieldObjectName(isRequired))
				.concat(
					fieldLenRange(
						Number(_.get(field, 'uiHint.minLength')),
						Number(_.get(field, 'uiHint.maxLength')),
						isRequired,
					),
				);
	}
	return isRequired ? yup.string().required(_T('Value is Required')) : yup.string();
};

export const getValidationRules = (
	name: string,
	fieldsMap: Map<string, FAWKES.FieldType>,
	_vType?: string,
): FAWKES.I_OBJECT => {
	let rules: FAWKES.I_OBJECT = {};
	const field = fieldsMap.get(`$.${name}`);
	if (!field) return rules;
	return getValidationRulesByField(field, _vType);
};

export const getValidationRulesByField = (field: any, _vType?: string): FAWKES.I_OBJECT => {
	let rules: FAWKES.I_OBJECT = {};
	let vType = _vType || _.get(field, 'uiHint.vtype');
	let isRequired = _.get(field, 'uiHint.allowBlank') === false;
	let prefix = _.get(field, 'uiHint.prefix');
	let regex = _.get(field, 'uiHint.regex');
	rules.validate = {};
	if (isRequired) {
		rules['required'] = _T('Value is required');
		rules.validate = {
			trapSpacesForRequiredFields,
		};
	}
	if (_.isFunction(vType)) {
		vType = vType(field);
	}
	rules.validate[vType] = (v: any) => {
		if ((v === prefix || v === '') && !rules['required']) return; // skip validate when it's optional
		if (validationRulesMap[vType]) {
			const error = validationRulesMap[vType](v, field);
			if (error) {
				return error;
			}
		}
	};
	if (regex) {
		rules.validate['regEx'] = (v: any) => {
			if ((v === prefix || v === '') && !rules['required']) return;
			if (validationRulesMap['regEx']) {
				const error = validationRulesMap['regEx'](v, field);
				if (error) {
					return error;
				}
			}
		};
	}
	return rules;
};

export const trapSpacesForRequiredFields = (value: any) => {
	if (typeof value === 'string' && value !== value.trim()) {
		return _T('No leading or trailing spaces.');
	}
	return null;
};

export const noSpacesAtEnd = (value: any) => {
	if (typeof value === 'string' && value.endsWith(' ')) {
		return _T('No extra spaces at the end');
	}
	return null;
};

export const snippetNameMaxLength = (value: any) => {
	if (typeof value === 'string' && value.length > 31) {
		return _T('Max length is 31 characters');
	}
	return null;
};

export const reservedSnippets = (value: any) => {
	const reservedSnippetNames = ['predefined'];
	if (typeof value === 'string') {
		const foundUnavailableSnippet = reservedSnippetNames.find(
			(snippetName) => snippetName.toLowerCase() === value?.toLowerCase(),
		);
		if (foundUnavailableSnippet) {
			return _T(`${value} is reserved`);
		}
	}
	return null;
};
