declare global {
	interface Window {
		//@ts-ignore
		__admin_cluster_url: any;
	}
}

import { hideModal, showModal } from 'src/ui-lib/core/services/actions';

import { _T } from 'src/utils/i18n-utils';
import { getLoginServiceURL } from 'src/ui-lib/core/services/CommonServices';
import { fawkesUtils } from 'sparky-framework';

export const getParam = (arg: string) => {
	const params = new URLSearchParams(window.location.search);
	if (params.has(arg)) {
		return params.get(arg);
	}
	return void 0;
};

//TODO we need to remove this code for July release
export const saveSession = ({ sub, token, exp }: { sub: string; token: string; exp: number }) => {
	sessionStorage.setItem('currentTenantId', sub);
	localStorage.setItem('currentTenantId', sub);
	localStorage.setItem(`token-${sub}`, token);
	localStorage.setItem(`token-exp-${sub}`, '' + exp);
	sessionStorage.setItem(`token-exp-${sub}`, '' + exp);
	setSessionAlert(exp);
	startSessionMonitor();
};

export const getSession = fawkesUtils?.getSession;

const FIVE_MINS = 5 * 60 * 1000;

let timer: number | undefined | NodeJS.Timeout;
let logouttimer: number | undefined | NodeJS.Timeout;
let inabout: number = 5;
export const setSessionAlert = (expAt: number) => {
	const expiringIn = expAt * 1000 - Date.now();
	let timeout = expiringIn - FIVE_MINS;
	if (timeout < 0) {
		timeout = 0;
		inabout = Math.floor(expiringIn / 60000);
	}
	clearTimeout(timer as number);
	timer = setTimeout(() => {
		clearTimeout(logouttimer as number);
		logouttimer = setTimeout(() => {
			window.location.hash = 'trylogin';
		}, inabout * 60000);

		let modal = {
			id: 'session-ending-warning',
			title: _T('Info'),
			type: 'Info',
			size: 'lg',
			toggle: () => {
				hideModal('session-ending-warning');
			},
			// TODO REVIEW translation with parameters
			message: _T(
				'Your session will expire in about {inabout} mins and you will be logged out. Please save your work before you are logged out.',
				{ inabout: inabout },
			),
			actions: [
				{
					text: _T('OK'),
					action: () => {
						hideModal('session-ending-warning');
					},
				},
			],
		};
		showModal(modal);
	}, timeout);
};

let sessionTimer: any;

//TODO we need to clean this for July release
export const startSessionMonitor = () => {
	clearInterval(sessionTimer);
	sessionTimer = setInterval(() => {
		const sub = sessionStorage.getItem('currentTenantId');
		if (Number(localStorage!.getItem(`token-exp-${sub}`)) > Number(sessionStorage!.getItem(`token-exp-${sub}`))) {
			// another window did a SAML Login - & now has updated token;
			// update the session timeout & alert. so user is not wrongly shown the session timeout alert.
			sessionStorage.setItem(`token-exp-${sub}`, localStorage.getItem(`token-exp-${sub}`)!);
			setSessionAlert(Number(localStorage.getItem(`token-exp-${sub}`)));
		}
	}, 2500);
};

/**
 * Try getting tenantId from URL, or else from session
 * If none found, then we must ask user to establist a session
 * by providing a tenantId or launch us from Hub.
 */
export const doLogin = () => {
	let loginUrl: unknown = getLoginServiceURL('api/sso/v1/login')['SERVICE_URL'];
	const urlParams = new URLSearchParams(window.location.search);
	let tenantId = urlParams.get('tenantId');
	if (tenantId) {
		loginUrl += `?tenantId=${tenantId}`;
	} else {
		tenantId = sessionStorage.getItem('currentTenantId');
		if (tenantId) {
			loginUrl += `?tenantId=${tenantId}`;
		}
	}
	if (!tenantId) {
		// should never reach here but in case doLogin is called out of conext.
		throw new Error('No tenantId found!');
	} else {
		window.location = loginUrl as Location;
	}
};

export const doLogout = () => {
	const sub = sessionStorage.getItem('currentTenantId');
	if (sub) {
		localStorage.removeItem(`token-${sub}`);
		localStorage.removeItem(`token-exp-${sub}`);
	}
	sessionStorage.clear();
	const logoutUrl: unknown = getLoginServiceURL('api/sso/v1/logout')['SERVICE_URL'];
	window.location = logoutUrl as Location;
};
