import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { getStore } from 'ui-lib/core/utils/storeRegistry';
import { SERVER_ERROR } from 'src/containers/TechSupport/actions';
import { parseError } from 'ui-lib/core/services/CommonServices';
import { commonQueryConfigs } from 'src/query-client-utils/commonFetchFn';

export interface queryConfig {
	queryKey: string;
	queryFn: () => Promise<any>;
}

const handleError = async (error: unknown, query: any) => {
	console.log('Error while fetching', error);
	if (error && typeof error === 'object')
		getStore().dispatch({
			type: SERVER_ERROR,
			errorMessage: parseError(error),
			showMessage: true,
		});
};

export const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: handleError,
	}),
	mutationCache: new MutationCache({
		onError: handleError,
	}),
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

export const setQueryConfigs = (queryConfigs: queryConfig[]) => {
	queryConfigs.forEach((config) => {
		queryClient.setQueryDefaults([config.queryKey], {
			queryFn: config.queryFn,
		});
	});
};

setQueryConfigs(commonQueryConfigs);
