/*eslint-disable import/no-cycle */
import { ajax } from 'rxjs/ajax';
import { getPathValue } from 'src/ui-lib/core/utils/json';
import { getStoreState, getStore } from 'src/ui-lib/core/utils/storeRegistry';
import { getAuthState, hasTsgSupport, store as sparkyStore, fawkesUtils } from 'sparky-framework';
import { parseAutoCompletionToOptions } from 'src/ui-lib/core/autorender/widgets/util';
import { getFieldXpath, RECORD_CONFIG_PATH_PREFIX_MAP } from 'src/ui-lib/core/autorender/schema/utils';
import { getAuthHeaders } from 'src/ui-lib/auth/lib';
import Pan from 'src/ui-lib/core/autorender/schema/Pan';
import _ from 'lodash';
import { errorMap } from 'src/ui-lib/core/services/errorMap';
import { formatStringTemplate } from 'src/ui-lib/core/utils/utils';
import { FAWKES } from 'src/typings/type';
import StartupManager from 'src/containers/main/StartupManager';
import { ClientErrorDetails } from 'src/containers/main/CommonTypes';
import { _T } from 'src/utils/i18n-utils';
import { PANORAMA_CONFIG } from 'src/constants';
export const SERVER_ERROR = 'SERVER_ERROR';
export const CLEAR_CURRENT_SERVER_ERROR = 'CLEAR_CURRENT_SERVER_ERROR';
export const CLIENT_ERROR = 'CLIENT_ERROR';
export const OPERATION_SUCCESS = 'OPERATION_SUCCESS';
export const MOCK_BASE_URL = 'http://localhost:9988';
export const VSYS = 'vsys1';
export const VSYS_SHARED = 'shared';

export const CONFIG_BASE_URI = '/api/config/v9.2';
export const AUTO_COMPLETE_URI = '/api/config/v9.2/complete';
export const EXTENSION_BASE_URL = '/api/extensions';
export const CONFIG_SASE_BASE_URI = '/api/sase/config/v1';

export const isMockService = () => {
	return false;
};

export function getAdminClusterURL() {
	return fawkesUtils?.getAdminClusterURL?.() || window.__admin_cluster_url || process.env.REACT_APP_ADMIN_CLUSTER;
}

export function getRedirectURL() {
	if (isMockService()) return MOCK_BASE_URL;
	let url =
		fawkesUtils?.getRedirectURL?.() || getPathValue(getStoreState() || {}, 'main.tenant.cluster.redirectURL') || '';
	if (
		StartupManager.getConfigManagedType() === PANORAMA_CONFIG &&
		StartupManager.isMigrationStateEligibleForFawkesUI() &&
		!StartupManager.hideManagementUIBasedOnMigrationState()
	) {
		const instances = getAuthState()?.instances || [];
		const fawkesInstances = instances.filter((ele: any) => {
			return ele.app_id === 'prisma_access';
		});
		const newMigratedInstance = fawkesInstances.filter((ele: any) => {
			return ele?.extra?.migrate_from;
		});
		if (fawkesInstances.length > 1 && newMigratedInstance.length > 0) {
			const paasUrl = newMigratedInstance[0]?.runtime_attributes?.paas_api_url;
			url = paasUrl || url;
		}
		const migrationTempRedirectUrl = _.get(getStoreState(), 'main.migrationTempRedirectUrl');
		// tenant is moved and page is not refreshed, use latest migrationTempRedirectUrl
		if (migrationTempRedirectUrl) {
			url = migrationTempRedirectUrl;
		}
	}

	return url.endsWith('/') ? url.substring(0, url.length - 1) : url;
}

export function getAccountSignature() {
	const { auth } = sparkyStore.getState();
	const notonorafter = auth?.user?.notonorafter;
	const currentSupportIds = _.get(auth, 'user.supportAccountIds', []);
	const currentAccountId = _.get(getStoreState(), 'main.loggedInUser.currentSupportAccountId', 0);
	const signature = currentSupportIds.find((id: any) => id.accountid === parseInt(currentAccountId))?.signature;
	return currentAccountId && notonorafter && signature
		? { 'x-signature': `${currentAccountId} ${notonorafter} ${signature}` }
		: {};
}

export function isAccountSignatureAvailable() {
	const signature = getAccountSignature();
	const accountSignatureAvailable = !Pan.isEmpty(signature);
	return accountSignatureAvailable;
}

export function getCustomServiceURL(serviceUrl: string): any {
	let SERVICE_BASE_URL = getRedirectURL();
	if (!SERVICE_BASE_URL) return '';
	SERVICE_BASE_URL = SERVICE_BASE_URL.endsWith('/')
		? SERVICE_BASE_URL.slice(0, SERVICE_BASE_URL.length - 1)
		: SERVICE_BASE_URL;
	serviceUrl = serviceUrl.startsWith('/') ? serviceUrl.slice(1, serviceUrl.length) : serviceUrl;
	return {
		SERVICE_URL: isMockService() ? `${MOCK_BASE_URL}/${serviceUrl}` : `${SERVICE_BASE_URL}/${serviceUrl}`,
	};
}

export function getAdminServiceURL(adminUrl: string, extraParams?: any): any {
	let urlParams = '?';
	if (!Pan.isEmpty(extraParams)) {
		for (const key in extraParams) {
			if (key && extraParams[key]) {
				urlParams = `${urlParams}&${key}=${extraParams[key]}`;
			}
		}
	}
	return {
		LOGIN_SERVICE_URL: isMockService()
			? `${MOCK_BASE_URL}/${adminUrl}${urlParams}`
			: `${getAdminClusterURL()}/${adminUrl}${urlParams}`,
	};
}

export function getLoginServiceURL(serviceUrl: string) {
	return {
		SERVICE_URL: isMockService() ? `${MOCK_BASE_URL}/${serviceUrl}` : `${getAdminClusterURL()}/${serviceUrl}`,
	};
}

export function getRulebasePrefix(action: FAWKES.I_OBJECT) {
	const { configLocation: { container: { type: containerType = '' } = {} } = {} }: any = action;
	const isContainerType = containerType === 'container';
	const prefix = RECORD_CONFIG_PATH_PREFIX_MAP[containerType];
	return `${prefix}.${isContainerType ? 'post-rulebase' : 'rulebase'}`;
}

export function getExtraInfoPrefix(action: FAWKES.I_OBJECT) {
	const { configLocation: { container: { type: containerType = '' } = {} } = {} } = action;
	const prefix = RECORD_CONFIG_PATH_PREFIX_MAP[containerType];
	return prefix;
}

export function parseMultiStatusError(error: FAWKES.I_OBJECT) {
	const xhr = error && error.xhr ? error.xhr : null;
	const response = xhr && xhr.response ? xhr.response : null;
	const message: FAWKES.I_OBJECT = {};
	if (_.has(response, 'failed_items')) {
		const newErrors = response.failed_items.map((entry: any) => {
			const message = `${entry.key} : ${entry.reason}`;
			const errorMsg = message ? message : 'Server Error';
			return formatStringTemplate(errorMsg);
		});
		message.newErrorMsg = newErrors;
		message.errorCode = xhr.status;
		return message;
	} else {
		//switch back to existing error handler in case its not returning multistatus code / error
		return parseError(error);
	}
}

export function isProductizedErrorStructure(error: FAWKES.I_OBJECT): boolean {
	return Array.isArray(error._errors) && error._errors.length > 0;
}

export function processProductizedError(error: FAWKES.I_OBJECT) {
	const detailedErrors = error._errors[0].details?.errors;
	const detailedMessage = error._errors[0].details?.message;

	let details = detailedErrors;

	if (Array.isArray(details)) {
		details = details.map((detail) => {
			if (Array.isArray(detail.params)) {
				if (typeof detail?.msg === 'string') detail.msg = detail.msg.concat(' ', detail.params.join(', '));
				if (typeof detail?.message === 'string')
					detail.message = detail.message.concat(' ', detail.params.join(', '));
			}
			return detail;
		});
	}

	if (Array.isArray(detailedMessage)) {
		detailedMessage.forEach((message) =>
			details.push({
				message,
			}),
		);
	}

	const errorCode = error._errors[0].code || 'API_I00013';
	const errorMessage = error._errors[0].message;
	let errorDetails;
	const type = details?.[0]?.type || '';
	const errorInfo = _.get(errorMap, type);
	const title = errorInfo?.title || _T('Invalid Request');
	if (details) {
		errorDetails = details.map((err: any = {}) => {
			// ADI-41270 UI is stuck at loading forever when disassociating the snippet with references, but API fails with valid error.
			const { msg = '', message = '' } = err;
			return formatStringTemplate(msg || message);
		});
	}
	return {
		productizedError: {
			errorDetails,
			errorCode,
			errorMessage,
		},
		title,
	};
}

// parse error from server api
export function parseError(error: FAWKES.I_OBJECT) {
	if (isProductizedErrorStructure(error)) return processProductizedError(error);
	if (error.response && isProductizedErrorStructure(error.response)) return processProductizedError(error.response);
	const xhr = error && error.xhr ? error.xhr : null;
	let response = xhr && xhr.response ? xhr.response : null;
	let message: FAWKES.I_OBJECT = {};

	if (xhr && xhr.status === 403) {
		//session timed out. Navigate to login
		let loginUrl: unknown = getLoginServiceURL('api/sso/v1/login')['SERVICE_URL'];
		//tenant from redux store
		const store = getStoreState();
		const tenantId = _.get(store, 'main.tenant.id', null);
		if (tenantId) {
			loginUrl += `?tenantId=${tenantId}`;
		}
		if (!hasTsgSupport()) {
			window.location = loginUrl as Location;
		}
		return;
	} else if (response && response.failed_items) {
		const errArr: any = [];
		const errMsgArr: any = [];
		let errMsg = '';
		response.failed_items.forEach((message: any) => {
			const errObj = {
				code: 'NS_0001',
				message: `${message.key}: ${message.reason}`,
				details: '',
			};
			errArr.push(errObj);
			errMsg += `${message.key}: ${message.reason}. \n`;
			errMsgArr.push(`${message.key}: ${message.reason}.`);
		});
		message = {
			msg: errMsg,
			errMsgArr: errMsgArr,
		};
		response = {
			_errors: errArr,
		};
	} else if (response && response.errorMessage) {
		message = { msg: response.errorMessage };
	} else if (response && response.error) {
		message = { msg: response.error };
	} else if (error && error.message) {
		message = { msg: error.message };
	} else {
		message = { msg: 'Server Error' };
	}

	// make a copy of the response
	if (response) {
		const responseObj = response._errors ? response._errors[0] : response;
		const {
			extra,
			errorCode,
			code,
			errorMessage,
			message: newErrorMessage,
			httpStatusCode,
			trackingId,
			extra: { errors: { entry: errorList = [] } = {} } = {},
			details: { errors: errorListProd = [], message: errorMsg = '' } = {},
		} = responseObj;
		message.extra = extra;
		message.errorCode = errorCode || code;
		message.errorMessage = errorMessage || errorMsg || newErrorMessage;
		message.httpStatusCode = httpStatusCode;
		message.trackingId = trackingId;
		const errorEntries = !_.isEmpty(errorList) ? errorList : errorListProd;
		const hasExtraError = Array.isArray(_.get(errorEntries?.[0], 'extra'));
		if (errorEntries.length > 0) {
			const newErrors = errorEntries.map((entry: any) => {
				const {
					message: errMsg,
					msg,
					params: { member: paramMembers = [] } = {},
					details: { errors: errorMembers = [] } = {},
				} = entry;
				const errorMsg = msg || errMsg ? msg || errMsg : 'Server Error';
				return formatStringTemplate(errorMsg, ...paramMembers, ...errorMembers);
			});
			if (hasExtraError) {
				const extraErrors = getExtraEntries(errorEntries);
				message.extra = { errors: extraErrors };
			}
			message.newErrorMsg = newErrors;
			const type = errorEntries[0]['@type'] || errorEntries[0]['type'];
			const errorInfo = _.get(errorMap, type);
			if (errorInfo && errorInfo.title) {
				message.title = errorInfo.title;
			}
		}
	}
	if (_.isString(message.msg) && message.msg.startsWith('ajax error')) message.msg = message.errorMessage;
	message.timestamp = new Date().toLocaleTimeString();
	message.trackingId = message.trackingId || Pan.id('tracking-gen-');
	return message;
}

export function parseQuarantineDeviceError(error: FAWKES.I_OBJECT) {
	const xhr = error && error.xhr ? error.xhr : null;
	let response = xhr && xhr.response ? xhr.response : null;
	let message: FAWKES.I_OBJECT = {};

	if (xhr && xhr.status === 403) {
		//session timed out. Navigate to login
		let loginUrl: unknown = getLoginServiceURL('api/sso/v1/login')['SERVICE_URL'];
		//tenant from redux store
		const store = getStoreState();
		const tenantId = _.get(store, 'main.tenant.id', null);
		if (tenantId) {
			loginUrl += `?tenantId=${tenantId}`;
		}
		window.location = loginUrl as Location;
		return;
	} else if (response && response.failed_items) {
		const errArr: any = [];
		const errMsgArr: any = [];
		let errMsg = '';
		response.failed_items.forEach((message: any) => {
			const errObj = {
				code: 'NS_0001',
				message: `${message.key}: ${message.reason}`,
				details: '',
			};
			errArr.push(errObj);
			errMsg += `${message.key}: ${message.reason}. \n`;
			errMsgArr.push(`${message.key}: ${message.reason}.`);
		});
		message = {
			msg: errMsg,
			errMsgArr: errMsgArr,
		};
		response = {
			_errors: errArr,
		};
	} else if (response && response.errorMessage) {
		message = { msg: response.errorMessage };
	} else if (response && response.error) {
		message = { msg: response.error };
	} else if (error && error.message) {
		message = { msg: error.message };
	} else {
		message = { msg: 'Server Error' };
	}

	if (message.msg && message.msg.startsWith('ajax error')) message.msg = message.errorMessage;
	message.timestamp = new Date().toLocaleTimeString();
	message.trackingId = message.trackingId || Pan.id('tracking-gen-');

	if (!response) {
		// inject 400 error code since /device-quarantine error returns 200 status code
		message.errorCode = 400;
	}

	return message;
}

// create an object for modal dialog for client side errors - currently supports strings, can be extended to support complex objects
export function parseClientError(_title: string, _body: string, _code = ''): ClientErrorDetails {
	const message = {
		title: _title,
		msg: _body,
		errorCode: _code,
	};
	return message;
}

const getExtraEntries = (errorEntries: []) => {
	if (!Array.isArray(errorEntries)) {
		return [];
	}
	const extraErrors = errorEntries.reduce((total: any, current: any) => {
		const { extra = [], type = '' } = current;
		const newExtra = extra?.map((str: string) => {
			return { prefix: `-> ${str}` };
		});
		const obj = {
			extra: extra,
			'@type': type,
			references: { entry: newExtra },
		};
		return [...total, obj];
	}, []);
	return extraErrors;
};

export function getAutoCompletionURL() {
	const SERVICE_BASE_URL = getRedirectURL();
	const serviceUrl = `${SERVICE_BASE_URL}${AUTO_COMPLETE_URI}`;
	return serviceUrl;
}

export function getGenericAutoCompleteWithParams({
	inputValue,
	schemaPath,
	location,
	filter,
	additional,
	onlyNotInUse = true,
	pobj,
	addOptions,
}: {
	inputValue: string;
	schemaPath: string;
	location: FAWKES.configLocation;
	filter?: FAWKES.func;
	additional?: any;
	onlyNotInUse?: boolean;
	pobj?: any;
	addOptions?: FAWKES.func;
}): Promise<any> {
	return new Promise(function (resolve, reject) {
		ajax(autoCompletionObservable(inputValue, schemaPath, location, additional, pobj))
			.toPromise()
			.then(
				(resp) => {
					let options = parseAutoCompletionToOptions(
						resp,
						undefined,
						undefined,
						undefined,
						onlyNotInUse,
						undefined,
					);
					if (_.isFunction(addOptions)) {
						options = [...options, ...addOptions()];
					}
					if (_.isFunction(filter)) {
						options = filter(options);
					}

					resolve(options);
				},
				(err) => {
					const store = getStore();
					store.dispatch({
						type: SERVER_ERROR,
						errorMessage: parseError(err),
						showMessage: false,
					});
					reject(err);
				},
			);
	});
}

export function getGenericAutoCompleteByObjectParams({
	inputValue,
	schemaPath,
	location,
	filter,
	onlyNotInUse,
	combineHelpString,
}: FAWKES.GenericAutoCompleteProps): Promise<any> {
	return new Promise(function (resolve, reject) {
		ajax(autoCompletionObservable(inputValue, schemaPath, location))
			.toPromise()
			.then(
				(resp) => {
					let options = parseAutoCompletionToOptions(
						resp,
						undefined,
						undefined,
						undefined,
						onlyNotInUse,
						combineHelpString,
					);
					if (_.isFunction(filter)) {
						options = filter(options);
					}
					resolve(options);
				},
				(err) => {
					const store = getStore();
					store.dispatch({
						type: SERVER_ERROR,
						errorMessage: parseError(err),
						showMessage: false,
					});
					reject(err);
				},
			);
	});
}

export function getGenericAutoComplete(
	inputValue: any,
	schemaPath: any,
	location: any,
	filter?: FAWKES.func,
	filer2?: any,
	cellInfo?: any,
	pobj?: any,
	additional?: any,
): Promise<any> {
	return new Promise(function (resolve, reject) {
		ajax(autoCompletionObservable(inputValue, schemaPath, location, additional, pobj))
			.toPromise()
			.then(
				(resp) => {
					let options = parseAutoCompletionToOptions(
						resp,
						undefined,
						undefined,
						undefined,
						undefined,
						undefined,
					);
					if (_.isFunction(filter)) {
						options = filter(options);
					}
					resolve(options);
				},
				(err) => {
					const store = getStore();
					store.dispatch({
						type: SERVER_ERROR,
						errorMessage: parseError(err),
						showMessage: false,
					});
					reject(err);
				},
			);
	});
}

export function getGenericAutoCompleteWithoutHelpString(
	inputValue: any,
	schemaPath: any,
	location: any,
	filter?: FAWKES.func,
): Promise<any> {
	return new Promise(function (resolve, reject) {
		ajax(autoCompletionObservable(inputValue, schemaPath, location))
			.toPromise()
			.then(
				(resp) => {
					let options = parseAutoCompletionToOptions(resp, undefined, undefined, undefined, undefined, false);
					if (_.isFunction(filter)) {
						options = filter(options);
					}
					resolve(options);
				},
				(err) => {
					const store = getStore();
					store.dispatch({
						type: SERVER_ERROR,
						errorMessage: parseError(err),
						showMessage: false,
					});
					reject(err);
				},
			);
	});
}

export function autoCompletionObservable(
	input: any,
	schemaPath: string,
	location: any,
	additional?: any,
	pobj?: any,
	includeXpath?: any,
) {
	const AUTO_COMPLETION_URL = getAutoCompletionURL();
	const xpath = getFieldXpath(schemaPath, location);
	const body: FAWKES.I_OBJECT = {
		xpath: xpath,
	};

	if (additional) {
		body['additional'] = additional;
	}

	if (pobj) {
		body['pobj'] = pobj;
	}

	if (includeXpath) {
		body['includeXpath'] = 'yes';
	}

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: AUTO_COMPLETION_URL,
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getMuLocalUsers() {
	const { SERVICE_URL } = getCustomServiceURL(
		`/sase/config/v1/local-users?type=cloud&folder=Mobile Users&pagination=false`,
	);
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getCertificateId(name: string, field: string, template: string) {
	const { SERVICE_URL } = getCustomServiceURL(`/api/config/v9.2/showVpnCertificateCommand`);
	const payload = {
		name,
		field,
		template,
	};
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		body: payload,
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}
