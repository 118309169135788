import _ from 'lodash';
import { PAGINATION_QUERY_PARAM } from 'src/containers/main/constants';
import { SNIPPET } from 'service-lib/services/constants';
import { getScopeParamFromType } from 'service-lib/services/scopeServices';
import { FAWKES } from 'src/typings/type';
import { getAuthHeaders } from 'ui-lib/auth/lib';
import Pan from 'ui-lib/core/autorender/schema/Pan';
import { getWhereUsedXpath } from 'ui-lib/core/autorender/schema/utils';
import {
	getRedirectURL,
	CONFIG_BASE_URI,
	CONFIG_SASE_BASE_URI,
	isMockService,
	MOCK_BASE_URL,
	AUTO_COMPLETE_URI,
	EXTENSION_BASE_URL,
} from 'ui-lib/core/services/CommonServices';
import { getStoreState } from 'ui-lib/core/utils/storeRegistry';
import { chunk_split } from 'src/utils/fileUtils';
import { appendQueryParamsToURL } from 'src/utils/url';
import { INCLUDE_LOCAL_QUERY_PARAM_OBJ } from 'src/containers/localConfig/constants';
import { isLocalConfigEnabled } from 'src/containers/config/features';
import { ANTISPYWARE_TABLE, VULNERABILITY_TABLE } from 'src/db/dbSchema';
import { from, of } from 'rxjs';
import { getCount, search } from 'src/db/dbOperations';

export * from 'src/service-lib/services/constants';

// service functions
export function getServiceURLS(
	serviceName: string,
	configLocation?: FAWKES.configLocation,
	extraParams: any = {},
	useProductizedApi = false,
	paramContainerTypeEqualsName = false,
	useName = '',
	withoutTypeParam = false,
) {
	const SERVICE_BASE_URL = getRedirectURL();
	let urlParams = '';
	const serviceUrl: FAWKES.I_OBJECT = {};
	let ConfigBaseUri = CONFIG_BASE_URI;
	const { ignoreContainer = false } = extraParams || {};

	const containerTypeMap: any = {
		container: 'folder',
		'on-prem': 'device',
	};
	serviceName = serviceName.startsWith('/') ? serviceName.slice(1, serviceName.length) : serviceName;
	//construct location url params
	if (configLocation && configLocation.container) {
		const { name, type } = configLocation.container || {};
		const containerType = _.get(configLocation, 'container.type', 'container');
		const locationUrlParam = `type=${type}`;
		const folderUrlParam = paramContainerTypeEqualsName
			? `${containerTypeMap[containerType]}=${name}`
			: `folder=${name}`;
		const snippetUrlParamWithoutName = type === SNIPPET ? `snippet=${name}` : '';
		const snippetUrlParamWithName = type === SNIPPET ? `snippet=${name}&name=${useName}` : '';

		const snippetUrlParam = useName ? snippetUrlParamWithName : snippetUrlParamWithoutName;

		if (useProductizedApi) {
			ConfigBaseUri = CONFIG_SASE_BASE_URI;
		}
		if (withoutTypeParam) {
			urlParams = snippetUrlParam ? `?${snippetUrlParam}` : `?${folderUrlParam}`;
		} else {
			urlParams = snippetUrlParam
				? `?${locationUrlParam}&${snippetUrlParam}`
				: `?${locationUrlParam}&${folderUrlParam}`;
		}

		if (ignoreContainer) {
			urlParams = '';
		}

		if (!Pan.isEmpty(extraParams)) {
			for (const key in extraParams) {
				if (key && extraParams[key] && key !== 'ignoreContainer') {
					if (Array.isArray(extraParams[key]) && key === 'additionalInfo') {
						urlParams = extraParams[key].reduce((result: any, curr: any) => {
							return `${result}&${key}=${curr}`;
						}, urlParams);
					} else if (key === 'useBpaAdditionalInfo') {
						urlParams = `${urlParams}&additionalInfo=bpa`;
					} else {
						urlParams = `${urlParams}&${key}=${extraParams[key]}`;
					}
				}
			}
		}

		serviceUrl.SERVICE_URL = isMockService()
			? `${MOCK_BASE_URL}/api/${serviceName}${urlParams}`
			: `${SERVICE_BASE_URL}${ConfigBaseUri}/${serviceName}${urlParams}`;
		serviceUrl.RENAME_ACTION_URL = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}/rename${urlParams}`;
		serviceUrl.CLONE_ACTION_URL = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}/multiclone${urlParams}`;
		serviceUrl.DISABLE_RULES_URL = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}/disable${urlParams}`;
		serviceUrl.MOVE_RULES_URL = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}/move${urlParams}`;
		serviceUrl.AUTO_COMPLETION_URL = `${SERVICE_BASE_URL}${AUTO_COMPLETE_URI}${urlParams}`;
	} else if (configLocation && configLocation.extension) {
		const extensionName = configLocation.extension.gpcs.name;
		serviceUrl.SERVICE_URL = isMockService()
			? `${MOCK_BASE_URL}/api/${serviceName}${urlParams}`
			: `${SERVICE_BASE_URL}${EXTENSION_BASE_URL}/${extensionName}/v1/${serviceName}`;
		serviceUrl.RENAME_ACTION_URL = `${SERVICE_BASE_URL}${EXTENSION_BASE_URL}/${extensionName}/v1/${serviceName}/rename`;
		serviceUrl.CLONE_ACTION_URL = `${SERVICE_BASE_URL}${EXTENSION_BASE_URL}/${extensionName}/v1/${serviceName}/clone`;
		serviceUrl.AUTO_COMPLETION_URL = `${SERVICE_BASE_URL}${EXTENSION_BASE_URL}/${extensionName}/v1/complete`;
	} else {
		urlParams = '?';
		if (!Pan.isEmpty(extraParams)) {
			for (const key in extraParams) {
				if (key && extraParams[key]) {
					urlParams = `${urlParams}&${key}=${extraParams[key]}`;
				}
			}
		}
		//no location. Set the serviceName as url
		serviceUrl.SERVICE_URL = isMockService()
			? `${MOCK_BASE_URL}/${serviceName}${urlParams}`
			: `${SERVICE_BASE_URL}/${serviceName}${urlParams}`;
	}

	return serviceUrl;
}

export function getCustomServiceURL(serviceUrl: string) {
	const SERVICE_BASE_URL = getRedirectURL();
	return {
		SERVICE_URL: isMockService() ? `${MOCK_BASE_URL}/${serviceUrl}` : `${SERVICE_BASE_URL}/${serviceUrl}`,
	};
}

export function getDefaultSecurityRulesObservable(serviceName: string, location: FAWKES.configLocation) {
	const { SERVICE_URL } = getServiceURLS(serviceName, location, { pos: 'post', isBpa: 'no' });
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getWhereUsedObservable(
	recordConfigPath: string,
	location: FAWKES.configLocation,
	itemArray: FAWKES.I_OBJECT,
	fromXPathArray?: any,
	detailedInfo = 'no',
	ignoreNonExistent = 'yes',
	depth = 1,
	withTypeMap = false,
) {
	const { SERVICE_URL } = getServiceURLS('api/config/v9.2/whereCommand', undefined, {
		'detailed-info': detailedInfo ? detailedInfo : 'no',
		'ignore-non-exist': ignoreNonExistent ? ignoreNonExistent : 'yes',
		depth,
	});
	const entryArray: any[] = [];
	if (!withTypeMap) {
		Object.keys(itemArray).forEach((loc) => {
			const toXpath = getWhereUsedXpath(recordConfigPath, { loc: loc, vsys: location.vsys });
			entryArray.push({
				'@to-xpath': toXpath,
				name: {
					member: itemArray[loc],
				},
			});
		});
	} else {
		Object.keys(itemArray).forEach((loc) => {
			const toXpath = getWhereUsedXpath(recordConfigPath, {
				loc: loc,
				type: itemArray[loc]?.type,
				vsys: location.vsys,
			});
			entryArray.push({
				'@to-xpath': toXpath,
				name: {
					member: itemArray[loc]?.items,
				},
			});
		});
	}

	const payload = {
		list: {
			entry: entryArray,
		},
	};
	if (fromXPathArray) {
		_.set(payload, 'from-xpath.member', fromXPathArray);
	}
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function editAuthPortalObservable(record: FAWKES.IFormData, location: FAWKES.configLocation) {
	const { SERVICE_URL } = getServiceURLS('Device/AuthenticationPortal', location);
	const url_base = SERVICE_URL;
	const service_url = url_base;

	const editRecord = {
		'captive-portal': {
			...record,
		},
	};

	return {
		withCredentials: isMockService() ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: service_url,
		body: JSON.stringify(editRecord),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function addKerberosKeytabActionObservable(
	record: FAWKES.IFormData,
	serviceName: string,
	location: FAWKES.configLocation,
) {
	const { name: folder, type } = location.container || {};

	const rec = {
		...record,
		folder,
		type,
	};

	const { SERVICE_URL } = getServiceURLS(serviceName, location);

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(rec),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function setSessionInfoSharingObservable(
	location: FAWKES.configLocation | undefined,
	payload: FAWKES.I_ACTION_PAYLOAD,
) {
	const prefix = "devices/entry[@name='localhost.localdomain']/deviceconfig/setting/wildfire/session-info-select";
	const SERVICE_URLS = getServiceURLS('configByPath', location, { prefix, isNewOnly: 'no' });
	return {
		withCredentials: true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URLS.SERVICE_URL,
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function addResponsePageObservable({
	record,
	location,
	pageName,
}: {
	record: FAWKES.I_OBJECT;
	location: FAWKES.configLocation;
	pageName?: string;
}) {
	const { fileContent, blockPage } = record;

	const { SERVICE_URL } = getServiceURLS('Device/BlockPage', location, { blockPage, pageName });
	//split the filecontent to 76 character length
	const fileContentSplit = chunk_split(fileContent, '76', '\n');
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify({
			fileContent: fileContentSplit,
		}),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function responsePageObservable({
	location,
	method,
	extraParams,
}: {
	location: FAWKES.configLocation;
	method: string;
	extraParams: any;
}) {
	const { SERVICE_URL } = getServiceURLS('Device/BlockPage', location, extraParams);

	return {
		withCredentials: isMockService() ? false : true,
		method,
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchPeerAnalysisObservable(category: any) {
	const { SERVICE_URL } = getServiceURLS('api/peerAnalysis/average', undefined, {
		category,
	});
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function updateConfigStatusObservable() {
	const { SERVICE_URL } = getCustomServiceURL('/api/config/v9.2/Operations/UpdateConfigStatus');
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchThreatSearchHitCountObservable() {
	const { SERVICE_URL } = getCustomServiceURL(`/api/config/v9.2/threatHitCountCommand`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function updateMuEpRegionsObservable(name: string, serviceType = 'mobile-users') {
	// has a name parameter - @name of MUEP
	const { SERVICE_URL } = getCustomServiceURL(
		`/api/config/v9.2/Plugins/CloudServices/MU/Regions?name=${name}&serviceType=${serviceType}`,
	);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getCountBySearchCriteriaObservable(profileType: string, formData: any): any {
	// const { SERVICE_URL } = getServiceURLS(`api/contentservice/${profileType}/countBySearchCriteria`);
	const tableName = profileType === 'vulnerability' ? VULNERABILITY_TABLE : ANTISPYWARE_TABLE;
	const rules = [];
	if (formData.rules && formData.rules.entry) {
		formData.rules.entry.forEach((item: any) => {
			const rule: FAWKES.I_OBJECT = {};
			rule['count'] = -1;
			rule['ruleName'] = item['@name'];
			if (item.category && item.category !== 'any') {
				rule['category'] = [item.category];
			}
			if (item.action && Object.keys(item.action)[0] !== 'default') {
				// rule['action'] = Object.keys(item.action)[0];
			}
			if (item['threat-name'] && item['threat-name'] !== 'any') {
				rule['name'] = item['threat-name'];
			}
			if (item.severity && item.severity.member) {
				const severity = item.severity.member.map((item: any) => item.value);
				if (severity[0] !== 'any') {
					rule['severity'] = severity;
				}
			}
			// different type profile has different value, we can't use undefined or `any` in this case
			if (profileType === 'vulnerability') {
				if (item.host) {
					if (item['host'] !== 'any') {
						rule['host'] = [item['host']];
					}
				}
				if (item['vendor-id'] && item['vendor-id'].member) {
					const id = item['vendor-id'].member.map((item: any) => item.value);
					if (id[0] !== 'any') {
						rule['vendor'] = id;
					}
				}
				if (item['cve'] && item['cve'].member) {
					const cve = item['cve'].member.map((item: any) => item.value);
					if (cve[0] !== 'any') {
						rule['cve'] = cve;
					}
				}
			}
			rules.push(rule);
		});
	}

	if (!rules.length) return of({ type: '' });
	const promises = rules.map((rule: any) => search(tableName, rule));
	promises.push(getCount(tableName));
	return from(Promise.all(promises));
}

export function getExternalDynamicListsRecord(location: FAWKES.configLocation | undefined, additionalInfo: any) {
	const { actionCalledFrom } = !_.isUndefined(additionalInfo) ? additionalInfo : { actionCalledFrom: undefined };

	// remove this key so that the getServiceURLS doesn't append it to the end of the api endpoint url
	if (!_.isEmpty(additionalInfo)) {
		delete additionalInfo.actionCalledFrom;
	}

	const { SERVICE_URL } = getServiceURLS('Objects/ExternalDynamicLists', location, { ...additionalInfo });

	// The below Hack is needed because this grid doesn't follow the usual logic which most grids follow (it doesn't use getRecordsActionObservable)
	// and it has a customized GET. We cannot send the `include-local=true` param for all GETs because on the form, since the form is not local-config-aware,
	// we need to explicitly NOT send the `include-local` param. The only way to distinguish if this function is being called from the grid or from the form, is
	// by explicitly signalling who the caller is by passing that info inside the `additionalInfo` object which HLRecordViewer calls this function with
	const apiEndpoint =
		isLocalConfigEnabled() && actionCalledFrom !== 'HLRecordViewer' // Hack! Hack!
			? appendQueryParamsToURL(SERVICE_URL, INCLUDE_LOCAL_QUERY_PARAM_OBJ)
			: SERVICE_URL;
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: apiEndpoint,
		headers: getAuthHeaders(),
	};
}

export function fetchOptmizationFailedCountObservable() {
	const { SERVICE_URL } = getServiceURLS(`api/sase/config/v1.0/policies/optimizationFailedCount`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${SERVICE_URL}${PAGINATION_QUERY_PARAM}`,
		headers: getAuthHeaders(),
	};
}
export function fetchCIETenantIdObservable() {
	const { container: { name = '', type = '' } = {} } = _.get(getStoreState(), 'main.configLocation');
	const scopeParam = getScopeParamFromType(type);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		url: `${getRedirectURL()}/sase/config/v1/project-based-accesses?${scopeParam}=${name}`,
		responseType: 'json',
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function updateCIETenantIdObservable(tenantId: any) {
	const { container: { name = '', type = '' } = {} } = _.get(getStoreState(), 'main.configLocation');
	const scopeParam = getScopeParamFromType(type);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		url: `${getRedirectURL()}/sase/config/v1/project-based-accesses?${scopeParam}=${name}`,
		responseType: 'json',
		dataType: 'json',
		ContentType: 'application/json',
		body: JSON.stringify({ cie_tenant_id: tenantId }),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getDDnsContentVendorsObservable() {
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${getRedirectURL()}/api/contentservice/ddns-content-vendors`,
		headers: getAuthHeaders(),
	};
}

export function getCommandObservable(url: string, method: string, command?: FAWKES.TROUBLESHOOTING_COMMAND) {
	const { SERVICE_URL } = getCustomServiceURL(`ngfw/commands/${url}`);
	return {
		withCredentials: true,
		method: method,
		body: command ? JSON.stringify(command) : '',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getGeneralSettingsObservable(configLocation: any) {
	const { container: { name = '', type = '' } = {} } = configLocation;
	const scopeParam = getScopeParamFromType(type);
	const url = `${getRedirectURL()}/api/sase/config/v1/device-config/general-settings?${PAGINATION_QUERY_PARAM}&${scopeParam}=${name}`;

	return {
		withCredentials: true,
		method: 'GET',
		headers: {
			...getAuthHeaders(),
			'Content-Type': 'application/json',
		},
		dataType: 'json',
		ContentType: 'application/json',
		url: url,
	};
}
