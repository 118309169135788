import { getAuthHeaders } from 'ui-lib/auth/lib';
import Pan from 'ui-lib/core/autorender/schema/Pan';
import { getLoginServiceURL } from 'ui-lib/core/services/CommonServices';

export function generateTechSupportDataObservable(caseId: any) {
	let { SERVICE_URL } = getLoginServiceURL('api/techSupport/generate');
	return {
		withCredentials: true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: { 'Content-Type': 'application/json', ...getAuthHeaders() },
		body: !Pan.isEmpty(caseId) ? JSON.stringify({ caseId }) : {},
	};
}

export function getRecentTechSupportRequestsObservable(limit?: any) {
	let { SERVICE_URL } = getLoginServiceURL('api/techSupport');
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: { 'Content-Type': 'application/json', ...getAuthHeaders() },
		body: Pan.isDefined(limit) ? JSON.stringify({ limit }) : {},
	};
}
