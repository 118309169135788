import { FAWKES } from 'src/typings/type';
import { getStore } from 'src/ui-lib/core/utils/storeRegistry';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const UPDATE_MODAL = 'UPDATE_MODAL';
export const SHOW_SLIDING_PANE = 'SHOW_SLIDING_PANE';
export const HIDE_SLIDING_PANE = 'HIDE_SLIDING_PANE';
export const UPDATE_SLIDING_PANE = 'UPDATE_SLIDING_PANE';
export const SHOW_PORTAL = 'SHOW_PORTAL';
export const HIDE_PORTAL = 'HIDE_PORTAL';
export const UPDATE_PORTAL = 'UPDATE_PORTAL';
export const SHOW_CONFIG_VIEW = 'SHOW_CONFIG_VIEW';
export const HIDE_CONFIG_VIEW = 'HIDE_CONFIG_VIEW';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const showModal = (modal: FAWKES.I_ACTION_PAYLOAD) => {
	const { dispatch } = getStore();
	dispatch({
		type: SHOW_MODAL,
		modal,
	});
};

export const hideModal = (id: string) => {
	const { dispatch } = getStore();
	dispatch({
		type: HIDE_MODAL,
		id,
	});
};

export const updateModal = (id: string, props: FAWKES.I_ACTION_PAYLOAD) => {
	const { dispatch } = getStore();
	dispatch({
		type: UPDATE_MODAL,
		id,
		props,
	});
};

export const showSlidingPane = (slidingPane: FAWKES.I_ACTION_PAYLOAD) => {
	const { dispatch } = getStore();
	dispatch({
		type: SHOW_SLIDING_PANE,
		slidingPane,
	});
};

export const hideSlidingPane = (id: string) => {
	const { dispatch } = getStore();
	dispatch({
		type: HIDE_SLIDING_PANE,
		id,
	});
};

export const updateSlidingPane = (id: string, props: FAWKES.I_ACTION_PAYLOAD) => {
	const { dispatch } = getStore();
	dispatch({
		type: UPDATE_SLIDING_PANE,
		id,
		props,
	});
};

export const showPortal = (portal: FAWKES.I_ACTION_PAYLOAD) => {
	const { dispatch } = getStore();
	dispatch({
		type: SHOW_PORTAL,
		portal,
	});
};

export const hidePortal = () => {
	const { dispatch } = getStore();
	dispatch({
		type: HIDE_PORTAL,
	});
};

export const showConfigView = (viewerName: string, viewerProps: FAWKES.I_ACTION_PAYLOAD) => {
	const { dispatch } = getStore();
	dispatch({
		type: SHOW_CONFIG_VIEW,
		viewerName,
		viewerProps: {
			...viewerProps,
			renderFromSlidingPaneOrForm: true,
		},
	});
};

export const hideConfigView = () => {
	const { dispatch } = getStore();
	dispatch({
		type: HIDE_CONFIG_VIEW,
	});
};

export const showLoading = () => {
	const { dispatch } = getStore();
	dispatch(startLoading());
};

export const hideLoading = () => {
	const { dispatch } = getStore();
	dispatch(finishLoading());
};

export const startLoading = (renderTo?: string | HTMLElement | undefined) => ({
	type: SHOW_LOADING,
	renderTo,
});

export const finishLoading = () => ({
	type: HIDE_LOADING,
});
