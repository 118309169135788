import { queryConfig } from 'src/ReactQueryClient';
import { ALL } from 'src/service-lib/services/constants';
import { FAWKES } from 'src/typings/type';
import { getAuthHeaders } from 'src/ui-lib/auth/lib';
import { getRedirectURL } from 'src/ui-lib/core/services/CommonServices';
import { DEVICES_QC_KEY, FOLDER_SCOPE_QC_KEY, SYNC_STATUS_QC_KEY } from 'src/query-client-utils/queryKeyConstants';
import { getStore, getStoreState } from 'src/ui-lib/core/utils/storeRegistry';
import {
	FETCH_SCOPE_MANAGE_INFO_SUCCESS,
	updateScopeManageInfo,
	UPDATE_DEVICES_INFO,
} from 'src/containers/main/actions';
import { PAGINATION_QUERY_PARAM } from 'src/containers/main/constants';
import { cloneDeep } from 'lodash';

export const fetchSyncStatus = async () => {
	const baseURL = getRedirectURL();
	const endpoint = '/sase/config/v1/device-config/sync-status';
	const url = new URL(endpoint, baseURL);
	url.searchParams.append('folder', ALL);
	const param: FAWKES.I_FETCH_PARAM = {
		method: 'GET',
		headers: getAuthHeaders(),
	};
	try {
		const response = await fetch(url.toString(), param);
		if (!response.ok) {
			throw new Error(`HTTP error ${response.status}`);
		}
		const json = await response.json();
		if (Array.isArray(json)) return json;
		return [];
	} catch (error) {
		console.error('Error fetching sync status:', error);
		return [];
	}
};

export const fetchDeviceDetails = async () => {
	const baseURL = getRedirectURL();
	const endpoint = '/ngfw/api/v1/devices';
	const url = new URL(endpoint, baseURL);
	const param: FAWKES.I_FETCH_PARAM = {
		method: 'GET',
		headers: getAuthHeaders(),
	};
	const response = await fetch(url.toString(), param);
	let data = await response.json();
	if (data.error) {
		data = [];
	}
	const devicesInfo = data.reduce((result: FAWKES.I_OBJECT, e: FAWKES.I_OBJECT) => {
		const { id } = e;
		return {
			...result,
			[id]: {
				...e,
			},
		};
	}, {});
	await getStore().dispatch({
		type: UPDATE_DEVICES_INFO,
		devicesInfo,
	});

	return data;
};

export const fetchFolderScope = async () => {
	const baseURL = getRedirectURL();
	const endpoint = `/sase/config/v1/folders?${PAGINATION_QUERY_PARAM}`;
	const url = new URL(endpoint, baseURL);
	const param: FAWKES.I_FETCH_PARAM = {
		method: 'GET',
		headers: getAuthHeaders(),
	};
	try {
		const response = await fetch(url.toString(), param);
		if (!response.ok) {
			throw new Error(`HTTP error ${response.status}`);
		}
		const json = await response.json();
		const { data = [] } = json;
		const { main: { devicesInfo = {}, haPairConfigDevicesMap = {} } = {} } = getStoreState();
		await getStore().dispatch({
			type: FETCH_SCOPE_MANAGE_INFO_SUCCESS,
			originalScopeData: cloneDeep(data),
		});
		await getStore().dispatch(
			updateScopeManageInfo({
				scopeData: data,
				devicesInfo,
				haPairConfigDevicesMap,
			}) as any,
		);
		return json;
	} catch (error) {
		console.error('Error fetching folder scope:', error);
		return [];
	}
};

export const commonQueryConfigs: queryConfig[] = [
	{
		queryKey: DEVICES_QC_KEY,
		queryFn: fetchDeviceDetails,
	},
	{
		queryKey: FOLDER_SCOPE_QC_KEY,
		queryFn: fetchFolderScope,
	},
	{
		queryKey: SYNC_STATUS_QC_KEY,
		queryFn: fetchSyncStatus,
	},
	// @Tulga, Enable this one day so it becomes one of part of default queries
	// {
	// 	queryKey: CLOUD_NGFW_RESOURCES_QC_KEY,
	// 	queryFn: fetchCloudNgfws
	// }
];
