/* eslint-disable prefer-const */
import {
	getCustomServiceURL,
	getAdminServiceURL,
	isMockService,
	getRedirectURL,
} from 'ui-lib/core/services/CommonServices';
import { ajax } from 'rxjs/ajax';
import { getServiceURLS, SNIPPET, JUPITER, LOCATION_KEY_MAP } from 'service-lib/services/services';
import Pan from 'ui-lib/core/autorender/schema/Pan';
import { getAuthHeaders } from 'ui-lib/auth/lib';
import _ from 'lodash';
import { FAWKES } from 'src/typings/type';

import { PAGINATION_QUERY_PARAM } from 'src/containers/main/constants';

export function fetchLatestPushStatusObservable() {
	let url = `api/sase/config/v1/latest-jobs`;

	let { SERVICE_URL } = getCustomServiceURL(url);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchRemoteNetworksStatusObservable(params?: { [k: string]: any }) {
	let url = `api/extensions/gpcs/status/getFWaaSOverviewStat`;
	if (params && _.isObject(params)) {
		url += '?';
		url += Object.keys(params).reduce((a, b) => {
			return a.length === 0
				? `${a}${b}=${(params as FAWKES.I_OBJECT)[b]}`
				: `${a}&${b}=${(params as FAWKES.I_OBJECT)[b]}`;
		}, '');
	}
	let { SERVICE_URL } = getCustomServiceURL(url);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchAggregateBandwidthStatusObservable(params: { [k: string]: any }) {
	let url = `api/extensions/gpcs/status/getAggBWAllStats`;
	if (params && _.isObject(params)) {
		url += '?';
		url += Object.keys(params).reduce((a, b) => {
			return a.length === 0
				? `${a}${b}=${(params as FAWKES.I_OBJECT)[b]}`
				: `${a}&${b}=${(params as FAWKES.I_OBJECT)[b]}`;
		}, '');
	}
	let { SERVICE_URL } = getCustomServiceURL(url);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchSpnListObservable(params: any) {
	let url = `api/extensions/gpcs/AggregatedBandwidth`;
	if (!params['unusedNpnList'] || params['unusedNpnList'].length === 0) {
		delete params['unusedNpnList'];
	}
	if (!params['npnList'] || params['npnList'].length === 0) {
		delete params['npnList'];
	}
	if (params && _.isObject(params)) {
		url += '?';
		url += Object.keys(params).reduce((a, b) => {
			return a.length === 0
				? `${a}${b}=${(params as FAWKES.I_OBJECT)[b]}`
				: `${a}&${b}=${(params as FAWKES.I_OBJECT)[b]}`;
		}, '');
	}
	let { SERVICE_URL } = getCustomServiceURL(url);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchFeatures() {
	let url = `api/features`;
	let { SERVICE_URL } = getAdminServiceURL(url);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchMobileUsersStatusObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/getGPaaSOverviewStat`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchMobileUsersCurrentUsersObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/remoteActiveUsersCount`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchMobileUsersCurrentUsersDetailObservable(queryString = '') {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/getGPaaSActiveUsers`);
	if (queryString !== '') {
		SERVICE_URL += `?queryParamName=query_str&queryString=${queryString}`;
	}
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}
export function logoutGPUserObservable(payload: any) {
	let { SERVICE_URL } = getCustomServiceURL(`/api/extensions/gpcs/ops/v1/user/logout`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		body: payload,
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function generateTicketObservable(payload: any) {
	let { SERVICE_URL } = getCustomServiceURL(`/api/config/v9.2/generateTicketCommand`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		body: payload,
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}
export function createFolderObservable(payload: any) {
	const SERVICE_URL = `${getRedirectURL()}/sase/config/v1/folders`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		body: JSON.stringify(payload),
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}
export function moveFolderObservable(payload: any, id: string) {
	const SERVICE_URL = `${getRedirectURL()}/sase/config/v1/folders/${id}/move`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'PUT',
		responseType: 'json',
		body: JSON.stringify(payload),
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}
export function deleteFolderObservable(id: string) {
	const SERVICE_URL = `${getRedirectURL()}/sase/config/v1/folders/${id}`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}
export function editFolderObservable(id: string, payload: any) {
	const SERVICE_URL = `${getRedirectURL()}/sase/config/v1/folders/${id}`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'PUT',
		responseType: 'json',
		body: JSON.stringify(payload),
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}
export function getLabelsObservable() {
	const SERVICE_URL = `${getRedirectURL()}/sase/config/v1/labels?${PAGINATION_QUERY_PARAM}`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getLabelGroupsObservable() {
	const SERVICE_URL = `${getRedirectURL()}/api/sase/config/v1/device-config/label-groups?${PAGINATION_QUERY_PARAM}`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}
export function createLabelObservable(payload: any) {
	const SERVICE_URL = `${getRedirectURL()}/sase/config/v1/labels`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}
export function getFolderByIdObservable(id: string) {
	const SERVICE_URL = `${getRedirectURL()}/sase/config/v1/folders/${id}?${PAGINATION_QUERY_PARAM}`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function newSaasRuleRecommendationsObservable(payload: any) {
	let { SERVICE_URL } = getCustomServiceURL(
		`/api/sase/config/v1.0/policies/saas-rule-recommendations?${PAGINATION_QUERY_PARAM}`,
	);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		headers: {
			...getAuthHeaders(),
			'Content-Type': 'application/json',
		},
		dataType: 'json',
		body: JSON.stringify(payload),
		url: SERVICE_URL,
		ContentType: 'application/json',
	};
}

export function fetchMobileUsersTotalUsersObservable(daysToDisplay = 90) {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/remote${daysToDisplay}DaysUsersCount`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchMobileUsersTotalUsersDetailObservable(queryString = '', daysToDisplay = 90) {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/getGPaaSLast${daysToDisplay}DaysUniqueUsers`);
	if (queryString !== '') {
		SERVICE_URL += `?queryParamName=query_str&queryString=${queryString}`;
		// SERVICE_URL += '&query_str='+queryString+'';
	}
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchMobileUsersTotalUsersDetailPaginatedObservable(
	filterString = '',
	daysToDisplay = 90,
	start = 0,
	limit = 9000,
) {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/getGPaaSLast${daysToDisplay}DaysUniqueUsers`);
	const ampersand = '%2526';
	SERVICE_URL += `?queryParamName=query_str&queryString=limit=${limit}${ampersand}start=${start}`;
	if (filterString !== '') {
		SERVICE_URL += `${ampersand}${filterString}`;
	}
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchMobileUsersCurrentUsersDetailPaginatedObservable(filterString = '', start = 0, limit = 9000) {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/getGPaaSActiveUsers`);
	const ampersand = '%2526';
	SERVICE_URL += `?queryParamName=query_str&queryString=limit=${limit}${ampersand}start=${start}`;
	if (filterString !== '') {
		SERVICE_URL += `${ampersand}${filterString}`;
	}
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchAllUsersForDownload(filterString = '', type: 'currentUsers' | 'totalUsers', daysToDisplay = 90) {
	let url = '';
	if (type === 'currentUsers') {
		const { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/getGPaaSActiveUsers`);
		url = SERVICE_URL;
	} else if (type === 'totalUsers') {
		const { SERVICE_URL } = getCustomServiceURL(
			`api/extensions/gpcs/status/getGPaaSLast${daysToDisplay}DaysUniqueUsers`,
		);
		url = SERVICE_URL;
	}
	if (filterString !== '') {
		url += `?queryParamName=query_str&queryString=${filterString}&export=true`;
	} else {
		url += `?export=true`;
	}
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: url,
		headers: getAuthHeaders(),
	};
}

export function fetchServiceConnectionStatusObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/getServiceConnectionOverviewStat`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchExplicitProxyStatusObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/getExplicitProxyOverviewStat`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchExplicitProxyCurrentUsersObservable(count?: any) {
	let url = `api/extensions/gpcs/epaasActiveUsersCount`;
	if (count === 'yes') {
		url = `api/extensions/gpcs/epaasActiveUsersCount?count=yes`;
	}
	let { SERVICE_URL } = getCustomServiceURL(url);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchExplicitProxyTotalUsersObservable(count?: any) {
	let url = `api/extensions/gpcs/epaas90DaysUsersCount`;
	if (count === 'yes') {
		url = `api/extensions/gpcs/epaas90DaysUsersCount?count=yes`;
	}
	let { SERVICE_URL } = getCustomServiceURL(url);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getGlobalMapContinentInfoObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/getGlobalMapContinentInfo`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getDetailInfoObservable(header: any) {
	let param = '';
	// TODO: Revisit how this is done so we can do translation
	switch (header) {
		case 'Remote Networks':
			param = 'getFWaaSRegionalStat';
			break;
		case 'Mobile Users':
			param = 'getGPaaSRegionalStat';
			break;
		case 'Service Connections':
			param = 'getServiceConnectionRegionalStat';
			break;
		case 'Explicit Proxy':
			param = 'getExplicitProxyRegionalStat';
			break;
		default:
			break;
	}
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/${param}`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getDirectorySyncObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/directory/v1/status`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getSecondLevelDetailInfoObservable(header: any, locations: any) {
	let param = '';
	switch (header) {
		case 'Remote Networks':
			param = 'remoteNetworkRegionDetails';
			break;
		case 'Mobile Users':
			param = 'mobileUserRegionDetails';
			break;
		case 'Service Connections':
			param = 'serviceConnectionRegionDetails';
			break;
		case 'Explicit Proxy':
			param = 'explicitProxyRegionDetails';
			break;
		case JUPITER:
			param = 'mobileUserRegionDetails'; //For jupiter ,same as GP
			break;
		default:
			break;
	}
	//let locationStr = locations.reduce((currStr:any, location:any) => currStr += `", "${location}`);
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/${param}`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		body: {
			regions: locations,
		},
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getSecondLevelTableDetailInfoObservable(header: any, locations: any) {
	let param = '';
	if (header === 'Remote Networks') {
		param = 'remoteNetworkRegionStatistics';
	} else if (header === 'Service Connections') {
		param = 'serviceConnectionRegionStatistics';
	}
	let locationStr = locations.reduce((currStr: any, location: any) => (currStr += `", "${location}`));
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/${param}`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		body: {
			regions: [locationStr],
		},
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getLicenseInfoObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/license`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getMigrationStatusObservable() {
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${getRedirectURL()}/api/migration/status`,
		headers: getAuthHeaders(),
	};
}

export function getFeaturesObservable() {
	let { LOGIN_SERVICE_URL } = getAdminServiceURL(`api/features`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: LOGIN_SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getTokenInfoObservable() {
	let { LOGIN_SERVICE_URL } = getAdminServiceURL(`api/ui/tokens`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: LOGIN_SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getApplicationsInfoObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/contentservice/application`);

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getSaasCertificatesObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/contentservice/applicationSaasCertificates`);

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getSaasRisksObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/contentservice/applicationSaasRisks`);

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getCloudApplicationsInfoObservable({ onlyGenAi }) {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/contentservice/cloud-applications?only-gen-ai=${onlyGenAi ? 'true' : 'false'}`,
	);

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchCertificateObservable(location) {
	const { SERVICE_URL } = getServiceURLS('Device/CertificateManagement/CertificatesInfo', location);

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getDNSSecurityCategoryInfoObservable() {
	const { SERVICE_URL } = getCustomServiceURL(`api/contentservice/dnsSecurityCategory`);

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getAdvancedDNSSecurityCategoryObservable() {
	const { SERVICE_URL } = getCustomServiceURL(`api/contentservice/adnsSecurityCategory`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getQoStatisticsObservable(site: string) {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/qosStatistics?site=${site}`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getDeviceOverviewStatObservable(action: string) {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/get-device-status/${action}`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function uploadPACObservable(body: string, fileName: string, fileType: string) {
	let { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/status/uploadPACFile`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: {
			file_type: fileType,
			file_name: fileName,
			pacFileContent: body,
		},
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function verifyPACFileObservable(body: string) {
	let { SERVICE_URL } = getCustomServiceURL(`/api/v1/verify/codes`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: {
			code: body,
		},
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function downloadFile(url: string, fileName: string, type: string, folder: string, failCb: any) {
	const { SERVICE_URL } = getCustomServiceURL(url);
	const obs = ajax({
		withCredentials: true,
		method: 'POST',
		url: SERVICE_URL,
		body: JSON.stringify({
			folder,
			type,
			fileName,
			format: 'pem',
		}),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});

	obs.toPromise().then(
		({ xhr: request }) => {
			// var disposition = request.getResponseHeader('content-disposition');
			// var matches = /"([^"]*)"/.exec(disposition);
			const filename = fileName ? fileName + '.pac' : 'file.pac';
			// The actual download
			const blob = new Blob([atob(_.get(request, `response.msg.completions`) || '')], { type: 'text/plain' });
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = filename;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		(err) => {
			failCb && failCb(err);
		},
	);
}

export function getUnreferencedObjectsObservable() {
	let { SERVICE_URL } = getCustomServiceURL('api/config/v9.2/object/unreferencedObjects');
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchConfigVersionSnapshotsObservable(deviceName?: any, extraQueryParams?: any) {
	let url;
	if (deviceName) {
		const { SERVICE_URL } = getCustomServiceURL(
			`api/sase/config/v1.0/config-versions-list?deviceName=${deviceName}`,
		);
		url = SERVICE_URL;
	} else {
		const { SERVICE_URL } = getCustomServiceURL(`api/sase/config/v1.0/audit/config-versions`);
		const params = extraQueryParams ? Object.keys(extraQueryParams) : [];
		let urlParams = '';
		params.forEach((key) => {
			urlParams = `${urlParams}${urlParams === '' ? '?' : '&'}${key}=${extraQueryParams[key]}`;
		});
		url = `${SERVICE_URL}${urlParams}`;
	}

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url,
		headers: getAuthHeaders(),
	};
}

export function fetchCurrentVersionsObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/sase/config/v1.0/audit/current-versions`);
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function pushConfigObservable(version: string) {
	let { SERVICE_URL } = getCustomServiceURL(`api/sase/config/v1.0/push-config?version=${version}`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: {
			version,
		},
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function loadConfigObservable(version: string) {
	let { SERVICE_URL } = getCustomServiceURL(`api/sase/config/v1.0/load-config?version=${version}`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: {
			version,
		},
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function compareConfigVersionsObservable(from: string, to: string, deviceName?: string) {
	const toQueryParam = to ? `&to=${_.isString(to) ? to.toLowerCase() : to}` : '';
	let url;

	if (deviceName) {
		const nameParam = LOCATION_KEY_MAP[deviceName] || deviceName;
		const { SERVICE_URL } = getCustomServiceURL(
			`api/sase/config/v1.0/device/config-versions/diff?name=${nameParam}&from=${
				_.isString(from) ? from.toLowerCase() : from
			}${toQueryParam}`,
		);
		url = SERVICE_URL;
	} else {
		const { SERVICE_URL } = getCustomServiceURL(
			`api/sase/config/v1.0/audit/config-versions/compare/version?from=${
				_.isString(from) ? from.toLowerCase() : from
			}${toQueryParam}`,
		);
		url = SERVICE_URL;
	}

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url,
		headers: getAuthHeaders(),
	};
}

export function compareDiffObservable(from: string, to: string, uuid: string, deviceName?: string) {
	const toQueryParam = to ? `&to=${_.isString(to) ? to.toLowerCase() : to}` : '';
	let url;

	if (deviceName) {
		const nameParam = LOCATION_KEY_MAP[deviceName] || deviceName;
		const { SERVICE_URL } = getCustomServiceURL(
			`api/sase/config/v1.0/device/config-versions/diff?name=${nameParam}&from=${
				_.isString(from) ? from.toLowerCase() : from
			}${toQueryParam}&uuid=${uuid}`,
		);
		url = SERVICE_URL;
	} else {
		const { SERVICE_URL } = getCustomServiceURL(
			`api/sase/config/v1.0/audit/config-versions/compare/uuid?from=${
				_.isString(from) ? from.toLowerCase() : from
			}${toQueryParam}&uuid=${uuid}`,
		);
		url = SERVICE_URL;
	}

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url,
		headers: getAuthHeaders(),
	};
}

export function fetchOptimizedSummaryObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/sase/config/v1.0/policies/security-rules/optimized/summary?${PAGINATION_QUERY_PARAM}`,
	);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}
export function fetchSecurityPostureMetricsObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/sase/config/v1.0/policies/security-rules/security-posture-metrics?${PAGINATION_QUERY_PARAM}`,
	);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}
export function fetchZeroHitSecRulesSidecarDetailsObservable(
	location: any,
	ruleName: string,
	locationName: string,
	position: string,
) {
	if (!Pan.isEmpty(position)) {
		let { SERVICE_URL } = getServiceURLS('ZeroHitObjectsEnhancedGet', location, { ruleName, pos: position });
		return {
			withCredentials: true,
			method: 'GET',
			responseType: 'json',
			url: SERVICE_URL,
			headers: getAuthHeaders(),
		};
	} else {
		let { SERVICE_URL } = getServiceURLS('ZeroHitObjectsEnhancedGet', location, { ruleName });
		return {
			withCredentials: true,
			method: 'GET',
			responseType: 'json',
			url: SERVICE_URL,
			headers: getAuthHeaders(),
		};
	}
}
export function fetchAllSecRulesDetailsObservable() {
	let { SERVICE_URL } = getCustomServiceURL('/api/config/v9.2/Policies/AllSecurityRules');

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchImportedSaasObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`/api/sase/config/v1.0/policies/saas-rule-recommendations/imported?${PAGINATION_QUERY_PARAM}`,
	);
	// let { SERVICE_URL } = getCustomServiceURL('/api/sase/config/v1.0/policies/saas-rule-recommendations/available-updates');
	// let { SERVICE_URL } = getCustomServiceURL('/api/sase/config/v1.0/policies/saas-rule-recommendations/force-sync');

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchSWGDecryptionProfileObservable(configLocation: any) {
	const { SERVICE_URL } = getServiceURLS('Objects/SwgDecryptionProfiles', configLocation);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function syncSaasObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`/api/sase/config/v1.0/policies/saas-rule-recommendations/force-sync?${PAGINATION_QUERY_PARAM}`,
	);
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchNewSaasObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`/api/sase/config/v1.0/policies/saas-rule-recommendations/new?${PAGINATION_QUERY_PARAM}`,
	);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchImportedHardRefreshObservable(url: string) {
	let { SERVICE_URL } = getCustomServiceURL(`/api/sase/config/v1.0/policies/saas-rule-recommendations/${url}`);

	return {
		withCredentials: true,
		method: 'DELETE',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function updateImportedSaasObservable(id: string) {
	let { SERVICE_URL } = getCustomServiceURL(`/api/sase/config/v1.0/policies/saas-rule-recommendations/${id}`);

	return {
		withCredentials: true,
		method: 'DELETE',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchAvailableUpdatesObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`/api/sase/config/v1.0/policies/saas-rule-recommendations/available-updates?${PAGINATION_QUERY_PARAM}`,
	);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchTransformedRulesObservable(configLocationName: string) {
	let { SERVICE_URL } = getCustomServiceURL(
		`/api/sase/config/v1.0/web-security-advanced-rule/getRulesByDeviceName/${configLocationName}?${PAGINATION_QUERY_PARAM}`,
	);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchCountryBlockObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`sase/config/v1/region-block?folder=swg-container?${PAGINATION_QUERY_PARAM}`,
	);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchZeroHitObjectsObservable() {
	let { SERVICE_URL } = getCustomServiceURL('/api/config/v9.2/object/zeroHitObjects');

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

// TODO: Refactor due this is the same as "fetchAllSecRulesDetailsObservable"
export function fetchOptimizedRulesDetailsObservable() {
	let { SERVICE_URL } = getCustomServiceURL('/api/config/v9.2/Policies/AllSecurityRules');

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}
export function fetchSecurityRulesToOptimizeObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/sase/config/v1.0/policies/security-rules/optimized?${PAGINATION_QUERY_PARAM}`,
	);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}
export function fetchOptimizationHistoryObservable() {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/sase/config/v1.0/policies/security-rules/history?${PAGINATION_QUERY_PARAM}`,
	);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}
export function fetchOptmizedSecurityRulesObservable(ruleId: string) {
	// uuid
	let { SERVICE_URL } = getCustomServiceURL(
		`api/sase/config/v1.0/policies/security-rules/history/${ruleId}?${PAGINATION_QUERY_PARAM}`,
	);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}
export function enableWebSecurityStatusObservable(isEnabled: boolean, configLocation: any = {}) {
	const enable = isEnabled ? 'yes' : 'no';
	const { container: { name: configLocationName = '', type: configLocationType = '' } = {} } = configLocation;

	let { SERVICE_URL } = getCustomServiceURL(
		`api/config/v9.2/enable?enable=${enable}&folder=${configLocationName}&type=${configLocationType}&swg=yes`,
	);

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: {
			enable: isEnabled,
		},
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchWebSecurityStatusObservable() {
	let { SERVICE_URL } = getCustomServiceURL(`api/config/v9.2/enable`);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchSaasAutomaticUpdatesObservable({ configLocation }: any) {
	const { container: { name: folder = '', type = '' } = {} } = configLocation;
	const snippetUrlParam = type === SNIPPET ? `type=${type}&snippet=${folder}` : '';
	const containerUrlParam = `type=${type}&folder=${folder}`;
	let { SERVICE_URL } = getCustomServiceURL(
		`/api/config/v9.2/enable-auto-saas-updates?${snippetUrlParam ? snippetUrlParam : containerUrlParam}`,
	);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function enableSaasAutomaticUpdatesObservable({
	isEnabled,
	configLocation,
}: {
	isEnabled: boolean;
	configLocation: any;
}) {
	const enable = isEnabled ? 'yes' : 'no';
	const { container: { name: folder = '', type = '' } = {} } = configLocation;
	const snippetUrlParam = type === SNIPPET ? `type=${type}&snippet=${folder}` : '';
	const containerUrlParam = `type=${type}&folder=${folder}`;
	let { SERVICE_URL } = getCustomServiceURL(
		`/api/config/v9.2/enable-auto-saas-updates?${
			snippetUrlParam ? snippetUrlParam : containerUrlParam
		}&enable=${enable}`,
	);

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		// body: {
		// 	"enable": isEnabled,
		// },
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function createScheduleCompletionObservable(payload: any) {
	// for new security policy form
	let { SERVICE_URL } = getCustomServiceURL('/api/config/v9.2/object/schedule');

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchPrismaSDWANConfigObservable({ configLocationName }) {
	let { SERVICE_URL } = getCustomServiceURL(
		`/sase/config/v1/device-config/sdwan-datacenter-capability?device=${configLocationName}`,
	);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

// Todo: add filter later
export function fetchDeviceOnboardingLabelGroupsObservable() {
	const SERVICE_URL = `${getRedirectURL()}/sase/config/v1/device-config/label-groups`;

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function createDeviceOnboardingLabelGroupsObservable(payload: any) {
	const SERVICE_URL = `${getRedirectURL()}/sase/config/v1/device-config/label-groups`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function deleteDeviceOnboardingLabelGroupsObservable(id: string) {
	const SERVICE_URL = `${getRedirectURL()}/sase/config/v1/device-config/label-groups/${id}`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getVulnerabilityFromContentServiceObserable() {
	const SERVICE_URL = `${getRedirectURL()}/api/contentservice/indexdb-vulnerability-threats`;
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getPhoneHomeFromContentServiceObserable() {
	const SERVICE_URL = `${getRedirectURL()}/api/contentservice/indexdb-antispyware-threats`;
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}
