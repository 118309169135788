import {
	ACCESS_AGENT,
	CONTAINER_ONPREM,
	GLOBALPROTECT,
	GPCS_CONTAINERS,
	MU_CONTAINER,
	SNIPPET,
	SWG_CONTAINER_TYPE,
} from 'service-lib/services/constants';
import { getAuthHeaders } from 'ui-lib/auth/lib';
import Pan from 'ui-lib/core/autorender/schema/Pan';
import { CONFIG_BASE_URI, CONFIG_SASE_BASE_URI, getRedirectURL } from 'ui-lib/core/services/CommonServices';
import { getStoreState } from 'ui-lib/core/utils/storeRegistry';
import _ from 'lodash';
import { FAWKES } from 'src/typings/type';
import { isPADisabled, isSnippetScope } from 'ui-lib/utils';
import {
	getLocationReverseKeyMap,
	getLocationReverseKeyMapSwgDisabled,
	isPBAEnabled,
} from 'service-lib/services/licenseManager';
import { isUserRoleSWGAdmin } from 'ui-lib/core/autorender/widgets/util';

import {
	AGENTLESS_ACCESS_FEATURE,
	STATIC_IP_POOL_FEATURE,
	STATIC_IP_POOL_P2_FEATURE,
} from 'src/containers/common/constants';
import {
	addRecordActionObservable,
	editRecordActionObservable,
	renameRecordActionObservable,
} from 'service-lib/services/recordServices';
import { getScopeParamFromType } from 'service-lib/services/scopeServices';
import { getServiceURLS } from 'service-lib/services/services';
import { prepareRequestOptions } from 'src/utils/requestUtils';
import { checkIsFeatureEnabled } from 'src/utils/featureFlagUtils';

export function isSWGEnabled() {
	return true; //Enable swg globally;
}

export function isMultiPortalEnabled() {
	return _.get(getStoreState(), 'config.Mobile Users.MULTI_GP_FLAG', false);
}

export function isLocalUdaEnabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, 'local-uda');
}

export function isUdaConfigPresent() {
	return !Pan.isEmpty(_.get(getStoreState(), 'config.Local UDA.UDA_CONFIG', {}));
}

export function isUdaWidgetSetToEnabled() {
	const isEnabled = _.get(getStoreState(), `config.Local UDA.UDA_ENABLE_STATUS`, '') === 'yes';
	return isEnabled;
}

export function isInnovationFlagEnabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, '32-innovation');
}

export function isEPTrustXAUEnabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, 'ep-trust-xau-headers');
}

export function isEPAgentProxyEnabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, 'ep-agent-proxy');
}

// export function isPAAgentProxyEnabled() {
// 	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
// 	return checkIsFeatureEnabled(featuresInfo, 'pa-agent-proxy');
// }

export function isPAAgentProxyGatewaylessEnabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, 'agent_to_ep_proxy_gatewayless');
}

export function isLocalZonesEnabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, 'local_zones');
}

export function isRnInternalGatewayEnabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, 'rn_internal_gateway');
}

export function isJupiterEnabled() {
	if (isPBAEnabled()) return true;
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, 'ztna_agent');
}

export function isAgentlessAccessEnabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, AGENTLESS_ACCESS_FEATURE);
}

export const muGpAvail = () => {
	if (isPADisabled()) return false;
	if (isUserRoleSWGAdmin()) return false;
	const storeState = getStoreState();
	const gpEnable =
		_.get(storeState, `config.${MU_CONTAINER}.enable-gp-ep.result.enable.mobile-users-global-protect`) || 'no';
	const activeGPError = _.get(getStoreState(), `main.gpDeviceOverviewStatError`) || false;
	const gpActive = activeGPError ? true : _.get(storeState, `main.gpDeviceOverviewStat.active`) || false;
	return !(gpEnable === 'no' && gpActive === false);
};

export const muJpAvail = () => {
	if (isUserRoleSWGAdmin()) return false;
	if (isPADisabled()) return false;
	const storeState = getStoreState();
	const jpEnable =
		_.get(storeState, `config.${MU_CONTAINER}.enable-gp-ep.result.enable.mobile-users-jupiter`) || 'no';
	return !(jpEnable === 'no');
};

export const agentlessAccessAvail = () => {
	if (isUserRoleSWGAdmin()) return false;
	if (isPADisabled()) return false;
	const storeState = getStoreState();
	const agentlessAccessEnable =
		_.get(storeState, `config.${MU_CONTAINER}.enable-gp-ep.result.enable.mobile-users-agentless-access`) || 'no';
	return !(agentlessAccessEnable === 'no');
};

export function getAgentDisplayLabel() {
	if (isJupiterEnabled() || isPBAEnabled() || muJpAvail()) return ACCESS_AGENT;
	else if (muGpAvail() && !muJpAvail()) return GLOBALPROTECT;
	else return GLOBALPROTECT;
}

export function getRoutesKeyMap(includeSWG = false) {
	const map = isSWGEnabled() ? getLocationReverseKeyMap() : getLocationReverseKeyMapSwgDisabled();
	const clonedMap = _.cloneDeep(map);
	!includeSWG && _.unset(clonedMap, `${SWG_CONTAINER_TYPE}`);
	return clonedMap;
}

function multiActionObservable({
	actionType,
	params: { records, serviceName, location, position, where, prefix, allErrors, dstRule, legacyServiceName, multiActionById = false },
	deviceTypeIsIncludePosition = true,
}: FAWKES.I_OBJECT) {
	let body: any = {};
	if (location && location.container) {
		const { name, type } = location.container || {};
		const positionGroups: any = Object.values(records).reduce((result: any, currentValue: any) => {
			if (currentValue['@position']) {
				(result[currentValue['@position']] = result[currentValue['@position']] || []).push(currentValue);
			} else if (currentValue['position']) {
				(result[currentValue['position']] = result[currentValue['position']] || []).push(currentValue);
			} else if (position !== 'post') {
				(result[position] = result[position] || []).push(currentValue);
			}
			return result;
		}, {});

		const rules = positionGroups[position];

		if (!rules) {
			return '';
		}

		body = {
			destination: {},
			source: {},
		};

		if (name) {
			if (type === 'snippet') {
				body.destination = {
					snippet: name,
				};
			} else {
				body.destination = {
					type: type,
					folder: name,
				};
			}
			if (prefix) {
				body.destination.position = prefix;
			}
		}

		const srcTypes: any = {};
		const predefined: any[] = [];
		const srcTypeById: any = {};
		const predefinedById: any[] = [];

		const folderScopeMap = _.get(getStoreState(), `main.scopeManage.folderScopeMap`, {});
		Object.entries(rules).forEach((item) => {
			const record: any = item[1];
			const loc = record['@loc'] || record['folder'] || record['device'];
			const recName = record['@name'] || record['name'] || record;
			const recId = record['@uuid'] || record['uuid'] || record['id'];
			if (loc === 'predefined' || record['@snippet-loc'] === 'predefined-snippet') {
				predefined.push(recName);
				if (multiActionById) {
					predefinedById.push(recId);
				}
			} else {
				srcTypes[loc] = srcTypes[loc] || [];
				srcTypes[loc].push(recName);
				if (multiActionById) {
					srcTypeById[loc] = srcTypeById[loc] || [];
					srcTypeById[loc].push(recId);
				}
			}
		});

		if (!Pan.isEmpty(srcTypes)) {
			const routesKeyMap: any = getRoutesKeyMap();
			body.source.deviceTypes = Object.entries(srcTypes).map((item) => {
				let result: any;
				if (!isSnippetScope()) {
					result = {
						members: item[1],
						folder: item[0],
						type:
							GPCS_CONTAINERS[routesKeyMap[item[0]]]?.type ||
							folderScopeMap?.[item[0]]?.type ||
							'container',
					};
				} else {
					result = {
						members: item[1],
						snippet: item[0],
					};
				}

				if (multiActionById && !Pan.isEmpty(srcTypeById[item[0]])) {
					result.ids = srcTypeById[item[0]]
				}

				if (deviceTypeIsIncludePosition) {
					result.position = position;
				}
				return result;
			});
		}

		if (!Pan.isEmpty(predefined)) {
			body.source.predefined = predefined;
		}
	}

	const SERVICE_BASE_URL = getRedirectURL();
	let service_url = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${legacyServiceName ? legacyServiceName : serviceName
		}/${actionType}?where=${where || 'top'}${dstRule ? `&dst=${dstRule}` : ''}`;
	if (typeof allErrors === 'boolean') {
		service_url += `&allErrors=${allErrors}`;
	}

	return prepareRequestOptions(service_url, 'POST', body);
}

export function disablePoliciesActionObservable(
	records: any,
	serviceName: string,
	location: any,
	disabled: any,
	position: any,
	key?: any,
	legacyServiceName?: string,
	additionalInfo?: any,
) {
	let positionGroups: any;

	if (key) {
		positionGroups = Object.values(records).reduce((result: any, currentValue: any) => {
			if (currentValue[key]['@position']) {
				(result[currentValue[key]['@position']] = result[currentValue[key]['@position']] || []).push(
					currentValue[key]['@name'],
				);
			} else if (position !== 'post') {
				(result[position] = result[position] || []).push(currentValue[key]['@name']);
			}
			return result;
		}, {});
	} else if (legacyServiceName) {
		positionGroups = Object.values(records).reduce((result: any, currentValue: any) => {
			if (currentValue['position']) {
				(result[currentValue['position']] = result[currentValue['position']] || []).push(currentValue['name']);
			} else if (position !== 'post') {
				(result[position] = result[position] || []).push(currentValue['name']);
			}
			return result;
		}, {});
	} else {
		positionGroups = Object.values(records).reduce((result: any, currentValue: any) => {
			if (currentValue['@position']) {
				(result[currentValue['@position']] = result[currentValue['@position']] || []).push(
					currentValue['@name'],
				);
			} else if (position !== 'post') {
				(result[position] = result[position] || []).push(currentValue['@name']);
			}
			return result;
		}, {});
	}

	const rules = positionGroups[position];
	if (!rules) {
		return '';
	}
	const typeInfo = additionalInfo?.['rule-type'] ? { entry: { 'rule-type': additionalInfo?.['rule-type'] } } : {};
	const { DISABLE_RULES_URL } = legacyServiceName
		? getServiceURLS(legacyServiceName, location)
		: getServiceURLS(serviceName, location);
	const url_base = DISABLE_RULES_URL;
	const service_url = `${url_base}&name=${rules.join(',')}&disabled=${disabled}&pos=${position}`;

	return prepareRequestOptions(service_url, 'POST', { ...typeInfo });
}

export function movePoliciesActionObservable({
	selectedRule,
	dstRule,
	position,
	serviceName,
	location,
	movePosition,
}: // allErrors
	FAWKES.I_OBJECT) {
	const { MOVE_RULES_URL } = getServiceURLS(serviceName, location);
	const pos = position || '';
	const url_base = MOVE_RULES_URL;
	const service_url = `${url_base}${url_base.indexOf('?') === -1 ? '?' : '&'
		}&pos=${pos}&name=${selectedRule}&where=${movePosition}${dstRule ? '&dst=' + dstRule : ''}`;

	// if (typeof allErrors === "boolean") {
	// 	service_url += `&allErrors=${allErrors}`;
	// }

	return prepareRequestOptions(service_url, 'POST', {});
}

export function deleteApplicationTagActionObservable(applicationTags: any, serviceName: string, location: any) {
	applicationTags = Array.isArray(applicationTags) ? applicationTags.join(',') : applicationTags;
	// let { SERVICE_URL } = getCustomServiceURL(`api/config/v9.2/Objects/ApplicationTags?name=${applicationTags}&location=${location});
	let { SERVICE_URL } = getServiceURLS(serviceName, location);
	SERVICE_URL = `${SERVICE_URL}&name=${applicationTags}`;
	return prepareRequestOptions(SERVICE_URL, 'DELETE');
}

export function editApplicationTagActionObservable(
	record: FAWKES.IFormData,
	serviceName: string,
	location: any,
	isNew?: boolean,
) {
	const { SERVICE_URL } = getServiceURLS(serviceName, location);
	const name = record['@name'];
	const service_url = `${SERVICE_URL}${SERVICE_URL.indexOf('?') === -1 ? '?' : '&'}name=${name}`;

	const editRecord = {
		entry: {
			...record,
			'@name': name,
		},
	};
	return prepareRequestOptions(service_url, isNew ? 'POST' : 'PUT', editRecord);
}

export function addRuleActionObservable(record: FAWKES.IFormData, serviceName: string, location: any) {
	const array = record.position && record.position.split('-');
	let pos = 'pre';
	if (array && array.length === 2) {
		pos = array[0];
	}
	delete record.position;
	const recordActionObservable = { ...addRecordActionObservable(record, serviceName, location) };
	recordActionObservable.url = recordActionObservable.url + `&pos=${pos}`;
	return recordActionObservable;
}

export function editRuleActionObservable(record: FAWKES.IFormData, serviceName: string, location: any) {
	const pos = record['new']['@position'] || '';
	delete record['new'].position;
	const recordActionObservable = { ...editRecordActionObservable(record, serviceName, location) };
	recordActionObservable.url = recordActionObservable.url + `&pos=${pos}`;
	return recordActionObservable;
}

export function deleteRulesActionObservable(
	records: FAWKES.IFormData,
	serviceName: string,
	location: any,
	position: any,
	useProductizedApi: any = false,
) {
	const positionGroups = Object.values(records).reduce((result, currentValue) => {
		const nameOfCurrent = currentValue['@name'] || currentValue['name'];
		const currentPosition = currentValue['@position'] || currentValue['position'];
		if (currentPosition) {
			(result[currentPosition] = result[currentPosition] || []).push(nameOfCurrent);
		} else if (position !== 'post') {
			(result[position] = result[position] || []).push(nameOfCurrent);
		}
		return result;
	}, {});
	const rules = positionGroups[position];
	if (!rules) {
		return '';
	}
	const { SERVICE_URL } = getServiceURLS(serviceName, location, undefined, useProductizedApi, useProductizedApi);

	const url_base = SERVICE_URL;
	let service_url = url_base + `${url_base.indexOf('?') === -1 ? '?' : '&'}name=${rules.join(',')}`;

	const { container: { type: containerType = '' } = {} } = location;

	if (containerType === 'container') {
		if (useProductizedApi) {
			service_url = service_url + `${service_url.indexOf('?') === -1 ? '?' : '&'}position=${position}`;
		} else {
			service_url = service_url + `${service_url.indexOf('?') === -1 ? '?' : '&'}pos=${position}`;
		}
	}

	return prepareRequestOptions(service_url, 'DELETE');
}

export function cloneRulesActionObservable(
	records: any,
	serviceName: string,
	location: any,
	position: any,
	where = 'bottom',
	swgEnabled: boolean,
	prefix: string,
	allErrors: any,
	dstRule: any,
	legacyServiceName?: string,
) {
	return multiActionObservable({
		actionType: 'multiclone',
		params: {
			records,
			serviceName,
			location,
			position,
			where,
			swgEnabled,
			prefix,
			allErrors,
			dstRule,
			legacyServiceName,
		},
	});
}

export function multimovePoliciesActionObservable({
	records,
	serviceName,
	location,
	position,
	where,
	swgEnabled,
	prefix,
	allErrors,
	dstRule,
	legacyServiceName,
}: any) {
	return multiActionObservable({
		actionType: 'multimove',
		params: {
			records,
			serviceName,
			location,
			position,
			where,
			swgEnabled,
			prefix,
			allErrors,
			dstRule,
			legacyServiceName,
		},
	});
}

export function multimoveObjectsActionObservable({
	records,
	serviceName,
	location,
	position,
	where,
	swgEnabled,
	prefix,
	allErrors,
	dstRule,
	multiActionById = false
}: any) {
	return multiActionObservable({
		actionType: 'multimove',
		params: {
			records,
			serviceName,
			location,
			position,
			where,
			swgEnabled,
			prefix,
			allErrors,
			dstRule,
			multiActionById
		},
		deviceTypeIsIncludePosition: false,
	});
}

export function renameRuleActionObservable(record: FAWKES.IFormData, serviceName: string, location: any) {
	const pos = record['new']['@position'] || '';
	const recordActionObservable = { ...renameRecordActionObservable(record, serviceName, location) };
	recordActionObservable.url = recordActionObservable.url + `&pos=${pos}`;
	return recordActionObservable;
}

export function requestFetchPoliciesObjectsNamesList({ serviceName, location, prefix, legacyServiceName }: any) {
	const SERVICE_BASE_URL = getRedirectURL();
	const { name, type } = location.container;
	const snippetUrlParam = type === SNIPPET ? `snippet=${name}` : '';
	const containerUrlParam = `type=${type}&folder=${name}`;
	const deviceUrlParam = type === CONTAINER_ONPREM ? `type=${type}&device=${name}` : '';
	let service_url = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}?${snippetUrlParam ? snippetUrlParam : containerUrlParam
		}${prefix ? `&pos=${prefix}` : ''}&includeParentNodes=no&isNameOnly=yes`;
	if (legacyServiceName) {
		service_url = `${SERVICE_BASE_URL}${CONFIG_SASE_BASE_URI}/${serviceName}?${snippetUrlParam ? snippetUrlParam : deviceUrlParam ? deviceUrlParam : containerUrlParam
			}${prefix ? `&position=${prefix}` : ''}&includeParentNodes=no&isNameOnly=yes`;
	}
	return prepareRequestOptions(service_url, 'GET');
}
export function getPrismaAccessIPObservable() {
	const SERVICE_URL = `${getRedirectURL()}/api/extensions/gpcs/prisma-access/gateways`;
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getProjectGroupsObservable() {
	const { container: { name = '', type = '' } = {} } = _.get(getStoreState(), 'main.configLocation');
	const scopeParam = getScopeParamFromType(type);
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: `${getRedirectURL()}/sase/config/v1/project-group-mappings?${scopeParam}=${name}`,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getDpaDomainUserGroupObservable() {
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: `${getRedirectURL()}/sase/config/v1/dpa-domain-usergroup`,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function moveConfigObjectActionObservable({ record, serviceName, location, where, allErrors, dstRule }: any) {
	const body = {};
	const { name, type } = location.container;
	const SERVICE_BASE_URL = getRedirectURL();
	const snippetUrlParam = type === SNIPPET ? `snippet=${name}` : '';
	const containerUrlParam = `type=${type}&folder=${name}`;
	let service_url = `${SERVICE_BASE_URL}${CONFIG_BASE_URI}/${serviceName}/move?name=${record['@name']}&${snippetUrlParam ? snippetUrlParam : containerUrlParam
		}&where=${where || 'top'}${dstRule ? `&dst=${dstRule}` : ''}`;
	if (typeof allErrors === 'boolean') {
		service_url += `&allErrors=${allErrors}`;
	}
	return prepareRequestOptions(service_url, 'POST', body);
}

export function isStaticIpPoolEnabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, STATIC_IP_POOL_FEATURE);
}

export function isStaticIpPoolP2Enabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, STATIC_IP_POOL_P2_FEATURE);
}

export function isUseDefaultBrowserEnabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, 'gp-use-default-browser');
}

export function getAutoTagActionObservable() {
	const { container: { locationPath = '', type = '' } = {} } = _.get(getStoreState(), 'main.configLocation');
	const scopeParam = getScopeParamFromType(type);
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: `${getRedirectURL()}/api/config/v9.2/Objects/AutoTag?type=${type}&${scopeParam}=${locationPath}`,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function simplemultiActionObservable({
	records = [],
	serviceName,
	location,
	where,
	dstRule,
	allErrors,
}: FAWKES.I_OBJECT) {
	const body: any = {};
	if (location && location.container) {
		const { name, type } = location.container || {};
		if (records?.length === 0) return;
		if (name) {
			if (type === 'snippet') {
				body.destination = {
					snippet: name,
				};
				body.source = {
					snippet: name,
					name: [],
				};
			} else {
				body.destination = {
					folder: name,
				};
				body.source = {
					folder: name,
					name: [],
				};
			}
		}
		records?.forEach((record) => {
			body.source.name?.push(record['name']);
		});
	}

	const SERVICE_BASE_URL = getRedirectURL();
	let service_url = `${SERVICE_BASE_URL}${CONFIG_SASE_BASE_URI}/${serviceName}:multimove?where=${where || 'top'}${dstRule ? `&dst=${dstRule}` : ''
		}`;
	if (typeof allErrors === 'boolean') {
		service_url += `&allErrors=${allErrors}`;
	}
	return prepareRequestOptions(service_url, 'POST', body);
}
