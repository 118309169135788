import { _T } from 'src/utils/i18n-utils';

export const errorMap = {
	ACCESS_DENIED: {
		title: _T('Permission Needed'),
	},
	INVALID_REFERENCE: {
		title: _T("Reference Doesn't Exist"),
	},
	INTERNAL_ERR: {
		title: _T('Issue for Support (Memory)'),
	},
	INVALID_ATTR: {
		title: _T('Unsupported Character'),
	},
	MISSING_ATTR_ERR: {
		title: _T('Missing Field'),
	},
	UNEXPECTED_NODE_ERROR: {
		title: _T('Invalid Config'),
	},
	UNEXPECTED_TEXT_ERR: {
		title: _T('Invalid Config'),
	},
	MISSING_NODE_ERROR: {
		title: _T('Missing Field'),
	},
	DUPLICATE_NODE_ERROR: {
		title: _T('Duplicate Field'),
	},
	UNIQUEIN_ERROR: {
		title: _T('Duplicate Name'),
	},
	ENCRPYTION_ERROR: {
		title: _T('Issue for Support (Encryption)'),
	},
	PROCESSING_ERROR: {
		title: _T('Issue for Suppport (Processing)'),
	},
	INVALID_KEYWORD: {
		title: _T('Unsupported Character'),
	},
	INVALID_STRING: {
		title: _T('Unsupported Character'),
	},
	INVALID_STRING_REGEX: {
		title: _T('Unsupported Regex'),
	},
	INVALID_STR_LEN: {
		title: _T('Field Length Exceeded'),
	},
	INVALID_STR_LEN_MIN: {
		title: _T('Field Length Not Met'),
	},
	INVALID_STR_LEN_MAX: {
		title: _T('Field Length Exceeded'),
	},
	INVALID_NSSAI_STRING: {
		title: _T('Not Supported for NSSAI'),
	},
	INVALID_IMEI_STRING: {
		title: _T('Not Supported for IMEI'),
	},
	INVALID_IMSI_STRING: {
		title: _T('Not Supported for IMSI'),
	},
	INVALID_INT: {
		title: _T('Enter a Number'),
	},
	INVALID_INT_RANGE: {
		title: _T('Enter a Number in Range'),
	},
	INVALID_INT_MIN: {
		title: _T('Enter a Smaller Number'),
	},
	INVALID_INT_MAX: {
		title: _T('Enter a Larger Number'),
	},
	INVALID_FLOAT: {
		title: _T('Enter a Float'),
	},
	INVALID_FLOAT_RANGE: {
		title: _T('Enter a Float in Range'),
	},
	INVALID_FLOAT_MIN: {
		title: _T('Enter a Smaller Float'),
	},
	INVALID_FLOAT_MAX: {
		title: _T('Enter a Larger Float'),
	},
	INVALID_IPSPEC_ADDRESS: {
		title: _T('Invalid IP Address'),
	},
	IPSPEC_NON_SUBNET_ERR: {
		title: _T('Use a Subnet Mask'),
	},
	IPSPEC_NON_LOOPBACK_ERR: {
		title: '',
	},
	INVALID_IPSPEC_IPV4: {
		title: _T('Use an IPv4 Address'),
	},
	INVALID_IPSPEC_IPV6: {
		title: _T('Use an IPv6 Address'),
	},
	INVALID_IPSPEC_MULTICAST: {
		title: _T('Use a Multicast Address'),
	},
	INVALID_IPSPEC_LOOPBACK: {
		title: _T('Use a Loopback Address'),
	},
	INVALID_IPSPEC_UNICAST: {
		title: _T('Use a Unicast Address'),
	},
	INVALID_IPSPEC_NONZERO: {
		title: _T('Use a Different IP Address'),
	},
	INVALID_IPSPEC_SUBNET: {
		title: _T('Use a Subnet Address'),
	},
	INVALID_IPSPEC_SUBNET_LEN: {
		title: _T('Use a Subnet Address'),
	},
	INVALID_IPRANGESPEC: {
		title: _T('Use a Subnet Address'),
	},
	INVALID_IPDISCONTMASK: {
		title: _T('Use a Netmask'),
	},
	OBJECT_NOT_PRESENT: {
		title: _T('Invalid Request'),
	},
	OBJECT_ALREADY_EXISTS: {
		title: _T('Invalid Request'),
	},
	OBJECT_NOT_UNIQUE: {
		title: _T('Invalid Request'),
	},
	CMD_DISABLED: {
		title: _T('Disabled Command'),
	},
	CMD_INVALID: {
		title: _T('Unsupported Command'),
	},
	CMD_INVALID_TARGET: {
		title: _T('No Request Target'),
	},
	INVALID_CTRDEV_NAME: {
		title: _T('Location Does Not Exist'),
	},
	INVALID_DESTINATION: {
		title: _T('Destination Does Not Exist'),
	},
	INVALID_SOURCE: {
		title: _T('Source Does Not Exist'),
	},
	INVALID_TARGET: {
		title: _T('Request Target Does Not Exist'),
	},
	EMPTY_TARGET: {
		title: _T('Missing Request Target'),
	},
	INVALID_UNIQUE_SOURCE: {
		title: _T('Delete One Object at a Time'),
	},
	REF_CHECK_FAIL: {
		title: _T('Issue for Support (References)'),
	},
	NON_ZERO_REFS: {
		title: _T("Can't Delete Objects in Use"),
	},
	RENAME_SAME_NEWNAME: {
		title: _T('Duplicate Name'),
	},
	RENAME_MISSING_NEWNAME: {
		title: _T('Missing Name'),
	},
	RENAME_NOT_ALLOWED: {
		title: _T("Can't Rename this Object"),
	},
	MOVE_OBJ_NOT_UNIQUE: {
		title: _T('Move One Object at a Time'),
	},
	MOVE_UP_INVALID: {
		title: _T('Already at the Top'),
	},
	MOVE_DOWN_INVALID: {
		title: _T('Already at the Bottom'),
	},
	MOVE_TOP_INVALID: {
		title: _T('Already at the Top'),
	},
	MOVE_BOTTOM_INVALID: {
		title: _T('Already at the Bottom'),
	},
	MOVE_AFT_BEF_SELF: {
		title: _T('Object Already Exists Here'),
	},
	MOVE_UNKNOWN_WHERE: {
		title: _T('Where would you like to move this?'),
	},
	MOVE_TLO_INVALID: {
		title: _T('Cannot Move'),
	},
	MOVE_EMPTY_WHERE: {
		title: _T('Where would you like to move this?'),
	},
	HIER_DEPTH_EXCEEDED: {
		title: _T('Hierarchy depth Exceeded'),
	},
};
