/*eslint-disable import/no-cycle */
import {
	CONFIG_BASE_URI,
	CONFIG_SASE_BASE_URI,
	getRedirectURL,
	isMockService,
} from 'ui-lib/core/services/CommonServices';
import Pan from 'ui-lib/core/autorender/schema/Pan';
import { getAuthHeaders } from 'ui-lib/auth/lib';
import { FAWKES } from 'src/typings/type';
import { getServiceURLS, GPCS_CONTAINERS } from 'service-lib/services/services';
import _ from 'lodash';
import { getLocationReverseKeyMap, getLocationReverseKeyMapSwgDisabled } from 'service-lib/services/licenseManager';
import { getScopeParamFromType } from 'service-lib/services/scopeServices';
import { getStoreState } from 'ui-lib/core/utils/storeRegistry';
import { isDeviceScope, isSnippetScope } from 'ui-lib/utils';
import {
	INCLUDE_LOCAL_QUERY_PARAM,
	INCLUDE_LOCAL_QUERY_PARAM_OBJ,
	IS_LOCAL_QUERY_PARAM,
} from 'src/containers/localConfig/constants';
import { appendQueryParamsToURL } from 'src/utils/url';
import { isLocalConfigEnabled } from 'src/containers/config/features';
import { isAPILocalConfigSensitive } from 'src/containers/localConfig/utils/utils';
import { RecordDataFetcherProps } from 'src/service-lib/hooks';
import { ajax } from 'rxjs/ajax';
import { getServiceURLForLocalRulebase } from 'src/containers/policies/localconfig/localConfigRulebaseServices';

export function addRecordActionObservable(
	record: FAWKES.IFormData,
	serviceName: string,
	location: FAWKES.configLocation,
	excludeEntry?: boolean,
	useProductizedApi = false,
	extraParams: any = {},
	paramContainerTypeEqualsName = false,
	useName = '',
) {
	const rec = {
		entry: {
			...record,
		},
	};
	let body = null;
	if (excludeEntry) {
		body = { ...record };
	} else {
		body = rec;
	}
	let { SERVICE_URL } = getServiceURLS(
		serviceName,
		location,
		extraParams,
		useProductizedApi,
		paramContainerTypeEqualsName,
		useName,
	);
	const { position, ignoreContainer } = extraParams;

	if (useProductizedApi && !ignoreContainer) {
		const [route, _] = SERVICE_URL.split('?');
		const { container: { name, type = '' } = {} } = location;
		const scopeParam = getScopeParamFromType(type);
		const modifiedQuery = `${scopeParam}=${name}`;
		if (position) {
			SERVICE_URL = route + `?${modifiedQuery}&position=${position}`;
		} else {
			SERVICE_URL = route + `?${modifiedQuery}`;
		}
	}

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function editRecordActionObservable(
	record: FAWKES.IFormData,
	serviceName: string,
	location: FAWKES.configLocation,
	prefix?: string,
	subPath?: string,
	useProductizedApi = false,
	extraParams = {},
) {
	const uuid = _.get(record, 'new.id', '');
	// specific case for old framework but new productized API's
	const { SERVICE_URL } = getServiceURLS(
		serviceName === 'networks/log-setting-profiles' ? `${serviceName}/${uuid}` : serviceName,
		location,
		{ prefix, subPath, ...extraParams },
		useProductizedApi,
	);
	const oldName = _.get(record, 'old.@name', '');
	const url_base = SERVICE_URL;
	let service_url = url_base;
	if (oldName) {
		service_url = `${url_base}${url_base.indexOf('?') === -1 ? '?' : '&'}name=${oldName}`;
	}
	if (serviceName === 'Objects/Tags' && oldName) {
		service_url = `${url_base}${url_base.indexOf('?') === -1 ? '?' : '&'}name=${encodeURIComponent(oldName)}`;
	}

	let editRecord: any = {
		entry: {
			...record.new,
			'@name': oldName,
		},
	};

	if (useProductizedApi) {
		const oldName = serviceName === 'networks/log-setting-profiles' ? record.new?.name : record.old?.name;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
		let [route, _] = service_url.split('?');
		const { container: { name, type = '' } = {} } = location;
		const scopeParam = getScopeParamFromType(type);
		const modifiedQuery = `${scopeParam}=${name}`;
		editRecord = { ...record.new, name: oldName };
		if (
			serviceName === 'zone-protection-profiles' ||
			serviceName === 'dos-protection-profiles' ||
			serviceName === 'local-users' ||
			serviceName === 'local-user-groups' ||
			serviceName === 'devices'
		) {
			const { id, ...rest } = record.new;
			route = `${route}/${id}`;
			editRecord = rest;
		}
		service_url = route + `?${modifiedQuery}`;
	}

	return {
		withCredentials: isMockService() ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: service_url,
		body: JSON.stringify(editRecord),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getRecordActionObservable(
	serviceName: string,
	recordId: any,
	location: FAWKES.configLocation,
	prefix?: any,
	subPath?: any,
	additionalInfo?: any,
	isHitCount?: any,
	isHitTimestamps?: any,
	isLastTriggered?: any,
	isBpa?: any,
	useProductizedApi?: boolean,
	extraParams: any = {},
	localConfigParams?: Record<string, unknown>,
	needGlobalMerge?: any,
) {
	let { SERVICE_URL } = getServiceURLS(
		serviceName,
		location,
		{
			prefix,
			subPath,
			additionalInfo: additionalInfo,
			isHitCount,
			isHitTimestamps,
			isLastTriggered,
			isBpa: isBpa,
			'global-merge': needGlobalMerge,
			...extraParams,
		},
		useProductizedApi,
	);

	const { ignoreContainer = false } = { ...extraParams };

	if (prefix) {
		return {
			withCredentials: isMockService() ? false : true,
			method: 'GET',
			responseType: 'json',
			url: SERVICE_URL,
			headers: getAuthHeaders(),
		};
	} else if (recordId) {
		const isLocalRulebaseRule =
			new URLSearchParams(document.location.search).get(IS_LOCAL_QUERY_PARAM) === 'yes' ? 'yes' : 'no';

		if (localConfigParams && _.get(localConfigParams, 'isLocalConfigAwareRule')) {
			const localRecordName = new URLSearchParams(document.location.search).get('localRecordName') || recordId;
			SERVICE_URL = getServiceURLForLocalRulebase(SERVICE_URL, localRecordName, isLocalRulebaseRule);
		} else {
			SERVICE_URL = `${SERVICE_URL}${SERVICE_URL.indexOf('?') === -1 ? '?' : '&'}name=${recordId}`;
		}
	}
	if (useProductizedApi) {
		if (!ignoreContainer) {
			const { container: { name = '', type = '' } = {} } = location;
			const scopeParam = getScopeParamFromType(type);
			const [route, query] = SERVICE_URL.split('?');
			let modifiedQuery = `${scopeParam}=${name}`;
			if (!Pan.isEmpty(extraParams)) {
				for (const key in extraParams) {
					if (key && extraParams[key] && key !== 'ignoreContainer') {
						if (Array.isArray(extraParams[key]) && key === 'additionalInfo') {
							extraParams[key].forEach(function (value: any) {
								modifiedQuery = `${modifiedQuery}&${key}=${value}`;
							});
						} else if (key === 'useBpaAdditionalInfo') {
							modifiedQuery = `${modifiedQuery}&additionalInfo=bpa`;
						} else {
							modifiedQuery = `${modifiedQuery}&${key}=${extraParams[key]}`;
						}
					}
				}
			}
			SERVICE_URL = route + `?${modifiedQuery}`;
			SERVICE_URL = `${SERVICE_URL}${SERVICE_URL.indexOf('?') === -1 ? '?' : '&'}name=${recordId}`;
		}
	}

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getRecordActionMergedObservable(
	serviceName: string,
	recordId: any,
	location: FAWKES.configLocation,
	prefix?: string,
	subPath?: string,
	includeParentNodes?: any,
) {
	let { SERVICE_URL } = getServiceURLS(serviceName, location, { prefix, subPath, includeParentNodes });

	if (prefix && subPath) {
		return {
			withCredentials: isMockService() ? false : true,
			method: 'GET',
			responseType: 'json',
			url: SERVICE_URL,
			headers: getAuthHeaders(),
		};
	} else if (recordId) {
		SERVICE_URL = `${SERVICE_URL}${SERVICE_URL.indexOf('?') === -1 ? '?' : '&'}name=${recordId}`;
	}
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getRecordsActionObservable(
	serviceName: string,
	location: any,
	prefix?: any,
	subPath?: any,
	isBpa?: any,
	isWhere?: any,
	isHitCount?: any,
	isHitTimestamps?: any,
	isLastTriggered?: any,
	isInfoNeeded?: any,
	useBpaAdditionalInfo?: any,
	useProductizedApi?: boolean,
	extraParams: any = {},
) {
	let { SERVICE_URL } = getServiceURLS(
		serviceName,
		location,
		{
			prefix,
			subPath,
			isBpa,
			isWhere,
			isHitCount,
			isHitTimestamps,
			isLastTriggered,
			isInfoNeeded,
			useBpaAdditionalInfo,
			...extraParams,
		},
		useProductizedApi,
	);

	const { ignoreContainer = false } = { ...extraParams };
	if (useProductizedApi && !ignoreContainer) {
		// only pass the service url without any of the added query params added by the getServiceURLS function.
		// We append our own queryparams here later for productizedAPIs
		const route = SERVICE_URL.split('?')[0];
		SERVICE_URL = appendExtraParamsAndPaginationAndScopeInfo(location, route, extraParams);
	}

	if (isLocalConfigEnabled() && isDeviceScope() && isAPILocalConfigSensitive(serviceName)) {
		if (extraParams[INCLUDE_LOCAL_QUERY_PARAM] !== undefined) {
			SERVICE_URL = appendQueryParamsToURL(SERVICE_URL, extraParams);
		} else {
			SERVICE_URL = appendQueryParamsToURL(SERVICE_URL, INCLUDE_LOCAL_QUERY_PARAM_OBJ);
		}
	}

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

// helper function to add pagination info and scope information. It also adds whatever is passed into
// "extraParams" to the query params of the SERVICE_URL
function appendExtraParamsAndPaginationAndScopeInfo(location, serviceURL, queryParamsObj) {
	const { pagination = false }: any = { ...queryParamsObj };
	const { container: { name = '', type = '' } = {} } = location;
	const scopeParam = getScopeParamFromType(type);
	const queryParamsToAppend = {
		[scopeParam]: name,
		pagination: pagination,
		...queryParamsObj,
	};
	return appendQueryParamsToURL(serviceURL, queryParamsToAppend);
}

export function addSimpleRecordActionObservable(
	record: FAWKES.IFormData,
	serviceName: string,
	location: FAWKES.configLocation,
) {
	const { SERVICE_URL } = getServiceURLS(serviceName, location);

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(record),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function deleteRecordByPathActionObservable(
	serviceName: string,
	location: FAWKES.configLocation,
	prefix?: string,
	subPath?: string,
) {
	const { SERVICE_URL } = getServiceURLS(serviceName, location, { prefix, subPath });
	return {
		withCredentials: isMockService() ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function addRecordByPathActionObservable(
	payload: FAWKES.I_ACTION_PAYLOAD,
	serviceName: string,
	location: FAWKES.configLocation,
	prefix?: string,
	subPath?: string,
	isNewOnly?: any,
) {
	const { SERVICE_URL } = getServiceURLS(serviceName, location, { prefix, subPath, isNewOnly });
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function moveRecordByPathActionObservable(
	serviceName: string,
	location: FAWKES.configLocation,
	prefix?: string,
	subPath?: string,
	dst?: any,
	where?: any,
) {
	const { SERVICE_URL } = getServiceURLS(serviceName, location, { prefix, subPath, dst, where });
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function renameRecordActionObservable(
	record: FAWKES.I_ACTION_PAYLOAD,
	serviceName: string,
	location: FAWKES.configLocation,
	extraParams = {},
	useProductizedApi?: boolean | undefined,
) {
	const { RENAME_ACTION_URL, SERVICE_URL } = getServiceURLS(serviceName, location, extraParams, useProductizedApi);

	if (useProductizedApi) {
		const oldName = record.old['name'];
		const newName = record.new['name'];
		let url_base = SERVICE_URL;
		const [route, rest] = url_base.split('?');
		url_base = `${route}:rename?${rest}`;
		return {
			withCredentials: isMockService() ? false : true,
			method: 'PUT',
			responseType: 'json',
			url: url_base,
			body: JSON.stringify({
				name: oldName,
				new_name: newName,
			}),
			headers: {
				'Content-Type': 'application/json',
				...getAuthHeaders(),
			},
		};
	}

	const oldName = record.old['@name'];
	const newName = record.new['@name'];
	const url_base = RENAME_ACTION_URL;
	let service_url = `${url_base}${url_base.indexOf('?') === -1 ? '?' : '&'}name=${oldName}&newname=${newName}`;
	if (serviceName === 'Objects/Tags' && oldName && newName) {
		service_url = `${url_base}${url_base.indexOf('?') === -1 ? '?' : '&'}name=${encodeURIComponent(
			oldName,
		)}&newname=${encodeURIComponent(newName)}`;
	}
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: service_url,
		body: JSON.stringify({}),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function deleteRecordsByNamesActionObservable(
	names: string[],
	serviceName: string,
	location: FAWKES.configLocation,
	prefix?: string,
	subPath?: string,
	useProductizedApi?: false,
) {
	const { SERVICE_URL } = getServiceURLS(serviceName, location, { prefix, subPath }, useProductizedApi);
	const url_base = SERVICE_URL;
	let service_url = url_base;
	if (!Pan.isEmpty(names)) {
		service_url = url_base + `${url_base.indexOf('?') === -1 ? '?' : '&'}name=${names.join(',')}`;
	}

	return {
		withCredentials: isMockService() ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: service_url,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function deleteRecordsActionObservable(
	records: FAWKES.IFormData | null,
	serviceName: string,
	location: FAWKES.configLocation,
	prefix?: string,
	subPath?: string,
	useProductizedApi?: boolean,
	supportSingleDeleteByName = false,
	extraParams = {},
	deleteById = false,
) {
	const { SERVICE_URL } = getServiceURLS(
		serviceName,
		location,
		{ prefix, subPath, ...extraParams },
		useProductizedApi,
	);
	const url_base = SERVICE_URL;
	let service_url = url_base;

	const names = [];
	const ids = []
	for (const key in records) {
		const record = records[key];
		names.push(encodeURIComponent(record['@name'] || record['name']) || encodeURIComponent(record));
		if (deleteById) {
			ids.push(encodeURIComponent(record['@uuid'] || record['uuid'] || record['id']));
		}
	}
	if (!Pan.isEmpty(names)) {
		service_url = url_base + `${url_base.indexOf('?') === -1 ? '?' : '&'}name=${names.join(',')}`;
		if (deleteById && !Pan.isEmpty(ids)) {
			service_url = url_base + `${url_base.indexOf('?') === -1 ? '?' : '&'}id=${ids.join(',')}`;
		}
	}

	if (useProductizedApi) {
		if (_.keys(records).length === 1) {
			for (const key in records) {
				const id = records[key].id || records[key]['override_id'];
				if (_.isString(id)) {
					service_url = service_url.slice(0, service_url.indexOf('?')) + `/${id}`;
				}
				if (supportSingleDeleteByName) {
					const { container: { name = '', type = '' } = {} } = location;
					const scopeParam = getScopeParamFromType(type);
					const [route] = SERVICE_URL.split('?');
					const modifiedQuery = `${scopeParam}=${name}&name=${names.toString()}`;
					service_url = route + `?${modifiedQuery}`;
				}
			}
		}
		if (_.keys(records).length > 1) {
			const { container: { name = '', type = '' } = {} } = location;
			const scopeParam = getScopeParamFromType(type);
			const [route, query] = SERVICE_URL.split('?');
			let modifiedQuery = `${scopeParam}=${name}&name=${names.toString()}`;
			if (deleteById && !Pan.isEmpty(ids)) {
				modifiedQuery = `${scopeParam}=${name}&id=${ids.toString()}`;
			}
			service_url = route + `?${modifiedQuery}`;
		}
	}

	return {
		withCredentials: isMockService() ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: service_url,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function simpleCloneActionObservable(
	records: FAWKES.IFormData,
	serviceName: string,
	location: FAWKES.configLocation,
	useProductizedApi?: boolean,
) {
	const SERVICE_BASE_URL = getRedirectURL();
	serviceName = serviceName.startsWith('/') ? serviceName.slice(1, serviceName.length) : serviceName;
	const CLONE_ACTION_URL = `${SERVICE_BASE_URL}${useProductizedApi ? CONFIG_SASE_BASE_URI : CONFIG_BASE_URI
		}/${serviceName}/multiclone`;
	let cloneObj: FAWKES.I_OBJECT = {};
	const service_url = CLONE_ACTION_URL;
	if (location && location.container) {
		const { name, type } = location.container || {};
		cloneObj = {
			destination: {},
			source: records.map(({ name, folder }) => ({ name, folder })),
		};
		if (name) {
			if (type === 'snippet') {
				cloneObj.destination = {
					snippet: name,
				};
			} else {
				cloneObj.destination = {
					folder: name,
				};
			}
		}
	}

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: service_url,
		body: JSON.stringify(cloneObj),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function cloneRecordsActionObservable(
	records: FAWKES.IFormData,
	serviceName: string,
	location: FAWKES.configLocation,
	prefix?: string,
	subPath?: string,
	swgEnabled?: any,
	allErrors?: any,
	useProductizedApi?: boolean,
) {
	const { CLONE_ACTION_URL } = getServiceURLS(serviceName, location, { allErrors }, useProductizedApi);
	let cloneObj: FAWKES.I_OBJECT = {};
	const service_url = CLONE_ACTION_URL;
	if (location && location.container) {
		const { name, type } = location.container || {};
		cloneObj = {
			destination: {},
			source: {},
		};
		if (name) {
			if (type === 'snippet') {
				cloneObj.destination = {
					snippet: name,
				};
			} else {
				cloneObj.destination = {
					type: type,
					folder: name,
				};
			}
			if (prefix) {
				cloneObj.destination.prefix = prefix;
			}
			if (subPath) {
				cloneObj.destination.subPath = subPath;
			}
		} else {
			//no matching destination location
		}

		const srcTypes: FAWKES.I_OBJECT = {},
			predefined = [];
		for (const key in records) {
			const record = records[key];
			const snippetLoc = record['@snippet-loc'] || record['snippet'];
			const loc =
				snippetLoc ||
				record['@loc'] ||
				record['folder'] ||
				record['device'] ||
				_.get(location, 'container.name');
			const recName = record['@name'] || record['name'] || record;

			if (loc === 'predefined' || record['@snippet-loc'] === 'predefined-snippet') {
				predefined.push(recName);
			} else if (snippetLoc || isSnippetScope()) {
				srcTypes[loc] = srcTypes[loc] || {
					isFromSnippet: true,
					member: [],
				};
				srcTypes[loc] = {
					...srcTypes[loc],
					member: [...srcTypes[loc].member, recName],
				};
			} else {
				srcTypes[loc] = srcTypes[loc] || [];
				srcTypes[loc].push(recName);
			}
		}
		const folderScopeMap = _.get(getStoreState(), `main.scopeManage.folderScopeMap`, {});
		if (!Pan.isEmpty(srcTypes)) {
			const sourceFolders = [];
			for (const src in srcTypes) {
				const locationKey: string = swgEnabled
					? _.get(getLocationReverseKeyMap(), `${src}`)
					: _.get(getLocationReverseKeyMapSwgDisabled(), `${src}`);
				let _obj: FAWKES.I_OBJECT = {};
				if (srcTypes[src].isFromSnippet) {
					_obj = {
						members: srcTypes[src].member,
						snippet: src,
					};
				} else {
					_obj = {
						members: srcTypes[src],
						folder: src,
						type:
							_.get(GPCS_CONTAINERS, `${locationKey}.type`) || folderScopeMap?.[src]?.type || 'container',
					};
				}
				if (prefix) {
					_obj.prefix = prefix;
				}
				if (subPath) {
					_obj.subPath = subPath;
				}
				sourceFolders.push(_obj);
			}
			cloneObj.source.deviceTypes = sourceFolders;
		}

		if (!Pan.isEmpty(predefined)) {
			cloneObj.source.predefined = predefined;
		}
	}

	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: service_url,
		body: JSON.stringify(cloneObj),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getRecordActionObservableWithProductizedAPI({
	serviceName,
	uuid,
	location,
	extraParams = {},
	paramContainerTypeEqualsName = false,
	withoutTypeParam = false,
}: {
	serviceName: string;
	uuid: string;
	location: FAWKES.configLocation;
	extraParams: any;
	paramContainerTypeEqualsName?: boolean;
	withoutTypeParam?: boolean;
}) {
	const { SERVICE_URL } = getServiceURLS(
		`${serviceName}/${uuid}`,
		location,
		{ ...extraParams },
		true,
		paramContainerTypeEqualsName,
		undefined,
		withoutTypeParam,
	);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function editRecordWithProductizedAPIObservable({
	serviceName,
	uuid,
	record,
	location,
	extraParams = {},
	paramContainerTypeEqualsName = false,
}: {
	serviceName: string;
	uuid: string;
	record: FAWKES.IFormData;
	location: FAWKES.configLocation | undefined;
	extraParams?: any;
	paramContainerTypeEqualsName?: boolean;
}) {
	let { SERVICE_URL } = getServiceURLS(
		`${serviceName}/${uuid}`,
		location,
		{ ...extraParams },
		true,
		paramContainerTypeEqualsName,
	);
	const [route, _] = SERVICE_URL.split('?');
	const { container: { name = '', type = '' } = {} } = location || {};
	const scopeParam = getScopeParamFromType(type);
	let modifiedQuery = `${scopeParam}=${name}`;
	if (!Pan.isEmpty(extraParams)) {
		for (const key in extraParams) {
			if (key && extraParams[key] && key !== 'ignoreContainer') {
				if (Array.isArray(extraParams[key]) && key === 'additionalInfo') {
					extraParams[key].forEach(function (value: any) {
						modifiedQuery = `${modifiedQuery}&${key}=${value}`;
					});
				} else if (key === 'useBpaAdditionalInfo') {
					modifiedQuery = `${modifiedQuery}&additionalInfo=bpa`;
				} else {
					modifiedQuery = `${modifiedQuery}&${key}=${extraParams[key]}`;
				}
			}
		}
	}
	SERVICE_URL = route + `?${modifiedQuery}`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(record),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function editRecordByPathActionObservable(
	payload: any,
	serviceName: string,
	location: FAWKES.configLocation,
	prefix?: string,
	subPath?: string,
) {
	const { SERVICE_URL } = getServiceURLS(serviceName, location, { prefix, subPath });
	return {
		withCredentials: isMockService() ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function editEntityRecordActionObservable(
	record: FAWKES.IFormData,
	serviceName: string,
	location: FAWKES.configLocation,
	useProductizedApi: boolean | undefined,
	extraParams = {},
) {
	const { SERVICE_URL } = getServiceURLS(serviceName, location, { ...extraParams }, useProductizedApi);
	const url_base = SERVICE_URL;
	const service_url = `${url_base}${url_base.indexOf('?') === -1 ? '?' : '&'}`;

	return {
		withCredentials: isMockService() ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: service_url,
		body: JSON.stringify(record),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export const fetchRecordFromProductizedApi = async ({ name, serviceName, configLocation, extraParams }) => {
	const response = await ajax(
		getRecordsActionObservable(
			serviceName,
			configLocation,
			null, // prefix
			null, // subPath
			null, // isBpa
			null, // isWhere
			null, // isHitCount
			null, // isHitTimestamps
			null, // isLastTriggered
			null, // isInfoNeeded
			null, // useBpaAdditionalInfo
			true, // useProductizedApi
			extraParams,
		),
	).toPromise();
	const responseObject = response?.response?.data || response?.response;
	return (responseObject || []).find((elem) => {
		// We need to do this because sometimes 'name' is `id` or `uuid`.
		return elem.name === name || elem.override_id === name || elem.id === name || elem.uuid === name;
	});
};

export const fetchRecordFromNonProductizedApi = async ({
	name,
	serviceName,
	configLocation,
	useProductizedApi,
	extraParams,
	resultPath,
	localConfigParams,
}) => {
	const response = await ajax(
		getRecordActionObservable(
			serviceName,
			name,
			configLocation,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			useProductizedApi,
			extraParams,
			localConfigParams,
		),
	)
		.toPromise()
		.then((response) => {
			const path = resultPath || (useProductizedApi ? 'response' : 'response.result.entry[0]');
			return _.get(response, path, {});
		});
	return response;
};

export const getRecord = async (props: RecordDataFetcherProps) => {
	const {
		name,
		serviceName,
		useProductizedApi,
		supportUUID,
		configLocation,
		extraParams,
		resultPath,
		localConfigParams,
	} = props;
	let record = {};
	try {
		if (supportUUID && useProductizedApi) {
			record = await fetchRecordFromProductizedApi({
				name,
				serviceName,
				configLocation,
				extraParams,
			});
		} else {
			record = await fetchRecordFromNonProductizedApi({
				name,
				serviceName,
				configLocation,
				useProductizedApi,
				extraParams,
				resultPath,
				localConfigParams,
			});
		}
		if (Array.isArray(record)) {
			return record[0];
		}
		return record;
	} catch (e) {
		// eslint-disable-next-line no-console
		console.log('Get record returning with error!', e.toString());
		return record;
	}
};
