import { isArray, isEmpty, isObject, isString } from 'lodash';
import { FAWKES } from 'src/typings/type';

export const isNonEmptyString = (value: string): boolean => {
	return isString(value) && !isEmpty(value);
};

export const isNonEmptyArray = (value: any[]): boolean => {
	return isArray(value) && !isEmpty(value);
};

export const isNonEmptyObject = (value: FAWKES.I_OBJECT): boolean => {
	return isObject(value) && !isEmpty(value);
};
