/* eslint-disable no-useless-escape */
import Pan from 'src/ui-lib/core/autorender/schema/Pan';
import { deletePath, getPathValue, getRecordPathWithFilters, setPathValue } from 'src/ui-lib/core/utils/json';
import { getStoreState } from 'src/ui-lib/core/utils/storeRegistry';
import { getLeafNodesMap } from 'src/ui-lib/core/autorender/schema/utils';
import _ from 'lodash';
import { FAWKES } from 'src/typings/type';
import { IGNORE_KEY } from 'src/ui-lib/constants';
import { store as sparkyStore } from 'sparky-framework';
import { SNIPPET } from 'service-lib/services/constants';
import { getConfigLocationFromURL } from 'src/ui-lib/utils';
import { getNavItemByRBAPath } from 'src/utils/routeUtils';
import StartupManager from 'src/containers/main/StartupManager';
import { checkIsFeatureEnabled } from 'src/utils/featureFlagUtils';
import ReactTooltip from 'react-tooltip';
import { isBulkConfigEnabled } from 'src/appUtils';

import {
	CURRENT_ITEM_KEY as CURRENT_ITEM_KEY_FROM_APPUTILS,
	INJECTED_REC_ID as INJECTED_REC_ID_FROM_APPUTILS,
	PANORAMA_CONFIG,
} from 'src/constants';

export const getFolderNameByUUID: any = (uuid: string, main?: any) => {
	const folderUUIDNameMap =
		main?.scopeManage?.folderUUIDNameMap || _.get(getStoreState(), 'main.scopeManage.folderUUIDNameMap', {});
	return folderUUIDNameMap[uuid];
};

export function convertArraySelectionValue(values: unknown) {
	const dValue = [];
	if (values && Array.isArray(values) && values.length > 0) {
		for (let index = 0; index < values.length; index++) {
			const valueObj = values[index];
			if (Pan.isObject(valueObj)) {
				dValue.push(
					Pan.apply(valueObj, {
						label: valueObj.label || valueObj.value,
					}),
				);
			} else if (Pan.isString(valueObj)) {
				dValue.push({
					value: valueObj,
					label: valueObj,
					[INJECTED_REC_ID]: generateGridRecId(),
				});
			}
		}
	}
	return dValue;
}

export function updateFilterCurrentIndex(filters: any[], key: any) {
	if (!Pan.isEmpty(filters)) {
		return filters.map((filter) => {
			const newFilter: FAWKES.I_OBJECT = {};
			if (filter && filter.find) {
				newFilter.find = filter.find;
				newFilter.replace = filter.replace;
				if (newFilter.replace.indexOf(CURRENT_ITEM_KEY) > 0 && !Pan.isEmpty(key)) {
					newFilter.replace = newFilter.replace.replace(CURRENT_ITEM_KEY, key);
				}
			} else if (filter && filter.append && filter.append.indexOf(CURRENT_ITEM_KEY) >= 0 && !Pan.isEmpty(key)) {
				newFilter.append = filter.append;
				newFilter.append = newFilter.append.replace(CURRENT_ITEM_KEY, key);
			}
			return newFilter;
		});
	}
}

export const INJECTED_REC_ID = INJECTED_REC_ID_FROM_APPUTILS;
export const CURRENT_ITEM_KEY = CURRENT_ITEM_KEY_FROM_APPUTILS;

export function getGridRecordFilterReplace(rec: FAWKES.I_OBJECT, recordConfigPath: string) {
	const prefix = recordConfigPath.endsWith('.entry')
		? 'entry'
		: recordConfigPath.endsWith('.member')
		? 'member'
		: null;
	if (rec && prefix) {
		return `${prefix}[?(@.${INJECTED_REC_ID}==\'${rec[INJECTED_REC_ID]}\')]`;
	}
}

export function getGridRecordFilterCurrentItemReplace(recordConfigPath: string) {
	const prefix = recordConfigPath.endsWith('.entry')
		? 'entry'
		: recordConfigPath.endsWith('.member')
		? 'member'
		: null;
	return `${prefix}[?(@.${INJECTED_REC_ID}==\'${CURRENT_ITEM_KEY}\')]`;
}

export function getGridRecordFilterFinder(recordConfigPath: string) {
	return recordConfigPath.endsWith('.entry') ? 'entry.*' : recordConfigPath.endsWith('.member') ? 'member.*' : null;
}

export function formDataPreProcess(
	formData: FAWKES.IFormData,
	fields: Array<FAWKES.FieldType>,
	rootField: any,
	filters?: FAWKES.I_OBJECT,
	record?: FAWKES.IFormData,
) {
	if (fields && rootField) {
		//if a field has dataMap, call that to change any data
		const leafNodesMap: any = getLeafNodesMap(rootField, fields, true);
		const fieldsMap: FAWKES.I_OBJECT = {};
		fields.forEach((item) => {
			fieldsMap[item.name || item.attrPath] = item;
		});
		for (const fieldName of rootField) {
			const fieldLeafNodes = leafNodesMap[fieldName];
			for (const child of fieldLeafNodes) {
				const childField = fieldsMap[child];
				if (childField && childField.dataMap) {
					const fieldValue = getPathValue(formData, childField.attrPath, filters);
					childField.dataMap(formData, fieldValue, filters, record);
				}
			}
		}
	}
	//insert __KEY__ in all the grid items(entry, member)
	return insertKeyInArray(formData);
}

export function formDataSetDefaults(
	formData: FAWKES.IFormData,
	fields: Array<FAWKES.FieldType>,
	subFieldsList: any,
	filters = [],
) {
	//set default values for those fields required and having default value or enum
	const fltrs = [...filters, { find: 'member.*', replace: 'member.0' }];
	for (const field of fields) {
		if (
			(!subFieldsList ||
				subFieldsList.indexOf(field.attrPath) >= 0 ||
				(field.mapping && subFieldsList.indexOf(field.name) >= 0)) &&
			(field.uiHint.allowBlank === false || field.uiHint.fieldRequired) &&
			(!field.attrPath.endsWith('.member') || field.uiHint.fieldRequired) &&
			(field.defaultValue ||
				field.uiHint.fieldRequiredValue ||
				field.type === 'enum' ||
				field.choiceAttr ||
				field.type === 'sequence')
		) {
			let defValue = field.defaultValue || field.uiHint.fieldRequiredValue;
			if (Pan.isFunction(field.defaultValue)) {
				defValue = field.defaultValue();
			}
			if (!defValue) {
				if (field.uiHint.enum) {
					//for enum we pick first element as default value
					defValue = field.uiHint.enum[0][0];
				} else if (field.type === 'sequence') {
					//for sequence leaf node, there wont be any default value
					defValue = {};
				}
			}
			if (!Pan.isEmpty(formData)) {
				if (getPathValue(formData, field.attrPath, fltrs)) {
					continue;
				}
			}
			setPathValue(formData, field.attrPath, defValue, fltrs);
		}
	}
	return formData;
}

export function insertKeyInArray(formData: any, useProductizedApi = false) {
	if (Pan.isArray(formData)) {
		const newFormData = [];
		for (let item of formData) {
			if (Pan.isObject(item)) {
				//entry array record
				item = insertKeyInArray(item, useProductizedApi);
				newFormData.push(Pan.apply({ [INJECTED_REC_ID]: generateGridRecId() }, item));
			} else if (useProductizedApi) {
				newFormData.push(Pan.apply({ [IGNORE_KEY]: item }, { [INJECTED_REC_ID]: generateGridRecId() }));
			} else {
				//member array record
				newFormData.push(Pan.apply({ value: item }, { [INJECTED_REC_ID]: generateGridRecId() }));
			}
		}
		return newFormData;
	} else if (Pan.isObject(formData)) {
		for (const i in formData) {
			formData[i] = insertKeyInArray(formData[i], useProductizedApi);
		}
		return formData;
	}
	return formData;
}

export function formDataPostProcess(
	formData: FAWKES.IFormData,
	fields: Array<FAWKES.FieldType>,
	rootField: any,
	useProductizedApi?: boolean,
) {
	if (fields && rootField) {
		//if a field has saveMap, call that to change any data
		const leafNodesMap: any = getLeafNodesMap(rootField, fields, true);
		const fieldsMap: FAWKES.I_OBJECT = {};
		fields.forEach((item) => {
			fieldsMap[item.name || item.attrPath] = item;
		});
		for (const fieldName of rootField) {
			const fieldLeafNodes: any = leafNodesMap[fieldName];
			for (const child of fieldLeafNodes) {
				const childField = fieldsMap[child];
				if (childField && childField.saveMap) {
					const fieldValue = getPathValue(formData, childField.attrPath);
					childField.saveMap(formData, fieldValue);
				}
				if (useProductizedApi && (childField?.type === 'rangedint' || childField?.type === 'float')) {
					const fieldValue = getPathValue(formData, childField.attrPath);
					if (fieldValue) {
						setPathValue(formData, childField.attrPath, Number(fieldValue));
					}
				}
				if (useProductizedApi && childField?.type === 'bool') {
					const fieldValue = getPathValue(formData, childField.attrPath) === 'yes' ? true : false;
					if (getPathValue(formData, childField.attrPath)) {
						setPathValue(formData, childField.attrPath, fieldValue);
					}
				}
			}
		}
	}

	//remove __KEY__ from all the grid items(entry, member)
	return deleteKeyInArray(formData);
}

export function isFieldsContainError(errors: any[]) {
	return Object.entries(errors).length === 0 ? false : true;
}

export function deleteKeyInArray(formData: any, isMemberEntry?: boolean, useProductizedApi?: boolean) {
	if (Pan.isArray(formData)) {
		const newFormData = [];
		for (const item of formData) {
			if (Pan.isObject(item)) {
				delete item[INJECTED_REC_ID];
				for (const m in item) {
					const isMember = m === 'member' ? true : false;
					item[m] = deleteKeyInArray(item[m], isMember, useProductizedApi);
				}
				if (isMemberEntry) {
					newFormData.push(item.value);
				} else if (item?.[IGNORE_KEY]) {
					newFormData.push(item[IGNORE_KEY]);
				} else {
					newFormData.push(item);
				}
			} else {
				newFormData.push(item);
			}
		}
		return newFormData;
	} else if (Pan.isObject(formData)) {
		for (const i in formData) {
			const isMember = i === 'member' ? true : false;
			formData[i] = deleteKeyInArray(formData[i], isMember, useProductizedApi);
		}
		return formData;
	}
	return formData;
}

const CHAR_SET = '0123456789abcdefghijklmnopqrstuvwxyz';

const ID_LENGTH = 8;

export function generateGridRecId() {
	let id = '';
	for (let i = 0; i < ID_LENGTH; i++) {
		id += CHAR_SET.charAt(Math.floor(Math.random() * CHAR_SET.length));
	}
	return id;
}

export function parseCleanupExtraInfo(completions = []) {
	const result = completions.map((cpl) => {
		const value = cpl['@value'];
		const type = cpl['@type'] || 'custom';
		let label: string = cpl['@value'];
		if (cpl['@help-string']) {
			label += ` (${cpl['@help-string']})`;
		}
		return {
			value,
			label,
			type,
		};
	});
	return result;
}

export function parseAutoCompletionToOptions(
	resp: any,
	doSort = true,
	sortByKey = 'label',
	path = '',
	onlyNotInUse = false,
	combineHelpString = true,
) {
	let result: any[] = [];

	if (resp) {
		const completions: any = path
			? getPathValue(resp, path)
			: getPathValue(resp, 'response.result.completions.completion');
		if (completions && Array.isArray(completions) && completions.length > 0) {
			if (completions.length === 1 && completions[0]['@value'].length === 0) {
				return result;
			}

			result = completions.map((cpl) => {
				const value = cpl['@value'];
				const type = cpl['@type'] || 'custom';
				let label = cpl['@value'];
				const inuse = cpl['@inuse'];
				const variableValue = _.get(cpl, '@variable-value', 'None');
				if (cpl['@help-string'] && combineHelpString) {
					label += ` (${cpl['@help-string']})`;
				}
				if (type === 'variable' || (_.isString(value) && value.startsWith('$'))) {
					label += ` (${variableValue})`;
				}
				return {
					value,
					label,
					type,
					'variable-value': variableValue,
					extraField: cpl['@extra-field'],
					inuse,
				};
			});
		} else if (completions && typeof completions === 'object') {
			for (const indx in completions) {
				let label: string = completions['@value'];
				if (completions['@help-string']) {
					label += ` (${completions['@help-string']})`;
				}

				result.push({
					value: completions[indx]['@value'],
					label: label,
					type: completions[indx]['@type'],
				});
			}
		}
	}

	if (onlyNotInUse) {
		result = result.filter((res) => res.inuse !== 'yes');
	}

	if (doSort) {
		result.sort(function (a, b) {
			const x = a[sortByKey];
			const y = b[sortByKey];
			return x < y ? -1 : x > y ? 1 : 0;
		});
	}

	return result;
}

export function isConfigManagedTypeReadyOnly() {
	const res =
		StartupManager.getConfigManagedType() === PANORAMA_CONFIG &&
		!(
			StartupManager.isMigrationStateEligibleForFawkesUI() &&
			!StartupManager.hideManagementUIBasedOnMigrationState()
		);
	return res;
}

export function getAllRBAScopeSet() {
	const auth = _.get(sparkyStore.getState(), 'auth', {});
	const access = auth?.access || [];
	const scopeSet = new Set<string>();
	for (let i = 0; i < access.length; i++) {
		const adminScope = auth.access[i].resource_scope;
		if (adminScope) {
			scopeSet.add(adminScope);
		}
	}
	return scopeSet;
}

export function getCurrentPredefinedAndCustomRoles(hideScope?: boolean) {
	const auth = _.get(sparkyStore.getState(), 'auth', {});
	let configLocation = _.get(getStoreState(), 'main.configLocation', {});
	if (_.isEmpty(configLocation)) {
		configLocation = getConfigLocationFromURL();
	}
	const scopeRolesMap = _.get(getStoreState(), 'main.scopeRolesMap', {});

	const scopeList: any[] =
		getFolderPermissionSetFromScopes(configLocation.container || {}, Object.keys(scopeRolesMap)) || [];

	if (scopeList.length > 0 && !hideScope) {
		const roles = scopeList.reduce((acc: any, scope: string) => {
			const scopeRoles = scopeRolesMap?.[scope] || [];
			return [...acc, scopeRoles.map((item: any) => item?.name)];
		}, []);
		return roles;
	} else {
		const tsgId = _.get(auth, 'tsg_id', '');
		const currentRoles = auth?.access?.get(`prn:${tsgId}:prisma_access:::`)?.roleNames || [];
		const predefinedRoles = auth?.access?.get(`prn:${tsgId}::::`)?.roleNames || [];
		const roles = [...currentRoles, ...predefinedRoles];
		return roles;
	}
}

const checkParentPermit = (permissionPath: string, ancetorPath: string, permissionSetMap: any) => {
	let currentPath = permissionPath;
	while (permissionSetMap?.get(currentPath)) {
		const cur = permissionSetMap?.get(currentPath);
		if (cur?.name === ancetorPath) return true;
		currentPath = cur?.parent;
		if (!currentPath) {
			break;
		}
	}
	return false;
};

export function getRBAPermissionByRBAC(rbaPath: string, config = null) {
	const navItem = getNavItemByRBAPath(rbaPath);
	const scopeRelated = !navItem?.hideScope;
	const auth = _.get(sparkyStore.getState(), 'auth', {});
	let configLocation: any = {};
	if (config) {
		configLocation = config;
	} else {
		configLocation = _.get(getStoreState(), 'main.configLocation', {});
	}
	if (_.isEmpty(configLocation)) {
		configLocation = getConfigLocationFromURL();
	}

	const PRISMA_ACCESS_PREFIX = 'prisma_access.';
	const routeToTest = PRISMA_ACCESS_PREFIX + rbaPath;
	const scopeRolesMap = _.get(getStoreState(), 'main.scopeRolesMap', {});

	let prismaHasAccessForParticularRoute = 'disable';
	if (rbaPath) {
		// ********* ALL APPS SERVICES & PRISMA ACCESS APP CHECK BEGIN *********
		// { scopes: { app_id:'prisma_access' }} means include all apps and services & prisma_access app
		if (auth?.access?.checkPermissionSet(routeToTest, { scopes: { app_id: 'prisma_access' } }) === 'write') {
			prismaHasAccessForParticularRoute = 'write';
		}
		if (auth?.access?.checkPermissionSet(routeToTest, { scopes: { app_id: 'prisma_access' } }) === 'read') {
			prismaHasAccessForParticularRoute = 'read';
		}
		// ********* ALL APPS SERVICES & PRISMA ACCESS APP CHECK END *********
	} else {
		for (let i = 0; i < auth?.access?.length; i++) {
			if (auth?.access[i]?.app_id === 'prisma_access' || auth?.access[i]?.app_id === '') {
				//empty means all apps&service
				if (auth?.access[i]?.aggPermissionSets?.has('prisma_access.all')) {
					if (auth?.access[i]?.aggPermissionSets?.get('prisma_access.all')?.access_types?.includes('write')) {
						prismaHasAccessForParticularRoute = 'write';
					}
					if (auth?.access[i]?.aggPermissionSets?.get('prisma_access.all')?.access_types?.includes('read')) {
						prismaHasAccessForParticularRoute = 'read';
					}
				}
			}
		}
	}

	// ********* SCOPE CHECK BEGIN *********
	if (scopeRolesMap && Object.keys(scopeRolesMap).length !== 0) {
		// We have scopes for this user - so check
		const scopeList: any[] =
			getFolderPermissionSetFromScopes(configLocation.container || {}, Object.keys(scopeRolesMap)) || [];
		// console.info('log......', auth,scopeList,routeToTest, configLocation.container?.name, scopeRolesMap, scopeRelated)
		const permissionSetMap = new Map();
		auth?.rolePermissionDefinition?.permissionSets?.forEach((permit: any) => {
			permissionSetMap?.set(permit.name, permit);
		});
		if (scopeList.length > 0 && scopeRelated) {
			//union roles in scope
			let R = false,
				W = false;
			scopeList.forEach((scope: string) => {
				const rolesInScope: any = scopeRolesMap?.[scope] || [];
				rolesInScope.forEach((role: any) => {
					role.permission_sets?.forEach((permit: any) => {
						if (
							permit.id === routeToTest ||
							permit.id === 'prisma_access.all' ||
							checkParentPermit(routeToTest, permit.id, permissionSetMap)
						) {
							if (permit?.access_types?.includes('write')) {
								W = true;
							}
							if (permit?.access_types?.includes('read')) {
								R = true;
							}
						}
					});
				});
			});
			return W ? 'enable' : R ? 'read-only' : 'disable';
		}
	}
	// ********* SCOPE CHECK END *********

	// *********  FALLBACK - IF NO SCOPES PRESENT, USE ALL APPS SERVICES + PRISMA ACCESS APP CHECK TO RETURN BEGIN *********
	if (prismaHasAccessForParticularRoute === 'write') {
		return 'enable';
	} else if (prismaHasAccessForParticularRoute === 'read') {
		return 'read-only';
	} else {
		return 'disable';
	}
	// *********  FALLBACK - IF NO SCOPES PRESENT, USE ALL APPS SERVICES + PRISMA ACCESS APP CHECK TO RETURN END *********
}

export function getRBAPermission(rbaPath: any, config = null) {
	if (!_.isArray(rbaPath)) {
		return getRBAPermissionByRBAC(rbaPath, config);
	}
	let readOnly = false;
	//for multiple permission rba, we do union
	for (let i = 0; i < rbaPath.length; i++) {
		const currentRbaPath = rbaPath[i];
		const permission = getRBAPermissionByRBAC(currentRbaPath, config);
		if (permission === 'enable') {
			return 'enable';
		} else if (permission === 'read-only') {
			readOnly = true;
		}
	}
	return readOnly ? 'read-only' : 'disable';
}

export function isViewPermissionEnabled(permission: string) {
	return permission === 'enable' || permission === 'read-only';
}

export function isPermissionEnabled(permission: string) {
	return permission === 'enable';
}

export function isPermissionDisabled(permission: string) {
	return permission === 'disable';
}

export function isPermissionReadOnly(permission: string) {
	return permission === 'read-only';
}

export function getLocationPermission(objLocation: string, configLocation: FAWKES.configLocation) {
	//check if the object location same as global location
	// let globalLocation = getPathValue(getStoreState(), 'main.configLocation');
	const locationName = configLocation ? (configLocation.container ? configLocation.container.name : null) : null;
	return locationName === objLocation;
}

export function getLocationPermissionProductized(objLocation: any, configLocation: FAWKES.configLocation) {
	const locationName = configLocation ? (configLocation.container ? configLocation.container.name : null) : null;
	if (objLocation?.folder) {
		return objLocation.folder === locationName;
	} else if (objLocation?.device) {
		return objLocation.device === locationName;
	}
	return true;
}

export function sortByKey(array: any[], key: string) {
	return array.sort(function (a, b) {
		const x = a[key];
		const y = b[key];
		return x < y ? -1 : x > y ? 1 : 0;
	});
}

export function deleteRecursivelyEmptyParents(formData: any, fieldAttrPath: string, filters?: any, key?: string) {
	//check if parent is empty. Then delete it
	const parentAttrPath = fieldAttrPath.slice(0, fieldAttrPath.lastIndexOf('.'));
	const parentValue = getPathValue(formData, parentAttrPath, filters, key);
	if (parentAttrPath !== '$' && parentValue !== false && Pan.isEmpty(parentValue)) {
		deletePath(formData, parentAttrPath, filters, key);
		deleteRecursivelyEmptyParents(formData, parentAttrPath, filters, key);
	} else {
		return;
	}
}

export function deleteRecursive(
	formData: any,
	field: FAWKES.FieldType,
	fieldsMap: FAWKES.I_OBJECT,
	filters?: any,
	key?: any,
	removeError?: any,
) {
	if (field) {
		field.attrPath && deletePath(formData, field.attrPath, filters, key);
		const recPath = getRecordPathWithFilters(formData, field.attrPath, filters, key);
		recPath && removeError && removeError(recPath);
		if (
			fieldsMap &&
			fieldsMap[field.attrPath || field.name] &&
			fieldsMap[field.attrPath || field.name]['childrenNames']
		) {
			fieldsMap[field.attrPath || field.name]['childrenNames'].forEach((name: string) => {
				deleteRecursive(formData, fieldsMap[name], fieldsMap, filters, key, removeError);
			});
		}
	}
}

export function getSelectedAndIndices(
	selected: FAWKES.I_OBJECT,
	gridData: any[],
	mainObjectKey?: string,
	idColumn?: string,
) {
	const selectedKeys = Object.keys(selected);
	gridData = gridData || [];
	let selectedIndexes = selectedKeys.map((key) => {
		// need to know if productized case. If any of gridData has name instead of @name it is productized
		if (idColumn && gridData?.length > 0 && gridData[0]?.[idColumn]) {
			return _.findIndex(gridData, { [idColumn]: key });
		}
		if (gridData?.length > 0 && gridData[0]?.name) {
			return _.findIndex(gridData, { name: key });
		}
		return _.findIndex(gridData, { '@name': key });
	});
	if (mainObjectKey) {
		selectedIndexes = selectedKeys.map((key) => {
			return _.findIndex(gridData, (data) => {
				if (idColumn) {
					const selectedKey = data[mainObjectKey][idColumn] || _.get(data, idColumn);
					return selectedKey === key;
				}
				return data[mainObjectKey]['@name'] === key;
			});
		});
	}
	selectedIndexes.sort();
	const selectedValues: any[] = [];
	selectedIndexes.forEach((idx) => {
		selectedValues.push(gridData[idx]);
	});
	return {
		selectedValues: selectedValues,
		min: Math.min(...selectedIndexes),
		max: Math.max(...selectedIndexes),
	};
}

export function getSelectedAndIndiceStaticIp(selected: FAWKES.I_OBJECT, gridData: any[], mainObjectKey?: string) {
	const selectedKeys = Object.keys(selected);
	gridData = gridData || [];
	let selectedIndexes = selectedKeys.map((key) => {
		// need to know if productized case. If any of gridData has name instead of @name it is productized
		if (gridData?.length > 0 && gridData[0]?.name) {
			return _.findIndex(gridData, { __KEY_: key });
		}
		return _.findIndex(gridData, { __KEY__: key });
	});
	if (mainObjectKey) {
		selectedIndexes = selectedKeys.map((key) => {
			return _.findIndex(gridData, (data) => {
				return data[mainObjectKey]['__KEY__'] === key;
			});
		});
	}
	selectedIndexes.sort();
	const selectedValues: any[] = [];
	selectedIndexes.forEach((idx) => {
		selectedValues.push(gridData[idx]);
	});
	return {
		selectedValues: selectedValues,
		min: Math.min(...selectedIndexes),
		max: Math.max(...selectedIndexes),
	};
}

export const isUserRoleInsightSupport = (navItem?: any) => {
	const roles: any = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	return (
		Array.isArray(roles) &&
		roles.some((r) => r === 'superuser' || r === 'msp_superuser' || r === 'web_security_admin')
	);
};

export const isAdminRole = (navItem?: any) => {
	const roles = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	return Array.isArray(roles) && roles.some((r) => r === 'superuser' || r === 'msp_superuser');
};

export const isUserRoleHasPushPermission = () => {
	const roles = getCurrentPredefinedAndCustomRoles();
	return (
		roles &&
		Pan.isArray(roles) &&
		roles.filter(
			(r: string) =>
				r === 'superuser' ||
				r === 'network_admin' ||
				r === 'security_admin' ||
				r === 'web_security_admin' ||
				r === 'soc_analyst' ||
				r === 'msp_superuser' ||
				r === 'tier_1_support' ||
				r === 'tier_2_support',
		).length > 0
	);
};

export const isUserRoleDlpOrSaas = (navItem?: any) => {
	const roles = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	return Array.isArray(roles) && roles.some((r) => r === 'datalosspreventionadmin' || r === 'saasadmin');
};
export const isUserRoleSaas = (navItem?: any) => {
	const roles = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	return Array.isArray(roles) && roles.some((r) => r === 'saasadmin');
};
export const isUserRoleProjectAdmin = (navItem?: any) => {
	const roles = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	if (Array.isArray(roles) && Array.isArray(roles?.[0])) return roles[0].some((r) => r === 'project_admin');
	else return Array.isArray(roles) && roles.some((r) => r === 'project_admin');
};
export const isUserRoleSuperAdmin = (navItem?: any) => {
	const roles = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	if (Array.isArray(roles) && Array.isArray(roles?.[0])) return roles[0].some((r) => r === 'superuser');
	else return Array.isArray(roles) && roles.some((r) => r === 'superuser');
};

export const isUserRoleHasManagePermission = (navItem?: any) => {
	const roles: any = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	return (
		roles &&
		Pan.isArray(roles) &&
		roles.filter((r: string) => r !== 'auditor' && r !== 'adem_tier_1_support').length > 0
	);
};
export const isUserRoleAdemSupport = (navItem?: any) => {
	const roles: any = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	return Array.isArray(roles) && roles.some((r) => r === 'adem_tier_1_support');
};

export const isUserRoleDataSecurityAdmin = (navItem?: any) => {
	const roles: any = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	return Array.isArray(roles) && roles.some((r) => r === 'data_security_admin');
};

export const isUserRoleHasEditPermission = () => {
	if (isConfigManagedTypeReadyOnly()) return false;
	const roles = getCurrentPredefinedAndCustomRoles();
	//temp code for ADI-10479
	if (
		checkIsFeatureEnabled(_.get(getStoreState(), 'main.featuresInfo'), 'url-filtering-admin') &&
		roles.includes('securityadmin')
	) {
		return window.location.pathname.includes('url-access-management');
	}
	//remove role check
	return (
		roles &&
		Pan.isArray(roles) &&
		roles.filter((r: string) => r === 'superuser' || r === 'security_admin' || r === 'msp_superuser').length > 0
	);
};
export const isUserRoleSWGAdmin = () => {
	const roles: any = getCurrentPredefinedAndCustomRoles();
	return roles && Pan.isArray(roles) && roles.filter((r: string) => r === 'web_security_admin').length > 0;
};

export const anyDefaultCertsExist = (certs) => {
	const defaultCertNames = [
		'Root CA',
		'Authentication Cookie CA',
		'SAML-Signing-Cert',
		'Forward-Trust-CA',
		'Forward-Trust-CA-ECDSA',
		'Forward-UnTrust-CA',
		'Forward-UnTrust-CA-ECDSA',
		'Authentication Cookie Cert',
	];
	let certsExist = false;
	const certMap = {};
	if (certs && Array.isArray(certs) && certs.length > 0) {
		certs.map((cert) => {
			certMap[cert['@name']] = cert['@name'];
		});
		defaultCertNames.map((certName) => {
			if (certMap[certName]) {
				//default cert exist
				//stop the loop
				certsExist = true;
				return certsExist;
			}
		});
	}
	return certsExist;
};

export const isUserRoleSecurityAdmin = () => {
	const roles: any = getCurrentPredefinedAndCustomRoles();
	return roles && Pan.isArray(roles) && roles.filter((r: string) => r === 'security_admin').length > 0;
};
export const getLevelOfGrid = (columns: any[]): number => {
	if (columns.length === 0) return 0;
	const nextColumns = columns.reduce((pre: FAWKES.I_OBJECT, cur: FAWKES.I_OBJECT) => {
		const children = cur.children || [];
		return pre.concat(children);
	}, []);
	return getLevelOfGrid(nextColumns) + 1;
};
export const supportVariable = (field: any) => {
	if (isBulkConfigEnabled()) return false;
	return field?.memberof?.find((member: any) => member.indexOf('/variable/') >= 0);
};

export const getVariableTypeByField = (field: any) => {
	const prefix = 'variable/entry[type/';
	const str = field?.memberof?.find((member: any) => member.indexOf(prefix) >= 0); //|| member.startsWith('#dev-hier/variable/entry[type/'));
	if (!str) return '';
	const startIndex = str.indexOf(prefix);
	const endIdx = str.indexOf(']');
	if (endIdx > 0) {
		const types = str.substring(startIndex + prefix.length, endIdx);
		if (types.indexOf('or') >= 0) {
			return types.split('or')?.map((item: string) => item?.trim());
		}
		return types;
	}
	return '';
};

export const getMultipleVariableTypesByField = (field: any) => {
	const prefix = 'variable/entry[type/';
	const types = field?.memberof?.map((member: any) => {
		const startIndex = member.indexOf(prefix);
		const endIndex = member.indexOf(']');
		if (endIndex > 0) {
			return member.substring(startIndex + prefix.length, endIndex);
		}
	});
	if (_.isEmpty(types)) return [];
	return types;
};

export const getLocationFromXpath = (xpath: string): Record<string, string> => {
	const containerPrefix = "container/entry[@name='";
	const devicePrefix = "device/on-prem/entry[@name='";
	const deviceIdx = xpath.indexOf(devicePrefix);
	if (deviceIdx >= 0) {
		const d1 = xpath.substring(deviceIdx + devicePrefix.length);
		const dIdx = d1.indexOf("'");
		if (dIdx < 0) return {};
		return {
			name: d1.substring(0, dIdx),
			type: 'on-prem',
		};
	}

	const idx1 = xpath.indexOf(containerPrefix);
	if (idx1 < 0) return {};
	const s1 = xpath.substring(idx1 + containerPrefix.length);
	const idx2 = s1.indexOf("'");
	if (idx2 < 0) return {};
	return {
		name: s1.substring(0, idx2),
		type: 'container',
	};
};

export function getFolderPermissionSetFromScopes({ name, type }: any, scopes: string[]) {
	const result: Set<string> = new Set<string>();
	const userScopeInfo = _.get(getStoreState(), 'main.userScopeInfo', {});
	if (type === SNIPPET) {
		const allSnippets = _.get(getStoreState(), 'main.snippetManage.allSnippets', {});
		const uuid = allSnippets?.[name]?.id;
		scopes.forEach((scope: string) => {
			const snippetSet = userScopeInfo[scope]?.snippetSet;
			if (snippetSet?.has(uuid)) {
				result.add(scope);
			}
		});
	} else {
		/*
			Depending on what configuration scope is picked in the dropdown, we need to check if scopes have that UUID in their folder set.
			If not, we need to check the parent folder of the current configuration scope, and so on.
			If we find a match, we add it to the result set.
		 */
		const folderScopeMap = _.get(getStoreState(), 'main.scopeManage.folderScopeMap', {});
		const originalScopeData = _.get(getStoreState(), 'main.scopeManage.originalScopeData', {});
		const globalFolderIdForStarFolder = originalScopeData?.[0]?.id;
		let uuid = folderScopeMap?.[name]?.id; // current configuration scope in the dropdown
		while (uuid && result.size === 0) {
			scopes.forEach((scope: string) => {
				const pathSet = userScopeInfo[scope]?.folderSet; // all uuid in the scope configured
				if (pathSet?.has(uuid) || pathSet?.has(globalFolderIdForStarFolder)) {
					result.add(scope);
				}
			});
			const currentFolderName = getFolderNameByUUID(uuid);
			const currentFolder = folderScopeMap?.[currentFolderName];
			uuid = currentFolder?.parent?.['id'];
		}
	}
	return Array.from(result);
}

export const userHasPermissionEnabled = (roles: any[], allowedPermissions: string[], permissionAccessType: string) => {
	const allowedRoles = ['superuser'];
	const allowedRolesExist = roles.find((role: any) => allowedRoles.includes(role?.name));
	if (!_.isUndefined(allowedRolesExist)) {
		return true;
	}

	if (roles.length > 1 && _.isUndefined(allowedRolesExist)) {
		roles.forEach((role: any) => {
			const hasPermit = role.permission_sets.find(
				(set: any) => allowedPermissions.includes(set?.id) && set.access_types.includes(permissionAccessType),
			);
			if (!_.isUndefined(hasPermit)) {
				return true;
			}
		});
	}
	return false;
};

export const getFolderPath = (uuid: string, main: any) => {
	const paths = [];
	const folderPaths = [];
	const visited = new Set<string>();
	let currentFolderName: string = getFolderNameByUUID(uuid, main);
	visited.add(uuid);
	const folderScopeMap = main?.scopeManage?.folderScopeMap || {};
	while (currentFolderName) {
		const curFolder = folderScopeMap?.[currentFolderName];
		if (!curFolder) {
			break;
		}
		paths.unshift(curFolder?.id);
		folderPaths.unshift(curFolder?.displayName);
		if (!visited.has(curFolder.parent?.name)) {
			currentFolderName = curFolder.parent?.name;
			visited.add(curFolder.parent?.id);
		} else {
			break;
		}
	}
	return paths;
};

export const rebuildTooltip = _.debounce(() => {
	ReactTooltip.hide();
	ReactTooltip.rebuild();
}, 200);

export const hasRoleExists = (roleName: string, includeScope = false) => {
	const auth = _.get(sparkyStore.getState(), 'auth', {});
	const tsgId = _.get(auth, 'tsg_id', '');
	const defaultRole = auth?.access?.get(`prn:${tsgId}:prisma_access:::`);
	const hasInDefaultRole = defaultRole?.roleNames?.indexOf('roleName') >= 0;
	if (hasInDefaultRole) return true;
	if (!includeScope) return false;
	const scopeRolesMap = _.get(getStoreState(), 'main.scopeRolesMap', {});
	const scopeNames = Object.keys(scopeRolesMap);
	for (let i = 0; i < scopeNames.length; i++) {
		for (let j = 0; j < scopeRolesMap[scopeNames[i]].length; j++) {
			if (scopeRolesMap[scopeNames[i]][j]?.name === roleName) {
				return true;
			}
		}
	}
};

export const formatApplicationContainer = (applicationMap, appMap) => {
	const dfs = function (node, path, map, longestPath, used) {
		const current = Object.keys(node);
		if (node.isLeaf) {
			if (!map[path]) {
				map[path] = [];
			}
			//find the longest leaf
			if (longestPath) {
				map[longestPath].push(applicationMap[path]);
				used.add(longestPath);
				used.add(path);
			}
			longestPath = path;
		}
		for (let i = 0; i < current?.length; i++) {
			dfs(node[current[i]], path ? path + '-' + current[i] : current[i], map, longestPath, used);
		}
		if (map[path]?.length === 0) {
			delete map[path];
		}
	};
	let appContainerMap = {};
	Object.keys(appMap).forEach((key: string) => {
		const apps = appMap[key];
		const rootNode = {};
		apps.forEach((app) => {
			const strs = app?.name?.split('-');
			let currentNode = rootNode;
			const N = strs.length;
			for (let i = 0; i < N; i++) {
				const currentStr = strs[i];
				if (!currentNode[currentStr]) {
					currentNode[currentStr] = {};
				}
				if (i === N - 1) {
					//we found the leaf node
					currentNode[currentStr] = { isLeaf: true };
				}
				currentNode = currentNode[currentStr];
			}
		});
		const map = {};
		const used = new Set();
		dfs(rootNode, '', map, '', used);
		appContainerMap[key] = apps.filter((app) => !used.has(app?.name));
		appContainerMap = { ...appContainerMap, ...map };
	});
	return appContainerMap;
};

export function capitalizeApplications(str) {
	if (!Pan.isDefined(str)) {
		return '';
	}
	str = str.replace(/-/g, ' ');
	str = str.split(' ');
	for (let i = 0, x = str.length; i < x; i++) {
		if (str[i]) {
			str[i] = str[i] === 'and' ? str[i] : str[i][0].toUpperCase() + str[i].substr(1);
		}
	}
	return str.join(' ');
}

export const mouseClickInSide = (x, y, container) => {
	return x < container.right && x > container.left && container.top < y && container.bottom > y;
};

export const isFromDefaultGPConfig = () => {
	return true;
};
