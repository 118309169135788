import { isLocalConfigEnabled } from 'src/containers/config/features';
import { isDeviceScope } from 'src/ui-lib/utils';
import { getAuthHeaders } from 'ui-lib/auth/lib';
import { isMockService, getRedirectURL, CONFIG_SASE_BASE_URI } from 'ui-lib/core/services/CommonServices';

export function getLocalRulesByLocalRefMarker(serviceName: string, useProductizedApi?: boolean, configLocation?: any) {
	const { container: { name: folder = '', type = '' } = {} } = configLocation;

	const containerUrlParam = `type=${type}&folder=${folder}`;
	const url = useProductizedApi
		? `${getRedirectURL()}${CONFIG_SASE_BASE_URI}/${serviceName}?${containerUrlParam}&isBpa=no&pagination=false&is-local=yes`
		: `${getRedirectURL()}/api/config/v9.2/${serviceName}?${containerUrlParam}&isInfoNeeded=yes&is-local=yes`;
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: url,
		headers: getAuthHeaders(),
	};
}

export const getServiceURLForLocalRulebase = (
	SERVICE_URL: string | string[],
	localRecordName: string,
	isLocalRulebaseRule: string,
) => {
	// Dont add isLocal if LCM is not enabled or we are not in Device Scope
	if (!isLocalConfigEnabled() || !isDeviceScope()) {
		return `${SERVICE_URL}${SERVICE_URL.indexOf('?') === -1 ? '?' : '&'}name=${localRecordName}`;
	} else {
		return `${SERVICE_URL}${
			SERVICE_URL.indexOf('?') === -1 ? '?' : '&'
		}name=${localRecordName}&is-local=${isLocalRulebaseRule}`;
	}
};
