import React, { FC, ReactElement } from 'react';
import { UncontrolledTooltip, FormFeedback } from 'reactstrap';

import 'src/ui-lib/core/autorender/widgets/FormFeedbackWidget/FormFeedbackWidget.scss';

interface Props {
	target: any;
	error?: string;
	[k: string]: any;
}

export const FormFeedbackWidget: FC<Props> = ({ target, inline, feedback, placement }): ReactElement => {
	placement = placement || 'auto';

	var element = document.querySelector(`[pid="${target}"]`) || document.getElementById(target) || target;
	if (typeof element !== 'object') {
		// if target element unmounted, don't show tooltip
		console.log('Element unmounted, no tooltip needed.');
		return <div />;
	}

	if (inline === true) {
		return <FormFeedback>{feedback}</FormFeedback>;
	}

	return (
		<UncontrolledTooltip
			delay={{ show: 0, hide: 0 }}
			className='form-feedback'
			placement={placement}
			target={element as HTMLElement}
			boundariesElement='window'
		>
			{feedback}
		</UncontrolledTooltip>
	);
};
