/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/first */
import { convertScopeResources } from 'src/containers/sbac/SbacUtil';

declare global {
	interface Window {
		//@ts-ignore
		DD_RUM: any;
	}
}

import {
	isUserLoggedIn,
	getLoggedInUser,
	doPushActionObservable,
	doRevertActionObservable,
	directoryDomainsObservable,
	getComplianceObservable,
	triggerComplianceObservable,
	saveUserPreferenceObservable,
	deleteUserPreferenceObservable,
	getAuthInfoObservable,
	getFqdnInfoObservable,
	getSubTenantInfoObservable,
	getDLPDataPatternsInfoObservable,
	setAuthEventToLogsObservable,
	getCapabilitiesInfoObservable,
	getUserPreferenceObservable,
	getUnifiedPolicyMetadataObservable,
	fetchFilterWidgetDataFromDBObservable,
} from 'src/containers/main/services';
import { _T } from 'src/utils/i18n-utils';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap, debounce, withLatestFrom, tap } from 'rxjs/operators';
import { of, from, interval, concat, empty } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import unset from 'lodash/unset';
import {
	getLicenseInfoObservable,
	getApplicationsInfoObservable,
	getDNSSecurityCategoryInfoObservable,
	getAdvancedDNSSecurityCategoryObservable,
	getTokenInfoObservable,
	getFeaturesObservable,
	getDeviceOverviewStatObservable,
	getMigrationStatusObservable,
	fetchDeviceOnboardingLabelGroupsObservable,
} from 'src/containers/dashboard/services';
import { getPathValue } from 'ui-lib/core/utils/json';
import { initAuth, getAuthHeaders } from 'ui-lib/auth/lib';
import {
	parseError,
	SERVER_ERROR,
	CLEAR_CURRENT_SERVER_ERROR,
	getRedirectURL,
} from 'ui-lib/core/services/CommonServices';
import {
	SHOW_MODAL,
	UPDATE_MODAL,
	HIDE_MODAL,
	startLoading,
	finishLoading,
	hideModal,
} from 'ui-lib/core/services/actions';
import { history, hasTsgSupport, store as sparkyStore, getAuthState } from 'sparky-framework';
import { ALLOW_REDIRECT_ROUTES, PAGINATION_QUERY_PARAM, PREFERENCES } from 'src/containers/main/constants';
import { isSnippetSharingEnabled, mapLicenseInfoData } from 'service-lib/services/licenseManager';
import { FAWKES } from 'src/typings/type';
import _ from 'lodash';
// eslint-disable-next-line import/namespace
import { getTargetRouteInfoForSnippetAndFolder } from 'src/utils/routeUtils';
import { getAllScopesObservable } from 'service-lib/services/scopeServices';
import { ConfigIndicator } from 'src/containers/manage/ConfigIndicator';
import { getBaseUrl, spiffyHeaders } from 'src/containers/custom-check/services';
import Pan from 'src/ui-lib/core/autorender/schema/Pan';
import { queryClient } from 'src/ReactQueryClient';
import { CLOUD_NGFW_RESOURCES_QC_KEY, FOLDER_SCOPE_QC_KEY } from 'src/query-client-utils/queryKeyConstants';
import { getStoreState } from 'ui-lib/core/utils/storeRegistry';
import { getSbacFeatureFlag } from 'src/utils/sharedServicesFeatureFlags';
import { getFawkesMainFromSparkyStore } from 'src/appUtils';
declare global {
	interface Window {
		DD_RUM?: any;
	}
}

export const DEFAULT_DEBOUNCE_TIME = 3000;

export const REGISTER_EXTENSION = 'REGISTER_EXTENSION';
export const IS_USER_LOGGEDIN = 'IS_USER_LOGGEDIN';
export const GET_LOGGEDIN_USER = 'GET_LOGGEDIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const START_MAIN_LOADING = 'START_MAIN_LOADING';
export const FINISH_MAIN_LOADING = 'FINISH_MAIN_LOADING';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const HIDE_SUCCESS_MESSAGE = 'HIDE_SUCCESS_MESSAGE';
export const CLEAR_NEW_ERRORS = 'CLEAR_NEW_ERRORS';
export const CHANGE_CONFIG_LOCATION = 'CHANGE_CONFIG_LOCATION';
export const CONFIG_PUSH = 'CONFIG_PUSH';
export const DO_PUSH = 'DO_PUSH';
export const DO_PUSH_SUCCESS = 'DO_PUSH_SUCCESS';
export const DO_REVERT = 'DO_REVERT';
export const FETCH_LICENSE_INFO = 'FETCH_LICENSE_INFO';
export const FETCH_TOKEN_INFO = 'FETCH_TOKEN_INFO';
export const SET_LICENSE_INFO = 'SET_LICENSE_INFO';
export const SET_TOKEN_INFO = 'SET_TOKEN_INFO';
export const FETCH_LICENSE_ERROR = 'FETCH_LICENSE_ERROR';
export const FETCH_TOKEN_ERROR = 'FETCH_TOKEN_ERROR';
export const DO_REVERT_SUCCESS = 'DO_REVERT_SUCCESS';
export const DIRECTORY_DOMAINS = 'DIRECTORY_DOMAINS';
export const DIRECTORY_DOMAINS_SUCCESS = 'DIRECTORY_DOMAINS_SUCCESS';
export const FETCH_AUTH_INFO = 'FETCH_AUTH_INFO';
export const AUTH_INFO_ERROR = 'AUTH_INFO_ERROR';
export const SET_AUTH_INFO = 'SET_AUTH_INFO';
export const FETCH_FQDN_INFO = 'FETCH_FQDN_INFO';
export const FQDN_INFO_ERROR = 'FQDN_INFO_ERROR';
export const SET_FQDN_INFO = 'SET_FQDN_INFO';
export const FETCH_SUBTENANT_INFO = 'FETCH_SUBTENANT_INFO';
export const SUBTENANT_INFO_ERROR = 'SUBTENANT_INFO_ERROR';
export const SET_SUBTENANT_INFO = 'SET_SUBTENANT_INFO';
export const FETCH_LICENSE_COMPLIANCE = 'FETCH_LICENSE_COMPLIANCE';
export const TRIGGER_LICENSE_COMPLIANCE = 'TRIGGER_LICENSE_COMPLIANCE';
export const SET_COMPLIANCE = 'SET_COMPLIANCE';
export const FETCH_APPLICATIONS_INFO = 'FETCH_APPLICATIONS_INFO';
export const SET_APPLICATIONS_INFO = 'SET_APPLICATIONS_INFO';
export const FETCH_APPLICATIONS_ERROR = 'FETCH_APPLICATIONS_ERROR';
export const FETCH_DNS_SECURITY_CATEGORY_INFO = 'FETCH_DNS_SECURITY_CATEGORY_INFO';
export const FETCH_ADVANCED_DNS_SECURITY_CATEGORY = 'FETCH_ADVANCED_DNS_SECURITY_CATEGORY';
export const SET_DNS_SECURITY_CATEGORY_INFO = 'SET_DNS_SECURITY_CATEGORY_INFO';
export const SET_ADVANCED_DNS_SECURITY_CATEGORY = 'SET_ADVANCED_DNS_SECURITY_CATEGORY';
export const FETCH_DNS_SECURITY_CATEGORY_ERROR = 'FETCH_DNS_SECURITY_CATEGORY_ERROR';
export const FETCH_ADVANCED_DNS_SECURITY_CATEGORY_ERROR = 'FETCH_ADVANCED_DNS_SECURITY_CATEGORY_ERROR';
export const FETCH_DLP_DATA_PATTERNS_INFO = 'FETCH_DLP_DATA_PATTERN_INFO';
export const SET_DLP_DATA_PATTERNS_INFO = 'SET_DLP_DATA_PATTERN_INFO';
export const FETCH_DLP_DATA_PATTERNS_ERROR = 'FETCH_DLP_DATA_PATTERN_ERROR';
export const FETCH_DLP_CAPABILITY_INFO = 'FETCH_DLP_CAPABILITY_INFO';
export const SET_DLP_CAPABILITY_INFO = 'SET_DLP_CAPABILITY_INFO';
export const FETCH_DLP_CAPABILITY_ERROR = 'FETCH_DLP_CAPABILITY_ERROR';
export const SET_REDIRECTED_FROM_GLOBAL_FIND = 'SET_REDIRECTED_FROM_GLOBAL_FIND';
export const FETCH_PUSH_DATA = 'FETCH_PUSH_DATA';
export const LOAD_PUSH_DATA = 'LOAD_PUSH_DATA';
export const FETCH_FEATURES = 'FETCH_FEATURES';
export const FETCH_FEATURES_ERROR = 'FETCH_FEATURES_ERROR';
export const SET_FEATURES_INFO = 'SET_FEATURES_INFO';
export const FETCH_EP_DEVICE_OVERVIEW_STAT = 'FETCH_EP_DEVICE_OVERVIEW_STAT';
export const FETCH_GP_DEVICE_OVERVIEW_STAT = 'FETCH_GP_DEVICE_OVERVIEW_STAT';
export const FETCH_JP_DEVICE_OVERVIEW_STAT = 'FETCH_JP_DEVICE_OVERVIEW_STAT';
export const SET_EP_DEVICE_OVERVIEW_STAT = 'SET_EP_DEVICE_OVERVIEW_STAT';
export const SET_GP_DEVICE_OVERVIEW_STAT = 'SET_GP_DEVICE_OVERVIEW_STAT';
export const SET_JP_DEVICE_OVERVIEW_STAT = 'SET_JP_DEVICE_OVERVIEW_STAT';
export const FETCH_EP_DEVICE_OVERVIEW_STAT_ERROR = 'FETCH_EP_DEVICE_OVERVIEW_STAT_ERROR';
export const FETCH_GP_DEVICE_OVERVIEW_STAT_ERROR = 'FETCH_GP_DEVICE_OVERVIEW_STAT_ERROR';
export const FETCH_JP_DEVICE_OVERVIEW_STAT_ERROR = 'FETCH_JP_DEVICE_OVERVIEW_STAT_ERROR';
export const SET_SAAS_CERTIFICATES = 'SET_SAAS_CERTIFICATES';
export const SET_SAAS_RISKS = 'SET_SAAS_RISKS';

export const SET_USER_PREFERENCE = 'SET_USER_PREFERENCE';
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const SAVE_USER_PREFERENCE = 'SAVE_USER_PREFERENCE';
export const SAVE_USER_PREFERENCE_SUCCESS = 'SAVE_USER_PREFERENCE_SUCCESS';
export const DELETE_USER_PREFERENCE = 'DELETE_USER_PREFERENCE';
export const DELETE_USER_PREFERENCE_SUCCESS = 'DELETE_USER_PREFERENCE_SUCCESS';
export const UPDATE_REFRESH_ID = 'UPDATE_REFRESH_ID';

export const GET_USER_PREFERENCE = 'GET_USER_PREFERENCE';
export const FETCH_LOGGED_IN_USER = 'FETCH_LOGGED_IN_USER';
export const SET_USER_PREFERENCE_ACTIVE_TAB = 'SET_USER_PREFERENCE_ACTIVE_TAB';
export const SET_USER_PREFERENCE_HIDE_POLICY_OPTIMIZER_POPUP = 'SET_USER_PREFERENCE_HIDE_POLICY_OPTIMIZER_POPUP';
export const SET_USER_PREFERENCE_HIDE_WELCOME_POPUP = 'SET_USER_PREFERENCE_HIDE_WELCOME_POPUP';
export const SET_USER_PREFERENCE_HIDE_SCOPE_POPUP = 'SET_USER_PREFERENCE_HIDE_SCOPE_POPUP';
export const SET_USER_PREFERENCE_FAVORITES = 'SET_USER_PREFERENCE_FAVORITES';
export const UPDATE_LAST_VISITED_URL = 'UPDATE_LAST_VISITED_URL';
export const UPDATE_GRID_CONFIG = 'UPDATE_GRID_CONFIG';
export const UPDATE_BPA_CONFIG = 'UPDATE_BPA_CONFIG';

export const GET_FILTER_WIDGET_DATA = 'GET_FILTER_WIDGET_DATA';
export const GET_FILTER_WIDGET_DATA_SUCCESS = 'GET_FILTER_WIDGET_DATA_SUCCESS';
export const SET_FILTER_WIDGET_DATA_LOADING = 'SET_FILTER_WIDGET_DATA_LOADING';
export const REMOVE_FILTER_WIDGET = 'REMOVE_FILTER_WIDGET';

export const INIT_MAIN = 'INIT_MAIN';
export const FETCH_MIGRATION_STATUS = 'FETCH_MIGRATION_STATUS';
export const UPDATE_MIGRATION_STATUS = 'UPDATE_MIGRATION_STATUS';
export const SET_AUTH_EVENT_TO_LOGS = 'SET_AUTH_EVENT_TO_LOGS';

export const SET_APP_CONFIG = 'SET_APP_CONFIG';

export const SET_RBI_CONFIGURATION = 'SET_RBI_CONFIGURATION';
export const FETCH_RBI_CONFIGURATION_ERROR = 'FETCH_RBI_CONFIGURATION_ERROR';
export const UPDATE_RBI_CONFIGURATION = 'UPDATE_RBI_CONFIGURATION';
export const PIN_SCOPE_MANAGE_VIEWER = 'PIN_SCOPE_MANAGE_VIEWER';
export const UPDATE_SCOPE_MANAGE_INFO = 'UPDATE_SCOPE_MANAGE_INFO';
export const FETCH_SCOPE_MANAGE_INFO_SUCCESS = 'FETCH_SCOPE_MANAGE_INFO_SUCCESS';
export const UPDATE_SCOPE_MANAGE_SCOPE_EXPANDED_MAP_INFO = 'UPDATE_SCOPE_MANAGE_SCOPE_EXPANDED_MAP_INFO';
export const UPDATE_SCOPE_MANAGE_SEARCH_STRING_INFO = 'UPDATE_SCOPE_MANAGE_SEARCH_STRING_INFO';
export const UPDATE_SNIPPET_MANAGE_INFO = 'UPDATE_SNIPPET_MANAGE_INFO';
export const UPDATE_CURRENT_SNIPPET = 'UPDATE_CURRENT_SNIPPET';
export const UPDATE_SNIPPET_MANAGE_SEARCH_STRING_INFO = 'UPDATE_SNIPPET_MANAGE_SEARCH_STRING_INFO';

export const UPDATE_HA_PAIR_CONFIG_DEVICES_INFO = 'UPDATE_HA_PAIR_CONFIG_DEVICES_INFO';
export const FETCH_HA_PAIR_CONFIG_DEVICES_INFO = 'FETCH_HA_PAIR_CONFIG_DEVICES_INFO';
export const UPDATE_DEVICES_INFO = 'UPDATE_DEVICES_INFO';
export const FETCH_DEVICES_INFO = 'FETCH_DEVICES_INFO';
export const PIN_CONFIGURATION_NAV_VIEWER = 'PIN_CONFIGURATION_NAV_VIEWER';
export const UPDATE_CONFIGURATION_NAV_SEARCH_STRING_INFO = 'UPDATE_CONFIGURATION_NAV_SEARCH_STRING_INFO';

export const UPDATE_WEB_SECURITY_ENABLE_INFO = 'UPDATE_WEB_SECURITY_ENABLE_INFO';
export const FETCH_WEB_SECURITY_ENABLE_INFO = 'FETCH_WEB_SECURITY_ENABLE_INFO';

export const UPDATE_USER_SCOPE_INFO = 'UPDATE_USER_SCOPE_INFO';

export const FETCH_MCAS_INFO = 'FETCH_MCAS_INFO';

export const STORE_DEVICE_MODEL_INFO = 'STORE_DEVICE_MODEL_INFO';
export const SET_CONFIG_INDICATORS = 'SET_CONFIG_INDICATORS';
export const SET_MOVE_TENANT_REDIRECTURL = 'SET_MOVE_TENANT_REDIRECTURL';

export const FETCH_CLOUD_NGFW_INFO_SUCCESS = 'FETCH_CLOUD_NGFW_INFO_SUCCESS';
export const UPDATE_PSK_INFO = 'UPDATE_PSK_INFO';
export const GET_UNIFIED_POLICY_METADATA = 'GET_UNIFIED_POLICY_METADATA';
export const GET_UNIFIED_POLICY_METADATA_SUCCESS = 'GET_UNIFIED_POLICY_METADATA_SUCCESS';

export const FETCH_DEVICE_ONBOARDING_LABEL_GROUPS = 'FETCH_DEVICE_ONBOARDING_LABEL_GROUPS';
export const FETCH_DEVICE_ONBOARDING_LABEL_GROUPS_SUCCESS = 'FETCH_DEVICE_ONBOARDING_LABEL_GROUPS_SUCCESS';

export const UPDATE_BOOTSTRAP_STATUS = 'UPDATE_BOOTSTRAP_STATUS';

const emptyAction = { type: '' };

const DefaultScopeNameMap: any = {
	All: _T('All'),
	'ngfw-shared': _T('All Firewalls'),
};

const SUPPORTED_CNGFW_MODEL_MAP: any = {
	'cngfw-azure': true,
	'cngfw-aws': true,
};

export const isCNGFWModel = (model = '') => {
	const modelStringInLowercase = model.toLocaleLowerCase();
	return Boolean(SUPPORTED_CNGFW_MODEL_MAP[modelStringInLowercase]);
};

const HARemapping = ({ children = [], devicesInfo = {}, haPairConfigDevicesMap = {} }: any): any => {
	const HAMap: any = {};
	return children.reduce((result: any, child: any) => {
		const { serial_number: serialNumber } = child;
		if (!serialNumber) {
			return [...result, child];
		} else {
			const newChild = {
				...child,
			};

			//Check HA PAIR FROM haPairConfigDevicesMap
			if (haPairConfigDevicesMap[serialNumber]) {
				const { primary_serial_number: primaryDeviceSerial, secondary_serial_number: secondaryDeviceSerial } =
					haPairConfigDevicesMap[serialNumber];

				if (serialNumber === primaryDeviceSerial) {
					HAMap[serialNumber] = { ...haPairConfigDevicesMap[serialNumber] };
					newChild.displayType = 'ha';
					if (HAMap[secondaryDeviceSerial]) {
						const index = result.findIndex((e: any) => e.serial_number === secondaryDeviceSerial);
						if (index >= 0) {
							const matched = result.splice(index, 1);
							newChild.children = [matched[0]];
						}
					}
				} else if (serialNumber === secondaryDeviceSerial) {
					HAMap[serialNumber] = { ...haPairConfigDevicesMap[serialNumber] };
					newChild.displayType = 'ha';
					if (HAMap[primaryDeviceSerial]) {
						const index = result.findIndex((e: any) => e.serial_number === primaryDeviceSerial);
						if (index >= 0) {
							result[index].children = [newChild];
							return [...result];
						}
					}
				}
			}

			//Check HA PAIR FROM deviceInfoMap
			if (!HAMap[serialNumber] && devicesInfo[serialNumber]) {
				const { ha_peer_serial: haPeerSerial } = devicesInfo[serialNumber];
				if (haPeerSerial && haPeerSerial !== serialNumber && !isNaN(haPeerSerial)) {
					newChild.displayType = 'ha';
					if (!HAMap[haPeerSerial]) {
						HAMap[serialNumber] = {
							primary_serial_number: serialNumber,
							secondary_serial_number: haPeerSerial,
						};
					} else {
						const { primary_serial_number: primarySerialNumber } = HAMap[haPeerSerial];
						const index = result.findIndex((e: any) => e.serial_number === primarySerialNumber);
						if (index >= 0) {
							result[index].children = [newChild];
							return [...result];
						}
					}
				}
			}

			return [...result, newChild];
		}
	}, []);
};

export const mapScopeNode = ({ node, devicesInfo = {}, haPairConfigDevicesMap = {} }: any): any => {
	if (!node) return;
	let scopeNodeMap = {};
	let scopeDisplayNameMap = {};
	let deviceContainerMap = {};
	let cloudNGFWMap = {};
	const { children } = node;

	if (children && children.length) {
		let totalSubNodeCount = 0;
		const totalDeviceSerialNumber: any = [];
		const { mappedChildren, mappedChildrenWithDevice } = children.reduce(
			(result: any, child: any) => {
				const {
					mappedNode: mappedChild,
					scopeNodeMap: mappedScopeNodeMap,
					scopeDisplayNameMap: mappedScopeDisplayNameMap,
					mappedNodeWithDevice: mappedChildWithDevice,
					deviceContainerMap: mappedDeviceContainerMap,
					cloudNGFWMap: mappedCloudNGFWMap,
				} = mapScopeNode({ node: child, devicesInfo, haPairConfigDevicesMap });

				const {
					childrenCount: totalSubNodeCountFromChild = 0,
					type = '',
					serial_number: serialNumberFromChild = '',
					deviceSerialNumberArray = [],
				} = mappedChild;

				if (type === 'on-prem') {
					totalSubNodeCount = totalSubNodeCount + 1;
					totalDeviceSerialNumber.push(serialNumberFromChild);
				} else {
					totalSubNodeCount = totalSubNodeCount + totalSubNodeCountFromChild;
					totalDeviceSerialNumber.push(...deviceSerialNumberArray);
				}

				scopeNodeMap = {
					...scopeNodeMap,
					...mappedScopeNodeMap,
				};

				scopeDisplayNameMap = {
					...scopeDisplayNameMap,
					...mappedScopeDisplayNameMap,
				};

				deviceContainerMap = {
					...deviceContainerMap,
					...mappedDeviceContainerMap,
				};

				cloudNGFWMap = {
					...cloudNGFWMap,
					...mappedCloudNGFWMap,
				};

				return {
					mappedChildren: [...result.mappedChildren, mappedChild],
					mappedChildrenWithDevice: mappedChildWithDevice
						? [...result.mappedChildrenWithDevice, mappedChildWithDevice]
						: [...result.mappedChildrenWithDevice],
				};
			},
			{
				mappedChildren: [],
				mappedChildrenWithDevice: [],
			},
		);

		const HARemappedChildren = HARemapping({
			children: mappedChildren,
			devicesInfo,
			haPairConfigDevicesMap,
		});

		const HARemappedChildrenWithDevice = HARemapping({
			children: mappedChildrenWithDevice,
			devicesInfo,
			haPairConfigDevicesMap,
		});

		const mappedNode = {
			...node,
			scope: node.name,
			displayName: DefaultScopeNameMap[node.name] || node['display_name'] || node.name,
			type: node.type,
			displayType: node.type === 'container' ? 'folder' : 'device',
			children: [...HARemappedChildren],
			childrenCount: totalSubNodeCount,
			deviceSerialNumberArray: totalDeviceSerialNumber,
			isDisabled: (key: string, type: string) => {
				const { isDisabled } = getTargetRouteInfoForSnippetAndFolder({ containerKey: key, type });
				return isDisabled;
			},
		};

		const mappedNodeWithDevice = {
			...node,
			scope: node.name,
			displayName: DefaultScopeNameMap[node.name] || node['display_name'] || node.name,
			type: node.type,
			displayType: node.type === 'container' ? 'folder' : 'device',
			children: [...HARemappedChildrenWithDevice],
			childrenCount: totalSubNodeCount,
			deviceSerialNumberArray: totalDeviceSerialNumber,
			isDisabled: (key: string, type: string) => {
				const { isDisabled } = getTargetRouteInfoForSnippetAndFolder({ containerKey: key, type });
				return isDisabled;
			},
		};

		return {
			mappedNode: mappedNode,
			scopeNodeMap: {
				...scopeNodeMap,
				[node.name]: mappedNode,
			},
			scopeDisplayNameMap: {
				...scopeDisplayNameMap,
				[node.name]: mappedNode.displayName,
			},
			deviceContainerMap: {
				...deviceContainerMap,
			},
			cloudNGFWMap: {
				...cloudNGFWMap,
			},
			mappedNodeWithDevice: mappedChildrenWithDevice.length ? mappedNodeWithDevice : null,
		};
	} else {
		let mappedNode = {
			...node,
			scope: node.name,
			displayName: DefaultScopeNameMap[node.name] || node['display_name'] || node.name,
			type: node.type,
			displayType: node.type === 'container' ? 'folder' : 'device',
			isDisabled: (key: string, type: string) => {
				const { isDisabled } = getTargetRouteInfoForSnippetAndFolder({ containerKey: key, type });
				return isDisabled;
			},
		};
		const HARemappedDevices = HARemapping({
			children: [mappedNode],
			devicesInfo,
			haPairConfigDevicesMap,
		});
		mappedNode = HARemappedDevices[0];

		const newNode = {
			mappedNode,
			scopeNodeMap: {
				...scopeNodeMap,
				[node.name]: mappedNode,
			},
			scopeDisplayNameMap: {
				...scopeDisplayNameMap,
				[node.name]: mappedNode.displayName,
			},
			deviceContainerMap: {
				...deviceContainerMap,
			},
			cloudNGFWMap: {
				...cloudNGFWMap,
			},
			mappedNodeWithDevice: node.type === 'on-prem' ? mappedNode : null,
		};

		if (node.type === 'on-prem') {
			newNode.deviceContainerMap = {
				...deviceContainerMap,
				[node.name]: node.device_only !== true,
			};
			if (Pan.isString(node.model) && isCNGFWModel(node.model)) {
				newNode.cloudNGFWMap = {
					...cloudNGFWMap,
					[node.name]: { ...node },
				};
				newNode.mappedNode.displayType = 'cngfw';
			}
		}

		return newNode;
	}
};

export const fetchAllScopeInfo = (): any => async (dispatch: any) => {
	// ********* SCOPES CHECK  - OVERRIDES PRISMA ACCESS APP *********
	try {
		// Protect behind feature flag for sbac
		const sbacFeatureFlag = getSbacFeatureFlag();

		if (sbacFeatureFlag) {
			// New scopes flow
			const mainState = _.get(getStoreState(), 'main');

			if (!isEmpty(mainState?.scopeManage) && !isEmpty(mainState?.snippetManage)) {
				const allScopesFromSparkyAuth = getAuthState()?.rolePermissionDefinition?.scopes;
				const globalFolderIdForStarFolder = mainState?.scopeManage?.originalScopeData?.[0].id;
				const allSnippetsForStarSnippet = mainState?.snippetManage?.allSnippets;
				const transformedScopeInfo = convertScopeResources(
					allScopesFromSparkyAuth,
					globalFolderIdForStarFolder,
					allSnippetsForStarSnippet,
					crypto.randomUUID(),
				);
				// TODO
				// 1. Should not see colo connect,
				// 2. should see gp etc. - make it looks like existing folders
				dispatch({
					type: UPDATE_USER_SCOPE_INFO,
					userScopeInfo: transformedScopeInfo,
				});
			} else {
				// console.log('Folder and snippet states not ready - will wait for another call');
				dispatch(emptyAction);
			}
		} else {
			// Old scopes flow
			const response = await ajax(getAllScopesObservable()).toPromise();
			dispatch({
				type: UPDATE_USER_SCOPE_INFO,
				userScopeInfo: response.response,
			});
		}
	} catch (e: any) {
		dispatch({
			type: SERVER_ERROR,
			errorMessage: parseError(e),
		});
	}
};

export const fetchScopeManageInfo = (): any => async (dispatch: any, getState: any) => {
	try {
		const requestUrl = `${getRedirectURL()}/sase/config/v1/folders?${PAGINATION_QUERY_PARAM}`;

		const param: FAWKES.I_FETCH_PARAM = {
			withCredentials: true,
			method: 'GET',
			headers: getAuthHeaders(),
			dataType: 'json',
			ContentType: 'application/json',
		};
		const scopeDataResponse = await fetch(requestUrl, param);

		const json = await scopeDataResponse.json();
		queryClient.setQueryDefaults([FOLDER_SCOPE_QC_KEY], { cacheTime: Infinity });
		queryClient.setQueryData([FOLDER_SCOPE_QC_KEY], json);
		const { data = [] } = json;

		const { main: { devicesInfo = {}, haPairConfigDevicesMap = {} } = {} } = getState();

		await dispatch({
			type: FETCH_SCOPE_MANAGE_INFO_SUCCESS,
			originalScopeData: _.cloneDeep(data),
		});

		await dispatch(
			updateScopeManageInfo({
				scopeData: data,
				devicesInfo,
				haPairConfigDevicesMap,
			}),
		);
	} catch (error: any) {
		await dispatch({
			type: SERVER_ERROR,
			errorMessage: parseError(error),
		});
	}
};

export const fetchCloudNgfwInfo = (): any => async (dispatch: any, getState: any) => {
	try {
		const authHeaders = getAuthHeaders();
		const param: FAWKES.I_FETCH_PARAM = {
			method: 'GET',
			headers: spiffyHeaders(authHeaders['x-auth-jwt']),
			dataType: 'json',
			ContentType: 'application/json',
		};
		const tenantId = getState().main?.tenant?.tenantId;
		const endpoint = `/cloudmgr/v1/cngfwresources?cloud_mgmt_tenant_id=${tenantId}`;
		const response = await fetch(`${getBaseUrl()}${endpoint}`, param);
		if (!response.ok) {
			throw new Error(`${response.status} ${response.statusText}`);
		}

		let json = [];
		if (response.status !== 204) {
			json = await response.json();
		}
		queryClient.setQueryData([CLOUD_NGFW_RESOURCES_QC_KEY], json);

		const cloudNgfwMap = json.reduce((idDeviceMap: Record<string, any>, device: any) => {
			idDeviceMap[device.ngfw_id] = device;
			return idDeviceMap;
		}, {});

		await dispatch({
			type: FETCH_CLOUD_NGFW_INFO_SUCCESS,
			cloudNgfwMap,
		});
		return json;
	} catch (error: any) {
		// eslint-disable-next-line no-console
		console.error('error', error);
	}
};

export const updateScopeManageInfo =
	({ scopeData = [], devicesInfo = {}, haPairConfigDevicesMap = {} }: any) =>
	async (dispatch: any, getState: any) => {
		const { children = [] } = scopeData[0] || {};
		let folderScopeMap: any = {};
		let folderDisplayNameMap: any = {
			...DefaultScopeNameMap,
		};
		let deviceContainerNameMap: any = {};
		let cloudNGFWNameMap: any = {};
		const { allFolders, folderWithDevices } = children.reduce(
			(result: any, node: any) => {
				const {
					mappedNode,
					scopeNodeMap,
					scopeDisplayNameMap,
					deviceContainerMap,
					cloudNGFWMap,
					mappedNodeWithDevice,
				} = mapScopeNode({
					node,
					devicesInfo,
					haPairConfigDevicesMap,
				});

				folderScopeMap = {
					...folderScopeMap,
					...scopeNodeMap,
				};
				folderDisplayNameMap = {
					...folderDisplayNameMap,
					...scopeDisplayNameMap,
				};

				deviceContainerNameMap = {
					...deviceContainerNameMap,
					...deviceContainerMap,
				};

				cloudNGFWNameMap = {
					...cloudNGFWNameMap,
					...cloudNGFWMap,
				};

				return {
					allFolders: [...result.allFolders, mappedNode],
					folderWithDevices: [...result.folderWithDevices, mappedNodeWithDevice],
				};
			},
			{
				allFolders: [],
				folderWithDevices: [],
			},
		);
		const rootNGFWNode = allFolders.filter((child: any) => child?.name === 'ngfw-shared');
		const rootNGFWNodeWithDevices = folderWithDevices.filter((child: any) => child?.name === 'ngfw-shared');
		const serialNumberNameMap: any = {};
		const folderUUIDNameMap: any = {};
		allFolders.forEach((folder: any) => {
			//bfs to set parent
			const queue: any[] = [];
			queue.push(folder);
			if (folder?.id) {
				folderUUIDNameMap[folder.id] = folder.name;
			}
			while (queue.length > 0) {
				const cur = queue.shift();
				if (cur.children) {
					cur.children.forEach((item: any) => {
						item.parent = cur;
						if (folderScopeMap[item?.name]) {
							folderScopeMap[item?.name].parent = cur;
						}
						queue.push(item);
						if (item.serial_number) {
							serialNumberNameMap[item.serial_number] = item.name;
						}
						if (item.id) {
							folderUUIDNameMap[item.id] = item.name;
						}
					});
				}
			}
		});
		await dispatch({
			type: UPDATE_SCOPE_MANAGE_INFO,
			folderScopeData: rootNGFWNode,
			folderScopeWithDevicesData: rootNGFWNodeWithDevices,
			allFolders,
			folderScopeMap,
			folderDisplayNameMap,
			deviceContainerNameMap,
			cloudNGFWNameMap,
			serialNumberNameMap,
			folderUUIDNameMap,
		});

		await dispatch(fetchAllScopeInfo());
	};

export const updateScopeMangeScopeExpandedMapInfo =
	({ scopeExpandedMap }: any) =>
	async (dispatch: any, getState: any) => {
		await dispatch({
			type: UPDATE_SCOPE_MANAGE_SCOPE_EXPANDED_MAP_INFO,
			scopeExpandedMap: scopeExpandedMap,
		});
	};

export const updateScopeMangeSearchStringInfo =
	({ searchString }: any) =>
	async (dispatch: any, getState: any) => {
		await dispatch({
			type: UPDATE_SCOPE_MANAGE_SEARCH_STRING_INFO,
			searchString,
		});
	};

export const updateConfigurationNavSearchStringInfo =
	({ searchString }: any) =>
	async (dispatch: any, getState: any) => {
		await dispatch({
			type: UPDATE_CONFIGURATION_NAV_SEARCH_STRING_INFO,
			searchString,
		});
	};

export const fetchCurrentSnippetInfo =
	({ scopeName, id }: any): any =>
	async (dispatch: any, getState: any) => {
		try {
			const isSnippetShareEnabled = isSnippetSharingEnabled();
			const requestUrl = isSnippetShareEnabled
				? `${getRedirectURL()}/sase/config/v1/device-config/snippets?${PAGINATION_QUERY_PARAM}&name=${scopeName}`
				: `${getRedirectURL()}/sase/config/v1/snippets?${PAGINATION_QUERY_PARAM}&name=${scopeName}`;

			const param: FAWKES.I_FETCH_PARAM = {
				withCredentials: true,
				method: 'GET',
				headers: getAuthHeaders(),
				dataType: 'json',
				ContentType: 'application/json',
			};
			const snippetDataResponse = await fetch(requestUrl, param);
			const defaultType = isSnippetShareEnabled ? 'local' : 'custom';
			const snippet = (await snippetDataResponse.json()) || {};

			await dispatch({
				type: UPDATE_CURRENT_SNIPPET,
				currentSnippet: {
					...snippet,
					snippetType: snippet?.type || defaultType,
					folders: (snippet.folders || []).map((folder: any) => {
						return folder.name;
					}),
				},
			});
		} catch (error: any) {
			await dispatch({
				type: SERVER_ERROR,
				errorMessage: parseError(error),
			});
		}
	};

export const fetchSnippetManageInfo = (): any => async (dispatch: any, getState: any) => {
	try {
		const isSnippetShareEnabled = isSnippetSharingEnabled();
		const requestUrl = isSnippetShareEnabled
			? `${getRedirectURL()}/sase/config/v1/device-config/snippets?${PAGINATION_QUERY_PARAM}`
			: `${getRedirectURL()}/sase/config/v1/snippets?${PAGINATION_QUERY_PARAM}`;
		const param: FAWKES.I_FETCH_PARAM = {
			withCredentials: true,
			method: 'GET',
			headers: getAuthHeaders(),
			dataType: 'json',
			ContentType: 'application/json',
		};
		const snippetDataResponse = await fetch(requestUrl, param);
		const defaultType = isSnippetShareEnabled ? 'local' : 'custom';

		const json = (await snippetDataResponse.json()) || {};

		let { data: snippets = [] } = json;
		if (isSnippetShareEnabled) {
			snippets = json;
		}

		const mappedSnippet = snippets.map((snippet: any) => {
			return {
				...snippet,
				scope: snippet.name,
				displayName: snippet['display_name'] || snippet.name,
				type: 'snippet',
				snippetType: snippet?.type || defaultType,
				displayType: 'snippet',
				isDisabled: (key: string, type: string) => {
					const { isDisabled } = getTargetRouteInfoForSnippetAndFolder({ containerKey: key, type });
					return isDisabled;
				},
			};
		});
		const { allSnippets, snippetDisplayMap } = mappedSnippet.reduce(
			(result: any, ele: any) => {
				if (ele['display_name']) {
					return {
						allSnippets: {
							...result.allSnippets,
							[ele.name]: {
								...ele,
							},
						},
						snippetDisplayMap: {
							...result.snippetDisplayMap,
							[ele.name]: ele['display_name'],
						},
					};
				} else {
					return {
						allSnippets: {
							...result.allSnippets,
							[ele.name]: {
								...ele,
							},
						},
						snippetDisplayMap: {
							...result.snippetDisplayMap,
						},
					};
				}
			},
			{
				allSnippets: {},
				snippetDisplayMap: {},
			},
		);

		await dispatch({
			type: UPDATE_SNIPPET_MANAGE_INFO,
			snippetScopeData: mappedSnippet,
			allSnippets,
			snippetDisplayMap,
		});

		await dispatch(fetchAllScopeInfo());
	} catch (error: any) {
		await dispatch({
			type: SERVER_ERROR,
			errorMessage: parseError(error),
		});
	}
};

export const updateSnippetMangeSearchStringInfo =
	({ searchString }: any) =>
	async (dispatch: any, getState: any) => {
		await dispatch({
			type: UPDATE_SNIPPET_MANAGE_SEARCH_STRING_INFO,
			searchString,
		});
	};

export const pinScopeManageViewer =
	({ isPinned }: any): any =>
	async (dispatch: any, getState: any) => {
		await dispatch({
			type: PIN_SCOPE_MANAGE_VIEWER,
			isPinned,
		});
		const { main: { loggedInUser: { preference = {} } = {} } = {} } = getState();
		const userPref = cloneDeep(preference);
		set(userPref, 'config.isScopeManageViewerPinned', isPinned);
		await dispatch(setUserPreference(userPref));
		await dispatch(saveUserPreference(userPref));
	};

export const resizeScopeManageViewer =
	({ width }: any): any =>
	async (dispatch: any, getState: any) => {
		const { main: { loggedInUser: { preference = {} } = {} } = {} } = getState();
		const userPref = cloneDeep(preference);
		set(userPref, 'config.scopeManageViewerWidth', width);
		await dispatch(setUserPreference(userPref));
		await dispatch(saveUserPreference(userPref));
	};

export const pinConfigurationSelectionViewer =
	({ isPinned }: any): any =>
	async (dispatch: any, getState: any) => {
		await dispatch({
			type: PIN_CONFIGURATION_NAV_VIEWER,
			isPinned,
		});
		const { main: { loggedInUser: { preference = {} } = {} } = {} } = getState();
		const userPref = cloneDeep(preference);
		set(userPref, 'config.isConfigurationNavViewerPinned', isPinned);
		await dispatch(setUserPreference(userPref));
		await dispatch(saveUserPreference(userPref));
	};

export const fetchDevicesInfo = (): any => async (dispatch: any, getState: any) => {
	try {
		const url = `${getRedirectURL()}/ngfw/api/v1/devices`;
		const param: FAWKES.I_FETCH_PARAM = {
			method: 'GET',
			headers: {
				...getAuthHeaders(),
				'Content-Type': 'application/json',
			},
		};

		await dispatch({
			type: FETCH_DEVICES_INFO,
		});

		const deviceInfoResponse = await fetch(url, param);

		let data = await deviceInfoResponse.json();
		if (data.error) {
			data = [];
		}

		const devicesInfo = data.reduce((result: any, e: any) => {
			const { id } = e;
			return {
				...result,
				[id]: {
					...e,
				},
			};
		}, {});

		await dispatch({
			type: UPDATE_DEVICES_INFO,
			devicesInfo,
		});
	} catch (error: any) {
		await dispatch({
			type: SERVER_ERROR,
			errorMessage: parseError(error),
		});
	}
};

export const fetchHAPairConfigDevicesInfo = (): any => async (dispatch: any, getState: any) => {
	try {
		const requestUrl = `${getRedirectURL()}/sase/config/v1/device-config/ha-devices?${PAGINATION_QUERY_PARAM}&folder=ngfw-shared`;

		const param: FAWKES.I_FETCH_PARAM = {
			withCredentials: true,
			method: 'GET',
			headers: getAuthHeaders(),
			dataType: 'json',
			ContentType: 'application/json',
		};

		await dispatch({
			type: FETCH_HA_PAIR_CONFIG_DEVICES_INFO,
		});

		const scopeDataResponse = await fetch(requestUrl, param);

		const json = await scopeDataResponse.json();

		const haPairConfigDevicesMap = Array.isArray(json)
			? json.reduce((result: any, entry: any) => {
					const { ha_devices: haDevices = [] } = entry;
					const map = haDevices.reduce((devicesMap: any, pair: any) => {
						const {
							primary_serial_number: primaryDeviceSerial,
							secondary_serial_number: secondaryDeviceSerial,
						} = pair;
						return {
							...devicesMap,
							[primaryDeviceSerial]: {
								...pair,
							},
							[secondaryDeviceSerial]: {
								...pair,
							},
						};
					}, {});

					return {
						...result,
						...map,
					};
			  }, {})
			: {};

		await dispatch({
			type: UPDATE_HA_PAIR_CONFIG_DEVICES_INFO,
			haPairConfigDevicesMap,
		});
	} catch (error: any) {
		await dispatch({
			type: SERVER_ERROR,
			errorMessage: parseError(error),
		});
	}
};

export const fetchWebSecurityEnableInfo = (): any => async (dispatch: any, getState: any) => {
	try {
		const url = `${getRedirectURL()}/api/config/v9.2/SwgEnabledDevices`;
		const param: FAWKES.I_FETCH_PARAM = {
			method: 'GET',
			headers: {
				...getAuthHeaders(),
				'Content-Type': 'application/json',
			},
		};

		await dispatch({
			type: FETCH_WEB_SECURITY_ENABLE_INFO,
		});

		const response = await fetch(url, param);
		const json = await response.json();
		const data = _.get(json, 'result.result.devices.entry', []);

		const webSecurityEnableInfo = data.reduce((result: any, ele: any) => {
			return {
				...result,
				[ele['@name']]: ele['@swg-enable'] || 'yes',
			};
		}, {});
		await dispatch({
			type: UPDATE_WEB_SECURITY_ENABLE_INFO,
			webSecurityEnableInfo,
		});
	} catch (error: any) {
		await dispatch({
			type: SERVER_ERROR,
			errorMessage: parseError(error),
		});
	}
};

export const initialMain = (main: FAWKES.I_OBJECT) => ({
	type: INIT_MAIN,
	main,
});

export const isUserLoggedInAction = () => ({
	type: IS_USER_LOGGEDIN,
});

export const getLoggedInUserAction = () => ({
	type: GET_LOGGEDIN_USER,
});

export const fetchMigrationStatus = () => ({
	type: FETCH_MIGRATION_STATUS,
});

export const updateMigrationStatus = ({ status, fawkesTenantId }: any) => ({
	type: UPDATE_MIGRATION_STATUS,
	status,
	migrationNewFawkesTenantId: fawkesTenantId,
});

export const getLoggedInUserSuccess = (user: any) => ({
	type: GET_USER_SUCCESS,
	user: user.response.user,
	tenant: user.response.tenant,
	preference: user.response.preference,
	extra: user.response.extra,
});

export const getLoggedInUserFail = () => ({
	type: GET_USER_FAIL,
});

export const loginSuccess = (tenant: any) => ({
	type: LOGIN_SUCCESS,
	tenant,
});

export const clearNewErrors = () => ({
	type: CLEAR_NEW_ERRORS,
});
export const clearCurrentServerError = () => ({
	type: CLEAR_CURRENT_SERVER_ERROR,
});

export const pushAction = () => ({
	type: CONFIG_PUSH,
});

export const loginFail = ({ noSession }: any) => ({
	type: LOGIN_FAIL,
	noSession,
});

export const startMainLoading = () => ({
	type: START_MAIN_LOADING,
});

export const finishMainLoading = () => ({
	type: FINISH_MAIN_LOADING,
});

export const showSuccessMessage = (successMessage: string) => ({
	type: SHOW_SUCCESS_MESSAGE,
	successMessage,
});

export const hideSuccessMessage = (message: string) => ({
	type: HIDE_SUCCESS_MESSAGE,
});

export const changeConfigLocation = (locationValue: any) => ({
	type: CHANGE_CONFIG_LOCATION,
	locationValue,
});

export const loadPushDataInReduxJob = (payload: FAWKES.I_ACTION_PAYLOAD) => ({
	type: LOAD_PUSH_DATA,
	payload,
});

export const doPush = (payload: FAWKES.I_ACTION_PAYLOAD) => ({
	type: DO_PUSH,
	payload,
});

export const doPushSuccess = () => ({
	type: DO_PUSH_SUCCESS,
});

export const doRevert = (params: FAWKES.I_ACTION_PAYLOAD) => ({
	type: DO_REVERT,
	params,
});

export const fetchLicenseInfo = () => ({
	type: FETCH_LICENSE_INFO,
});

export const fetchRBIConfiguration = () => async (dispatch: any, getState: any) => {
	try {
		const param: FAWKES.I_FETCH_PARAM = {
			withCredentials: true,
			method: 'GET',
			headers: getAuthHeaders(),
			dataType: 'json',
			ContentType: 'application/json',
		};
		const response = await fetch(
			`${getRedirectURL()}/api/sase/config/v1/remote-browser-isolation/settings?${PAGINATION_QUERY_PARAM}&snippet=rbi`,
			param,
		);

		const json = await response.json();

		const { selected_vendor: selectedVendor, vendors = [] } = json;
		const data = {
			selectedVendor,
			vendors: vendors.reduce((result: any, vendor: any) => {
				const { name, attributes = [], id } = vendor;
				return {
					...result,
					[name]: {
						id,
						attributes: attributes.reduce((settings: any, attr: any) => {
							const { name, value = '' } = attr;
							return {
								...settings,
								[name]: value,
							};
						}, {}),
					},
				};
			}, {}),
		};

		await dispatch({
			type: SET_RBI_CONFIGURATION,
			data,
		});
	} catch (error) {
		await dispatch({
			type: FETCH_RBI_CONFIGURATION_ERROR,
		});
		throw error;
	}
};

export const updateRbiByPan = (paloAltoEnabled: boolean) => async (dispatch: any, getState: any) => {
	// eslint-disable-next-line no-useless-catch
	try {
		const param: FAWKES.I_FETCH_PARAM = {
			withCredentials: true,
			method: 'POST',
			headers: {
				...getAuthHeaders(),
				'Content-Type': 'application/json',
			},
			dataType: 'json',
			ContentType: 'application/json',
			body: JSON.stringify({
				enabled: 'yes',
			}),
		};
		const response = await fetch(
			`${getRedirectURL()}/api/sase/config/v1/remote-browser-isolation/palo-alto-vendor?snippet=rbi`,
			param,
		);

		if (response.status !== 200) {
			throw response.statusText;
		} else {
			await dispatch(fetchRBIConfiguration());
		}
	} catch (error) {
		throw error;
	}
};

export const updateRBISelectedVendor =
	({ selectedVendor, onComplete }: any) =>
	async (dispatch: any, getState: any) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const param: FAWKES.I_FETCH_PARAM = {
				withCredentials: true,
				method: 'PUT',
				headers: {
					...getAuthHeaders(),
					'Content-Type': 'application/json',
				},
				dataType: 'json',
				ContentType: 'application/json',
				body: JSON.stringify({
					selected_vendor: selectedVendor,
				}),
			};
			const response = await fetch(
				`${getRedirectURL()}/api/sase/config/v1/remote-browser-isolation/settings?snippet=rbi`,
				param,
			);

			if (response.status !== 200) {
				throw response.statusText;
			} else {
				await dispatch(fetchRBIConfiguration());
			}
		} catch (error) {
			throw error;
		}
	};

export const updateRBIVendorConfiguration =
	({ config, id, vendorName, onComplete }: any) =>
	async (dispatch: any, getState: any) => {
		// eslint-disable-next-line no-useless-catch
		try {
			const configKeys = Object.keys(config);
			const requestBody = {
				name: vendorName,
				attributes: configKeys.map((key) => {
					return {
						name: key,
						value: config[key],
					};
				}),
			};
			const param: FAWKES.I_FETCH_PARAM = {
				withCredentials: true,
				method: 'PUT',
				headers: {
					...getAuthHeaders(),
					'Content-Type': 'application/json',
				},
				dataType: 'json',
				ContentType: 'application/json',
				body: JSON.stringify(requestBody),
			};
			const response = await fetch(
				`${getRedirectURL()}/api/sase/config/v1/remote-browser-isolation/settings/vendors/${id}?snippet=rbi`,
				param,
			);

			if (response.status !== 200) {
				throw response.statusText;
			} else {
				await dispatch(fetchRBIConfiguration());
			}
		} catch (error) {
			throw error;
		}
	};

export const fetchFeatures = () => ({
	type: FETCH_FEATURES,
});

export const fetchTokenInfo = () => ({
	type: FETCH_TOKEN_INFO,
});

export const fetchEPDeviceOverviewStat = () => ({
	type: FETCH_EP_DEVICE_OVERVIEW_STAT,
});

export const setEPDeviceOverviewStat = (epStatus: any) => ({
	type: SET_EP_DEVICE_OVERVIEW_STAT,
	epStatus,
});

export const fetchGPDeviceOverviewStat = () => ({
	type: FETCH_GP_DEVICE_OVERVIEW_STAT,
});
export const fetchJPDeviceOverviewStat = () => ({
	type: FETCH_JP_DEVICE_OVERVIEW_STAT,
});

export const setGPDeviceOverviewStat = (gpStatus: any) => ({
	type: SET_GP_DEVICE_OVERVIEW_STAT,
	gpStatus,
});

export const setJPDeviceOverviewStat = (gpStatus: any) => ({
	type: SET_JP_DEVICE_OVERVIEW_STAT,
	gpStatus,
});

export const fetchAuthInfo = () => ({
	type: FETCH_AUTH_INFO,
});

export const fetchFqdnInfo = () => ({
	type: FETCH_FQDN_INFO,
});

export const fetchSubTenantInfo = () => ({
	type: FETCH_SUBTENANT_INFO,
});

export const setLicenseInfo = (licenseInfo: any) => ({
	type: SET_LICENSE_INFO,
	licenseInfo,
});

export const setFeaturesInfo = (featuresInfo: any) => ({
	type: SET_FEATURES_INFO,
	featuresInfo,
});

export const setTokenInfo = (tokenInfo: any) => ({
	type: SET_TOKEN_INFO,
	tokenInfo,
});

export const setAuthInfo = (authInfo: any) => ({
	type: SET_AUTH_INFO,
	authInfo,
});

export const setFqdnInfo = (fqdnInfo: any) => ({
	type: SET_FQDN_INFO,
	fqdnInfo,
});

export const setSubTenantInfo = (subTenantInfo: any) => ({
	type: SET_SUBTENANT_INFO,
	subTenantInfo,
});

export const fetchApplicationsInfo = () => {
	return {
		type: FETCH_APPLICATIONS_INFO,
	};
};

export const setApplicationsInfo = (applicationsInfo: any, aceAppContainerSet = new Set()) => ({
	type: SET_APPLICATIONS_INFO,
	applicationsInfo,
	aceAppContainerSet,
});

export const setSaaSCertificates = (saasCertificates: any) => ({
	type: SET_SAAS_CERTIFICATES,
	saasCertificates,
});

export const setSaaSRisks = (saasRisks: any) => ({
	type: SET_SAAS_RISKS,
	saasRisks,
});
export const fetchDNSSecurityCategoryInfo = () => {
	return {
		type: FETCH_DNS_SECURITY_CATEGORY_INFO,
	};
};

export const fetchAdvancedDNSSecurityCategory = () => {
	return {
		type: FETCH_ADVANCED_DNS_SECURITY_CATEGORY,
	};
};

export const setDNSSecurityCategoryInfo = (categoriesInfo: any) => ({
	type: SET_DNS_SECURITY_CATEGORY_INFO,
	categoriesInfo,
});

export const setAdvancedDNSSecurityCategoryInfo = (categoriesInfo: any) => ({
	type: SET_ADVANCED_DNS_SECURITY_CATEGORY,
	categoriesInfo,
});

export const fetchDLPDataPatternsInfo = () => {
	return {
		type: FETCH_DLP_DATA_PATTERNS_INFO,
	};
};

export const setDLPDataPatternsInfo = ({ dataPatternsInfo }: any) => ({
	type: SET_DLP_DATA_PATTERNS_INFO,
	dataPatternsInfo: dataPatternsInfo.entry || [],
});

export const fetchDLPCapabilityInfo = () => {
	return {
		type: FETCH_DLP_CAPABILITY_INFO,
	};
};

export const setDLPCapabilityInfo = ({ capabilityInfo }: any) => ({
	type: SET_DLP_CAPABILITY_INFO,
	dlpCapabilities: capabilityInfo || [],
});

export const setRedirectedFromGlobalFind = (globalFindRedirection) => ({
	type: SET_REDIRECTED_FROM_GLOBAL_FIND,
	globalFindRedirection,
});

export const doRevertSuccess = (revertMsg: string) => ({
	type: DO_REVERT_SUCCESS,
	revertMsg,
});

export const fetchDirectoryDomains = () => ({
	type: DIRECTORY_DOMAINS,
});

export const fetchLicenseCompliance = () => ({
	type: FETCH_LICENSE_COMPLIANCE,
});

export const triggerLicenseCompliance = () => ({
	type: TRIGGER_LICENSE_COMPLIANCE,
});

export const setCompliance = (compliance: any) => ({
	type: SET_COMPLIANCE,
	compliance,
});

export const fetchDirectoryDomainsSuccess = (response: any) => {
	const directoryDomains = _.get(response, 'response') || [];
	return {
		type: DIRECTORY_DOMAINS_SUCCESS,
		directoryDomains,
	};
};

export const setUserPreference = (userPreference: any) => {
	const fawkesMain = getFawkesMainFromSparkyStore() || {};
	const clonedFawkesMain = _.clone(fawkesMain);
	const { loggedInUser = {} } = clonedFawkesMain;
	loggedInUser.preference = userPreference;
	sparkyStore.dispatch({
		type: 'set-values',
		values: {
			fawkesMain: clonedFawkesMain,
		},
	});
	return {
		type: SET_USER_PREFERENCE,
		userPreference,
	};
};

export const setLoggedinUser = (userPreference: any, extra: any, scmFlagsFromUser: any) => {
	const fawkesMain = getFawkesMainFromSparkyStore() || {};
	const clonedFawkesMain = _.clone(fawkesMain);
	const { loggedInUser = {} } = clonedFawkesMain;
	loggedInUser.preference = userPreference;
	loggedInUser.extra = extra;
	loggedInUser.scmFlagsFromUser = scmFlagsFromUser;

	sparkyStore.dispatch({
		type: 'set-values',
		values: {
			fawkesMain: clonedFawkesMain,
		},
	});
	return {
		type: SET_LOGGED_IN_USER,
		userPreference,
		extra,
		scmFlagsFromUser,
	};
};

/**
 *
 * @param {*} userPreference :the entire preference object to save on main store
 * @param {*} debounceTime :debounce time unit is milliseconds, if you want to fire immediately, can skip pass in this param
 */
export const saveUserPreference = (userPreference: any, debounceTime = 0) => ({
	type: SAVE_USER_PREFERENCE,
	userPreference,
	debounceTime,
});

export const saveUserPreferenceSuccess = (userPreference: any) => ({
	type: SAVE_USER_PREFERENCE_SUCCESS,
	userPreference,
});

/**
 *
 * @param {*} key :the key attribute to delete if specified
 * if key is empty, it will delete the entire user preference object
 */
export const deleteUserPreference = (key = '') => ({
	type: DELETE_USER_PREFERENCE,
	key,
});

export const deleteUserPreferenceSuccess = (resp: any) => ({
	type: DELETE_USER_PREFERENCE_SUCCESS,
});

export const updateRefreshId = (reduxStateId: any, refreshProps?: any) => ({
	type: UPDATE_REFRESH_ID,
	reduxStateId,
	refreshProps,
});

export const updateLastVisitedUrl = (lastVisitedUrl: string) => ({
	type: UPDATE_LAST_VISITED_URL,
	lastVisitedUrl,
});

export const updateGridConfig = ({ gridId, tableData }: any) => ({
	type: UPDATE_GRID_CONFIG,
	gridId,
	tableData,
});

export const updateBPAConfig = ({ bpaId, bpaData }: any) => ({
	type: UPDATE_BPA_CONFIG,
	bpaId,
	bpaData,
});

export const getFilterWidgetData = ({ reduxId, params, endpoint, paginated }: any) => ({
	type: GET_FILTER_WIDGET_DATA,
	reduxId,
	params,
	endpoint,
	paginated,
});

export const fetchFilterWidgetDataSuccess = (action: any = {}, response: any = {}) => ({
	type: GET_FILTER_WIDGET_DATA_SUCCESS,
	reduxId: action.reduxId,
	response: response,
});

export const setFilterWidgetDataLoading = ({ reduxId, loading }: any) => ({
	type: SET_FILTER_WIDGET_DATA_LOADING,
	reduxId,
	loading,
});

export const removeFilterWidget = ({ reduxId }: any) => ({
	type: REMOVE_FILTER_WIDGET,
	reduxId,
});

export const setAuthEventToLogs = (user: any, detail: any, replaceLocation = false) => ({
	type: SET_AUTH_EVENT_TO_LOGS,
	user,
	detail,
	replaceLocation,
});

export const setAuthEventToLogsEpic = (action$: any) => {
	return action$.pipe(
		ofType(SET_AUTH_EVENT_TO_LOGS),
		mergeMap((action: any) =>
			ajax(setAuthEventToLogsObservable()).pipe(
				mergeMap((resp) => {
					//ADI-11907
					//clear the sessionStorage only for FAWKES when there is no TSG support
					if (!hasTsgSupport()) {
						const sub = sessionStorage.getItem('currentTenantId');
						if (sub) {
							localStorage.removeItem(`token-${sub}`);
							localStorage.removeItem(`token-exp-${sub}`);
						}
						sessionStorage.clear();
						if (action.replaceLocation) {
							window.location.replace(action.detail.link);
						}
					}

					return of(emptyAction);
				}),
				catchError((error) => {
					if (!hasTsgSupport()) {
						//ADI-11907
						//clear the sessionStorage only for FAWKES when there is no TSG support
						//in case of TSG, sparky will handle it
						const sub = sessionStorage.getItem('currentTenantId');
						if (sub) {
							localStorage.removeItem(`token-${sub}`);
							localStorage.removeItem(`token-exp-${sub}`);
						}
						sessionStorage.clear();
						if (action.replaceLocation) {
							window.location.replace(action.detail.link);
						}
					}

					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error),
						showMessage: false,
					});
				}),
			),
		),
	);
};

export const setAppConfig = (config: any, appBaseURL: string) => ({
	type: SET_APP_CONFIG,
	config,
	appBaseURL,
});

export const getUnifiedPolicyMetadata = () => ({
	type: GET_UNIFIED_POLICY_METADATA,
});

export const getUnifiedPolicyMetadataSuccess = (response) => ({
	type: GET_UNIFIED_POLICY_METADATA_SUCCESS,
	response,
});

export const getFilterWidgetDataEpic = (action$: any) =>
	action$.pipe(
		ofType(GET_FILTER_WIDGET_DATA),
		mergeMap((action: any) => {
			return concat(
				of(setFilterWidgetDataLoading({ reduxId: action.reduxId, loading: true })),
				fetchFilterWidgetDataFromDBObservable({
					params: action.params,
					endpoint: action.endpoint,
				}).pipe(
					mergeMap((response) => of(fetchFilterWidgetDataSuccess(action, response))),
					catchError((error) =>
						of({
							type: SERVER_ERROR,
							errorMessage: parseError(error),
						}),
					),
				),
				of(setFilterWidgetDataLoading({ reduxId: action.reduxId, loading: false })),
			);
		}),
	);

export const fetchDirectoryDomainsEpic = (action$: any) => {
	return action$.pipe(
		ofType(DIRECTORY_DOMAINS),
		mergeMap((action) =>
			directoryDomainsObservable().pipe(
				mergeMap((response) => of(fetchDirectoryDomainsSuccess(response))),
				catchError((error) => {
					return empty();
					// of(
					// 	{
					// 		type: "ERROR",
					// 		errorMessage: parseError(error)
					// 	}
					// );
				}),
			),
		),
	);
};

export const postProcess = (response: any, payload: any) => {
	if (!payload || !payload.type) return emptyAction;
	const { type, postAction } = payload;
	const result = postAction && postAction(response);
	if (type === SHOW_MODAL) {
		const { modal } = result;
		if (modal) {
			return {
				type: SHOW_MODAL,
				modal,
			};
		}
	}
	if (type === UPDATE_MODAL) {
		const { id, props } = result;
		if (id && props) {
			return {
				type: UPDATE_MODAL,
				id,
				props,
			};
		}
	}
	return emptyAction;
};

export const isUserLoggedInEpic = (action$: any) => {
	return action$.pipe(
		ofType(IS_USER_LOGGEDIN),
		mergeMap((action) =>
			isUserLoggedIn().pipe(
				concatMap((response: any) => {
					const resp = response.response;
					if (resp && resp.isLoggedIn && resp.tenant) {
						return of(loginSuccess(resp.tenant), getLoggedInUserAction());
					} else {
						return of(loginFail(resp));
					}
				}),
				catchError((error) => {
					return of({
						type: LOGIN_FAIL,
					});
				}),
			),
		),
	);
};

export const fetchMigrationStatusEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_MIGRATION_STATUS),
		mergeMap((action) =>
			ajax(getMigrationStatusObservable()).pipe(
				mergeMap((resp) => {
					const {
						response: { state, fawkesTenantId },
					} = resp;
					return of(
						updateMigrationStatus({
							status: state,
							fawkesTenantId,
						}),
					);
				}),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error),
						showMessage: false,
					});
				}),
			),
		),
	);
};

export const getLoggedInUserEpic = (action$: any) =>
	action$.pipe(
		ofType(GET_LOGGEDIN_USER),
		mergeMap((action) =>
			getLoggedInUser().pipe(
				mergeMap((response) => {
					const { response: { tenant: { type = '' } = {} } = {} } = response;
					initAuth(response);

					if (type === 'panorama') {
						return of(getLoggedInUserSuccess(response), fetchMigrationStatus()).pipe(
							tap(() => {
								const lastVisitedUrl = get(
									response,
									'response.preference.params.config.lastVisitedUrl',
								);
								const routePathname = get(history, 'location.pathname');

								if (lastVisitedUrl && ALLOW_REDIRECT_ROUTES.includes(routePathname)) {
									history.push(lastVisitedUrl);
								}
							}),
						);
					} else {
						return of(getLoggedInUserSuccess(response)).pipe(
							tap(() => {
								const lastVisitedUrl = get(
									response,
									'response.preference.params.config.lastVisitedUrl',
								);
								const routePathname = get(history, 'location.pathname');

								if (lastVisitedUrl && ALLOW_REDIRECT_ROUTES.includes(routePathname)) {
									history.push(lastVisitedUrl);
								}
							}),
						);
					}
				}),
				catchError((error) => {
					return of({
						type: GET_USER_FAIL,
					});
				}),
			),
		),
	);

export const doPushEpic = (action$: any) => {
	return action$.pipe(
		ofType(DO_PUSH),
		mergeMap((action: any) =>
			from(action.payload.params).pipe(
				mergeMap((id) =>
					doPushActionObservable(id).pipe(
						mergeMap((response) =>
							of(
								doPushSuccess(),
								{
									type: HIDE_MODAL,
									id: action.payload.modalId,
								},
								postProcess(response, action.payload),
							),
						),
						catchError((error) => {
							return of({
								type: SERVER_ERROR,
								errorMessage: parseError(error),
							});
						}),
					),
				),
			),
		),
	);
};

export const doRevertEpic = (action$: any) => {
	return action$.pipe(
		ofType(DO_REVERT),
		mergeMap((action: any) => {
			return concat(
				of(startLoading()),
				doRevertActionObservable().pipe(
					mergeMap((response) =>
						of({
							type: SHOW_MODAL,
							modal: {
								id: 'revert-success-modal',
								isOpen: true,
								title: _T('Success'),
								type: 'Info',
								size: 'md',
								actions: [
									{
										text: _T('Close'),
										action: () => {
											hideModal('PushConfigManagerModal');
											hideModal('revert-success-modal');
										},
									},
								],
								message: getPathValue(response, 'response.result.result.msg.line'),
							},
						}),
					),
					catchError((error) => {
						return of({
							type: SERVER_ERROR,
							errorMessage: parseError(error),
						});
					}),
				),
				of(finishLoading()),
			);
		}),
	);
};

export const fetchLicenseInfoEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_LICENSE_INFO),
		mergeMap((action) =>
			ajax(getLicenseInfoObservable()).pipe(
				mergeMap((response) => of(setLicenseInfo(mapLicenseInfoData(getPathValue(response, 'response'))))),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FETCH_LICENSE_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const fetchFeatureEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_FEATURES),
		mergeMap((action) =>
			ajax(getFeaturesObservable()).pipe(
				mergeMap((response) => of(setFeaturesInfo(getPathValue(response, 'response')))),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FETCH_FEATURES_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const fetchTokenInfoEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_TOKEN_INFO),
		mergeMap((action) =>
			ajax(getTokenInfoObservable()).pipe(
				mergeMap((resp) => of(setTokenInfo(resp.response))),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FETCH_TOKEN_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const fetchAuthInfoEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_AUTH_INFO),
		mergeMap((action) =>
			ajax(getAuthInfoObservable()).pipe(
				mergeMap((resp) => of(setAuthInfo(resp.response))),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: AUTH_INFO_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const fetchFqdnInfoEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_FQDN_INFO),
		mergeMap((action) =>
			ajax(getFqdnInfoObservable()).pipe(
				mergeMap((resp) => of(setFqdnInfo(resp.response))),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FQDN_INFO_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const fetchSubTenantInfoEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_SUBTENANT_INFO),
		mergeMap((action) =>
			ajax(getSubTenantInfoObservable()).pipe(
				mergeMap((resp) => of(setSubTenantInfo(resp.response))),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: SUBTENANT_INFO_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const fetchEPDeviceOverviewStatEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_EP_DEVICE_OVERVIEW_STAT),
		mergeMap((action) =>
			ajax(getDeviceOverviewStatObservable('getExplicitProxyOverviewStat')).pipe(
				mergeMap((resp) => of(setEPDeviceOverviewStat(_.get(resp, `response.msg`)))),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FETCH_EP_DEVICE_OVERVIEW_STAT_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const fetchGPDeviceOverviewStatEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_GP_DEVICE_OVERVIEW_STAT),
		mergeMap((action) =>
			ajax(getDeviceOverviewStatObservable('getGPaaSOverviewStat')).pipe(
				mergeMap((resp) => of(setGPDeviceOverviewStat(_.get(resp, `response.msg`)))),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FETCH_GP_DEVICE_OVERVIEW_STAT_ERROR,
						},
					);
				}),
			),
		),
	);
};
export const fetchJPDeviceOverviewStatEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_JP_DEVICE_OVERVIEW_STAT),
		mergeMap((action) =>
			ajax(getDeviceOverviewStatObservable('getJupiterOverviewStat')).pipe(
				mergeMap((resp) => of(setJPDeviceOverviewStat(_.get(resp, `response.msg`)))),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FETCH_JP_DEVICE_OVERVIEW_STAT_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const fetchApplicationsInfoEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_APPLICATIONS_INFO),
		mergeMap((action) => {
			// if (isACEEnabled()) {
			// 	return forkJoin({
			// 		applications: ajax(getApplicationsInfoObservable()),
			// 		cloud_applications: ajax(getCloudApplicationsInfoObservable()),
			// 	}).pipe(
			// 		mergeMap((response) => {
			// 			const predefinedAppResponse = getPathValue(response.applications, 'response.data');
			// 			const aceAppsResponse = getPathValue(response.cloud_applications, 'response.data');
			// 			const predefinedApps: any = _.uniqBy(predefinedAppResponse, (app: any) => {
			// 				return app.id;
			// 			});
			// 			const aceApps: any = _.uniqBy(aceAppsResponse, (app: any) => {
			// 				return app.id;
			// 			});
			// 			const aceAppContainerSet = new Set();
			// 			aceApps.forEach((app: any) => {
			// 				app.isACE = true;
			// 				if (app['application_container']) {
			// 					aceAppContainerSet.add(app['application_container']);
			// 				}
			// 			});
			// 			return of(setApplicationsInfo([].concat(predefinedApps, aceApps), aceAppContainerSet));
			// 		}),
			// 		catchError((error) =>
			// 			of(
			// 				{
			// 					type: SERVER_ERROR,
			// 					errorMessage: parseError(error),
			// 					showMessage: false,
			// 				},
			// 				{
			// 					type: FETCH_APPLICATIONS_ERROR,
			// 				},
			// 			),
			// 		),
			// 	);
			// } else {
			return ajax(getApplicationsInfoObservable()).pipe(
				mergeMap((response) => {
					const predefinedAppResponse = getPathValue(response, 'response.data');
					const predefinedApps: any = _.uniqBy(predefinedAppResponse, (app: any) => {
						return app.id;
					});
					return of(setApplicationsInfo(predefinedApps));
				}),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FETCH_APPLICATIONS_ERROR,
						},
					);
				}),
			);
			// }
		}),
	);
};

export const fetchDNSSecurityCategoryInfoEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_DNS_SECURITY_CATEGORY_INFO),
		mergeMap((action) =>
			ajax(getDNSSecurityCategoryInfoObservable()).pipe(
				mergeMap((response) => {
					return of(setDNSSecurityCategoryInfo(getPathValue(response, 'response')));
				}),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FETCH_DNS_SECURITY_CATEGORY_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const fetchAdvancedDNSSecurityCategoryEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_ADVANCED_DNS_SECURITY_CATEGORY),
		mergeMap((action) =>
			ajax(getAdvancedDNSSecurityCategoryObservable()).pipe(
				mergeMap((response) => {
					if (!Pan.isEmpty(response.response)) {
						return of(setAdvancedDNSSecurityCategoryInfo(getPathValue(response, 'response')));
					} else {
						return of(setAdvancedDNSSecurityCategoryInfo([]));
					}
				}),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FETCH_ADVANCED_DNS_SECURITY_CATEGORY_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const fetchDLPDataPatternsInfoEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_DLP_DATA_PATTERNS_INFO),
		mergeMap((action) =>
			ajax(getDLPDataPatternsInfoObservable()).pipe(
				mergeMap((response) => {
					return of(
						setDLPDataPatternsInfo({
							dataPatternsInfo: getPathValue(response, 'response.result'),
						}),
					);
				}),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FETCH_DLP_DATA_PATTERNS_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const fetchDLPCapabilityInfoEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_DLP_CAPABILITY_INFO),
		mergeMap((action) =>
			ajax(getCapabilitiesInfoObservable('dlp')).pipe(
				mergeMap((response) => {
					return of(
						setDLPCapabilityInfo({
							capabilityInfo: getPathValue(response, 'response.capabilities'),
						}),
					);
				}),
				catchError((error) => {
					return of(
						{
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						},
						{
							type: FETCH_DLP_CAPABILITY_ERROR,
						},
					);
				}),
			),
		),
	);
};

export const setUserPreferenceHidePolicyOptmizerPopup = (value: any) => ({
	type: SET_USER_PREFERENCE_HIDE_POLICY_OPTIMIZER_POPUP,
	value,
});

export const setUserPreferenceHidePolicyOptmizerPopupEpic = (action$: any, store: any) => {
	return action$.pipe(
		ofType(SET_USER_PREFERENCE_HIDE_POLICY_OPTIMIZER_POPUP),
		mergeMap((action: any) => {
			const userPref = store.value.main.loggedInUser.preference
				? cloneDeep(store.value.main.loggedInUser.preference)
				: {};
			set(userPref, `config.${PREFERENCES.HIDE_POLICY_OPTMIZER_POPUP}`, action.value);
			return of(setUserPreference(userPref), saveUserPreference(userPref));
		}),
	);
};

export const setUserPreferencehideWelcomePopup = (value: any) => ({
	type: SET_USER_PREFERENCE_HIDE_WELCOME_POPUP,
	value,
});

export const setUserPreferencehideWelcomePopupEpic = (action$: any, store: any) => {
	return action$.pipe(
		ofType(SET_USER_PREFERENCE_HIDE_WELCOME_POPUP),
		mergeMap((action: any) => {
			const userPref = store.value.main.loggedInUser.preference
				? cloneDeep(store.value.main.loggedInUser.preference)
				: {};
			set(userPref, `config.${PREFERENCES.HIDE_WELCOME_POPUP}`, action.value);
			return of(setUserPreference(userPref), saveUserPreference(userPref));
		}),
	);
};

export const setUserPreferenceHideScopePopup = (value: any) => ({
	type: SET_USER_PREFERENCE_HIDE_SCOPE_POPUP,
	value,
});

export const setUserPreferenceHideScopePopupEpic = (action$: any, store: any) => {
	return action$.pipe(
		ofType(SET_USER_PREFERENCE_HIDE_SCOPE_POPUP),
		mergeMap((action: any) => {
			const userPref = store.value.main.loggedInUser.preference
				? cloneDeep(store.value.main.loggedInUser.preference)
				: {};
			set(userPref, `config.${PREFERENCES.HIDE_SCOPE_POPUP}`, action.value);
			return of(setUserPreference(userPref), saveUserPreference(userPref));
		}),
	);
};

export const setUserPreferenceActiveTab = (activeTab: any) => ({
	type: SET_USER_PREFERENCE_ACTIVE_TAB,
	activeTab,
});

export const setUserPreferenceActiveTabsEpic = (action$: any, store: any) => {
	return action$.pipe(
		ofType(SET_USER_PREFERENCE_ACTIVE_TAB),
		mergeMap((action: any) => {
			const userPref = store.value.main.loggedInUser.preference
				? cloneDeep(store.value.main.loggedInUser.preference)
				: {};
			const activeTabs = get(userPref, 'config.activeTabs', {});
			const activeTabsPref = { ...activeTabs, ...action.activeTab };
			if (!isEqual(activeTabsPref, activeTabs)) {
				set(userPref, 'config.activeTabs', activeTabsPref);
				return of(setUserPreference(userPref), saveUserPreference(userPref, DEFAULT_DEBOUNCE_TIME));
			} else {
				return of(emptyAction);
			}
		}),
	);
};

export const fetchLoggedInUser = () => ({
	type: FETCH_LOGGED_IN_USER,
});

export const fetchLoggedInUserEpic = (action$: any, state$: any) => {
	return action$.pipe(
		ofType(FETCH_LOGGED_IN_USER),
		mergeMap(() => {
			return ajax(getUserPreferenceObservable()).pipe(
				mergeMap((resp) => {
					const preference = get(resp, 'response.preference.params.config', {});
					const scmFlagsFromUser = get(resp, 'response.scmFlags', {});
					const extra = get(resp, 'response.extra', {});
					return of(setLoggedinUser({ config: { ...preference } }, extra, scmFlagsFromUser));
				}),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error),
					});
				}),
			);
		}),
	);
};

export const setUserPreferenceFavorites = ({ key, value, isSelected }: any) => ({
	type: SET_USER_PREFERENCE_FAVORITES,
	key,
	value,
	isSelected,
});

export const setUserPreferenceFavoritesEpic = (action$: any, store: any) => {
	return action$.pipe(
		ofType(SET_USER_PREFERENCE_FAVORITES),
		mergeMap((action: any) => {
			const { key, value, isSelected } = action;
			const userPref = _.get(store, 'value.main.loggedInUser.preference')
				? cloneDeep(store.value.main.loggedInUser.preference)
				: {};
			const favorites = get(userPref, `config.favorites.${key}`, []);
			let favoritesPref = cloneDeep(favorites);
			favoritesPref = favoritesPref.filter((fav: any) => {
				return fav.path !== value?.path;
			});
			!isSelected && favoritesPref.push(value);
			if (!isEqual(favoritesPref, favorites)) {
				set(userPref, `config.favorites.${key}`, favoritesPref);
				return of(setUserPreference(userPref), saveUserPreference(userPref, DEFAULT_DEBOUNCE_TIME));
			} else {
				return of(emptyAction);
			}
		}),
	);
};

export const saveUserPreferenceEpic = (action$: any) => {
	return action$.pipe(
		ofType(SAVE_USER_PREFERENCE),
		debounce((action: any) => (action.debounceTime ? interval(action.debounceTime) : interval(0))),
		mergeMap((action: any) =>
			// POST request to save pref to DB
			saveUserPreferenceObservable(action.userPreference).pipe(
				mergeMap((resp) =>
					of(
						// currently we have separate action for update main store,
						// this is a placeholder if we want to do something after success call in the future
						saveUserPreferenceSuccess(action.userPreference),
					),
				),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error),
					});
				}),
			),
		),
	);
};

export const fetchLicenseComplianceEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_LICENSE_COMPLIANCE),
		mergeMap((action) =>
			ajax(getComplianceObservable()).pipe(
				mergeMap((resp) => of(setCompliance(get(resp, 'response.result.result')))),
				catchError((error) => {
					return of({
						type: '',
					});
				}),
			),
		),
	);
};

export const triggerLicenseComplianceEpic = (action$: any) => {
	return action$.pipe(
		ofType(TRIGGER_LICENSE_COMPLIANCE),
		mergeMap((action) =>
			ajax(triggerComplianceObservable()).pipe(
				mergeMap((resp) => of(setCompliance(get(resp, 'response.result.result')))),
				catchError((error) => {
					return of({
						type: '',
					});
				}),
			),
		),
	);
};

export const deleteUserPreferenceEpic = (action$: any, state$: any) => {
	return action$.pipe(
		ofType(DELETE_USER_PREFERENCE),
		withLatestFrom(state$),
		mergeMap(([action, state]) => {
			if (!isEmpty(action.key)) {
				// delete by key is to delete the preference object by specified attribute
				const userPreference = get(state, 'main.loggedInUser.preference');
				unset(userPreference, action.key);

				return saveUserPreferenceObservable(userPreference).pipe(
					mergeMap((resp) => of(saveUserPreferenceSuccess(userPreference))),
					catchError((error) => {
						return of({
							type: SERVER_ERROR,
							errorMessage: parseError(error),
						});
					}),
				);
			}
			// DELETE preference object in DB
			return deleteUserPreferenceObservable().pipe(
				mergeMap((resp) => of(deleteUserPreferenceSuccess(resp))),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error),
					});
				}),
			);
		}),
	);
};

export const updateLastVisitedUrlEpic = (action$: any, state$: any) => {
	return action$.pipe(
		ofType(UPDATE_LAST_VISITED_URL),
		mergeMap((action: any) => {
			const { lastVisitedUrl } = action;
			const userPrefStore = get(state$, 'value.main.loggedInUser.preference', {});
			const userPref = cloneDeep(userPrefStore);
			const lastVisitedUrlState = get(userPref, 'config.lastVisitedUrl');

			if (lastVisitedUrlState !== lastVisitedUrl) {
				set(userPref, 'config.lastVisitedUrl', lastVisitedUrl);

				return of(setUserPreference(userPref), saveUserPreference(userPref, DEFAULT_DEBOUNCE_TIME));
			}

			return of(emptyAction);
		}),
	);
};
export const updateGridConfigEpic = (action$: any, state$: any) =>
	action$.pipe(
		ofType(UPDATE_GRID_CONFIG),
		mergeMap((action) => {
			const {
				gridId,
				tableData: { key, data },
			}: any = action;
			const userPrefStore = get(state$, 'value.main.loggedInUser.preference', {});
			const userPref = cloneDeep(userPrefStore);
			const gridConfigState = get(userPref, `config.grids[${gridId}]`, {});

			if (!isEqual(gridConfigState[key], undefined) !== data) {
				set(userPref, `config.grids[${gridId}][${key}]`, data);

				return of(setUserPreference(userPref), saveUserPreference(userPref, DEFAULT_DEBOUNCE_TIME));
			}

			return of(emptyAction);
		}),
	);

export const updateBPAConfigEpic = (action$: any, state$: any) =>
	action$.pipe(
		ofType(UPDATE_BPA_CONFIG),
		mergeMap((action) => {
			const {
				bpaId,
				bpaData: { key, data },
			}: any = action;
			const userPrefStore = get(state$, 'value.main.loggedInUser.preference', {});
			const userPref = cloneDeep(userPrefStore);
			const bpaConfigState = get(userPref, `config.bpa[${bpaId}]`, {});
			if (!isEqual(bpaConfigState[key], data)) {
				set(userPref, `config.bpa[${bpaId}][${key}]`, data);
				return of(setUserPreference(userPref), saveUserPreference(userPref, DEFAULT_DEBOUNCE_TIME));
			}

			return of(emptyAction);
		}),
	);

export const handleServerErrorEpic = (action$: any) => {
	return action$.pipe(
		ofType(SERVER_ERROR),
		mergeMap((action: any) => {
			action &&
				window.DD_RUM &&
				window.DD_RUM.addError(action.error || action.errorMessage || '', {
					type: 'server_error',
				});
			return of(emptyAction);
		}),
	);
};

export const updateMcasInfo = (UnsanctionedAppLists: any) => ({
	type: FETCH_MCAS_INFO,
	UnsanctionedAppLists,
});

export const storeDeviceModelData = (modelInfo: any[] = [], modelName = '') => ({
	type: STORE_DEVICE_MODEL_INFO,
	modelInfo,
	modelName,
});

export const setConfigIndicators = (configIndicators: ConfigIndicator) => ({
	type: SET_CONFIG_INDICATORS,
	configIndicators,
});

export const updatePSKInfo = (pskDetails: any) => ({
	type: UPDATE_PSK_INFO,
	pskDetails,
});

export const setMoveTenantState = (redirectURL: string) => ({
	type: SET_MOVE_TENANT_REDIRECTURL,
	redirectURL,
});

export const fetchDeviceOnboardingLabelGroup = () => ({
	type: FETCH_DEVICE_ONBOARDING_LABEL_GROUPS,
});

export const fetchDeviceOnboardingLabelGroupEpic = (action$: any) => {
	return action$.pipe(
		ofType(FETCH_DEVICE_ONBOARDING_LABEL_GROUPS),
		mergeMap(() =>
			concat(
				of(startLoading()),
				ajax(fetchDeviceOnboardingLabelGroupsObservable()).pipe(
					mergeMap((res) => {
						return of(finishLoading(), fetchDeviceOnboardingLabelGroupSuccess(_.get(res, 'response', [])));
					}),
					catchError((error) => {
						return of(finishLoading(), {
							type: SERVER_ERROR,
							errorMessage: parseError(error),
							showMessage: false,
						});
					}),
				),
			),
		),
	);
};

export const fetchDeviceOnboardingLabelGroupSuccess = (deviceOnboardingLabelGroupsDetails: any) => {
	return {
		type: FETCH_DEVICE_ONBOARDING_LABEL_GROUPS_SUCCESS,
		deviceOnboardingLabelGroupsDetails,
	};
};
export const getUnifiedPolicyMetadataEpic = (action$: any, state$: any) =>
	action$.pipe(
		ofType(GET_UNIFIED_POLICY_METADATA),
		mergeMap((action) => {
			const unifiedPolicyMetadata = get(state$, 'value.main.unifiedPolicyMetadata');
			const { auth } = sparkyStore.getState();
			if (unifiedPolicyMetadata) {
				return of(emptyAction);
			}
			return ajax(getUnifiedPolicyMetadataObservable(auth?.tsg_id)).pipe(
				mergeMap((resp) => of(getUnifiedPolicyMetadataSuccess(resp))),
				catchError((error) => {
					return of({
						type: SERVER_ERROR,
						errorMessage: parseError(error),
					});
				}),
			);
		}),
	);
