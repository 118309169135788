/* eslint-disable import/no-cycle */
/* eslint-disable no-useless-escape */
import Pan from 'src/ui-lib/core/autorender/schema/Pan';
import trim from 'lodash/trim';
import moment from 'moment';
import { _T } from 'src/utils/i18n-utils';
import { FAWKES } from 'src/typings/type';
import { isVariable } from 'src/ui-lib/utils';
import { supportVariable } from 'src/ui-lib/core/autorender/widgets/util';
import _ from 'lodash';

export const validationRulesMap: {
	[k: string]: any;
} = {
	noAllowBlank: (value: unknown) => {
		if (Pan.isEmpty(value))
			// TODO: Need translation? Might be used for logic
			return 'Value is required';
	},
	isValidPattern: function (str: string) {
		if (str.length < 7) {
			return _T('The length of the pattern must be at least 7 characters');
		}
		return null;
	},
	objectName: function (str: string, field: FAWKES.FieldType) {
		if (str && !str.match(/^[0-9a-zA-Z]{1}([0-9a-zA-Z_-]|[ ]|[.])*$/)) {
			return _T(
				'A valid object name must start with an alphanumeric character and can contain zero or more alphanumeric characters, underscore "_", hyphen "-", dot "." or spaces.',
			);
		}
		return validationRulesMap.isString(str, field);
	},
	isTimeOrDateTime: function (str: string) {
		const selectedTime = moment(str, 'YYYY/MM/DD@HH:mm');
		if (selectedTime.isBefore(new Date())) {
			return _T('One or more values are in the past.');
		}
		return null;
	},
	isEmail: function (str: string) {
		if (
			str &&
			!str.match(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			)
		) {
			return _T('Invalid Email Address');
		}
		return null;
	},
	isValidGoogleOrgId: function (str: string) {
		const matches = str.match(/^[0-9]+$/);
		if (!matches) {
			return _T('Google Organization ID must be a numeric value');
		}
	},
	isHexadecimal: function (str: string) {
		// if (str) {
		//     var regex = /[0-9A-Fa-f]{6}/g;
		//     if(str.match(regex)) {
		//         return null
		//     }
		// };
		// return _T('Invalid Key ID (must be a hexadecimal value)');
		//remove validation from UI, let BE validate it. This is the same behavior as PANOS
		return null;
	},
	isString: function (str: string, field: FAWKES.FieldType) {
		let length = 0;
		if (str) {
			length = str.length;
		}
		let minLength = field && field.uiHint && field.uiHint.minLength;
		minLength = minLength || Math.min;
		let maxLength = field && field.uiHint && field.uiHint.maxLength;
		maxLength = maxLength || Math.max;
		if (length < minLength) return _T('The minimum length is ') + minLength + _T(' characters.');
		if (length > maxLength) return _T('The maximum length is ') + maxLength + _T(' characters.');
		return null;
	},
	isNumber: function (str: string, field: FAWKES.FieldType) {
		return validationRulesMap.rangedInt(str, field);
	},
	validNumber: function (str: string, min?: number, max?: number) {
		const n = parseInt(str);
		if (isNaN(n)) {
			return false;
		}
		// check to make sure that there is only digits
		if (str.match(/^([0-9])+$/) == null) {
			return false;
		}
		if (min !== undefined && n < min) {
			return false;
		}
		if (max !== undefined && n > max) {
			return false;
		}
		return true;
	},
	validNumberRangeList: function (str: string, min?: number, max?: number) {
		const tokensArray = ('' + str).split(',');
		for (let i = 0; i < tokensArray.length; i++) {
			if (tokensArray[i].charAt(tokensArray[i].length - 1) === '-') {
				// cannot end in -
				return false;
			}
			if (tokensArray[i].charAt(0) === '-') {
				// if the first char is - then it is a negative number, consider it as a number
				// instead of a range.
				if (!validationRulesMap.validNumber(tokensArray[i], min, max)) {
					return false;
				}
			} else {
				const numbersArray: any[] = tokensArray[i].split('-');
				for (let j = 0; j < numbersArray.length; j++) {
					if (!validationRulesMap.validNumber(numbersArray[j], min, max)) {
						return false;
					}
				}
				if (numbersArray.length === 2) {
					const num1 = numbersArray[0] - 0;
					const num2 = numbersArray[1] - 0;
					if (num1 > num2) {
						return false;
					}
				}
			}
		}
		return true;
	},
	rangeList: function (v: string, field: FAWKES.FieldType) {
		const min = (field && field.uiHint && field.uiHint.minValue) || 0;
		const max = (field && field.uiHint && field.uiHint.maxValue) || 65535;
		if (!Pan.isEmpty(v) && !validationRulesMap.validNumberRangeList(v, min, max)) {
			return _T('Invalid range');
		}
		return null;
	},
	rangeThreatIdList: (val = '', field: FAWKES.FieldType) => {
		/*** Could be number (123), list of numbers (1,2,3,...), range of numbers (11-13), mixed (1,2,3-6) */
		const listSplitter = field?.uiHint?.listSplitter || ',';
		const rangeSplitter = field?.uiHint?.rangeSplitter || '-';
		const minLength = field?.uiHint?.minLength;
		if (minLength && val?.length < minLength)
			return _T('The minimum length is {minLength} characters.', { minLength: minLength });
		const splitIt = (splitter: string) => val.split(splitter).map((it) => trim(it));
		if (val.indexOf(listSplitter) !== -1) {
			const splitted = splitIt(listSplitter);
			for (const v of splitted) {
				const e: unknown = validationRulesMap.rangeThreatIdList(v, field);
				if (e) return e;
			}
		} else if (val.indexOf(rangeSplitter) !== -1) {
			const splitted = splitIt(rangeSplitter);
			if (splitted.length === 2) {
				for (const v of splitted) {
					const e: unknown = validationRulesMap.rangeThreatIdList(v, field);
					if (e) return e;
				}
				if (parseInt(splitted[1]) <= parseInt(splitted[0])) {
					return _T('Range is incorrect');
				}
			} else {
				return _T('Range should be with one range separator');
			}
		} else {
			return validationRulesMap.rangedInt(val, field);
		}
	},
	portList: function (v: string | undefined, field: FAWKES.FieldType) {
		const min = (field && field.uiHint && field.uiHint.minValue) || 0;
		const max = (field && field.uiHint && field.uiHint.maxValue) || 65535;
		const ports = v?.split(',') || [];
		let msg = null;

		ports.map((port: string) => {
			const p = parseInt(port);
			if (!Pan.isEmpty(p) && !validationRulesMap.validNumberRangeList(p.toString(), min, max)) {
				msg = _T('One or more ports are out of range');
			}
			return null;
		});
		return msg;
	},
	rangedInt: function (str: string, field: FAWKES.FieldType, validationInfo: any = undefined) {
		if (typeof str === 'number') {
			const isRequired = _.get(field, 'uiHint.allowBlank') === false;
			if (isNaN(str) && isRequired) return _T('Value is Required');
			const min = field && field.uiHint && field.uiHint.minValue;
			const max = field && field.uiHint && field.uiHint.maxValue;
			if (min !== undefined && str < min) return _T('Value is less than minimum');
			if (max !== undefined && str > max) return _T('Value is greater than maximum');
			return null;
		} else {
			const n = parseInt(str);
			if (isNaN(n)) return _T('Invalid value');
			// check to make sure that there is only digits
			// Do not need to check it anymore
			// if (!Pan.isEmpty(str) && str.match(/^([0-9])+$/) == null) {
			//     return "Invalid value";
			// }

			const min = (field && field.uiHint && field.uiHint.minValue) || (validationInfo && validationInfo.minValue);
			const max = (field && field.uiHint && field.uiHint.maxValue) || (validationInfo && validationInfo.maxValue);
			const matches = str.match(/^[0-9]+$/);
			if (!matches) {
				return _T('Value must only contain integers');
			}
			if (min !== undefined && n < min) return _T(`Value is less than ${min}`);
			if (max !== undefined && n > max) return _T(`Value is greater than ${max}`);
			return null;
		}
	},
	//to be deprecated due to inconsistent return params, please use isValidIpV4Address instead
	isIpV4Address: function (str: string) {
		let i;
		const matches: any = str ? str.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)(\/[0-9]{1,3})?$/) : null;
		if (!matches) return _T('Invalid IP address');
		for (i = 1; i <= 4; i++) {
			const n = parseInt(matches[i], 10);
			if (isNaN(n)) return _T('Invalid IP address');
			if (n === 0 && matches[i] !== '0') return _T('Invalid IP address'); // Check for multiple zeros
			if (!(n >= 0 && n <= 255)) return _T('Invalid IP address');
		}
		matches.shift(); // drop the complete string match
		for (i = 0; i < matches.length; i++) matches[i] = parseInt(matches[i], 10);
		return { ip: validationRulesMap.octectsToLong.apply(null, matches), octects: matches };
	},
	isIpV4AddressMask: function (str: string, requireMask?: boolean) {
		/* accept both 1.1.1.1 and 1.1.1.1/30 */
		const matches = str ? str.match(/^([^\/]+)\/([^\/]+)$/) : null;
		if (!requireMask && !matches) return validationRulesMap.isIpV4Address(str);
		if (matches) {
			if (isNaN(Number(matches[2]))) return _T('Invalid IP address');
			const n = parseInt(matches[2], 10);
			return n >= 0 && n <= 32 && validationRulesMap.isIpV4Address(matches[1]);
		}
		return validationRulesMap.isIpV4Address(str);
	},
	//adding a new utility to check address and mask both and return consistent values(checks ip and cidr)
	isValidIpV4Address: function (str: string) {
		const defaultErrValidationMsg = {
			isValidIPv4: false,
			errorMsg: _T('Invalid IP address'),
			ip: null,
			octects: [],
		};
		if (!str) {
			return defaultErrValidationMsg;
		}
		//check if cidr is available
		const cidrMatches: any = str ? str.match(/^([^\/]+)\/([^\/]+)$/) : null;
		if (cidrMatches) {
			if (isNaN(Number(cidrMatches[2]))) {
				return defaultErrValidationMsg;
			}
			const n = parseInt(cidrMatches[2], 10);
			if (n < 0 || n > 32) {
				return { ...defaultErrValidationMsg, errorMsg: _T('Invalid subnet mask') };
			}
		}
		const ipStr = cidrMatches ? cidrMatches[1] : str;

		//check if address is valid
		const addressMatches: any = ipStr ? ipStr.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)(\/[0-9]{1,3})?$/) : null;
		if (!addressMatches) return defaultErrValidationMsg;
		for (let i = 1; i <= 4; i++) {
			const n = parseInt(addressMatches[i], 10);
			if (isNaN(n)) return defaultErrValidationMsg;
			if (n === 0 && addressMatches[i] !== '0') return defaultErrValidationMsg; // Check for multiple zeros
			if (!(n >= 0 && n <= 255)) return defaultErrValidationMsg;
		}
		addressMatches.shift(); // drop the complete string match
		for (let i = 0; i < addressMatches.length; i++) {
			addressMatches[i] = parseInt(addressMatches[i], 10);
		}
		return {
			isValidIPv4: true,
			errorMsg: '',
			ip: validationRulesMap.octectsToLong.apply(null, addressMatches),
			octects: addressMatches,
		};
	},
	// Check if the IP address is without the subnet
	isIpAddressWithoutSubnet: function (v: string) {
		return v.split('/').length > 1 ? false : true;
	},
	ipAndIntegerSubnetMaskV4orV6SubnetMaskRequired: function (v: string) {
		return validationRulesMap.isIpAddressMask(v, true);
	},
	ipAndIntegerSubnetMaskV4orV6: function (v: string) {
		return validationRulesMap.isIpAddressMask(v, undefined);
	},
	octectsToLong: function (a: number, b: number, c: number, d: number) {
		// mozilla return negative number for 192 << 24
		// while 7f << 24 is positive
		// return (a << 24) + (b << 16) + (c << 8) + d;
		return a * (1 << 24) + b * (1 << 16) + c * (1 << 8) + d;
	},
	isIpV4Netmask: function (str: string) {
		let cidr;
		const result: any = validationRulesMap.isIpV4Address(str);
		if (result && result.ip) {
			let bit = 0x80000000; // #b10000000000000000000000000000000
			cidr = 0;
			while (bit & result.ip) {
				cidr++;
				bit >>>= 1;
			}
			return { mask: result.ip, cidr: cidr };
		} else {
			cidr = parseInt(str, 10);
			if (isNaN(cidr)) return _T('Invalid IP address');
			if (cidr < 0 || cidr > 32) return _T('Invalid IP address');
			let mask = 0xffffffff;
			let bits = cidr;
			while (bits > 0) {
				mask >>>= 1;
				bits--;
			}
			return { mask: ~mask, cidr: cidr };
		}
	},
	isIpV6Address: function (str: string) {
		// Original
		return !!str.match(
			/^((([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){6}:[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){5}:([0-9A-Fa-f]{1,4}:)?[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){4}:([0-9A-Fa-f]{1,4}:){0,2}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){3}:([0-9A-Fa-f]{1,4}:){0,3}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){2}:([0-9A-Fa-f]{1,4}:){0,4}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){6}((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|(([0-9A-Fa-f]{1,4}:){0,5}:((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|(::([0-9A-Fa-f]{1,4}:){0,5}((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|([0-9A-Fa-f]{1,4}::([0-9A-Fa-f]{1,4}:){0,5}[0-9A-Fa-f]{1,4})|(::([0-9A-Fa-f]{1,4}:){0,6}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){1,7}:))$/,
		);
		// return !!str.match(/^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/);
	},
	isIpV6Netmask: function (str: string) {
		return validationRulesMap.inRange(str, 3, 128);
	},
	isIpV6AddressMask: function (str: string, requireMask?: boolean) {
		// default unicast route address
		if (str === '::/0') return true;
		const arr = str.split('/');
		if (!requireMask && arr.length === 1) {
			return validationRulesMap.isIpV6Address(arr[0]);
		} else if (arr.length === 2) {
			return validationRulesMap.isIpV6Netmask(arr[1]) && validationRulesMap.isIpV6Address(arr[0]);
		}
		return '';
	},
	isValidMulticastAddress: function (str: string) {
		// Check first if the IP address is a valid IPv4 address or subnet
		const ipv4addr = validationRulesMap.isIpV4Address(str);
		const ipv4mask = validationRulesMap.isIpV4AddressMask(str);
		if (typeof ipv4mask === 'string' || typeof ipv4addr === 'string') {
			return false;
		}
		// Check if the IP address is a valid IPv4 multicast address
		// IPv4 multicast address range: 224.0.0.0 - 239.255.255.255
		function IPToNumber(s: string) {
			const arr = s.split('.');
			let n = 0;
			for (let i = 0; i < 4; i++) {
				n = n * 256;
				n += parseInt(arr[i], 10);
			}
			return n;
		}
		const min = IPToNumber('224.0.0.0');
		const max = IPToNumber('239.255.255.255');

		const ipNum = IPToNumber(str);

		const isInvalid = ipNum != 0 && (ipNum < min || ipNum > max);

		return !isInvalid;
	},
	isIpV6AddressSubnet: function (str: string) {
		// return a string for error or return null for no error
		if (str === '::/0') return null;
		let r = null;
		const arr = str.split('/');
		if (arr.length === 2) {
			r = validationRulesMap.isIpV6Netmask(arr[1]) && validationRulesMap.isIpV6Address(arr[0]);
			if (r === false) {
				// either of the above is false, so it can not be an ipv6 subnet/prefix
				r = _T('Invalid Ipv6 Address/Prefix Length');
			}
		} else {
			r = _T('Invalid Ipv6 Address/Prefix Length');
		}
		return r;
	},
	isIpAddress: function (str: string) {
		const ipV4: any = validationRulesMap.isIpV4Address(str);
		if ((ipV4 && ipV4.ip) || validationRulesMap.isIpV6Address(str)) {
			return '';
		}
		return _T('Invalid IP address');
	},
	_isIpAddress: function (str: string) {
		const ipV4: any = validationRulesMap.isIpV4Address(str);
		if ((ipV4 && ipV4.ip) || validationRulesMap.isIpV6Address(str)) {
			return null;
		}
		return _T('Invalid IP address');
	},
	inRange: function (str: string, min?: number, max?: number) {
		const n = parseInt(str);
		if (isNaN(n)) return false;
		// check to make sure that there is only digits
		if (str.match(/^([0-9])+$/) == null) {
			return false;
		}
		if (min !== undefined && n < min) return false;
		return !(max !== undefined && n > max);
	},
	ipAndIntegerSubnetMaskV4: function (v: string) {
		const ipv4: any = validationRulesMap.isIpV4AddressMask(v);
		if (ipv4 && Pan.isDefined(ipv4['ip'])) {
			return '';
		}
		return _T('Invalid IP address');
	},
	ipAndIntegerSubnetMaskV6: function (v: string) {
		const ipv6 = validationRulesMap.isIpV6AddressMask(v);
		if (ipv6) {
			return '';
		}
		return _T('Invalid IP address');
	},
	isIpAddressMask: function (str: string, requireMask?: boolean) {
		if (!Pan.isEmpty(str)) {
			const ipv4: any = validationRulesMap.isIpV4AddressMask(str, requireMask);
			const ipv6 = validationRulesMap.isIpV6AddressMask(str, requireMask);
			if ((ipv4 && ipv4['ip'] >= 0) || ipv6) {
				return '';
			}
		}
		return _T('Invalid IP address');
	},
	_isIpAddressMask: function (str: string, requireMask?: boolean) {
		if (!Pan.isEmpty(str)) {
			const ipv4: any = validationRulesMap.isIpV4AddressMask(str, requireMask);
			const ipv6 = validationRulesMap.isIpV6AddressMask(str, requireMask);
			if ((ipv4 && ipv4['ip'] >= 0) || ipv6) {
				return null;
			}
		}
		return _T('Invalid IP address');
	},
	ipRange: function (v: string) {
		const arr = v.split ? v.split('-') : '';
		// TODO: Need translation? Might be used for logic
		if (arr.length !== 2) return _T('Invalid IP address range');
		if (validationRulesMap.isIpAddressMask(arr[0]) || validationRulesMap.isIpAddressMask(arr[1])) {
			return _T('Invalid IP address range');
		}
	},

	multiVtype: function (value: any, field: FAWKES.FieldType) {
		if (isVariable(value) && supportVariable(field)) {
			return null;
		}
		let vtypeText = null;
		if (field && field.multitypes) {
			const enumValues = field.multitypes['enum'];
			if (enumValues) {
				for (let j = 0; j < enumValues.length; j++) {
					if (enumValues[j][0] === value) {
						return null;
					}
				}
			}
			const strVal = field.multitypes['string'];
			if (strVal) {
				if (strVal.regex) {
					if (value.match(strVal.regex)) {
						// found match for user-provided regex
						vtypeText = undefined;
						return null;
					} else {
						if (field.uiHint.regexText) {
							vtypeText = field.uiHint.regexText;
						} else {
							vtypeText = _T('Value does not conform to the validation pattern');
						}
						// return vtypeText;
					}
				}
				if (strVal.maxlen) {
					if (value?.length > Number(strVal.maxlen)) {
						vtypeText = _T('Value exceed max length.');
					}
				}
			}
		}

		const multiValidationInfo = field.uiHint.multiValidationInfo;
		for (let i = 0; i < multiValidationInfo.length; i++) {
			const validationInfo = multiValidationInfo[i];
			let newVtypeText = undefined;
			const vtype: string = validationInfo.vtype;
			// if (vtype === 'isString') {
			//     continue;
			// }
			if (vtype) {
				const compVtypeText = validationRulesMap[vtype](value, field, validationInfo);
				if (compVtypeText) {
					if (compVtypeText['ip']) {
						vtypeText = undefined;
					} else if (compVtypeText !== vtypeText) {
						newVtypeText = compVtypeText;
					} else {
						newVtypeText = vtypeText;
					}
					if (!vtypeText) {
						// record the first error
						vtypeText = newVtypeText;
					} else {
						vtypeText += ', ' + _T('or ') + newVtypeText;
					}
				} else {
					// found a type without vtype, consider it pass
					vtypeText = undefined;
					break;
				}
			} else if (validationInfo.regex) {
				if (field.uiHint.regex) {
					if (value.match(field.uiHint.regex)) {
						// found match for user-provided regex
						vtypeText = undefined;
						break;
					} else {
						if (field.uiHint.regexText) {
							vtypeText = field.uiHint.regexText;
						} else {
							vtypeText = _T('Value does not conform to the validation pattern');
						}
					}
				} else if (!validationInfo.regex.test(value)) {
					if (field.uiHint.regexText) {
						vtypeText = field.uiHint.regexText;
					} else if (validationInfo.helpstring) {
						vtypeText = validationInfo.helpstring;
					} else {
						vtypeText =
							_T('Value does not conform to the validation pattern: ') + validationInfo.regex.source;
					}
				} else {
					// found a string with regex but meets the criteria
					vtypeText = undefined;
					break;
				}
			} else {
				// found a type without vtype, consider it pass
				vtypeText = undefined;
				break;
			}
		}
		return vtypeText;
	},

	regEx: function (value: any, field: FAWKES.FieldType) {
		let vtypeText = null;
		if (field && field.uiHint && field.uiHint.regex) {
			if (field.uiHint.regex.test(value)) {
				// found match for user-provided regex
				vtypeText = undefined;
			} else {
				if (field.uiHint.regexText) {
					vtypeText = field.uiHint.regexText;
				} else {
					vtypeText = _T('Value does not conform to the validation pattern: ') + field.uiHint.regex.source;
				}
			}
		}
		return vtypeText;
	},

	customCpValue: function (value: any, field: FAWKES.FieldType) {
		if (value.match(/^([0-1]{6})$/)) {
			return undefined;
		} else {
			return _T("codepoint in format 'xxxxxx' where x is {0|1}");
		}
	},

	isNotPrivateIP: function (v: any) {
		let vtypeText = null;
		// 10.0.0.0 - 10.255.255.255
		const privateIpPattern1 = /10\.(([1-9]?\d|[12]\d\d)\.){2}([1-9]?\d|[12]\d\d)/;
		// 172.16.0.0 - 172.31.255.255
		// const privateIpPattern2 = /(172\.(1[6-9]|[2-9]\d|[12]\d\d)(\.([1-9]?\d|[12]\d\d)){2}|192\.168\.0\.0|192\.([1-9]?\d|1[0-5]\d|16[0-7])(\.([1-9]?\d|[12]\d\d)){2}|(17[3-9]|18\d|19[01])(\.([1-9]?\d|[12]\d\d)){3})/;
		const privateIpPattern2 =
			/(172\.)((1[6-8]|2[0-9]|3[0-1])\.)(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])/;
		// 192.168.0.0 - 192.168.255.255
		const privateIpPattern3 = /192\.168\.([1-9]?\d|[12]\d\d)\.([1-9]?\d|[12]\d\d)/;

		const privateIpPattern4 = /^127.0.0.1$/;

		if (validationRulesMap.isIpAddress(v)) {
			vtypeText = _T('Enter valid IP address');
		} else if (v.includes('/')) {
			vtypeText = _T('Invalid. Cannot use netmask');
		} else if (privateIpPattern1.test(v)) {
			vtypeText = _T('This IP address is private. Please enter a valid public address');
		} else if (privateIpPattern2.test(v)) {
			vtypeText = _T('This IP address is private. Please enter a valid public address');
		} else if (privateIpPattern3.test(v)) {
			vtypeText = _T('This IP address is private. Please enter a valid public address');
		} else if (privateIpPattern4.test(v)) {
			vtypeText = _T('Enter valid IP address');
		}

		return vtypeText;
	},

	isInRangeAndNotPrivate: function (v: any) {
		let vtypeText = null;
		const arr = v.includes('-') ? v.split('-') : [v];
		if (arr.length !== 2) {
			vtypeText = _T('Invalid IP address range');
		}
		if (
			(validationRulesMap.isNotPrivateIP(arr[0]) || validationRulesMap.isNotPrivateIP(arr[1])) &&
			arr.length === 2
		) {
			vtypeText = _T('Invalid IP address range. Must include public IP addresses');
		}
		return vtypeText;
	},

	isValidFQDN: function (value: any) {
		if (value && !value.match(/(?=^.{4,253}\.?$)(^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,63}\.?$)/)) {
			return _T('Invalid Domain Name');
		}
		return null;
	},

	isIPAddressWithoutMaskOrFQDN: function (value: any) {
		const subnetValidation = validationRulesMap.isIpAddressWithoutSubnet(value) ? null : _T('Subnet not allowed');
		const ipValidation = validationRulesMap._isIpAddress(value) ? _T('Invalid Address or FQDN') : null;
		const fqdnValidation = validationRulesMap.isValidFQDN(value) ? _T('Invalid Address or FQDN') : null;
		return fqdnValidation && (subnetValidation || ipValidation);
	},
};
