export const VULNERABILITY_TABLE = 'vulnerability';
export const ANTISPYWARE_TABLE = 'phoneHome';

export interface IThreat1 {
	id: number;
	category: string;
	cve: string;
	details: string;
	host: string;
	name: string;
	severity: string;
	vendor: string;
	version: string;
}

export interface IThreat2 {
	category: string;
	details: string;
	id: number;
	name: string;
	severity: string;
	version: string;
}
