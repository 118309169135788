/* eslint-disable prefer-const, import/no-cycle */

import { _T } from 'src/utils/i18n-utils';
import { JUPITER } from 'service-lib/services/constants';
import _, { cloneDeep, set } from 'lodash';
import { APP_BASE_URL_PREFIX } from 'src/AppConfig';
import { getStoreState } from 'src/ui-lib/core/utils/storeRegistry';
import { getStore } from 'src/ui-lib/core/utils/storeRegistry';
import { saveUserPreference, setUserPreference } from 'src/containers/main/actions';
import { history } from 'sparky-framework';
import Pan from 'src/ui-lib/core/autorender/schema/Pan';
import { hideModal } from 'src/ui-lib/core/services/actions';
import { getScopeNameMap } from 'src/containers/firewallSetup/folderManagement/utils';
import { getRBAPermission, isViewPermissionEnabled } from 'src/ui-lib/core/autorender/widgets/util';
import { getState } from '@sparky/framework';
import { PRISMA_ACCESS_RBA } from 'src/service-lib/services/constants';
import { generateContainerParamsByName } from 'src/ui-lib/utils';

const appBaseURL = _.get(getStoreState(), 'main.appBaseURL', APP_BASE_URL_PREFIX);

export const INJECTED_REC_ID = '__KEY__';

export const CHAR_SET = '0123456789abcdefghijklmnopqrstuvwxyz';

export const ID_LENGTH = 8;
export const DEFAULT_MAX_DROPDOWN_OPTIONS = 500;
export const USER_GROUP_DEFAULT_TOOLTIP = _T(
	`Search with double quotes (‘’ “) for exact match. For example “PANW_User”`,
);

export const scopeMap = [
	{ title: _T('Shared'), path: `${appBaseURL}/prisma-access`, key: 'prisma-access' },
	{ title: _T('Mobile Users'), path: `${appBaseURL}/mu-device`, key: 'mu-device' },
	{ title: _T('Mobile Users'), path: `${appBaseURL}/mobile-users`, key: 'mobile-users' },
	{ title: _T('Remote Networks'), path: `${appBaseURL}/remote-networks`, key: 'remote-networks' },
	{ title: _T('Service Connections'), path: `${appBaseURL}/service-connections`, key: 'service-connections' },
	{ title: _T('GlobalProtect'), path: `${appBaseURL}/mobile-users-gp`, key: 'mobile-users-gp' },
	{ title: _T('Explicit Proxy'), path: `${appBaseURL}/mobile-users-ep`, key: 'mobile-users-ep' },
	{ title: _T(JUPITER), path: `${appBaseURL}/mobile-users-jp`, key: 'mobile-users-jp' },
];

export const VARIABLE_TYPE_MAP = {
	'ip-netmask': _T('IP Netmask: IP address or a network using the slash notation - ip_address/mask'),
	'ip-range': _T('IP Range: Range of IP addresses using the notation ip_address-ip_address'),
	'ip-wildcard': _T(
		'IP Wildcard: IP wildcard address in the format of an IPv4 address followed by a slash and a mask',
	),
	fqdn: _T('FQDN: Domain Name'),
	'group-id': _T('Group ID'),
	'device-id': _T('Device ID'),
	'device-priority': _T('Device Priority'),
	interface: _T(
		'Interface: Physical or logical interfaces that represent firewall ports through which traffic enters or exits the firewall',
	),
	'as-number': _T('AS Number: BGP Autonomous System Number'),
	'qos-profile': _T('Qos Profile'),
	'egress-max': _T('Egress Max'),
	'router-id': _T('Router ID: Router Id in the format of an IPv4 address that is unique to a routing context'),
	'link-tag': _T('Link Tag'),
	rate: _T('Rate (e.g. for flood protection thresholds): Value specified as a rate (e.g times/sec)'),
	count: _T('Count: Integer value used for Count specifications e.g. in thresholds'),
	percent: _T('Percent (Egress Max (%), Egress Guaranteed (%) etc): Value expressed in percentage'),
	port: _T('Port: Service Port'),
	timer: _T('Timer: Value used for timer specifications'),
	zone: _T('Zone: Security zone used in policies and represents a logical grouping of interfaces'),
};

export const fromContainerType = 'fromContainerType';

export const fromContainerName = 'fromContainerName';

export const CONTAINER_LOCATION_PATH_PARAM = 'locationPath';

export const unsavedChangesWarning = _T(
	'You have unsaved changes in your configuration. Would you like to save the changes before exiting?',
);
export const unsavedChangesDialogTitle = _T('Unsaved Changes');

export const GRID_LOADED = 'GRID_LOADED';
export const DATA_UPDATED = 'DATA_UPDATED';
export const ASSOCIATE_SNIPPET = 'ASSOCIATE_SNIPPET';
export const DISASSOCIATE_SNIPPET = 'DISASSOCIATE_SNIPPET';
export const CONFIG_LOCATION_CHANGED = 'CONFIG_LOCATION_CHANGED';
export const REGISTER_GRID = 'REGISTER_GRID';
export const MOVE_SNIPPETS = 'MOVE_SNIPPETS';
export const REMOVE_EMPTY_RECORD = 'REMOVE_EMPTY_RECORD';
export const GRID_UPDATE = 'GRID_UPDATE';
export const IGNORE_KEY = 'ignore_key_for_array_field';

export const VPN_MONITOR_STATUS = {
	NOT_CHECKED: '0',
	UP: '1',
	DOWN: '2',
	FAILING: '3',
};

const setFilterPreference =
	(perfLoc, name): any =>
	async (dispatch: any, getState: any) => {
		const { main: { loggedInUser: { preference = {} } = {} } = {} } = getState();
		const userPref = cloneDeep(preference);
		set(userPref, `config.${perfLoc}`, name);
		await dispatch(setUserPreference(userPref));
		await dispatch(saveUserPreference(userPref));
	};

const isLocationInScope = (location, locationType, rbaPath = '', allowPredefinedRedirect = false) => {
	const config = { container: { locationPath: location, name: location, type: locationType } };
	const rbac = isViewPermissionEnabled(getRBAPermission(rbaPath, config));
	const scopeNameMap = getScopeNameMap();
	let snippetScopeData = _.get(getStoreState(), `main.snippetManage.snippetScopeData`, []);
	const isSnippetPredefined = (data: any) => {
		if (data?.name === 'predefined-snippet' && !allowPredefinedRedirect) return true;
		if (data?.snippetType) {
			return data?.snippetType === 'predefined' || data?.snippetType === 'readonly';
		}
		return false;
	};
	snippetScopeData = snippetScopeData
		.filter((data: any) => {
			if (!isSnippetPredefined(data)) {
				return true;
			} else {
				if (data.name === 'rbi' || data.name === 'predefined-snippet') {
					return false;
				}
				return true;
			}
		})
		.map((e) => {
			return e.name;
		});

	if (locationType == 'snippet') {
		return rbac && snippetScopeData.includes(location);
	} else {
		return rbac && Object.prototype.hasOwnProperty.call(scopeNameMap, location);
	}
};

export const convertObjectTypeDisplayName = (otName) => {
	const displayNameArray = otName.split('_');
	return displayNameArray.map((n) => n.charAt(0).toUpperCase() + n.slice(1)).join(' ');
};

export const objRedirect = (url: string, name: string, locationType: string, location: string, configSearch = true) => {
	const PAlocations = [
		'Prisma Access',
		'Mobile Users Container',
		'Remote Networks',
		'Service Connections',
		'Mobile Users',
		'Mobile Users Explicit Proxy',
	];
	const actualPALoc = (location) => {
		switch (location) {
			case 'Prisma Access':
				return 'prisma-access';
			case 'Mobile Users Container':
				return 'mobile-users';
			case 'Remote Networks':
				return 'remote-networks';
			case 'Service Connections':
				return 'service-connections';
			case 'Mobile Users':
				return 'mobile-users-gp';
			case 'Mobile Users Explicit Proxy':
				return 'mobile-users-ep';
			default:
				return location;
		}
	};
	const isPALocation = PAlocations.includes(location);
	const search = isPALocation
		? `?container=${actualPALoc(location)}${configSearch ? '&configSearch=true' : ''}`
		: `?container=${location}&containerType=${locationType}${configSearch ? '&configSearch=true' : ''}`;
	const historyObject: any = {
		pathname: url,
		search: search,
	};
	const { location: { state: locationState = {} } = {} } = history;
	const state = {
		...locationState,
	};
	if (state && !Pan.isEmpty(state)) {
		historyObject.state = state;
	}
	return historyObject;
};

const handleGlobalFindRedirection = async (data, filter, path) => {
	if (filter) {
		await getStore().dispatch(setFilterPreference(`filters.${filter}`, data.name.name));
	}
	const historyObject = objRedirect(`${appBaseURL}/${path}`, data.name.name, data.locationType, data.location);
	hideModal('globalFindWindow');
	history.push(historyObject);
};

export const handleGlobalFindFormRedirectionWithName = async (data, path, encode = false) => {
	const { name: { name: objName = '' } = {} } = data;
	if (objName) {
		const objPath = encode ? encodeURIComponent(objName) : objName;
		const historyObject = objRedirect(
			`${appBaseURL}/${path}/${objPath}`,
			data.name.name,
			data.locationType,
			data.location,
		);
		hideModal('globalFindWindow');
		history.push(historyObject);
	}
};

export const handleGlobalFindFormRedirectionWithUuid = async (data, path) => {
	const { name: { name: rulename = '', uuid = '' } = {} } = data;
	if (rulename && uuid) {
		const historyObject = objRedirect(
			`${appBaseURL}/${path}/${uuid}`,
			data.name.name,
			data.locationType,
			data.location,
		);
		hideModal('globalFindWindow');
		historyObject.search = `${historyObject.search}&localRecordName=${rulename}`;
		history.push(historyObject);
	}
};

export const handleGlobalFindThreatsRedirection = async (data, path) => {
	const { name: { name: objName = '' } = {} } = data;
	if (objName) {
		if (data?.location === 'predefined-snippet') {
			// predefined threat type: use current scope
			const storeState = getStoreState();
			const name = _.get(storeState, 'main.configLocation.container.name');
			const configLocationContainer = _.get(storeState, 'main.configLocation.container');
			const { location: { state: locationState = {} } = {} } = history;
			const state = {
				...locationState,
			};
			const url = generateContainerParamsByName(
				`${appBaseURL}/${path}`,
				name,
				configLocationContainer,
				new URLSearchParams(`&threat=${encodeURIComponent(objName)}&configSearch=true`),
				state,
			);
			hideModal('globalFindWindow');
			history.push(url);
		} else {
			// custom threat type: use object location
			const historyObject = objRedirect(
				`${appBaseURL}/${path}`,
				data.name.name,
				data.locationType,
				data.location,
			);
			hideModal('globalFindWindow');
			historyObject.search = `${historyObject.search}&threat=${encodeURIComponent(objName)}`;
			history.push(historyObject);
		}
	}
};

export const objectRedirectMap = {
	vnrDataConvertor: (vnrData) => {
		let redirectionConsumableData: {
			location?: string;
			locationType?: string;
			name?: { name: string; uuid: string };
		} = {};
		if (vnrData?.folder) {
			redirectionConsumableData.location = vnrData.folder;
			redirectionConsumableData.locationType = 'container';
		} else if (vnrData?.snippet) {
			redirectionConsumableData.location = vnrData.snippet;
			redirectionConsumableData.locationType = 'snippet';
		} else if (vnrData?.device) {
			redirectionConsumableData.location = vnrData.device;
			redirectionConsumableData.locationType = 'on-prem';
		}
		redirectionConsumableData.name = { name: vnrData?.name, uuid: vnrData?.uuid };
		return redirectionConsumableData;
	},
	Address_group: {
		display_name: _T('Address Group'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'address_groups'),
		handler: (data) => {
			handleGlobalFindRedirection(data, 'address_group_default', 'objects/address-groups');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/address-groups');
		},
	},
	Address: {
		display_name: _T('Address'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'address'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'address_default', 'objects/addresses');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/addresses');
		},
	},

	Application_filter: {
		display_name: _T('Application Filter'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'application_filters'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'application_filter_default', 'objects/application-filters');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/application-filters');
		},
	},
	Application_group: {
		display_name: _T('Application Group'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'application_groups'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'application_group_default', 'objects/application-groups');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/application-groups');
		},
	},
	Application: {
		display_name: _T('Application'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'applications'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'objects/applications');
		},
	},
	Authentication_profile: {
		display_name: _T('Authentication Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'authentication'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'authentication_profile_simple',
				'identity-and-access-services/authentication/tabs/profiles',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'identity-and-access-services/authentication/profiles');
		},
	},
	Authentication_sequence: {
		display_name: _T('Authentication Sequence'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'authentication'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'identity-and-access-services/authentication/tabs/profiles');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'identity-and-access-services/authentication/sequence');
		},
	},
	Certificate_profile: {
		display_name: _T('Certificate Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'certificate_management'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'objects/certificate-management');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/certificate-management/profiles');
		},
	},
	Certificate: {
		display_name: _T('Certificate'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'certificate_management'),
		handler: async (data) => {
			const path = 'objects/certificate-management';
			const { main: { loggedInUser: { preference = {} } = {} } = {} } = getState();
			const userPref = cloneDeep(preference);
			set(userPref, `config.filters.custom_certificate_simple`, data.name.name);
			set(userPref, `config.filters.cloud-certificates-grid`, data.name.name);
			await getStore().dispatch(setUserPreference(userPref));
			await getStore().dispatch(saveUserPreference(userPref));
			const historyObject = objRedirect(
				`${appBaseURL}/${path}`,
				data.name.name,
				data.locationType,
				data.location,
			);
			hideModal('globalFindWindow');
			history.push(historyObject);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/certificate-management/custom-certificates');
		},
	},
	Device_object: {
		display_name: _T('Device'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_object'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'devices_default', 'objects/devices');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/devices');
		},
	},
	Devices_deviceconfig_setting_cloudapp_cloudapp_srvr_addr: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_content_id: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_custom_logo_login_screen: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_custom_logo_main_ui: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_custom_logo_pdf_report_footer: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_custom_logo_pdf_report_header: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_iot_edge: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_logging_enhanced_application_logging_disable_application: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_management_common_criteria_alarm_generation: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_management_device_monitoring: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_management_quota_settings: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_management_secure_conn_client: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_session_settings: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_session_timeouts: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_tcp: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_setting_vpn: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup/tabs/interface');
		},
	},
	Devices_deviceconfig_system_authentication: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_aux_1: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_aux_2: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_device_telemetry: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_general_geo_location: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_general: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_hsm_settings: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_log_export_schedule: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_log_interface: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_log_link: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_management_interface: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_motd_and_banner: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_panorama: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_route: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_services: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_snmp_setting_access_setting_version_v3_users: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_snmp_setting_access_setting_version_v3_views: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_snmp_setting_access_setting: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_snmp_setting: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_ssh_profiles_ha_profiles: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_ssh_profiles_mgmt_profiles_server_profiles: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_ssh_regenerate_hostkeys_ha: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_ssh_regenerate_hostkeys_mgmt: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},
	Devices_deviceconfig_system_update_schedule: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'device_setup'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, false, 'device-settings/device-setup');
		},
	},

	Devices_network_dhcp_interface: {
		display_name: _T('DHCP Interface'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'dhcp'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'dhcp_server_simple', 'device-settings/dhcp');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/dhcp/dhcpServer', true);
		},
	},
	Devices_network_dns_proxy: {
		display_name: _T('DNS Proxy'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'dns_proxy'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'dns_proxies_default', 'device-settings/dns-proxy');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/dns-proxy');
		},
	},
	Devices_network_interface_cellular: {
		display_name: _T('Interface Cellular'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'interfaces'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'cellular_simple', 'device-settings/interfaces/tabs/cellular');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'device-settings/interfaces/cellular');
		},
	},
	Devices_network_profiles_zone_protection_profile: {
		display_name: _T('Zone Protection Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'dos_protection'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'zone_protection_profiles_simple', 'security-services/dos-protection');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/dos-protection/zone-protection-profile');
		},
	},
	Devices_network_routing_profile_bfd: {
		display_name: _T('Routing Profile BFD Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '5'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '5'));
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/routing/routing-profiles/bfd/profile');
		},
	},

	Devices_network_routing_profile_bgp_address_family_profile: {
		display_name: _T('Routing Profile BGP Address Family Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '3'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '3'));
			handleGlobalFindFormRedirectionWithName(
				data,
				'device-settings/routing/routing-profiles/bgp/address-family-profile',
			);
		},
	},
	Devices_network_routing_profile_bgp_auth_profile: {
		display_name: _T('Routing Profile BGP Auth Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '3'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '3'));
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/routing/routing-profiles/bgp/auth-profile');
		},
	},

	Devices_network_routing_profile_bgp_filtering_profile: {
		display_name: _T('Routing Profile BGP Filtering Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '3'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '3'));
			handleGlobalFindFormRedirectionWithName(
				data,
				'device-settings/routing/routing-profiles/bgp/filtering-profile',
			);
		},
	},
	Devices_network_routing_profile_bgp_redistribution_profile: {
		display_name: _T('Routing Profile BGP Redistribution Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '3'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '3'));
			handleGlobalFindFormRedirectionWithName(
				data,
				'device-settings/routing/routing-profiles/bgp/redistribution-profile',
			);
		},
	},

	Devices_network_routing_profile_filters_access_list: {
		display_name: _T('Routing Profile Filters Access List'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindFormRedirectionWithName(
				data,
				'device-settings/routing/routing-profiles/filters/route-access-lists',
			);
		},
	},
	Devices_network_routing_profile_filters_as_path_access_list: {
		display_name: _T('Routing Profile Filters AS Path Access List'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindFormRedirectionWithName(
				data,
				'device-settings/routing/routing-profiles/filters/route-as-path-access-lists',
			);
		},
	},
	Devices_network_routing_profile_filters_community_list: {
		display_name: _T('Routing Profile Filters Community List'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindFormRedirectionWithName(
				data,
				'device-settings/routing/routing-profiles/filters/route-community-lists',
			);
		},
	},
	Devices_network_routing_profile_filters_prefix_list: {
		display_name: _T('Routing Profile Filters Prefix List'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindFormRedirectionWithName(
				data,
				'device-settings/routing/routing-profiles/filters/route-prefix-lists',
			);
		},
	},
	Devices_network_routing_profile_filters_route_maps_bgp_bgp_entry: {
		display_name: _T('Routing Profile Filters Route Maps BGP'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindFormRedirectionWithName(
				data,
				'device-settings/routing/routing-profiles/filters/route-maps-bgp-lists',
			);
		},
	},
	Devices_network_routing_profile_filters_route_maps_redistribution_redist_entry: {
		display_name: _T('Routing Profile Filters Route Maps Redistribution'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '1'));
			handleGlobalFindFormRedirectionWithName(
				data,
				'device-settings/routing/routing-profiles/filters/route-maps-redistribution-lists',
			);
		},
	},

	Devices_network_routing_profile_ospf_auth_profile: {
		display_name: _T('Routing Profile OSPF Auth Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '2'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '2'));
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/routing/routing-profiles/ospf/auth-profile');
		},
	},

	Devices_network_routing_profile_ospf_redistribution_profile: {
		display_name: _T('Routing Profile OSPF Redistribution Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '2'));
			handleGlobalFindRedirection(data, '', 'device-settings/routing/tabs/routing-profiles');
		},
		handleRedirectionToForm: async (data) => {
			await getStore().dispatch(setFilterPreference('activeTabs.profiles-tab', '2'));
			handleGlobalFindFormRedirectionWithName(
				data,
				'device-settings/routing/routing-profiles/ospf/redistribution-profile',
			);
		},
	},

	Devices_network_tunnel_ipsec: {
		display_name: _T('IPSec Tunnel'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'ipsec_tunnels'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'ip_sec_tunnel_simple', 'device-settings/ipsec-tunnel');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/ipsec-tunnel/vpn-cluster');
		},
	},

	Devices_network_virtual_wire: {
		display_name: _T('Virtual Wire'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'virtual_wire'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'virtual_wire_default', 'device-settings/virtual-wire');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/virtual-wire');
		},
	},

	Dynamic_user_group: {
		display_name: _T('Dynamic User Group'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'dynamic_user_groups'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'dynamic_user_group_default', 'objects/dynamic-user-group');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/dynamic-user-group');
		},
	},

	External_list: {
		display_name: _T('External List'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'external_dynamic_lists'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'external_dynamic_list_default', 'objects/dynamic-block-lists');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/dynamic-block-lists');
		},
	},

	Global_protect_global_protect_authentication: {
		display_name: _T('Global Protect Authentication Profile'),
		isRedirectable: (data) =>
			isLocationInScope(data.location, data.locationType, PRISMA_ACCESS_RBA.globalProtect_BB),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'gp_authentication_profile_simple',
				'device-settings/global-protect/tabs/profiles',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(
				data,
				'device-settings/global-protect/profiles/authentication-profile',
			);
		},
	},

	Global_protect_global_protect_gateway_tunnel_config: {
		display_name: _T('Global Protect Agent Tunnel Setting'),
		isRedirectable: (data) =>
			isLocationInScope(data.location, data.locationType, PRISMA_ACCESS_RBA.globalProtect_BB),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'agent_tunnel_settings_simple',
				'device-settings/global-protect/tabs/agent-settings',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/global-protect/agent/tunnel-settings');
		},
	},
	Global_protect_global_protect_gateway_tunnel: {
		display_name: _T('Global Protect Gateway Agent Profile'),
		isRedirectable: (data) =>
			isLocationInScope(data.location, data.locationType, PRISMA_ACCESS_RBA.globalProtect_BB),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'gateway_agent_profile_simple',
				'device-settings/global-protect/tabs/profiles',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(
				data,
				'device-settings/global-protect/profiles/gateway-agent-profile',
			);
		},
	},
	Global_protect_global_protect_gateway: {
		display_name: _T('Global Protect Gateway'),
		isRedirectable: (data) =>
			isLocationInScope(data.location, data.locationType, PRISMA_ACCESS_RBA.globalProtect_BB),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'gateway_simple', 'device-settings/global-protect');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/global-protect/gateway');
		},
	},

	Global_protect_global_protect_portal_agent_config: {
		display_name: _T('Global Protect Agent App Setting'),
		isRedirectable: (data) =>
			isLocationInScope(data.location, data.locationType, PRISMA_ACCESS_RBA.globalProtect_BB),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'agent_app_settings_simple',
				'device-settings/global-protect/tabs/agent-settings',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/global-protect/agent/app-settings');
		},
	},
	Global_protect_global_protect_portal_agent: {
		display_name: _T('Global Protect Portal Agent Profile'),
		isRedirectable: (data) =>
			isLocationInScope(data.location, data.locationType, PRISMA_ACCESS_RBA.globalProtect_BB),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'portal_agent_profile_simple',
				'device-settings/global-protect/tabs/profiles',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(
				data,
				'device-settings/global-protect/profiles/portal-agent-profile',
			);
		},
	},

	Global_protect_global_protect_portal: {
		display_name: _T('Global Protect Portal'),
		isRedirectable: (data) =>
			isLocationInScope(data.location, data.locationType, PRISMA_ACCESS_RBA.globalProtect_BB),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'portal_simple', 'device-settings/global-protect');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'device-settings/global-protect/portal');
		},
	},

	Link_tag: {
		display_name: _T('Link Tag'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'sdwan_policy'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'link_tags_simple', 'security-services/sdwan/tabs/link-tags');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/sdwan/link-tags');
		},
	},
	Local_user_database_user_group: {
		display_name: _T('Local User Groups'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'local_users_groups'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'local_user_groups_simple',
				'identity-and-access-services/local-users-and-user-groups/tabs/user-groups',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(
				data,
				'identity-and-access-services/local-users-and-user-groups/user-groups',
			);
		},
	},
	Local_user_database_user: {
		display_name: _T('Local Users'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'local_users_groups'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'local_users_simple',
				'identity-and-access-services/local-users-and-user-groups',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(
				data,
				'identity-and-access-services/local-users-and-user-groups/users',
			);
		},
	},

	Log_settings_http: {
		display_name: _T('Log Setting HTTP Server Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'log_forwarding'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'http_server_simple', 'objects/logForwarding/tabs/http');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'objects/logForwarding/http/serverProfile');
		},
	},

	Log_settings_profiles: {
		display_name: _T('Log Settings Profiles'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'log_forwarding'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'log_forwarding_profile_simple', 'objects/logForwarding');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/logForwarding/profiles');
		},
	},

	Log_settings_syslog: {
		display_name: _T('Log Setting Syslog Server Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'log_forwarding'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'syslog_server_simple', 'objects/logForwarding/tabs/syslog');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'objects/logForwarding/syslog/serverProfile');
		},
	},
	Object_variable_interface_aggregate_ethernet: {
		display_name: _T('Interface Aggregate Group'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'interfaces'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'ethernet_simple', 'device-settings/interfaces');
		},
		handleRedirectionToForm: async (data) => {
			const { name: { name: objName = '' } = {} } = data;
			if (objName) {
				const path = 'device-settings/interfaces/aggregate-ethernet';
				const objPath = encodeURIComponent(objName);
				const historyObject = objRedirect(
					`${appBaseURL}/${path}/${objPath}`,
					data.name.name,
					data.locationType,
					data.location,
				);
				hideModal('globalFindWindow');
				historyObject.search = `${historyObject.search}&type=aggregate-interface`;
				history.push(historyObject);
			}
		},
	},
	Object_variable_interface_cellular: {
		display_name: _T('Interface Cellular'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'interfaces'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'cellular_simple', 'device-settings/interfaces/tabs/cellular');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'device-settings/interfaces/cellular');
		},
	},
	Object_variable_interface_ethernet: {
		display_name: _T('Interface Ethernet'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'interfaces'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'ethernet_simple', 'device-settings/interfaces');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/interfaces/ethernet');
		},
	},

	Object_variable_interface_loopback: {
		display_name: _T('Interface Loopback'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'interfaces'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'loopback_simple', 'device-settings/interfaces/tabs/loopback');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'device-settings/interfaces/loopback');
		},
	},

	Object_variable_interface_tunnel: {
		display_name: _T('Interface Tunnel'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'interfaces'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'tunnel_simple', 'device-settings/interfaces/tabs/tunnel');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'device-settings/interfaces/tunnel');
		},
	},

	Object_variable_interface_vlan: {
		display_name: _T('Interface Vlan'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'interfaces'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'vlan_simple', 'device-settings/interfaces/tabs/vlan');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'device-settings/interfaces/vlan');
		},
	},
	Ocsp_responder: {
		display_name: _T('OCSP Responder'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'certificate_management'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'objects/certificate-management');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/certificate-management/oscpresponder', true);
		},
	},

	Post_rulebase_default_security_rules_rules: {
		display_name: _T('Security Policy Default Rule'),
		isInUI: false,
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'security_policy'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'security_policy_rule_default', 'security-services/security');
		},
		handleRedirectionToForm: async (data) => {
			const { name: { name: objName = '' } = {} } = data;
			const path = 'security-services/security';
			if (objName) {
				const objPath = encodeURIComponent(objName);
				const historyObject = objRedirect(
					`${appBaseURL}/${path}/${objPath}`,
					data.name.name,
					data.locationType,
					data.location,
				);
				hideModal('globalFindWindow');
				historyObject.search = `${historyObject.search}&pos=post&isBpa=yes&defaultRule=yes&ruleType=default`;
				history.push(historyObject);
			}
		},
	},

	Profile_group: {
		display_name: _T('Profile Group'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'profile_groups'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'profile_group_default', 'security-services/security-profile-groups');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/security-profile-groups');
		},
	},

	Profiles_ai_security: {
		display_name: _T('AI Security Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'ai_security'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'ai_security_profile_default', 'security-services/ai-security');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/ai-security');
		},
	},

	Profiles_custom_url_category: {
		display_name: _T('Custom URL Category'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'url_access_management'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'custom_url_categories_simple',
				'security-services/url-access-management',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(
				data,
				'security-services/url-access-management/custom-url-category',
			);
		},
	},

	Profiles_decryption: {
		display_name: _T('Decryption Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'decryption'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'decryption_policy_simple', 'security-services/decryption');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/decryption/profiles');
		},
	},

	Profiles_dns_security: {
		display_name: _T('DNS Security Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'dns_security'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'dns_security_profile_simple', 'security-services/dns-security');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/dns-security');
		},
	},
	Profiles_dos_protection: {
		display_name: _T('DoS Protection Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'dos_protection'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'do_s_protection_profiles_simple', 'security-services/dos-protection');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/dos-protection/dos-protection-profile');
		},
	},
	Profiles_file_blocking: {
		display_name: _T('File Blocking Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'file_blocking'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'file_blocking_profile_simple', 'security-services/file-blocking');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/file-blocking');
		},
	},

	Profiles_hip_objects: {
		display_name: _T('HIP Objects'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'global_hip_objects'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'hip_object_default', 'objects/hip-objects');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/hip-objects');
		},
	},
	Profiles_hip_profiles: {
		display_name: _T('HIP Profiles'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'global_hip_profiles'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'hip_profile_default', 'objects/hip-profiles');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/hip-profiles');
		},
	},

	Profiles_saas_security: {
		display_name: _T('HTTP Header Insertion Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'http_header_insertion'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'http_header_insertion_profile_default',
				'security-services/http-header-insertion',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/http-header-insertion');
		},
	},

	Profiles_sdwan_error_correction: {
		display_name: _T('SD-WAN Error Connection Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'sdwan_policy'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'error_connection_profile_simple',
				'security-services/sdwan/tabs/error-correction',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/sdwan/error');
		},
	},
	Profiles_sdwan_path_quality: {
		display_name: _T('SD-WAN Path Quality Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'sdwan_policy'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'path_quality_profile_simple',
				'security-services/sdwan/tabs/path-quality',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/sdwan/path-quality');
		},
	},
	Profiles_sdwan_saas_quality: {
		display_name: _T('SD-WAN SaaS Quality Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'sdwan_policy'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'saa_s_quality_profile_simple',
				'security-services/sdwan/tabs/saas-quality',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/sdwan/saas');
		},
	},
	Profiles_sdwan_traffic_distribution: {
		display_name: _T('SD-WAN Traffic Distribution Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'sdwan_policy'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'traffic_distribution_profile_simple',
				'security-services/sdwan/tabs/traffic-distribution',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/sdwan/traffic');
		},
	},
	Profiles_spyware: {
		display_name: _T('Anti-Spyware Security Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'anti_spyware'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'anti_spyware_security_profile_simple', 'security-services/anti-spyware');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/anti-spyware');
		},
	},
	Profiles_url_filtering: {
		display_name: _T('URL Access Management Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'url_access_management'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'url_access_management_profile_simple',
				'security-services/url-access-management',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/url-access-management');
		},
	},
	Profiles_virus_and_wildfire_analysis: {
		display_name: _T('WildFire and Antivirus Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'wildfire_antivirus'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'wild_fire_and_antivirus_profile_simple',
				'security-services/wildfire-and-antivirus',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/wildfire-and-antivirus');
		},
	},
	Profiles_vulnerability: {
		display_name: _T('Vulnerability Protection Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'vulnerability_protection'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'vulnerability_protection_profile_simple',
				'security-services/vulnerability-protection',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/vulnerability-protection');
		},
	},

	Redistribution_agent: {
		display_name: _T('Redistribution Agents'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'identity_redistribution'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'agent_default', 'identity-and-access-services/identityRedistribution');
		},
	},

	Region: {
		display_name: _T('Region'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'regions'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'region_default', 'objects/regions');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/regions');
		},
	},
	Rulebase_application_override_rules: {
		display_name: _T('Application Override Rule'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'application_override'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'application_override_policy_rule_default',
				'security-services/application-override',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'security-services/application-override');
		},
	},
	Rulebase_authentication_rules: {
		display_name: _T('Authentication Rule'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'authentication'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'authentication_rule_simple',
				'identity-and-access-services/authentication',
			);
		},
		handleRedirectionToForm: async (data) => {
			const { name: { name: rulename = '', uuid = '' } = {} } = data;
			const path = 'identity-and-access-services/authentication/rules';
			if (rulename && uuid) {
				const historyObject = objRedirect(
					`${appBaseURL}/${path}/${uuid}`,
					data.name.name,
					data.locationType,
					data.location,
				);
				hideModal('globalFindWindow');
				historyObject.search = `${historyObject.search}&compositeKey=af&localRecordName=${rulename}`;
				history.push(historyObject);
			}
		},
	},
	Rulebase_decryption_rules: {
		display_name: _T('Decryption Policies'),
		isInUI: false,
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'decryption'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'decryption_policy_simple', 'security-services/decryption');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'security-services/decryption/policies');
		},
	},

	Rulebase_dos_rules: {
		display_name: _T('DoS Protection Rule'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'dos_protection'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'do_s_protection_rule_simple', 'security-services/dos-protection');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'security-services/dos-protection/dos-protection-rules');
		},
	},
	Rulebase_nat_rules: {
		display_name: _T('NAT Policy Rule'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'nat'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'nat_policy_rule_default', 'security-services/nat');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'security-services/nat');
		},
	},
	Rulebase_pbf_rules: {
		display_name: _T('Policy Based Forwarding Rule'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'pbf'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'policy_based_forwarding_rule_default', 'security-services/pbf');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'security-services/pbf');
		},
	},
	Rulebase_qos_rules: {
		display_name: _T('QoS Policy Rule'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'qos'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'qo_s_policy_rule_default', 'security-services/qos');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/qos/policy');
		},
	},
	Rulebase_sdwan_rules: {
		display_name: _T('SD-WAN Policy Rule'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'sdwan_policy'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'sd_wan_policy_rules_simple', 'security-services/sdwan');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/sdwan/rules');
		},
	},
	Rulebase_security_rules: {
		display_name: _T('Security Rule'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'security_policy'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'security_policy_rule_default', 'security-services/security');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'security-services/security');
		},
	},
	Saas_external_list: {
		display_name: _T('SaaS Application Endpoints'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'saas_application_management'),
		handler: async (data) => {
			const historyObject: any = {
				pathname: `${appBaseURL}/saas-application-endpoints`,
				search: '&configSearch=true',
			};
			const { location: { state: locationState = {} } = {} } = history;
			const state = {
				...locationState,
			};
			if (state && !Pan.isEmpty(state)) {
				historyObject.state = state;
			}
			hideModal('globalFindWindow');
			history.push(historyObject);
		},
	},
	Scep: {
		display_name: _T('SCEP'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'certificate_management'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'objects/certificate-management');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/certificate-management/scep');
		},
	},
	Schedule: {
		display_name: _T('Schedule'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'schedules'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'schedules_default', 'objects/schedules');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/schedules');
		},
	},
	Sdwan_interface_profile: {
		display_name: _T('SD-WAN Interface Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'sdwan_policy'),
		handler: async (data) => {
			handleGlobalFindRedirection(
				data,
				'sd_wan_interface_profile_simple',
				'security-services/sdwan/tabs/interface',
			);
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/sdwan/interface');
		},
	},
	Secure_web_gateway_block_settings: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'hybrid_swg'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'device-settings/proxy');
		},
	},
	Secure_web_gateway_mode: {
		display_name: '',
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'hybrid_swg'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'device-settings/proxy');
		},
	},

	Server_profile_kerberos: {
		display_name: _T('Kerberos Server Profiles'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'authentication'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'identity-and-access-services/authentication/tabs/Kerberos');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'identity-and-access-services/authentication/kerberos');
		},
	},
	Server_profile_ldap: {
		display_name: _T('LDAP Server Profiles'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'authentication'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'identity-and-access-services/authentication/tabs/LDAP');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'identity-and-access-services/authentication/ldap');
		},
	},
	Server_profile_mfa_server_profile: {
		display_name: _T('MFA Server Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'authentication'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'identity-and-access-services/authentication');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'identity-and-access-services/authentication/mfa');
		},
	},
	Server_profile_radius: {
		display_name: _T('Radius Server Profiles'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'authentication'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'identity-and-access-services/authentication/tabs/Radius');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'identity-and-access-services/authentication/radius');
		},
	},
	Server_profile_saml_idp: {
		display_name: _T('SAML Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'authentication'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'identity-and-access-services/authentication/tabs/SAML');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'identity-and-access-services/authentication/saml');
		},
	},
	Server_profile_tacplus: {
		display_name: _T('TACACS+ Server Profiles'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'authentication'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'identity-and-access-services/authentication/tabs/TACACS+');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'identity-and-access-services/authentication/tacacs');
		},
	},
	Service_group: {
		display_name: _T('Service Group'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'service_groups'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'service_group_default', 'objects/service-groups');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/service-groups');
		},
	},
	Service: {
		display_name: _T('Service'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'services'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'service_default', 'objects/services');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/services');
		},
	},
	Ssl_tls_service_profile: {
		display_name: _T('SSL/TLS Service Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'certificate_management'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'objects/certificate-management');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/certificate-management/ssltls');
		},
	},
	Swg_custom_rulebase_security_rules: {
		display_name: _T('Custom Web Access Policies'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'web_security'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'security-services/web-security');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/web-security/custom-web-access-policy');
		},
	},
	Swg_default_rulebase_security_rules: {
		display_name: _T('Default Web Access Policies'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'web_security'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'security-services/web-security');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/web-security/default-web-access-policy');
		},
	},
	Swg_device_identification_type: {
		display_name: _T('Device Categories'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'web_security'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'security-services/web-security/tabs/device-posture');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/web-security/device-posture/type');
		},
	},
	Swg_device_posture_profile: {
		display_name: _T('Device Posture Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'web_security'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'security-services/web-security/tabs/device-posture');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'security-services/web-security/device-posture/profile');
		},
	},
	Swg_security_settings_decryption: {
		display_name: _T('Security Settings Decryption'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'web_security'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'security-services/web-security/tabs/decryption');
		},
	},
	Swg_security_settings_dns_security: {
		display_name: _T('Security Settings DNS Security'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'web_security'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'security-services/web-security/tabs/dns');
		},
	},
	Swg_security_settings_file_blocking: {
		display_name: _T('Security Settings File Control Profile'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'web_security'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'security-services/web-security/tabs/file-control');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(
				data,
				'security-services/web-security/file-control/file-control-settings',
			);
		},
	},
	Tag: {
		display_name: _T('Tag'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'tags'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'tag_default', 'objects/tags');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'objects/tags', true);
		},
	},
	// Threat_exception_virus_and_wildfire_analysis: {
	// 	display_name: '',
	// 	isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'wildfire_antivirus'),
	// 	handler: async (data) => {
	// 		handleGlobalFindRedirection(data, '', 'security-services/wildfire-and-antivirus');
	// 	},
	// },
	// Threat_exception_vulnerability: {
	// 	display_name: _T('Vulnerability Protection Override'),
	// 	isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'vulnerability_protection'),
	// 	handler: async (data) => {
	// 		handleGlobalFindRedirection(data, '', 'security-services/vulnerability-protection');
	// 	},
	// },
	Threats_phone_home: {
		display_name: _T('Anti-Spyware Threats'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'anti_spyware', true),
		handler: async (data) => {
			handleGlobalFindThreatsRedirection(data, 'security-services/anti-spyware-threat-search');
		},
	},
	Threats_spyware: {
		display_name: _T('Anti-Spyware Custom Signatures'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'anti_spyware'),
		handler: async (data) => {
			handleGlobalFindThreatsRedirection(data, 'security-services/anti-spyware-threat-search');
		},
	},
	Threats_vulnerability: {
		display_name: _T('Vulnerability Threats'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'vulnerability_protection', true),
		handler: async (data) => {
			handleGlobalFindThreatsRedirection(data, 'security-services/vulnerability-threat-search');
		},
	},
	Traffic_object: {
		display_name: _T('Traffic Object'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'traffic_object'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'traffic_object_default', 'objects/traffic-objects');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'objects/traffic-objects');
		},
	},
	Url_admin_override: {
		display_name: _T('URL Admin Override'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'url_access_management'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, '', 'security-services/url-access-management/tabs/settings');
		},
	},
	Zone: {
		display_name: _T('Zone'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'zones'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'zone_default', 'device-settings/zones');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/zones');
		},
	},
	Devices_network_logical_router: {
		display_name: _T('Logical Router'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'routers_simple', 'device-settings/routing');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/routing/logical-router');
		},
	},
	Devices_network_virtual_router: {
		display_name: _T('Virtual Router'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType, 'routing'),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'routers_simple', 'device-settings/routing');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithName(data, 'device-settings/routing/logical-router');
		},
	},
	Variable: {
		display_name: _T('Variable'),
		isRedirectable: (data) => isLocationInScope(data.location, data.locationType),
		handler: async (data) => {
			handleGlobalFindRedirection(data, 'variable-manage-grid', 'variables');
		},
		handleRedirectionToForm: async (data) => {
			handleGlobalFindFormRedirectionWithUuid(data, 'variables');
		},
	},
};
