import * as yup from 'yup';
import { FAWKES } from 'src/typings/type';
import { getFieldName, getValidationSchemaByField } from 'src/ui-lib/core/utils/validationRuleSchema';
import {
	ALL,
	CONTAINER,
	CONTAINER_CLOUD,
	CONTAINER_ONPREM,
	LOCATION_REVERSE_KEY_MAP,
	SNIPPET,
} from 'service-lib/services/constants';
import { isNGFWDisabled, isPADisabled } from 'src/ui-lib/utils';
import { getStoreState } from 'src/ui-lib/core/utils/storeRegistry';
import _ from 'lodash';
import { validationRulesMap } from 'src/ui-lib/core/utils/validationRules';
import { _T } from 'src/utils/i18n-utils';

export const formatStringTemplate = (template: string | undefined, ...args: Array<any>): string => {
	const regex = new RegExp('{-?[0-9]+}', 'g');
	return template!.replace(regex, (item) => {
		const intVal = parseInt(item.substring(1, item.length - 1));
		let replace;
		if (intVal >= 0) {
			replace = args[intVal];
		} else if (intVal === -1) {
			replace = '{';
		} else if (intVal === -2) {
			replace = '}';
		} else {
			replace = '';
		}
		return replace;
	});
};

export const completeUrl = (url: string): string => {
	return url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
};

export const exportTextFile = ({
	content,
	fileName,
	extension = 'txt',
}: {
	content: string;
	fileName: string;
	extension?: string;
}) => {
	let textFile: string | null = null;
	const makeTextFile = function (text: string) {
		const data = new Blob([text], { type: 'text/plain' });

		if (textFile !== null) {
			window.URL.revokeObjectURL(textFile);
		}

		textFile = window.URL.createObjectURL(data);

		return textFile;
	};

	const link = document.createElement('a');
	link.setAttribute('download', `${fileName}.${extension}`);
	link.href = makeTextFile(content);
	document.body.appendChild(link);

	// wait for the link to be added to the document
	window.requestAnimationFrame(function () {
		const event = new MouseEvent('click');
		link.dispatchEvent(event);
		document.body.removeChild(link);
	});
};

export function getArrayFromString(str = '', separator: string | null = null) {
	let result: Array<string> = [];
	if (separator) {
		result = str.split(separator);
	} else if (str.indexOf('\n')) {
		result = str.indexOf('\r') ? str.replace(/[\r]+/gm, '').split('\n') : str.split('\n');
	} else if (str.indexOf(',')) {
		result = str.split(',');
	} else if (str.indexOf('\r')) {
		result = str.split('\r');
	}
	return result.filter((item) => item.trim());
}

export function arrayMoveMutable(array: Array<any>, fromIndex: number, toIndex: number) {
	const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

	if (startIndex >= 0 && startIndex < array.length) {
		const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

		const [item] = array.splice(fromIndex, 1);
		array.splice(endIndex, 0, item);
	}
}

export function arrayMoveImmutable(array: Array<any>, fromIndex: number, toIndex: number) {
	array = [...array];
	arrayMoveMutable(array, fromIndex, toIndex);
	return array;
}

export const generateValidateSchema = (
	field: FAWKES.FieldType | undefined,
	fieldsMap: Map<string, FAWKES.FieldType>,
	parentSchema: yup.AnyObjectSchema | null,
): yup.AnyObjectSchema | undefined => {
	if (!field || !field.name) return;
	let schema: any;
	if (field.childrenNames && field.childrenNames.length > 0) {
		schema = yup.object();
		field.childrenNames.forEach((subFieldName: string) => {
			const subField = fieldsMap.get(subFieldName);
			const name: string = getFieldName(subField);
			const subSchema = generateValidateSchema(fieldsMap.get(subFieldName), fieldsMap, schema);
			if (subSchema) {
				if (name === 'choice' && subSchema.fields) {
					Object.keys(subSchema.fields).forEach((k) => {
						schema = (schema as yup.ObjectSchema<any>).shape({
							[k]: subSchema.fields[k],
						});
					});
				} else {
					schema = (schema as yup.ObjectSchema<any>).shape({
						[name]: subSchema,
					});
				}
			}
		});
	} else {
		const validateSchema = getValidationSchemaByField(field);
		if (validateSchema) {
			schema = validateSchema;
		}
	}
	return schema;
};

export const getRecordConfigPathMap = (path: string) => {
	const container_prefix = '$.config.devices.entry.container.entry.';
	const cloud_prefix = '$.config.devices.entry.device.cloud.entry.';
	const onprem_prefix = '$.config.devices.entry.device.on-prem.entry.';
	const snippet_prefix = '$.config.devices.entry.snippet.entry.';
	return {
		[CONTAINER]: container_prefix + path,
		[CONTAINER_CLOUD]: cloud_prefix + path,
		[CONTAINER_ONPREM]: onprem_prefix + path,
		[SNIPPET]: snippet_prefix + path,
	};
};

export const renderGridData = (gridData: any) => {
	if (!gridData) return null;
	const allSnippets = _.get(getStoreState(), 'main.snippetManage.allSnippets', []);
	let filteredForPA = [];
	let filteredForNGFW = [];
	let filteredForSnippet = [];
	let filteredForAll = [];
	if (gridData) {
		filteredForPA = gridData.filter(
			(obj: any) => obj['@loc'] && LOCATION_REVERSE_KEY_MAP[obj['@loc']] && !allSnippets[obj['@loc']],
		);
		filteredForNGFW = gridData.filter(
			(obj: any) =>
				obj['@loc'] &&
				!LOCATION_REVERSE_KEY_MAP[obj['@loc']] &&
				!allSnippets[obj['@loc']] &&
				obj['@loc'] !== ALL,
		);
		filteredForSnippet = gridData.filter((obj: any) => obj['@loc'] && allSnippets[obj['@loc']]);
		filteredForAll = gridData.filter((obj: any) => obj['@loc'] && obj['@loc'] === ALL);
	}

	let cleanUpGridData: any = [...filteredForAll, ...filteredForSnippet];
	if (!isNGFWDisabled()) {
		cleanUpGridData = [...cleanUpGridData, ...filteredForNGFW];
	}

	if (!isPADisabled()) {
		cleanUpGridData = [...cleanUpGridData, ...filteredForPA];
	}

	return cleanUpGridData;
};

export const checkIPv4AddressWithoutSubnetMask = (value) => {
	if (!validationRulesMap.isValidIpV4Address(value).isValidIPv4) {
		if (
			value?.split('/').length > 1 &&
			validationRulesMap.isValidIpV4Address(value).errorMsg === _T('Invalid subnet mask')
		)
			return _T('Subnet mask is not allowed'); // Since the default isValidIpV4Address function allows a subnet, override the error message to simply block subnet masks - it's misleading otherwise
		return validationRulesMap.isValidIpV4Address(value).errorMsg;
	} else {
		if (value?.split('/').length > 1) return _T('Subnet mask is not allowed');
	}
};

export const isStringAndNotAVariable = (str: any) => str && typeof str === 'string' && str.charAt(0) !== '$';
