export const BOOTSTRAP_STATUS_QC_KEY = 'BOOTSTRAP_STATUS';
export const ROUTING_STATUS_QC_KEY = 'ROUTING_STATUS';
export const SYNC_STATUS_QC_KEY = 'SYNC_STATUS';
export const DEVICES_QC_KEY = 'DEVICES';
export const FOLDER_SCOPE_QC_KEY = 'FOLDER_SCOPE';
export const CLOUD_NGFW_RESOURCES_QC_KEY = 'CLOUD_NGFW_RESOURCES';
export const VULNERABILITY_PROTECTION_PROFILE_QC_KEY = 'VULNERABILITY_PROTECTION_PROFILE';
export const VULNERABILITY_OVERRIDES_QC_KEY = 'VULNERABILITY_OVERRIDES';
export const SECURITY_PROFILES_QC_KEY = 'SECURITY_PROFILES';
export const CONTENT_VULNERABILITY_QC_KEY = 'CONTENT_VULNERABILITY';
export const VULNERABILITY_BLOCKED_THREAT_COUNT_QC_KEY = 'VULNERABILITY-BLOCKED-THREAT-COUNT';
export const URL_ACCESS_MANAGEMENT_QC_KEY = 'URL_ACCESS_MANAGEMENT';
export const VULNERABILITY_BPA_RESULT = 'VULNERABILITY_BPA_RESULT';
export const IPSEC_CRYPTO_PROFILE_BPA = 'IpSec Crypto Profile';
export const URL_ACCESS_ALLOW_URL_QC_KEY = 'URL_ACCESS_ALLOW_URL';
export const DEVICE_HA_BPA_RESULT = 'DEVICE_HA_BPA_RESULT';
export const URL_ACCESS_PROFILE_BPA_RESULT = 'URL_ACCESS_PROFILE_BPA_RESULT';
export const BLOCKED_THREAT_COUNT_QC_KEY = 'BLOCKED_THREAT_COUNT';
export const CONTENT_ANTISPYWARE_QC_KEY = 'CONTENT_ANTISPYWARE';
export const ANTISPYWARE_OVERRIDES_QC_KEY = 'ANTISPYWARE_OVERRIDES';
export const CUSTOM_SPYWARE_SIGNATURES_QC_KEY = 'CUSTOM_SPYWARE_SIGNATURES';
export const ANTISPYWARE_PROFILES_QC_KEY = 'ANTISPYWARE_PROFILES';
export const ANTISPYWARE_PROFILES_BPA_RESULT = 'ANTISPYWARE_PROFILES_BPA_RESULT';
export const APPLICATIONS_QC_KEY = 'APPLICATIONS';
export const APPLICATION_FILTERS_QC_KEY = 'APPLICATIONS_FILTERS';
export const WILDFIRE_ANALYSIS_SETTINGS_QC_KEY = 'WILDFIRE_ANALYSIS_SETTINGS';
export const WILDFIRE_ANTIVIRUS_PROFILE_QC_KEY = 'WILDFIRE_ANTIVIRUS_PROFILE';
export const WILDFIRE_ANTIVIRUS_PROFILES_BPA_RESULT = 'WILDFIRE_ANTIVIRUS_PROFILES_BPA_RESULT';
export const APP_OVERRIDE_POLICIES_QC_KEY = 'APP_OVERRIDE_RULES';
export const APP_OVERRIDE_POLICIES_BPA_RESULT = 'APP_OVERRIDE_RULES_BPA_RESULT';
export const TAGS_QC_KEY = 'TAGS';
export const POLICIES_AND_ADDRESSED_QC_KEY = 'POLICIES_AND_ADDRESSED';
export const AVAILABLE_DEVICES_QC_KEY = 'AVAILABLE_DEVICES';
export const SUBSCRIBERS_QC_KEY = 'UCF_SUBSCRIBERS';
export const CUSTOM_VULNERABILITY_SIGNATURES_QC_KEY = 'CUSTOM_VULNERABILITY_SIGNATURES';
export const CUSTOM_URL_CATEGORIES_QC_KEY = 'CUSTOM_URL_CATEGORIES';
export const WHERE_COMMAND_QC_KEY = 'WHERE_COMMAND';
export const ANTISPYWARE_THREAT_DETAILS_QC_KEY = 'ANTISPYWARE_THREAT_DETAILS';
export const VULNERABILITY_THREAT_DETAILS_QC_KEY = 'VULNERABILITY_THREAT_DETAILS';
export const ADDRESSES_BPA_RESULT = 'ADDRESSES_BPA_RESULT';
export const CURRENT_VERSIONS_QC_KEY = 'CURRENT_VERSIONS';
export const PUSH_CONFIGS_QC_KEY = 'PUSH_CONFIGS';
export const ADMIN_USERS_QC_KEY = 'ADMIN_USERS';
export const HA_DEVICES_QC_KEY = 'HA_DEVICES';
export const UNPUSHED_CHANGES_QC_KEY = 'UNPUSHED_CHANGES';
