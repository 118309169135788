import { getFrameworkVar, getAuthState } from 'sparky-framework';

export function getSbacFeatureFlag() {
	let feature_flag = false;
	const tsg_id = getAuthState()?.tsg_id || '';
	const sbacTsgIds = getFrameworkVar('fawkes_sbac_tsgs') || [];
	if (Array.isArray(sbacTsgIds) && sbacTsgIds?.includes(tsg_id)) {
		feature_flag = true;
	}
	return feature_flag;
}
