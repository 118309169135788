/* eslint-disable prefer-const, import/no-cycle */

import _ from 'lodash';
import { getStoreState } from 'ui-lib/core/utils/storeRegistry';
import { getAuthState } from 'sparky-framework';
import Pan from 'ui-lib/core/autorender/schema/Pan';
import moment from 'moment';
/* eslint-disable import/no-cycle */
import { _T } from 'src/utils/i18n-utils';

import {
	SASE_PRISMA_ACCESS_BRAND,
	MU_CONTAINER,
	RN_CONTAINER,
	SC_CONTAINER,
	MU_GP_CONTAINER,
	MU_EP_CONTAINER,
	DLP_ADDON_LICENSE_TYPES,
	DLP_ADDON_LICENSE_APP_ID,
	PRISMA_ACCESS_BROSWER_LICENSE_APP_ID,
	SAAS_INLINE_ADDON_LICENSE_APP_ID,
	NG_CASB_X_LICENSE_BUNDLE_APP_ID,
	PA_ADD_LOC,
	PRISMA_ACCESS_CONTAINER,
	PRISMA_ACCESS_KEY,
	MU_KEY,
	RN_KEY,
	SC_KEY,
	MU_JP_KEY,
	MU_GP_KEY,
	MU_EP_KEY,
	SWG_CONTAINER_TYPE,
	SWG_CONTAINER,
	ALL,
	TRAFFIC_REPLICATION_ADDON_LICENSE_APP_ID,
	EPDLP_ADDON_LICENSE_TYPES,
	UNLIMITED_PRIVATE_APP_ACCESS_ADD_ON,
	SNIPPET,
	SITE,
} from 'src/service-lib/services/constants';
import { generateGridRecId, getRBAPermission, isViewPermissionEnabled } from 'ui-lib/core/autorender/widgets/util';
import { checkIsFeatureEnabled } from 'src/utils/featureFlagUtils';
import { getFeaturesInfoFromStore } from 'src/containers/config/features';
import { getFawkesMainFromSparkyStore } from 'src/appUtils';

const mergeWithoutDuplictae = (arr1: any, arr2: any) => {
	return arr2.reduce(
		(result: any, ele: any) => {
			if (result.indexOf(ele) >= 0) {
				return result;
			} else {
				return [...result, ele];
			}
		},
		[...arr1],
	);
};

export const isLicenseActive = (expiration: any) => {
	if (Pan.isEmpty(expiration)) {
		return false;
	}
	if (expiration && Date.now() - new Date(expiration).getTime() > 0) {
		return false;
	}
	return true;
};

const SERVICE = 'service';

export const LICENSE_CHECK_LOCATIONS = [MU_CONTAINER, RN_CONTAINER, SC_CONTAINER, MU_GP_CONTAINER, MU_EP_CONTAINER];

// TODO: Need translation?
export const LicenseTypeDisplayMap: any = {
	'GBL-MU-RN-BUS': _T('{brandName} Business', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'GBL-MU-RN-BUS-PRM': _T('{brandName} Business Premium', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'GBL-MU-RN-ENT': _T('{brandName} Enterprise', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LCL-MU-RN-BUS': _T('{brandName} Local Business', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LCL-MU-RN-BUS-PRM': _T('{brandName} Local Business Premium', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LCL-MU-RN-ENT': _T('{brandName} Local Enterprise', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'EVAL-LCL-MU-RN-ENT': _T('{brandName} Eval', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'EVAL-GBL-MU-RN-ENT': _T('{brandName} Global', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LAB-MU-RN': _T('{brandName} Lab', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'NFR-MU-RN': _T('{brandName} NFR', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'SE-LCL-MU-RN': _T('{brandName} SE', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'GBL-MU-ENT': _T('{brandName} Mobile User Enterprise', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'GBL-MU-BUS': _T('{brandName} Mobile User Business', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'GBL-MU-BUS-PRM': _T('{brandName} Mobile User Business Premium', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'GBL-MU-ZTNA': _T('{brandName} Mobile User ZTNA', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LCL-MU-ENT': _T('{brandName} Mobile User Local Enterprise', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LCL-MU-BUS': _T('{brandName} Mobile User Local Business', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LCL-MU-BUS-PRM': _T('{brandName} Mobile User Local Business Premium', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LCL-MU-ZTNA': _T('{brandName} Mobile User Local ZTNA', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'GBL-RN-ENT': _T('{brandName} Net Enterprise', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'GBL-RN-BUS': _T('{brandName} Net Business', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'GBL-RN-BUS-PRM': _T('{brandName} Net Business Premium', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'GBL-RN-ZTNA': _T('{brandName} Net ZTNA', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LCL-RN-ENT': _T('{brandName} Net Local Enterprise', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LCL-RN-BUS': _T('{brandName} Net Local Business', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LCL-RN-BUS-PRM': _T('{brandName} Net Local Business Premium', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'LCL-RN-ZTNA': _T('{brandName} Net Local ZTNA', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'PAE-SITE-ENT-25': _T('{brandName} Enterprise', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'PAE-SITE-ENT-50': _T('{brandName} Enterprise', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'PAE-SITE-ENT-250': _T('{brandName} Enterprise', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'PAE-SITE-ENT-1000': _T('{brandName} Enterprise', { brandName: SASE_PRISMA_ACCESS_BRAND }),
	'PAE-SITE-ENT-2500': _T('{brandName} Enterprise', { brandName: SASE_PRISMA_ACCESS_BRAND }),
};

const sharedMuRnLicenseTypes: string[] = [
	'GBL-MU-RN-BUS',
	'GBL-MU-RN-BUS-PRM',
	'GBL-MU-RN-ENT',
	'LCL-MU-RN-BUS',
	'LCL-MU-RN-BUS-PRM',
	'LCL-MU-RN-ENT',
	'LAB-MU-RN',
	'NFR-MU-RN',
	'SE-LCL-MU-RN',
	'EVAL-LCL-MU-RN-ENT',
	'EVAL-GBL-MU-RN-ENT',
];

const muLicenseTypes: string[] = [
	'GBL-MU-ENT',
	'GBL-MU-BUS',
	'GBL-MU-BUS-PRM',
	'GBL-MU-ZTNA',
	'LCL-MU-ENT',
	'LCL-MU-BUS',
	'LCL-MU-BUS-PRM',
	'LCL-MU-ZTNA',
];

const siteLicenseTypes: string[] = [
	'PAE-SITE-ENT-25',
	'PAE-SITE-ENT-50',
	'PAE-SITE-ENT-250',
	'PAE-SITE-ENT-1000',
	'PAE-SITE-ENT-2500',
];

const rnLicenseTypes: string[] = [
	'GBL-RN-ENT',
	'GBL-RN-BUS',
	'GBL-RN-BUS-PRM',
	'GBL-RN-ZTNA',
	'LCL-RN-ENT',
	'LCL-RN-BUS',
	'LCL-RN-BUS-PRM',
	'LCL-RN-ZTNA',
];

const internalLicenseTypes: string[] = [
	'PAN-GPCS-NET-LAB-1YR',
	'PAN-GPCS-USER-LAB-1YR',
	'PAN-GPCS-NET-50-E60',
	'PAN-GPCS-USER-100-E60',
	'PAN-GPCS-NET-200-E60',
	'PAN-GPCS-USER-200-E60',
	'PAN-GPCS-USER-200-E30',
	'PAN-GPCS-NET-200-E30',
	'PAN-GPCS-USER-SE-1YR',
	'PAN-GPCS-NET-SE-1YR',
];

const aiAccessLicenseType = ['AI-ACCESS-X', 'AI-ACCESS-X-EVAL'];
const LOGGING_SERVICE_EXTENSION_NAMES = ['Logging Service', 'logging_service', 'Strata Logging Service'];

//Check if location addon included, returns location addon 'PA-ADD-LOC' size
const getLocationAddonSize = (licenseSummary: any) => {
	const locationAddonInfo = _.get(licenseSummary.addonLicenses, PA_ADD_LOC);
	const locationAddonSize = locationAddonInfo ? parseInt(locationAddonInfo.size) : 0;
	return locationAddonSize;
};

//ADI-17285 : There should not be any affect on EP configuration when location addon is added
const getEPServiceLocationSize = (serviceLocationSize: any, licenseSummary: any) => {
	const locationAddonSize = getLocationAddonSize(licenseSummary);
	return isNaN(parseInt(serviceLocationSize)) ? Infinity : parseInt(serviceLocationSize) - locationAddonSize;
};

export const mapLicenseInfoData = (licenseInfo: any) => {
	if (Pan.isEmpty(licenseInfo)) return false;
	if (licenseInfo.msg) {
		const muExpiry = licenseInfo.msg['mu-license-expiry'];
		const rnExpiry = licenseInfo.msg['mu-license-expiry'];
		const scExpiry =
			!_.isEmpty(muExpiry) && !_.isEmpty(rnExpiry)
				? moment(muExpiry).isBefore(moment(rnExpiry))
					? rnExpiry
					: muExpiry
				: muExpiry || rnExpiry;
		return {
			...licenseInfo,
			licenseInfoLoad: true,
			licenseSummary: {
				isNewLicense: false,
				[MU_CONTAINER]: {
					active: licenseInfo.msg['mu-active'] || false,
					expiration: licenseInfo.msg['mu-license-expiry'] || '',
					licenseType: licenseInfo.msg['mu-license-type'] || '',
					size: licenseInfo.msg['mu-purchased-size'] || 0,
					serviceLocationScope: 'Worldwide',
					serviceLocationSize: Infinity,
				},
				[RN_CONTAINER]: {
					active: licenseInfo.msg['rn-active'] || false,
					expiration: licenseInfo.msg['rn-license-expiry'] || '',
					licenseType: licenseInfo.msg['rn-license-type'] || '',
					rnSKU: licenseInfo.msg['rn-sku'] || '',
					size: licenseInfo.msg['rn-purchased-size'] || 0,
					serviceLocationScope: 'Worldwide',
					serviceLocationSize: Infinity,
				},
				[SC_CONTAINER]: {
					active: licenseInfo.msg['rn-active'] || licenseInfo.msg['mu-active'] || false,
					serviceLocationSize: Infinity,
					expiration: scExpiry,
				},
				[MU_GP_CONTAINER]: {
					active: licenseInfo.msg['mu-active'] || false,
					expiration: licenseInfo.msg['mu-license-expiry'] || '',
					licenseType: licenseInfo.msg['mu-license-type'] || '',
					size: licenseInfo.msg['mu-purchased-size'] || 0,
					serviceLocationScope: 'Worldwide',
					serviceLocationSize: Infinity,
				},
				[MU_EP_CONTAINER]: {
					active: licenseInfo.msg['mu-active'] || false,
					expiration: licenseInfo.msg['mu-license-expiry'] || '',
					licenseType: licenseInfo.msg['mu-license-type'] || '',
					size: licenseInfo.msg['mu-purchased-size'] || 0,
					serviceLocationScope: 'Worldwide',
					serviceLocationSize: Infinity,
				},
			},
		};
	} else {
		const {
			entitlements: { addons = [], base_editions: baseEditions = [] } = {},
			features_enabled: {
				mobile_users: mobileUserLicenseDetail = {},
				remote_networks: remoteNetworksDetail = {},
				service_connection: serviceConnectionsDetail = {},
			} = {},
			license_expiration: licenseExpiration,
			services = [],
			features_enabled: serviceFeatures = {},
		} = licenseInfo;

		const licenseDetail = {
			...licenseInfo,
			licenseInfoLoad: true,
			licenseSummary: {
				isNewLicense: true,
				[MU_CONTAINER]: {
					active: false,
					expiration: '',
					licenseType: '',
					size: 0,
					serviceLocationSize: 0,
					privateAppAccess: null,
				},
				[RN_CONTAINER]: {
					active: false,
					expiration: '',
					licenseType: '',
					rnSKU: '',
					size: 0,
					serviceLocationSize: 0,
					privateAppAccess: null,
				},
				[SC_CONTAINER]: {
					active: false,
					size: 0,
					serviceLocationSize: 0,
					expiration: '',
				},
				[MU_GP_CONTAINER]: {
					active: false,
					expiration: '',
					licenseType: '',
					size: 0,
					serviceLocationSize: 0,
					privateAppAccess: null,
				},
				[MU_EP_CONTAINER]: {
					active: false,
					expiration: '',
					licenseType: '',
					size: 0,
					serviceLocationSize: 0,
					privateAppAccess: null,
				},
				addonLicenses: addons.reduce((result: any, addon: any) => {
					return {
						...result,
						[addon.license_type]: {
							...addon,
						},
					};
				}, {}),
				addons,
				serviceBasedAddonLicense: addons.reduce((result: any, addon: any) => {
					if (addon.addon_type === SERVICE) {
						return {
							...result,
							[addon.license_type]: {
								...addon,
							},
						};
					}
					return {
						...result,
					};
				}, {}),
				isInterConnectEnabled: false,
				privateAppAccess: null,
				features: [],
				services,
				serviceFeatures,
			},
		};

		let sharedMuRnLicenseType = '';
		let muLicenseType = '';
		let rnLicenseType = '';
		let siteLicenseType = '';
		let categorySet = new Set();

		baseEditions.forEach((license: any) => {
			if (license.category) {
				categorySet.add(license.category);
			}
			if (sharedMuRnLicenseTypes.indexOf(license.license_type) >= 0) {
				sharedMuRnLicenseType = license.license_type;
			} else if (muLicenseTypes.indexOf(license.license_type) >= 0) {
				muLicenseType = license.license_type;
			} else if (rnLicenseTypes.indexOf(license.license_type) >= 0) {
				rnLicenseType = license.license_type;
			} else if (siteLicenseTypes.indexOf(license.license_type) >= 0) {
				siteLicenseType = license.license_type;
			}
		});

		licenseDetail.licenseSummary.sharedMuRnLicenseType = sharedMuRnLicenseType;
		licenseDetail.licenseSummary.categorySet = categorySet;

		if (!Pan.isEmpty(mobileUserLicenseDetail)) {
			const {
				user_cap: { size: muSize = 0 } = {},
				panos: { features = [] } = {},
				service_location: { single_tenant: { scope = {}, size: serviceLocationSize = 0 } = {} } = {},
			} = mobileUserLicenseDetail;

			licenseDetail.licenseSummary.features = mergeWithoutDuplictae(
				[...licenseDetail.licenseSummary.features],
				[...features],
			);

			licenseDetail.licenseSummary[MU_CONTAINER] = {
				...licenseDetail.licenseSummary[MU_CONTAINER],
				licenseType: sharedMuRnLicenseType || muLicenseType,
				active: isLicenseActive(licenseExpiration),
				expiration: licenseExpiration || '',
				size: parseInt(muSize),
				features: features,
				serviceLocationScope: scope === 'worldwide' ? _T('Worldwide') : _T('Local'),
				serviceLocationSize: isNaN(parseInt(serviceLocationSize)) ? Infinity : parseInt(serviceLocationSize),
			};

			licenseDetail.licenseSummary[MU_GP_CONTAINER] = {
				...licenseDetail.licenseSummary[MU_GP_CONTAINER],
				licenseType: sharedMuRnLicenseType || muLicenseType,
				active: isLicenseActive(licenseExpiration),
				expiration: licenseExpiration || '',
				size: parseInt(muSize),
				features: features,
				serviceLocationScope: scope === 'worldwide' ? _T('Worldwide') : _T('Local'),
				serviceLocationSize: isNaN(parseInt(serviceLocationSize)) ? Infinity : parseInt(serviceLocationSize),
			};

			licenseDetail.licenseSummary[MU_EP_CONTAINER] = {
				...licenseDetail.licenseSummary[MU_EP_CONTAINER],
				licenseType: sharedMuRnLicenseType || muLicenseType,
				active: isLicenseActive(licenseExpiration),
				expiration: licenseExpiration || '',
				size: parseInt(muSize),
				features: features,
				serviceLocationScope: scope === 'worldwide' ? _T('Worldwide') : _T('Local'),
				serviceLocationSize: getEPServiceLocationSize(serviceLocationSize, licenseDetail.licenseSummary),
			};
		}

		if (!Pan.isEmpty(remoteNetworksDetail)) {
			const {
				bandwidth_cap: { size: rnSize = 0 } = {},
				panos: { features = [] } = {},
				service_location: { single_tenant: { scope = {}, size: serviceLocationSize = 0 } = {} } = {},
				net_interconnect: netInterconnect = null,
				sites: sites = [],
			} = remoteNetworksDetail;

			licenseDetail.licenseSummary.features = mergeWithoutDuplictae(
				[...licenseDetail.licenseSummary.features],
				[...features],
			);

			licenseDetail.licenseSummary[RN_CONTAINER] = {
				...licenseDetail.licenseSummary[RN_CONTAINER],
				licenseType: sharedMuRnLicenseType || rnLicenseType || siteLicenseType,
				active: isLicenseActive(licenseExpiration),
				expiration: licenseExpiration || '',
				size: parseInt(rnSize),
				features: features,
				sites: sites,
				serviceLocationScope: scope === 'worldwide' ? 'Worldwide' : 'Local',
				serviceLocationSize: isNaN(parseInt(serviceLocationSize)) ? Infinity : parseInt(serviceLocationSize),
			};
			if (!Pan.isEmpty(sites)) {
				licenseDetail.licenseSummary[MU_CONTAINER] = {
					...licenseDetail.licenseSummary[MU_CONTAINER],
					licenseType: siteLicenseType,
					active: isLicenseActive(licenseExpiration),
					expiration: licenseExpiration || '',
					features: features,
					serviceLocationScope: scope === 'worldwide' ? _T('Worldwide') : _T('Local'),
					serviceLocationSize: isNaN(parseInt(serviceLocationSize))
						? Infinity
						: parseInt(serviceLocationSize),
				};

				licenseDetail.licenseSummary[MU_EP_CONTAINER] = {
					...licenseDetail.licenseSummary[MU_EP_CONTAINER],
					licenseType: siteLicenseType,
					active: isLicenseActive(licenseExpiration),
					expiration: licenseExpiration || '',
					features: features,
					serviceLocationScope: scope === 'worldwide' ? _T('Worldwide') : _T('Local'),
					serviceLocationSize: getEPServiceLocationSize(serviceLocationSize, licenseDetail.licenseSummary),
				};
			}

			licenseDetail.licenseSummary.isInterConnectEnabled = Boolean(netInterconnect);
		}

		if (!Pan.isEmpty(serviceConnectionsDetail)) {
			const {
				private_app_access: {
					enabled_for: privateAppAccessEnabledFor = {},
					single_tenant: { size: privateAppAccessSize = 0 } = {},
				} = {},
				private_app_access: privateAppAccess,
				service_location: { single_tenant: { scope = {}, size: serviceLocationSize = 0 } = {} } = {},
			} = serviceConnectionsDetail;

			licenseDetail.licenseSummary[SC_CONTAINER] = {
				...licenseDetail.licenseSummary[SC_CONTAINER],
				active: isLicenseActive(licenseExpiration),
				expiration: licenseExpiration || '',
				size: isNaN(parseInt(privateAppAccessSize)) ? Infinity : parseInt(privateAppAccessSize),
				serviceLocationScope: scope === 'worldwide' ? _T('Worldwide') : _T('Local'),
				serviceLocationSize: isNaN(parseInt(serviceLocationSize)) ? Infinity : parseInt(serviceLocationSize),
			};

			if (privateAppAccess && privateAppAccessEnabledFor.length > 0) {
				const isMuEnabled = privateAppAccessEnabledFor.indexOf('mobile_users') >= 0;
				const isRnEnabled = privateAppAccessEnabledFor.indexOf('remote_networks') >= 0;

				licenseDetail.licenseSummary.privateAppAccess = {
					privateAppAccessEnabled: true,
					privateAppAccessSize,
					enabledForBothMuRn: isMuEnabled && isRnEnabled,
				};
				if (isMuEnabled) {
					licenseDetail.licenseSummary[MU_CONTAINER].privateAppAccess = {
						privateAppAccessEnabled: true,
						privateAppAccessSize,
					};
				}
				if (isRnEnabled) {
					licenseDetail.licenseSummary[RN_CONTAINER].privateAppAccess = {
						privateAppAccessEnabled: true,
						privateAppAccessSize,
					};
				}
			}
		}
		// console.info('licenseDetail', licenseDetail)
		return licenseDetail;
	}
};

export const getLicenseInfoFromRedux = () => {
	const licenseInfo = _.get(getStoreState(), 'main.licenseInfo');
	// ADI-8888 Default to sparky store's licenseInfo. In case when it render the Left-Nav
	// fawkes store's license info is not ready. So license validation won't work for nav-node
	const isFawkesStoreLicenseInfoExisted = !Pan.isEmpty(licenseInfo);

	//to decouple
	const fawkesMain = getFawkesMainFromSparkyStore();

	return isFawkesStoreLicenseInfoExisted ? licenseInfo : mapLicenseInfoData(fawkesMain.licenseInfo);
};

export const isNewLicense = () => {
	const licenseInfo = getLicenseInfoFromRedux();
	return licenseInfo.licenseInfoLoad ? licenseInfo.licenseSummary.isNewLicense : false;
};

export const isLicenseEnabled = (location: any) => {
	const licenseInfo = getLicenseInfoFromRedux();
	return licenseInfo.licenseInfoLoad ? _.get(licenseInfo, `licenseSummary.${location}.active`) : false;
};

export const hasSiteLicense = () => {
	const licenseInfo = getLicenseInfoFromRedux();
	const rnLicenseInfo = _.get(
		getStoreState(),
		`config.${RN_CONTAINER}.remoteNetworksLicense.operational_license`,
		'',
	);
	return rnLicenseInfo
		? rnLicenseInfo === SITE
		: licenseInfo.licenseInfoLoad
		? !Pan.isEmpty(_.get(licenseInfo, `licenseSummary.${RN_CONTAINER}.sites`))
		: false;
};

export const getRNLicenseModel = () => {
	const rnLicenseModel = _.get(getStoreState(), `config.${RN_CONTAINER}.remoteNetworksLicense.license_model`, []);
	return rnLicenseModel;
};

export const getSitesFromRN = () => {
	const licenseInfo = getLicenseInfoFromRedux();
	return licenseInfo.licenseInfoLoad ? _.get(licenseInfo, `licenseSummary.${RN_CONTAINER}.sites`, []) : [];
};

export const getLicenseExpiration = (location?: any) => {
	const licenseInfo = getLicenseInfoFromRedux();

	if (!location) {
		return licenseInfo.licenseInfoLoad ? licenseInfo.license_expiration : '';
	}

	return licenseInfo.licenseInfoLoad ? _.get(licenseInfo, `licenseSummary.${location}.expiration`) : '';
};

export const getLicenseIssueDate = () => {
	const licenseInfo = getLicenseInfoFromRedux();
	return licenseInfo.association && licenseInfo.association.issue_date ? licenseInfo.association.issue_date : '';
};

export const getLicenseSupportSize = (location: any) => {
	const licenseInfo = getLicenseInfoFromRedux();
	return licenseInfo.licenseInfoLoad ? _.get(licenseInfo, `licenseSummary.${location}.size`) : 0;
};

export const getMUContainerAllocation = (container: any) => {
	const totalAllocation = getLicenseSupportSize(MU_CONTAINER);
	const epAllocation =
		_.get(getStoreState(), `config.${MU_CONTAINER}.Mobile Users Explicit Proxy Users.result.users`) || 0;

	if (container === MU_GP_CONTAINER) {
		return parseInt(totalAllocation) - parseInt(epAllocation);
	}
	return epAllocation;
};

export const getLicenseType = (location?: any) => {
	const licenseInfo = getLicenseInfoFromRedux();

	if (!location) {
		return licenseInfo.licenseInfoLoad ? licenseInfo.licenseSummary.sharedMuRnLicenseType : '';
	}

	return licenseInfo.licenseInfoLoad ? _.get(licenseInfo, `licenseSummary.${location}.licenseType`) : '';
};

export const getLicenseCategorySet = () => {
	const licenseInfo = getLicenseInfoFromRedux();
	return licenseInfo.licenseInfoLoad ? licenseInfo.licenseSummary.categorySet : new Set();
};

export const getSharedLicenseType = () => {
	const licenseInfo = getLicenseInfoFromRedux();
	return licenseInfo.licenseInfoLoad ? licenseInfo.licenseSummary.sharedMuRnLicenseType : '';
};

export const getLicenseFeatures = (location: any) => {
	const licenseInfo = getLicenseInfoFromRedux();

	if (!location) {
		return licenseInfo.licenseInfoLoad ? _.get(licenseInfo, 'licenseSummary.features', []) : [];
	}

	return licenseInfo.licenseInfoLoad ? _.get(licenseInfo, `licenseSummary.${location}.features`, []) : [];
};

export const getAddonLicense = (type: string) => {
	const licenseInfo = getLicenseInfoFromRedux();
	return licenseInfo.licenseInfoLoad ? _.get(licenseInfo, `licenseSummary.addonLicenses.${type}`) : null;
};

export const getAllServiceBasedAddonLicensesKey = () => {
	const licenseInfo = getLicenseInfoFromRedux();
	return licenseInfo.licenseInfoLoad ? Object.keys(licenseInfo.licenseSummary.serviceBasedAddonLicense) : [];
};

export const getServiceBasedAddonLicense = (type: string) => {
	const licenseInfo = getLicenseInfoFromRedux();
	return licenseInfo.licenseInfoLoad ? _.get(licenseInfo, `licenseSummary.serviceBasedAddonLicense.${type}`) : null;
};

export const isAddonLicenseEnabledByAppId = (appId: string) => {
	const licenseInfo = getLicenseInfoFromRedux();
	if (!licenseInfo.licenseInfoLoad) {
		return false;
	} else {
		const services = _.get(licenseInfo, `licenseSummary.services`) || [];
		return services.indexOf(appId) >= 0;
	}
};

export const getAddonLicenseByAppId = (appId: string) => {
	const licenseInfo = getLicenseInfoFromRedux();
	if (!licenseInfo.licenseInfoLoad) {
		return null;
	} else {
		const addons = _.get(licenseInfo, `licenseSummary.addons`, []);
		const matched = addons.filter((addon: any) => addon['app_id'] === appId);
		return matched[0];
	}
};
export const isAddonEnabledByAppId = (appId: string) => {
	const licenseInfo = getLicenseInfoFromRedux();
	if (!licenseInfo.licenseInfoLoad) {
		return null;
	} else {
		const addons = _.get(licenseInfo, `licenseSummary.addons`, []);
		const matched = addons.filter((addon: any) => addon['app_id'] === appId);
		return matched.length > 0;
	}
};

export const getBaseLicenseEnabledByAppId = (appId: string) => {
	const licenseInfo = getLicenseInfoFromRedux();
	if (!licenseInfo.licenseInfoLoad) {
		return [];
	} else {
		const baseLicenses = _.get(licenseInfo, `entitlements.base_editions`, []);
		const matched = baseLicenses.filter((addon: any) => addon['app_id'] === appId);
		return matched[0];
	}
};

export const isBaseLicenseEnabledByAppId = (appId: string) => {
	return !Pan.isEmpty(getBaseLicenseEnabledByAppId(appId));
};

export const getLicenseSupportServiceLocationScope = (location: any) => {
	const licenseInfo = getLicenseInfoFromRedux();

	if (!location) {
		return licenseInfo.licenseInfoLoad ? licenseInfo.licenseSummary[MU_CONTAINER].serviceLocationScope : '';
	}

	return licenseInfo.licenseInfoLoad ? _.get(licenseInfo, `licenseSummary.${location}.serviceLocationScope`) : '';
};

export const getLicenseSupportServiceLocationSize = (location: any) => {
	const licenseInfo = getLicenseInfoFromRedux();

	if (!location) {
		return licenseInfo.licenseInfoLoad ? licenseInfo.licenseSummary[MU_CONTAINER].serviceLocationSize : Infinity;
	}

	return licenseInfo.licenseInfoLoad
		? _.get(licenseInfo, `licenseSummary.${location}.serviceLocationSize`)
		: Infinity;
};

export const getComplianceInfoFromRedux = () => {
	const {
		main: { compliance },
	}: any = getStoreState();

	return compliance;
};

export const getComplianceInfo = (location: string) => {
	const compliance = getComplianceInfoFromRedux();
	return _.get(compliance, `license-compliance.${location}`) || {};
};

export const isLocationCompliant = (location: string) => {
	const compliance = getComplianceInfoFromRedux();
	const complianceInfo = _.get(compliance, `license-compliance.${location}`); //empty complianceInfo means location is compliant
	return compliance && (!complianceInfo || LICENSE_CHECK_LOCATIONS.indexOf(location) === -1);
};

export const isEverythingCompliant = () => {
	const compliance = getComplianceInfoFromRedux();
	const complianceStatus = _.get(compliance, 'license-compliance.@status');
	return complianceStatus !== 'no';
};

export const getLastCompliantCheckTimeStamp = () => {
	const compliance = getComplianceInfoFromRedux();
	const lastCompliantCheckTimeStamp = _.get(compliance, 'license-compliance.@timestamp', '');
	return lastCompliantCheckTimeStamp;
};

export const formatCompliance = (compliance: any) => {
	if (compliance) {
		const complianceEntry = _.get(compliance, 'license-compliance.entry');
		if (compliance && complianceEntry) {
			complianceEntry.forEach((entry: any) => {
				compliance['license-compliance'][entry['@name']] = entry;
			});
		}
	}
	return compliance;
};

export const isPrivateAppAccessEnabled = (location: string) => {
	const licenseInfo = getLicenseInfoFromRedux();

	if (!location) {
		return (
			Boolean(getAddonLicenseByAppId(UNLIMITED_PRIVATE_APP_ACCESS_ADD_ON)) ||
			Boolean(licenseInfo.licenseInfoLoad && licenseInfo.licenseSummary.privateAppAccess)
		);
	}

	return Boolean(licenseInfo.licenseInfoLoad && _.get(licenseInfo, `licenseSummary.${location}.privateAppAccess`));
};

export const isPrivateAppAccessEnabledForBothMuRn = () => {
	const licenseInfo = getLicenseInfoFromRedux();

	return Boolean(
		licenseInfo.licenseInfoLoad &&
			licenseInfo.licenseSummary.privateAppAccess &&
			licenseInfo.licenseSummary.privateAppAccess.enabledForBothMuRn,
	);
};

export const getPrivateAppAccessSize = (location?: string) => {
	const licenseInfo = getLicenseInfoFromRedux();

	if (!location) {
		return licenseInfo.licenseInfoLoad && licenseInfo.licenseSummary.privateAppAccess
			? licenseInfo.licenseSummary.privateAppAccess.privateAppAccessSize
			: 0;
	}

	return _.get(licenseInfo, `licenseSummary.${location}.privateAppAccess.privateAppAccessSize`) || 0;
};

export const isNetInterconnectEnabled = () => {
	const licenseInfo = getLicenseInfoFromRedux();
	return licenseInfo.licenseInfoLoad && licenseInfo.licenseSummary.isInterConnectEnabled;
};

export const isInternalLicenseType = (location: string) => {
	const licenseType = getLicenseType(location);
	return ['LAB-MU-RN', 'SE-LCL-MU-RN'].indexOf(licenseType) >= 0;
};

export const isAddonLicenseEnabled = (addonLicenseKeys: any) => {
	let addonLicense = null;
	const addonLicenseKeysArray = addonLicenseKeys || [];
	addonLicenseKeysArray.forEach((type: string) => {
		const licenseDetail = getAddonLicense(type);
		if (licenseDetail) {
			addonLicense = licenseDetail;
		}
	});

	return addonLicense ? true : false;
};

// This is needed only to show/hide Secure Inbound Tab based on old license
export const isRnSkuIntLicenseType = (location: string) => {
	const licenseInfo = getLicenseInfoFromRedux();
	const rnSKU = _.get(licenseInfo, `licenseSummary.${location}.rnSKU`) || '';

	return internalLicenseTypes.indexOf(rnSKU) >= 0;
};

export const isNgPA = () => {
	return _.get(getStoreState(), 'main.loggedInUser.tenant.is_nextgenpa', false);
};

export const isScNatUpdate = () => {
	const features = _.get(getStoreState(), 'main.featuresInfo', []);
	const isScNatUpdate = features.find(
		(feature: { name: string; toggle: 0 | 1 }) => feature?.name === 'isScNatUpdate',
	);
	return _.get(isScNatUpdate, 'toggle', false);
};

export const isInfraScNatUpdate = () => {
	const features = _.get(getStoreState(), 'main.featuresInfo', []);
	const isInfraScNatUpdate = features.find(
		(feature: { name: string; toggle: 0 | 1 }) => feature?.name === 'infra-sc-nat',
	);
	return _.get(isInfraScNatUpdate, 'toggle', false);
};

export const isPreservePrenatEnabled = () => {
	const features = _.get(getStoreState(), 'main.featuresInfo', []);
	const isPreservePrenatEnabled = features.find(
		(feature: { name: string; toggle: 0 | 1 }) => feature?.name === 'sc-nat',
	);
	return _.get(isPreservePrenatEnabled, 'toggle', false);
};

export const isScUserIdEnabled = () => {
	const features = _.get(getStoreState(), 'main.featuresInfo', []);
	const isScUserIdEnabled = features.find(
		(feature: { name: string; toggle: 0 | 1 }) => feature?.name === 'sc-userid-redis',
	);
	return _.get(isScUserIdEnabled, 'toggle', false);
};

export const isGlobalFindEnabled = () => {
	const features = _.get(getStoreState(), 'main.featuresInfo', []);
	const isGlobalFindEnabled = features.find(
		(feature: { name: string; toggle: 0 | 1 }) => feature?.name === 'global-find',
	);
	return _.get(isGlobalFindEnabled, 'toggle', false);
};

export const isPaDDNSEnabled = () => {
	let featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	const isFeatureEnabled = checkIsFeatureEnabled(featuresInfo, 'ddns_feature_flag');
	return isFeatureEnabled;
};

export const isPrismaAccessBrowserEnabled = () => {
	return (
		isBaseLicenseEnabledByAppId(PRISMA_ACCESS_BROSWER_LICENSE_APP_ID) ||
		isAddonEnabledByAppId(PRISMA_ACCESS_BROSWER_LICENSE_APP_ID)
	);
};

export const isTalonLicenseActive = () => {
	const talonBaseLicenses = getBaseLicenseEnabledByAppId(PRISMA_ACCESS_BROSWER_LICENSE_APP_ID);
	const baseLicenseExpiration = talonBaseLicenses?.license_expiration;

	const talonAddonLicenses = getAddonLicenseByAppId(PRISMA_ACCESS_BROSWER_LICENSE_APP_ID);
	const addonLicenseExpiration = talonAddonLicenses?.license_expiration;
	return isLicenseActive(baseLicenseExpiration) || isLicenseActive(addonLicenseExpiration);
};

export const isDLPLicenseEnabled = () => {
	return isAddonLicenseEnabled(DLP_ADDON_LICENSE_TYPES) || isAddonLicenseEnabledByAppId(DLP_ADDON_LICENSE_APP_ID);
};

export const isEPDLPLicenseEnabled = () => {
	return isAddonLicenseEnabled(EPDLP_ADDON_LICENSE_TYPES);
};

export const isDLPInstanceEnabled = () => {
	const main = _.get(getStoreState(), 'main');
	const DLPInstance = _.get(main, 'loggedInUser.tenant.info.tsgInstances', []).filter(
		(item: { app_id: string }) => item?.app_id === 'dlp',
	);
	return DLPInstance.length > 0;
};

export const isSaaSInlineLicenseEnabled = () => {
	const main = _.get(getStoreState(), 'main');
	const SaasInlineInstance = _.get(main, 'loggedInUser.tenant.info.tsgInstances', []).filter(
		(item: { app_id: string }) => item?.app_id === 'aperture',
	);

	return isAddonLicenseEnabledByAppId(SAAS_INLINE_ADDON_LICENSE_APP_ID) || SaasInlineInstance.length > 0;
};

export const isAIAccessLicenseEnabled = () => {
	const main = _.get(getStoreState(), 'main');
	const NG_CASB = _.get(main, 'loggedInUser.tenant.info.tsgInstances', []).filter(
		(item: { app_id: string; entitlements: any }) =>
			item?.app_id === 'ng_casb' && item.entitlements && Pan.isArray(item.entitlements),
	);
	if (NG_CASB?.length > 0) {
		const isEnabled = NG_CASB.some((instance: any) => {
			return instance.entitlements.some((ele) => aiAccessLicenseType.includes(ele['license_type']));
		});
		return isEnabled;
	} else {
		return false;
	}
};

export const isCASBXLicenseEnabled = () => {
	const main = _.get(getStoreState(), 'main');
	const NG_CASB = _.get(main, 'loggedInUser.tenant.info.tsgInstances', []).filter(
		(item: { app_id: string; entitlements: any }) =>
			item?.app_id === 'ng_casb' && item.entitlements && Pan.isArray(item.entitlements),
	);
	if (NG_CASB?.length > 0) {
		const isEnabled = NG_CASB.some((instance: any) => {
			return instance.entitlements.some((ele) => ele['license_type'] === 'CASBX');
		});
		return isEnabled;
	} else {
		return false;
	}
};
export const isTrafficReplicationLicenseEnabled = () => {
	return isAddonLicenseEnabledByAppId(TRAFFIC_REPLICATION_ADDON_LICENSE_APP_ID);
};

export const isNG_CASBLicenseEnabled = () => {
	const main = _.get(getStoreState(), 'main');
	const isNG_CASB = _.get(main, 'loggedInUser.tenant.info.tsgInstances', []).filter(
		(item: { app_id: string }) => item?.app_id === 'ng_casb',
	);
	return isAddonEnabledByAppId(NG_CASB_X_LICENSE_BUNDLE_APP_ID) || isNG_CASB?.length > 0;
};

export const isPBAEnabled = () => {
	const isPBA = _.get(getStoreState(), 'main.licenseInfo.is_pba_enabled', false);
	return isPBA;
};

/**
 * License check for Saas Inline: AHL includes 4 cases
 * 1. CASBX add on license
 * 2. PA + Saas Inline bundled license
 * 3. PA + CASB bundled license - app_id = "aperture", accommodated in 2nd case
 * 4. Saas Inline add on license - app_id = "aperture", accommodated in 2nd case
 * @returns boolean if any of the cases is true
 */
export const isSaasInlineAHLLicenseEnabled = () => {
	return isNG_CASBLicenseEnabled() || isSaaSInlineLicenseEnabled();
};

export const isTenantWithCDL = () => {
	const instances = getAuthState()?.instances;
	for (const tsgInstance of instances) {
		if (LOGGING_SERVICE_EXTENSION_NAMES.includes(tsgInstance.app_id)) {
			return true;
		}
	}
	return false;
};

export const isDLPEnabled = () => {
	return (isNewLicense() && isDLPLicenseEnabled()) || isDLPInstanceEnabled() || isNG_CASBLicenseEnabled();
};
// function getPosition(string: string, subString: string, index: number): number {
//   return string && string.split(subString, index).join(subString).length || -1;
// }

export const isNextSchemaEnabled = () => {
	//to decouple
	let featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	if (Pan.isEmpty(_.get(getStoreState(), 'main.featuresInfo', []))) {
		const fawkesMain = getFawkesMainFromSparkyStore();
		featuresInfo = fawkesMain?.featuresInfo;
	}
	return checkIsFeatureEnabled(featuresInfo, 'next-schema');
};

export const isRbiByPanLicenseEnabled = () => {
	const isEnabled = isAddonEnabledByAppId('rbi');
	return isEnabled;
};

export const isRbiCDREnabled = () => {
	let featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, 'remote_browser_isolation_cdr');
};

export const isRbiByPanEnabled = () => {
	//to decouple
	const fawkesMain = getFawkesMainFromSparkyStore();
	const configLocationName =
		_.get(getStoreState(), 'main.configLocation.container.name') ||
		_.get(fawkesMain, 'configLocation.container.name');
	const configLocationType =
		_.get(getStoreState(), 'main.configLocation.container.type') ||
		_.get(fawkesMain, 'configLocation.container.type');
	const featuresInfo = getFeaturesInfoFromStore();
	const isPermissionEnabled = isViewPermissionEnabled(getRBAPermission('rbi'));
	const isFfEnabled = checkIsFeatureEnabled(featuresInfo, 'remote_browser_isolation');
	const isValidLoc =
		(getLocationReverseKeyMap()[configLocationName] ? true : false) ||
		configLocationName === ALL ||
		configLocationType === SNIPPET;
	return isFfEnabled && isPermissionEnabled && isValidLoc;
};

export const getProfileName = (node: string) => {
	const startPos: number = node.lastIndexOf('@name=') + 6;
	const endPos: number = node.lastIndexOf(']') - 1;
	return node && startPos > 0 && endPos > 0 ? node.substring(startPos, endPos) : '';
};

export const getProfileNameFromPG = (node: string) => {
	const startPos: number = node.lastIndexOf('text()=') + 8;
	const endPos: number = node.lastIndexOf(']') - 1;
	return node && startPos > 0 && endPos > 0 ? node.substring(startPos, endPos) : '';
};

export const getLicenseComplianceGridData = (licenseComplianceInfo: any) => {
	const info = _.get(licenseComplianceInfo, 'license-compliance.entry') || [];
	const gridData: any[] = [];
	info.map((info: any) => {
		const errors = _.get(info, 'feature-compliance-errors.entry') || [];
		errors.forEach((error: any) => {
			Object.values(error).forEach((err: any) => {
				let obj = {
					__KEY__: generateGridRecId(),
					name: '',
					location: '',
					profileType: error['@name'],
				};
				obj.location = info['@name'];
				const profileName = !err.includes('/profile-group') ? getProfileName(err) : getProfileNameFromPG(err);
				obj.name = profileName;
				if (!Pan.isEmpty(profileName)) {
					gridData.push(obj);
				}
			});
		});
		return null;
	});
	return gridData;
};
export function isJupiterEnabled() {
	if (isPBAEnabled()) return true;
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, 'ztna_agent');
}

export function isOcspTrafficOutInternetEnabled() {
	const featuresInfo = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(featuresInfo, 'ocsp_traffic_out_internet');
}

export const getLocationReverseKeyMap = () => {
	const LOCATION_REVERSE_KEY_MAP: any = {
		[PRISMA_ACCESS_CONTAINER]: PRISMA_ACCESS_KEY,
		[MU_CONTAINER]: MU_KEY,
		[RN_CONTAINER]: RN_KEY,
		[SC_CONTAINER]: SC_KEY,
		[MU_GP_CONTAINER]: isJupiterEnabled() ? MU_JP_KEY : MU_GP_KEY,
		//[MU_JP_CONTAINER]:  MU_JP_KEY,
		[MU_EP_CONTAINER]: MU_EP_KEY,
		[SWG_CONTAINER_TYPE]: SWG_CONTAINER,
	};
	return LOCATION_REVERSE_KEY_MAP;
};
export const getLocationReverseKeyMapSwgDisabled = () => {
	const LOCATION_REVERSE_KEY_MAP_SWG_DISABLED = {
		[PRISMA_ACCESS_CONTAINER]: PRISMA_ACCESS_KEY,
		[MU_CONTAINER]: MU_KEY,
		[RN_CONTAINER]: RN_KEY,
		[SC_CONTAINER]: SC_KEY,
		[MU_GP_CONTAINER]: isJupiterEnabled() ? MU_JP_KEY : MU_GP_KEY,
		// [MU_JP_CONTAINER]: isJupiterEnabled()? MU_JP_KEY:MU_GP_KEY,
		[MU_EP_CONTAINER]: MU_EP_KEY,
		[SWG_CONTAINER_TYPE]: SWG_CONTAINER,
	};
	return LOCATION_REVERSE_KEY_MAP_SWG_DISABLED;
};
export const getProfileURLMapping = (profileType: string): string => {
	const profileUrlMapping: any = {
		'security-policy': 'security-services/security',
		// confirmed
		'anti-spyware': 'anti-spyware',
		'vulnerability-protection': 'vulnerability-protection',
		'wildfire-and-antivirus': 'wildfire-and-antivirus',
		'dns-security': 'dns-security',
		'url-access-management': 'url-access-management',
		'file-blocking': 'file-blocking',
		'http-header-insertion': 'http-header-insertion',
		'profile-groups': 'security-profile-groups',
		decryption: 'security-services/decryption',
		// confirmed
		'url-filtering': 'url-access-management',
	};
	return profileUrlMapping[profileType] || '';
};

export const getContainer = (container: string): string => {
	const containerMapping: any = {
		'Mobile Users Container': 'mobile-users',
		'Prisma Access': 'prisma-access',
		'Mobile Users': isJupiterEnabled() ? 'mobile-users-jp' : 'mobile-users-gp',
		'Mobile Users Explicit Proxy': 'mobile-users-ep',
		'Remote Networks': 'remote-networks',
		'Service Connections': 'service-connections',
	};
	return containerMapping[container] || '';
};

export const getContainerDisplayName = (container: string): string => {
	const containerMapping: any = {
		'Mobile Users Container': 'Mobile Users',
		'Prisma Access': 'Shared',
		'Mobile Users': isJupiterEnabled() ? 'Access Agent' : 'GlobalProtect',
		'Mobile Users Explicit Proxy': 'Explicit Proxy',
		'Remote Networks': 'Remote Networks',
		'Service Connections': 'Service Connections',
	};
	return containerMapping[container] || '';
};

export const isPrismaSdwanDCEnabled = () => {
	const features = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(features, 'prisma_sdwan_dc');
};

export const isAiRsPhase2Enabled = () => {
	const features = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(features, 'ai-rs-phase2');
};

export const isAiRsQ3Enabled = () => {
	const features = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(features, 'ai-rs-q3');
};

export const isSnippetSharingEnabled = () => {
	const features = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(features, 'snippet-sharing');
};

export const isNGPAIpv6Enabled = () => {
	const features = _.get(getStoreState(), 'main.featuresInfo', []);
	return checkIsFeatureEnabled(features, 'ngpa_ipv6_feature_flag');
};
