import { PAGINATION_QUERY_PARAM } from 'src/containers/main/constants';
import { getAuthHeaders } from 'ui-lib/auth/lib';
import { getRedirectURL, isMockService } from 'ui-lib/core/services/CommonServices';

export function createScopeObservable(data: { name: string; folders?: string[]; snippets?: string[] }) {
	const url = `${getRedirectURL()}/api/sase/config/v1/scopes`;
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url,
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getAllScopesObservable() {
	const url = `${getRedirectURL()}/api/sase/config/v1/scopes?${PAGINATION_QUERY_PARAM}`;
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function deleteScopeObservable(id: string) {
	const url = `${getRedirectURL()}/api/sase/config/v1/scopes/${id}`;
	return {
		withCredentials: isMockService() ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function updateScopeObservable(
	data: {
		name: string;
		folders?: string[];
		snippets?: string[];
	},
	id: string,
) {
	const url = `${getRedirectURL()}/api/sase/config/v1/scopes/${id}`;
	return {
		withCredentials: isMockService() ? false : true,
		method: 'PUT',
		responseType: 'json',
		url,
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function cloneScopeObservable(id: string) {
	const url = `${getRedirectURL()}/api/sase/config/v1/scopes/${id}/clone`;
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url,
		body: {},
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getScopeParamFromType(scopeType: string | undefined) {
	let scopeParam = '';
	switch (scopeType) {
		case 'snippet':
			scopeParam = 'snippet';
			break;
		case 'on-prem':
			scopeParam = 'device';
			break;
		default:
			scopeParam = 'folder';
	}

	return scopeParam;
}
