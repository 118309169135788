import { get, isEmpty, isEqual } from 'lodash';
import { ConflictsRowObject } from 'src/containers/localConfig/components/LocalConflictsGrid';
import {
	LocalConfigDevicesFetchResponse,
	LocalConfigFoldersFetchResponse,
} from 'src/containers/localConfig/formatters/LocalConfigApiResponseFormatter';
import Pan from 'src/ui-lib/core/autorender/schema/Pan';
// eslint-disable-next-line import/no-cycle
import { DeviceConflictsRowObject } from 'src/containers/localConfig/components/LocalConflictsDevicesGrid';
import { getStoreState } from 'ui-lib/core/utils/storeRegistry';
import { LOCAL_CONFIG_INCLUDE_SET } from 'src/containers/localConfig/constants';
import { FAWKES } from 'src/typings/type';

export const convertAPIResponseToLocalConflictsGridComponentProps = (
	fireWallConfigConflicts: LocalConfigFoldersFetchResponse,
): Array<ConflictsRowObject> => {
	const { firewall_conflicts } = fireWallConfigConflicts;
	if (!firewall_conflicts || !Array.isArray(firewall_conflicts)) {
		return [];
	}

	return firewall_conflicts.map((elem) => {
		return {
			name: elem.name,
			serialNumber: elem.name,
			num_conflicts: elem.num_conflicts,
		} as ConflictsRowObject;
	});
};

export const convertAPIResponseToPrunedObjectsGridComponentProps = (
	gridData,
	isPruned = 1,
): Array<DeviceConflictsRowObject> => {
	return gridData
		.filter((item) => item?.isPruned === isPruned)
		.map((elem) => {
			return {
				name: elem.name,
				xpath_key: elem.xpath,
				id: elem.name + Pan.id(),
				conflict_type: elem.conflict_type,
				isPruned: elem?.isPruned,
				prunedReason: elem?.prunedReason,
			};
		});
};

export const convertAPIResponseToLocalConflictDevicesGridComponentProps = (
	fireWallConfigConflicts: LocalConfigDevicesFetchResponse,
): Array<DeviceConflictsRowObject> => {
	const { all_conflicts } = fireWallConfigConflicts;
	if (!all_conflicts || !Array.isArray(all_conflicts)) {
		return [];
	}
	return all_conflicts.map((elem) => {
		return {
			name: elem.name,
			xpath_key: elem.xpath_key,
			id: elem.name + Pan.id(),
			conflict_type: elem.conflict_type,
		} as DeviceConflictsRowObject;
	});
};

export const isAPILocalConfigSensitive = (serviceName: string) => {
	return LOCAL_CONFIG_INCLUDE_SET.has(serviceName);
};

export const isObjectLocal = (record: any) => {
	return (
		// special case for AuthenticaionProfile responses. ¯\(º_o)/¯
		get(record, 'data.@local') === 'yes' ||
		get(record, 'data.local') ||
		get(record, 'local') === true ||
		get(record, '@local') === 'yes' ||
		get(record, 'ap.@local') === 'yes'
	);
};

export const isOverriding = (params) => {
	return (
		get(params, 'data.@override') === 'yes' ||
		get(params, 'data.override') === true ||
		get(params, 'data.override_id') !== undefined ||
		get(params, 'override') === 'yes' ||
		get(params, 'override') === true ||
		get(params, '@override') === 'yes' ||
		get(params, '@override-uuid') !== undefined ||
		get(params, 'override_id') !== undefined
	);
};

export const isNotOverriding = (params) => {
	// attempting to combing this function and the one above via negation is a bit error-prone because of JS casting string to boolean values,
	// so we'll just define two functions separately here
	return get(params, '@override') === 'no' || get(params, 'override') === false;
};

export const isLocalObjectConflicting = (params: any) => {
	// there is a conflict if and only if the record is from local device, and it overrides stuff from scm
	return isObjectLocal(params) && isOverriding(params);
};

export const isObjectSelectable = (params: FAWKES.I_OBJECT): boolean => {
	return !isObjectLocal(params) || isOverriding(params);
};

export const isRecordTypeDeviceGroup = (record: Record<string, unknown>) => {
	const recordType =
		get(record, 'data.@local-object-type', '') ||
		get(record, '@local-object-type', '') ||
		get(record, 'data.local_object_type') ||
		get(record, 'local_object_type');
	return recordType === 'dg'; // dg stands for 'device-group'
};

export const disableActionsForLocalConfigObject = (record: any): boolean => {
	if (isObjectLocal(record)) {
		if (isNotOverriding(record)) {
			return false;
		}
		const overrideLoc = get(record, 'override_loc') || get(record, '@override-loc');
		const name = get(getStoreState(), 'main.configLocation.container.name', '');
		if (overrideLoc !== name) {
			return true;
		}
	}
	return false;
};

export const getRecordToRender = ({ cloudConfigFormDataRecord, localConfigDeviceRecord, showLocalDeviceConfig }) => {
	const isCloudRecordPresent = !isEmpty(cloudConfigFormDataRecord);
	const isLocalRecordPresent = !isEmpty(localConfigDeviceRecord);
	let shouldRenderLocalDeviceConfig = false;
	if (isCloudRecordPresent && isLocalRecordPresent) {
		// if both records are present, render based on `showLocalDeviceConfig`
		shouldRenderLocalDeviceConfig = showLocalDeviceConfig;
	} else if (!isLocalRecordPresent) {
		// if localrecord is not present, don't try to render the local config.
		shouldRenderLocalDeviceConfig = false;
	} else {
		// if we are here, then it means that only local record exists, and the cloud record is empty.
		// Render the local record
		shouldRenderLocalDeviceConfig = true;
	}
	const recordToRender = shouldRenderLocalDeviceConfig ? localConfigDeviceRecord : cloudConfigFormDataRecord;
	return {
		isRenderingLocalRecord: shouldRenderLocalDeviceConfig,
		recordToRender: recordToRender,
	};
};

export const getFLORecordToRender = ({ cloudConfigFormDataRecord, localConfigDeviceRecord, showLocalDeviceConfig }) => {
	const recordToRender = showLocalDeviceConfig ? localConfigDeviceRecord : cloudConfigFormDataRecord;
	return {
		isRenderingLocalRecord: showLocalDeviceConfig,
		recordToRender: recordToRender,
	};
};

export const isDiffPresent = ({ cloudRecord, deviceRecord }) => {
	const diffExists = !isEqual(cloudRecord, deviceRecord);
	if (!diffExists || isEmpty(deviceRecord) || isEmpty(cloudRecord)) {
		return false;
	}
	return true;
};
