import _ from 'lodash';
import { getScopeParamFromType } from 'service-lib/services/scopeServices';
import { FAWKES } from 'src/typings/type';
import { getAuthHeaders } from 'ui-lib/auth/lib';
import { isMockService } from 'ui-lib/core/services/CommonServices';
import { CustomCheck } from 'src/containers/custom-check/model/types';
import { getAuthState, getFrameworkServiceUrl } from '@sparky/framework';
import { isSnippetScope } from 'ui-lib/utils';
import { getDeviceContainerParentLocation } from 'src/utils/utils';
import { getStoreState } from 'src/ui-lib/core/utils/storeRegistry';
import { IPSEC_CRYPTO_PROFILE } from 'src/containers/common/reduxConstants';

let baseUrl: string;
export const getBaseUrl = () => {
	if (!baseUrl) {
		const paRegion = getAuthState()?.instances?.get('prisma_access')?.region;
		const spiffyUrl = getFrameworkServiceUrl('spiffy', { region: paRegion });

		baseUrl = spiffyUrl;
	}
	return baseUrl || '';
};

export const spiffyHeaders = (authToken: string) => {
	return {
		// 'x-tenant-id': cdlTenantId,
		// 'x-instance-id': tenantId,
		// 'x-aiops-tid': aiopsTenantId,
		'x-source-app': 'buckbeak',
		'x-auth-iamtoken': authToken,
	};
};

export function getBpaChecksObservable() {
	const authHeaders = getAuthHeaders();
	const getUrl = `${getBaseUrl()}/spiffy/v2/bp/metadata?platform=cloud_mgmt&mode=all`;
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: getUrl,
		headers: spiffyHeaders(authHeaders['x-auth-jwt']),
	};
}

export function getBpaChecksObservableByObjType(location: string, objectType: string) {
	const authHeaders = getAuthHeaders();
	const currentLocation = getDeviceContainerParentLocation();
	const getUrl = `${getBaseUrl()}/spiffy/v2/bp/metadata?platform=cloud_mgmt&mode=all&co_type=${objectType}&location=${currentLocation}`;
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: getUrl,
		headers: spiffyHeaders(authHeaders['x-auth-jwt']),
	};
}

export function getCustomCheckContentObservable() {
	const authHeaders = getAuthHeaders();
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${getBaseUrl()}/spiffy/v5/customcheckcontent`,
		headers: spiffyHeaders(authHeaders['x-auth-jwt']),
	};
}

export function getCustomCheckObservable(id: number) {
	const authHeaders = getAuthHeaders();
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${getBaseUrl()}/spiffy/v1/customcheck?id=${id}`, // detail page - custom
		headers: spiffyHeaders(authHeaders['x-auth-jwt']),
	};
}

export function saveCustomCheckObservable(customCheck: CustomCheck) {
	const authHeaders = getAuthHeaders();
	return {
		withCredentials: isMockService() ? false : true,
		method: 'PUT',
		responseType: 'json',
		url: `${getBaseUrl()}/spiffy/v1/customcheck`, // save/edit
		body: JSON.stringify(customCheck),
		headers: spiffyHeaders(authHeaders['x-auth-jwt']),
	};
}

export function deleteCustomCheckObservable(id: number) {
	const authHeaders = getAuthHeaders();
	return {
		withCredentials: isMockService() ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: `${getBaseUrl()}/spiffy/v1/customcheck?id=${id}`,
		headers: spiffyHeaders(authHeaders['x-auth-jwt']),
	};
}

//TODO find mapping for the commented out lines in the following map
const SpiffyServiceMap: Record<string, string> = {
	// "device/admin_role",
	// "device/administrator",
	// "device/authentication_profile",
	'Device/AuthenticationProfile': 'device/authentication_profile',
	'Device/RadiusProfiles': 'device/radius_server_profile',
	'Device/LDAPProfiles': 'device/ldap_server_profile',
	'Device/TACPlusProfiles': 'device/tacplus_server_profile',
	'Device/AuthenticationSequence': 'device/authentication_sequence',
	'Device/AuthenticationPortal': 'device/authentication_portal',
	'Device/CertificateManagement/CertificatesInfo': 'device/certificate',
	'Device/SSLTLSServiceProfiles': 'device/ssl_tls_service_profile',
	// "device/device_setup_authentication",
	// "device/device_setup_content_id",
	// "device/device_setup_general",
	// "device/device_setup_logging_reporting",
	'management-interface': 'device/device_setup_management_interface',
	// "device/device_setup_minimum_password_complexity",
	// "device/device_setup_policy_rulebase",
	// "device/device_setup_secure_communication",
	'global-service': 'device/device_setup_services',
	session: 'device/device_setup_session',
	'session-timeout': 'device/device_setup_session-timeouts',
	// "device/device_setup_telemetry",
	configByPath: 'device/device_setup_wildfire',
	dynamic_updates: 'device/dynamic_updates',
	// "device/folder",
	// "device/high_availability",
	// "device/ldap_server_profile",
	// "device/licenses",
	// "device/log_settings_config",
	// "device/log_settings_system",
	// "device/radius_server_profile",
	// "device/scheduled_config_export",
	// "device/snippet",
	// "device/snmp_trap_server_profile",
	// "device/software",
	// "device/support",
	// "device/syslog_server_profile",
	// "device/tacplus_server_profile",
	// "device/template",
	// "device/template_stack",
	// "device/user_id",
	// "device/virtual_systems",
	'Network/GlobalProtectGateways': 'network/global_protect_gateway',
	'Network/GlobalProtectPortals': 'network/global_protect_portal',
	// "network/gre_tunnel",
	'networks/interface-management-profiles': 'network/interface_management_profile',
	[IPSEC_CRYPTO_PROFILE]: 'network/ipsec_crypto_profile',
	'Network/Zones': 'network/zone',
	'zone-protection-profiles': 'network/zone_protection_profile',
	'Objects/AntiSpywareSecurityProfiles': 'objects/anti_spyware_profile',
	// "objects/antivirus_profile",
	'Objects/DecryptionProfiles': 'objects/decryption_profile',
	'dos-protection-profiles': 'objects/dos_protection_profile',
	'Objects/FileBlockingSecurityProfiles': 'objects/file_blocking_profile',
	'Objects/LogForwardingSecurityProfiles': 'objects/log_forwarding_profile',
	'Objects/URLFilteringSecurityProfiles': 'objects/url_filtering_profile',
	'Objects/VulnerabilityProtectionSecurityProfiles': 'objects/vulnerability_protection_profile',
	'Objects/WildFireAndAntivirusSecurityProfiles': 'objects/antivirus_wildfire_analysis_profile',
	'Objects/DnsSecurityProfiles': 'objects/dns_security_profile',
	'networks/log-setting-profiles': 'objects/log_forwarding_profile',
	'Policies/ApplicationOverrideRules': 'policies/app_override',
	'Policies/AuthenticationRules': 'policies/captive_portal_rule',
	'Policies/DecryptionRules': 'policies/decryption_rule',
	// "policies/decryption_rulebase",
	'Policies/DoSRules': 'policies/dos_protection_rule',
	'Policies/PolicyBasedForwardingRules': 'policies/policy_based_forwarding_rule',
	'Policies/SecurityRules': 'policies/security_rule',
	// "policies/security_rulebase",
	// "policies/tunnel_inspection",

	// R1 release supported checks
	'Objects/Addresses': 'objects/address',
	'Objects/Applications': 'objects/application',
	'Objects/AddressGroups': 'objects/address_group',
	'Objects/ApplicationGroups': 'objects/application_group',
	'Objects/ApplicationFilters': 'objects/application_filter',
	'Objects/Services': 'objects/service',
	'Objects/ServiceGroups': 'objects/service_group',
	'Objects/Regions': 'objects/region',
	'Objects/ExternalDynamicLists': 'device/external_dynamic_list',
	'Objects/Schedules': 'objects/schedule',
	'Objects/DynamicUserGroup': 'objects/dynamic_user_group',
	'Objects/GlobalProtectHIPObjects': 'objects/hip_objects',
	'Objects/GlobalProtectHIPProfiles': 'objects/hip_profile',
	'Objects/Tags': 'objects/tag',
	variables: 'objects/variable',
};

export const isBpaAvailableForServiceName = (serviceName: string) => !!SpiffyServiceMap[serviceName];

export const getBpaResultQueryParams = () => {
	const name = getDeviceContainerParentLocation();
	const folderScopeMap = _.get(getStoreState(), 'main.scopeManage.folderScopeMap', {});
	const allSnippets = _.get(getStoreState(), 'main.snippetManage.allSnippets', {});
	const type = folderScopeMap[name]?.type || allSnippets[name]?.type || '';
	const scopeParam = getScopeParamFromType(type);
	return {
		type: scopeParam,
		name,
	};
};

export const getBPAResultsObservableFromMapPath = (bpaMapPath: string, configLocation: FAWKES.configLocation): any => {
	const params = getBpaResultQueryParams();
	const query = new URLSearchParams(params).toString();
	const authHeaders = getAuthHeaders();
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${getBaseUrl()}/spiffy/v1/bp/result/${bpaMapPath}?${query.toString()}`,
		headers: spiffyHeaders(authHeaders['x-auth-jwt']),
	};
};

export const getBpaPathFromServiceName = (serviceName: string) => SpiffyServiceMap[serviceName];

export const getBPAResultsObservable = (serviceName: string, configLocation: FAWKES.configLocation): any => {
	const endPath = SpiffyServiceMap[serviceName];
	if (!endPath) {
		console.error(`No Spiffy BPA result path found for ${serviceName}`);
		return {};
	}
	return getBPAResultsObservableFromMapPath(endPath, configLocation);
};

export const getFeatureAdoptionObservable = (configLocation: FAWKES.configLocation): any => {
	const { container: { name = '', type = '' } = {} } = configLocation;
	if (isSnippetScope()) return {};
	const scopeParam = getScopeParamFromType(type);
	const query = `type=${scopeParam}&name=${name}`;
	const authHeaders = getAuthHeaders();
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: `${getBaseUrl()}/spiffy/v1/bp/adoption/policies/security_rule?${query}&result=summary`,
		headers: spiffyHeaders(authHeaders['x-auth-jwt']),
	};
};
