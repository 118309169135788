import { ajax } from 'rxjs/ajax';
import { getAdminServiceURL, getCustomServiceURL, isMockService } from 'ui-lib/core/services/CommonServices';
import { isCASBXLicenseEnabled, isSaaSInlineLicenseEnabled } from 'service-lib/services/licenseManager';
import { getAuthHeaders } from 'ui-lib/auth/lib';
import { getParam, saveSession, getSession } from 'ui-lib/core/utils/sessionHelper';
import { jwt_decode } from 'ui-lib/core/utils/jwt-decode';
import { throwError, of, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getValidUserPreference } from 'src/utils/utils';
import Pan from 'ui-lib/core/autorender/schema/Pan';
import { getRedirectURL } from 'src/ui-lib/core/services/CommonServices';
import { ANTISPYWARE_TABLE, VULNERABILITY_TABLE } from 'src/db/dbSchema';
import { getCount, search } from 'src/db/dbOperations';

/*
	return minimalistic tenant information and then get user info from API server
	after token verification
*/
export function isUserLoggedIn(): any {
	const jwToken = window.location.hash.split('#_rjwt=')[1];
	if (jwToken) {
		// it was a redirect
		const { sub, exp, iat, redirectURL, supportAccountId } = jwt_decode(jwToken, undefined);
		if (exp && Date.now() < exp * 1000 && iat && Date.now() > iat * 1000 && sub === getParam('tenantId')) {
			// token is not expired, issued earlier & match the tenant
			window.location.hash = ''; // reset hash
			saveSession({ sub, token: jwToken, exp });
			console.log('Sending the token.');
			return of({
				response: {
					ok: true,
					isLoggedIn: true,
					tenant: {
						supportAccountId,
						cluster: {
							redirectURL,
						},
					},
				},
			});
		}
	} else {
		// got here after a refresh of the page or first time by navigating or copy-pasting url.
		// tenantId from url or sessionId.
		//if not from URL, get from localstorage and save it in sessionstorage
		let tenantId = getParam('tenantId');
		tenantId = tenantId || localStorage.getItem('currentTenantId') || undefined;
		// see if we have token or not - with tenantId from url or from session storage
		const token = getSession(tenantId);
		if (token) {
			const { sub, exp, iat, redirectURL, supportAccountId } = jwt_decode(token, undefined);
			if (exp && Date.now() < exp * 1000 && iat && Date.now() > iat * 1000) {
				console.log('Sending the token, with T=', sub);
				saveSession({ sub, exp, token });
				return of({
					response: {
						ok: true,
						isLoggedIn: true,
						tenant: {
							supportAccountId,
							cluster: {
								redirectURL,
							},
						},
					},
				});
			}
		}
		// no redirected token or token expired or invalid.
		console.log('noSession since No TOKEN NO TENANT-ID.');
		return of({
			response: {
				ok: true,
				isLoggedIn: false,
				noSession: !tenantId && !token,
			},
		});
	}
}

export function getLoggedInUser() {
	console.log('GET USer');
	const { SERVICE_URL } = getCustomServiceURL('api/sso/v1/user');
	if (!SERVICE_URL) {
		// SERVICE address is not available yet.
		console.log('NOT READY');
		return throwError(new Error('NOT_READY'));
	}
	return ajax({
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	}).pipe(
		map((r) => {
			return r;
		}),
		catchError((e) => {
			return throwError(e);
		}),
	);
}

export function doPushActionObservable(params: any) {
	const { SERVICE_URL } = getCustomServiceURL(`api/config/v9.2/Push`);
	return ajax({
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: JSON.stringify(params),
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
}

export function doRevertActionObservable() {
	const { SERVICE_URL } = getCustomServiceURL('api/config/v9.2/revert');
	return ajax({
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	});
}

export function directoryDomainsObservable() {
	const { SERVICE_URL } = getCustomServiceURL('api/directory/v1/domain/status');
	return ajax({
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		body: {},
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
}

export function getUserPreferenceObservable() {
	const SERVICE_URL = `${getRedirectURL()}/api/sso/v1/user`;
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function saveUserPreferenceObservable(params: any) {
	const { SERVICE_URL } = getCustomServiceURL('api/preference');
	const userPreference = getValidUserPreference(params);
	return ajax({
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: SERVICE_URL,
		body: { params: userPreference },
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
}

export function deleteUserPreferenceObservable() {
	const { SERVICE_URL } = getCustomServiceURL('api/preference');
	return ajax({
		withCredentials: isMockService() ? false : true,
		method: 'DELETE',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
}

export function getComplianceObservable() {
	const { SERVICE_URL } = getCustomServiceURL('api/config/v9.2/Operations/licenseCompliance');
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}
export function triggerComplianceObservable() {
	const { SERVICE_URL } = getCustomServiceURL('api/config/v9.2/Operations/TriggerLicenseCompliance');
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchFilterWidgetDataObservable({ endpoint, params }: any) {
	const { SERVICE_URL } = getCustomServiceURL(endpoint);

	return ajax({
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(params),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
}

export function fetchThreatNamesDataObservable(signatureType: string) {
	const { SERVICE_URL } = getCustomServiceURL(`api/contentservice/${signatureType}/name`);

	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchThreatFilterDataObservable(endpoint: any) {
	const { SERVICE_URL } = getCustomServiceURL(endpoint);

	return ajax({
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
}

export function fetchThreatDetailsObservable(idList: any) {
	let { SERVICE_URL } = getCustomServiceURL('api/threatvault/search');
	SERVICE_URL += `?idList=[${idList.join(',')}]`;
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchThreatDetailsQueryObservable(threatId: string) {
	const url = `api/contentservice/threat-details/${threatId}`;
	const { SERVICE_URL } = getCustomServiceURL(url);
	return ajax({
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
}

export function getAuthInfoObservable() {
	const { LOGIN_SERVICE_URL } = getAdminServiceURL(`api/xseer/auth`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: LOGIN_SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getFqdnInfoObservable() {
	const { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/gpFQDN`);
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getSubTenantInfoObservable() {
	const { SERVICE_URL } = getCustomServiceURL(`api/extensions/gpcs/get/get_tenant_subtenant_info`);

	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchThreatDetailsQueryWithUtidsObservable(threatId: string) {
	const url = `api/threatvault/query?utids=[{"utid": ${threatId}}]`;
	const { SERVICE_URL } = getCustomServiceURL(url);
	return ajax({
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
}

export function getDLPDataPatternsInfoObservable() {
	const { SERVICE_URL } = getCustomServiceURL('/api/config/v9.2/Objects/DLPDataPatterns');
	const url = `${SERVICE_URL}?isPredefined=true`;
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url,
		headers: getAuthHeaders(),
	};
}

export function setAuthEventToLogsObservable() {
	const { LOGIN_SERVICE_URL } = getAdminServiceURL('api/sso/v1/cleanup');
	return {
		withCredentials: isMockService() ? false : true,
		method: 'POST',
		responseType: 'json',
		url: LOGIN_SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getCapabilitiesInfoObservable(cap_name = '') {
	const { SERVICE_URL } = getCustomServiceURL('/api/capabilities/associations');
	const url = `${SERVICE_URL}?cap_name=${cap_name}`;
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url,
		headers: getAuthHeaders(),
	};
}

export function isACEEnabled() {
	// const aceFeatureFlagEnabled = checkIsFeatureEnabled(_.get(getStoreState(), 'main.featuresInfo', []), 'saas-ace');

	if (isSaaSInlineLicenseEnabled() || isCASBXLicenseEnabled()) {
		return true;
	}
	return false;
}

export function getJobIdsObservable(folderUuid: any) {
	const { SERVICE_URL } = getCustomServiceURL(`ngfw/commands/jobs?action=routing&folder=${folderUuid}`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function getJobDetailsObservable(jobId = '') {
	const { SERVICE_URL } = getCustomServiceURL(`ngfw/commands/jobs/${jobId}`);
	return {
		withCredentials: isMockService() ? false : true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: getAuthHeaders(),
	};
}

export function fetchDnsProxyJobIdFromExecuteObservable({ devices, fqdn = '', type }: any) {
	const { SERVICE_URL } = getCustomServiceURL(`/ngfw/commands/networking/dns-proxy`);
	const payload = !Pan.isEmpty(fqdn)
		? {
				command: 'show-dns-proxy-cache-filter-fqdn',
				devices: devices,
				arguments: {
					fqdn: fqdn,
					type: type,
				},
		  }
		: {
				command: 'show-dns-proxy-cache-all',
				devices: devices,
		  };

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}
export function fetchNatJobIdFromExecuteObservable({ devices }: any) {
	const { SERVICE_URL } = getCustomServiceURL(`/ngfw/commands/running-ippool`);
	const payload = {
		command: 'show-running-ippool',
		devices: devices,
	};

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}
export function fetchRoutingJobIdFromExecuteObservable(
	{ devices, destination = '', folderUuid }: any,
	isVirtualRouterEnabled: boolean,
) {
	const { SERVICE_URL } = getCustomServiceURL(
		`/ngfw/commands/${isVirtualRouterEnabled ? 'routing' : 'advanced-routing'}/route`,
	);
	const payload = !Pan.isEmpty(destination)
		? // search the routing table
		  {
				command: isVirtualRouterEnabled ? 'show-routing-route-opt' : 'show-advanced-routing-route-opt',
				devices: devices,
				arguments: {
					route: {
						destination: destination,
					},
				},
				extra: {
					action: 'routing',
					folder: folderUuid,
				},
		  }
		: // show routing table
		  {
				command: isVirtualRouterEnabled ? 'show-routing-route' : 'show-advanced-routing-route',
				devices: devices,
				extra: {
					action: 'routing',
					folder: folderUuid,
				},
		  };

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchEdlJobIdFromExecuteObservable({ devices, searchStr = '', type = '', edlName }: any) {
	const { SERVICE_URL } = getCustomServiceURL(`/ngfw/commands/edl`);
	let arg = null;
	if (type === 'predefinedIp') {
		arg = {
			predefinedIp: {
				name: edlName,
			},
		};
	}
	if (type === 'predefinedUrl') {
		arg = {
			predefinedUrl: {
				name: edlName,
			},
		};
	}
	if (type === 'domain') {
		arg = {
			domain: {
				name: edlName,
			},
		};
	}
	if (type === 'imei') {
		arg = {
			imei: {
				name: edlName,
			},
		};
	}
	if (type === 'imsi') {
		arg = {
			imsi: {
				name: edlName,
			},
		};
	}
	if (type === 'url') {
		arg = {
			url: {
				name: edlName,
			},
		};
	}
	if (type === 'ip') {
		arg = {
			ip: {
				name: edlName,
			},
		};
	}

	const payload = !Pan.isEmpty(searchStr)
		? // Search string in all EDLs
		  {
				command: 'request-edl-global-find',
				devices: devices,
				arguments: {
					searchStr: searchStr,
				},
		  }
		: // Show EDL Stats
		  {
				command: 'request-edl-stats',
				devices: devices,
				arguments: arg,
		  };

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchValidEntriesObservable(request: any) {
	const { SERVICE_URL } = getCustomServiceURL(`/ngfw/commands/edl`);

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(request),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function edlRefreshObservable(request: any) {
	const { devices } = request;
	const { SERVICE_URL } = getCustomServiceURL(`/ngfw/commands/edl`);
	const payload = {
		command: 'request-edl-refresh',
		devices: devices,
		arguments: request.arguments,
	};
	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchDagJobIdFromExecuteObservable({ devices, dagName = '' }: any) {
	const { SERVICE_URL } = getCustomServiceURL('/ngfw/commands/identity/dynamic-address-group');

	const payload = !Pan.isEmpty(dagName)
		? {
				command: 'show-dag',
				devices: devices,
				arguments: {
					name: dagName,
				},
				extra: {
					action: 'dag',
				},
		  }
		: {
				command: 'show-dag-all',
				devices: devices,
				extra: {
					action: 'dag',
				},
		  };

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchUserGroupJobIdFromExecuteObservable({ devices, searchStr = '' }: any) {
	const { SERVICE_URL } = getCustomServiceURL('/ngfw/commands/identity/user-group-mapping');

	const payload = !Pan.isEmpty(searchStr)
		? {
				command: 'show-user-ids-match-user',
				devices: devices,
				arguments: {
					name: searchStr,
				},
		  }
		: {
				command: 'show-user-ids-all',
				devices: devices,
		  };

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchDugJobIdFromExecuteObservable({ devices, dugName = '', userName = '' }: any) {
	const { SERVICE_URL } = getCustomServiceURL('/ngfw/commands/identity/user-group-mapping');

	const payload = !Pan.isEmpty(dugName)
		? {
				command: 'show-user-group-name',
				devices: devices,
				arguments: {
					name: dugName,
				},
		  }
		: {
				command: 'show-user-ids-match-user',
				devices: devices,
				arguments: {
					name: userName,
				},
		  };

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchUserIpMappingFromExecuteObservable({ devices, searchStr = '' }: any) {
	const { SERVICE_URL } = getCustomServiceURL('/ngfw/commands/identity/show-ip-user-mapping');

	const payload = !Pan.isEmpty(searchStr)
		? {
				command: 'show-ip-user-mapping',
				devices: devices,
				arguments: {
					ip: searchStr,
				},
		  }
		: {
				command: 'show-ip-user-mapping-all',
				devices: devices,
		  };

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchQuarantinedDeviceList({ devices }: any) {
	const { SERVICE_URL } = getCustomServiceURL('/ngfw/commands/identity/request-device-quarantine-list');

	const payload = {
		command: 'request-device-quarantine-list',
		devices: devices,
	};

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function addQuarantinedDeviceList({ devices, hostid, serialno }: any) {
	const { SERVICE_URL } = getCustomServiceURL('/ngfw/commands/identity/request-device-quarantine-list');

	const payload = {
		command: 'request-device-quarantine-list-add',
		devices: devices,
		arguments: {
			hostid: hostid,
			serialno: serialno,
		},
	};

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function deleteQuarantinedDeviceList({ devices, hostid }: any) {
	const { SERVICE_URL } = getCustomServiceURL('/ngfw/commands/identity/request-device-quarantine-list');

	const payload = {
		command: 'request-device-quarantine-list-delete',
		devices: devices,
		arguments: {
			hostid: hostid,
		},
	};

	return {
		withCredentials: true,
		method: 'POST',
		body: JSON.stringify(payload),
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getUnifiedPolicyMetadataObservable(tsgId: string) {
	const { SERVICE_URL } = getCustomServiceURL(
		`/api/saasproxysvc/saas-inline/inline/recommend/unified-policy/api/v1/metadata?tsgId=${tsgId}`,
	);
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function getSaasInstancesObservable({ tsgId, appId, page = '0', size = '10', sort = 'app_instance,asc' }: any) {
	let { SERVICE_URL } = getCustomServiceURL(`api/saasproxysvc/saas-inline/saas-visibility/api/v1/instances/quarter?`);
	tsgId && (SERVICE_URL += `tsgId=${tsgId}`);
	appId && (SERVICE_URL += `&appId=${appId}`);
	page && (SERVICE_URL += `&page=${page}`);
	size && (SERVICE_URL += `&size=${size}`);
	sort && (SERVICE_URL += `&sort=${sort}`);
	return {
		withCredentials: true,
		method: 'GET',
		responseType: 'json',
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}

export function fetchFilterWidgetDataFromDBObservable({ endpoint, params }: any) {
	let tableName;
	const { count, includeTotal } = params;
	if (endpoint?.indexOf('api/contentservice/vulnerability/search') >= 0) {
		tableName = VULNERABILITY_TABLE;
	} else if (endpoint?.indexOf('/api/contentservice/antispyware/search') >= 0) {
		tableName = ANTISPYWARE_TABLE;
	}
	const promises = [search(tableName, { count })];
	if (includeTotal) {
		promises.push(getCount(tableName));
	}
	return from(Promise.all(promises));
}

export function getSaasUsersObservable({
	tsgId,
	appId,
	tenants = [],
	searchKeyword = '',
	page = '0',
	size = '10',
	sort = 'app_instance,asc',
}: any) {
	let { SERVICE_URL } = getCustomServiceURL(
		`api/saasproxysvc/saas-inline/saas-visibility/api/v1/user-accounts/search/quarter?`,
	);
	tsgId && (SERVICE_URL += `tsgId=${tsgId}`);
	page && (SERVICE_URL += `&page=${page}`);
	size && (SERVICE_URL += `&size=${size}`);
	const payload = {
		appId: appId || '',
		tenants: tenants,
		searchKeyword: searchKeyword,
	};
	return {
		withCredentials: true,
		method: 'POST',
		responseType: 'json',
		body: JSON.stringify(payload),
		url: SERVICE_URL,
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	};
}
