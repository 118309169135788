import {
	ACCESS_CONTROL,
	URL_CATEGORY_REDUX_ID,
	CUSTOME_URL_CATEGORY,
	URL_ADMIN_OVERRIDES,
	ANTI_SPYWARE_OVERRIDES,
	ANTI_SPYWARE_SECURITY_PROFILES,
	CUSTOM_SPYWARE_SIGNATURES,
	CUSTOM_VULNERABILITY_SIGNATURES,
	DNS_SECURITY_PROFILES,
	SECURITY_POLICY_RULES,
	SESSION_INFO_SHARING,
	VULNERABILITY_PROTECTION_OBJECTS,
	VULNERABILITY_PROTECTION_OVERRIDES,
	WILDFIRE_AND_ANTIVIRUS_OBJECTS,
	WILDFIRE_SETTINGS,
	URL_ADVANCED_SETTINGS,
	FILE_BLOCKING_OBJECTS,
	SAAS_SECURITY_PROFILES,
	AI_SECURITY_PROFILES,
	SECURITY_PROFILE_GROUPS,
	SWG_FILE_BLOCKING_SECURITY_PROFILES,
	SWG_CUSTOM_WEB_POLICY,
	DEVICE_POSTURE_PROFILE_SWG,
	APPLICATION_GROUP_WITH_CONTROL_SWG,
	DEVICE_IDENTIFICATION_SWG,
	DECRYPTION_PROFILE,
	DECRYPTION_RULES,
	DOS_PROTECTION_PROFILE_REDUX_ID,
	DOS_RULES,
	ZONE_PROTECTION_PROFILE_REDUX_ID,
	APPLICATION_OVERRIDE_RULES,
	POLICY_BASED_FORWARDING_RULES,
	NAT_RULES,
	QO_RULES,
	QOS_PROFILE,
	QOS_INTERFACE,
	SDWAN_RULES,
	SDWAN_PATH_QUALITY,
	SDWAN_SAAS_QUALITY,
	SDWAN_TRAFFIC_DISTRIBUTION,
	SDWAN_ERROR_CORRECTION,
	SDWAN_INTERFACE_PROFILE,
	SDWAN_LINK_TAGS,
	AUTHENTICATION_RULES,
	AUTHENTICATION_PORTAL_CONFIG,
	AUTHENTICATION_PORTAL_CONFIG_,
	MFA_SERVER,
	AUTHENTICATION_PROFILE_GRID,
	AUTHENTICATION_SEQUENCE,
	SAML_PROFILE,
	LDAP_PROFILE,
	TACACS_PROFILE,
	KERBEROS_PROFILE,
	RADIUS_PROFILE,
	DIR_SYNC,
	REDISTRIBUTION_AGENTS,
	LOCAL_USERS_REDUX_ID,
	LOCAL_USER_GROUPS_REDUX_ID,
	ADDRESS_REDUX_ID,
	ADDRESS_GROUP_REDUX_ID,
	REGION_REDUX_ID,
	APPLICATIONS_REDUX_ID,
	APPLICATIONS_GROUPS_REDUX_ID,
	APPLICATION_FILTERS_REDUX_ID,
	SERVICE_OBJECTS,
	SERVICE_GROUP_REDUX_ID,
	DYNAMIC_USER_GROUP_OBJECTS,
	HIP_OBJECTS,
	HIP_PROFILES,
	TAGS_OBJECTS,
	AUTO_TAG_ACTIONS_OBJECTS,
	LOG_FORWARDING_OBJECTS,
	SYS_LOG,
	HTTP_SERVER,
	DEVICE_SUPPORT_REDUX_ID,
	EDL_REDUX_ID,
	SCHEDULES_OBJECTS,
	CUSTOM_CERTIFICATE,
	CERTIFICATE_MANAGEMENT_PROFILE,
	SCEP_REDUX_ID,
	SSL_TLS_SERVICE_PROFILE,
	OCSP_REPONDER,
	ZTNA_AGENT_SETTINGS_REDUX_ID,
	PROJECT_BASED_ACCESS_REDUX_ID,
	ETHERNET_INTERFACE,
	AGGREGATE_ETHERNET,
	VLAN_REDUX_ID,
	LOOPBACK_REDUX_ID,
	TUNNEL_REDUX_ID,
	CELLULAR_REDUX_ID,
	ZONES_NETWORK,
	VIRTUAL_WIRE,
	LOGICAL_ROUTERS_REDUX_ID,
	ADDRESS_FAMILY_PROFILE_REDUX_ID,
	AUTH_PROFILE_REDUX_ID,
	FILTERING_PROFILE_REDUX_ID,
	REDISTRIBUTION_PROFILE_REDUX_ID,
	VR_REDISTRIBUTION_PROFILE_REDUX_ID,
	ROUTE_ACCESS_LIST_ID,
	ROUTE_AS_PATH_ACCESS_LIST_ID,
	ROUTE_PREFIX_LIST_ID,
	ROUTE_MAP_BGP_LIST_ID,
	ROUTE_MAPS_DISTRIBUTION_LIST_ID,
	OSPF_AUTH_PROFILE_REDUX_ID,
	ROUTE_COMMUNITY_LIST_ID,
	IPSEC_TUNNEL,
	DHCP_SERVER,
	DHCP_SERVER_RELAY,
	DNS_PROXIES,
	GP_PORTAL,
	GATEWAY_REDUX_ID,
	GP_APP_SETTINGS,
	GP_TUNNEL_SETTINGS,
	GP_AUTHENTICATION_PROFILE,
	PORTAL_AGENT_PROFILE,
	GATEWAY_AGENT_PROFILE,
	VPN_CLUSTERS,
	AUTO_VPN_SETTING,
	SWG_DEFAULT_WEBPOLICY,
	DHCP_PROFILE,
	TRAFFIC_OBJECTS_REDUX_ID,
	DECRYPTION_EXCLUSION,
	DEVICE_SETTING_PASSWORD_PROFILES,
	DEVICE_SETTING_ADMINISTRATORS,
	SNMP_SERVER,
	EMAIL_SERVER,
	DELEGATION_PROFILE_REDUX_ID,
} from 'src/containers/common/reduxConstants';
import {
	MU_EP_KEY,
	MU_GP_KEY,
	MU_GP_KEY_2,
	MU_KEY,
	RN_KEY,
	SC_KEY,
	PRISMA_ACCESS_KEY,
	SNIPPET,
	CONTAINER,
	CONTAINER_ONPREM,
	RN_CONTAINER,
	SC_CONTAINER,
	MU_GP_CONTAINER,
	MU_EP_CONTAINER,
	JUPITER,
	LOCATION_KEY_MAP,
	NGFW_SHARED,
	PRISMA_ACCESS_CONTAINER,
	ALL,
} from 'service-lib/services/constants';
import { getStoreState } from 'ui-lib/core/utils/storeRegistry';
import _ from 'lodash';
import { FAWKES } from 'src/typings/type';
import { APP_BASE_URL_PREFIX } from 'src/AppConfig';
import Pan from 'ui-lib/core/autorender/schema/Pan';
import { isCNGFWDevice, isCNGFWDeviceAWS } from 'src/ui-lib/utils';
import { isFullQoSParity, isUnifiedPolicyEnabled } from 'src/containers/config/features';
import { getFeatureFlag } from '@sparky/framework';
import { getFawkesMainFromSparkyStore } from 'src/appUtils';

export const isV3NavEnabled = () => {
	return getFeatureFlag('sparky.framework.use_scm_nav_v3') || localStorage.getItem('scm_nav_v3_bypass') === 'bypass';
};

export const isNGFWEndDeviceNode = ({ scopeKey }: any) => {
	const folderScopeMap = _.get(getStoreState(), 'main.scopeManage.folderScopeMap', {});
	const PA_END_DEVICE_NODE: any = {
		[RN_CONTAINER]: true,
		[SC_CONTAINER]: true,
		[MU_GP_CONTAINER]: true,
		[MU_EP_CONTAINER]: true,
		[JUPITER]: true,
	};

	if (PA_END_DEVICE_NODE[scopeKey]) return true;

	if (folderScopeMap[scopeKey] && folderScopeMap[scopeKey].type === 'on-prem') {
		return true;
	}

	return false;
};

export const isWebSecurityDisabled = ({ scopeKey }: any) => {
	const webSecurityEnableInfo = _.get(getStoreState(), 'main.webSecurityEnableInfo', {});

	if (scopeKey === SC_CONTAINER) return true;
	if (isUnifiedPolicyEnabled()) {
		return false;
	}

	return isNGFWEndDeviceNode({ scopeKey }) && webSecurityEnableInfo[scopeKey] !== 'yes';
};

export const isWebSecurityDisabledByPAKey = ({ scopeKey: key }: any) => {
	const webSecurityEnableInfo = _.get(getStoreState(), 'main.webSecurityEnableInfo', {});
	const convertedKey = LOCATION_KEY_MAP[key];

	if (convertedKey === SC_CONTAINER) return true;

	if (isUnifiedPolicyEnabled()) {
		return false;
	}

	return isNGFWEndDeviceNode({ scopeKey: convertedKey }) && webSecurityEnableInfo[convertedKey] !== 'yes';
};

export const isComplianceChecksDisabled = ({ scopeKey: key }: any) => {
	const convertedKey = LOCATION_KEY_MAP[key] || key;
	return !(convertedKey === PRISMA_ACCESS_CONTAINER || convertedKey === NGFW_SHARED);
};

export const getPathRestrictionsMap = (store: any) => {
	const isDeviceQuarantineEnabled = true;

	return {
		'/security-services/security': [SC_KEY],
		'/anti-spyware': [SC_KEY],
		'/vulnerability-protection': [SC_KEY],
		'/wildfire-and-antivirus': [SC_KEY],
		'/dns-security': [SC_KEY],
		'/url-access-management': [SC_KEY],
		'/file-blocking': [SC_KEY],
		'/http-header-insertion': [SC_KEY],
		'/data-loss-prevention': [SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY],
		'/objects/logForwarding': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/device-settings/interfaces': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/device-settings/dhcp': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/device-settings/routing': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/device-settings/zones': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/device-settings/dns-proxy': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/device-settings/haconfig': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/device-settings/device-setup': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'device-settings/proxy': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/device-settings/virtual-wire': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/device-settings/ipsec-tunnel': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/device-settings/global-protect': [
			PRISMA_ACCESS_KEY,
			SC_KEY,
			RN_KEY,
			MU_KEY,
			MU_EP_KEY,
			MU_GP_KEY,
			MU_GP_KEY_2,
		],
		'/device-settings/global-protect/tabs/profiles': [
			PRISMA_ACCESS_KEY,
			SC_KEY,
			RN_KEY,
			MU_KEY,
			MU_EP_KEY,
			MU_GP_KEY,
			MU_GP_KEY_2,
		],
		'/device-settings/global-protect/tabs/agent-settings': [
			PRISMA_ACCESS_KEY,
			SC_KEY,
			RN_KEY,
			MU_KEY,
			MU_EP_KEY,
			MU_GP_KEY,
			MU_GP_KEY_2,
		],
		'/security-services/sdwan': [ALL, PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/security-services/pbf': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/security-services/nat': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
		'/security-services/dos-protection': [
			PRISMA_ACCESS_KEY,
			SC_KEY,
			RN_KEY,
			MU_KEY,
			MU_EP_KEY,
			MU_GP_KEY,
			MU_GP_KEY_2,
		],
		'/security-profile-groups': [SC_KEY],
		'/saas-application-management': [SC_KEY],
		'/security-services/decryption': [SC_KEY],
		'/security-services/qos': isFullQoSParity()
			? [MU_KEY, MU_GP_KEY, MU_EP_KEY, MU_GP_KEY_2]
			: [ALL, MU_KEY, MU_GP_KEY, MU_EP_KEY, MU_GP_KEY_2],
		'/security-services/application-override': [SC_KEY],
		'/identity-and-access-services/directorySync': [SC_KEY],
		'/objects/global-protect/hip-objects': [SC_KEY, MU_EP_KEY],
		'/objects/global-protect/hip-profiles': [SC_KEY, MU_EP_KEY],
		'/objects/devices': [SC_KEY, MU_EP_KEY, MU_GP_KEY_2],
		'/identity-and-access-services/identityRedistribution': [
			MU_EP_KEY,
			MU_KEY,
			...(isDeviceQuarantineEnabled ? [] : [MU_GP_KEY_2, MU_GP_KEY]),
		],
		'/objects/device-quarantine-list': [
			MU_EP_KEY,
			MU_GP_KEY,
			MU_GP_KEY_2,
			MU_KEY,
			...(isDeviceQuarantineEnabled ? [] : [PRISMA_ACCESS_KEY, RN_KEY, SC_KEY]),
		],
		'/security-services/web-security': isWebSecurityDisabledByPAKey,
		'/security-posture/compliance-checks': isComplianceChecksDisabled,
		'/local-config/folder-conflicts': [
			PRISMA_ACCESS_KEY,
			SC_KEY,
			RN_KEY,
			MU_KEY,
			MU_EP_KEY,
			MU_GP_KEY,
			MU_GP_KEY_2,
		],
		'/local-config/device-conflicts': [
			ALL,
			PRISMA_ACCESS_KEY,
			SC_KEY,
			RN_KEY,
			MU_KEY,
			MU_EP_KEY,
			MU_GP_KEY,
			MU_GP_KEY_2,
		],
		'/operations/troubleshooting': [
			ALL,
			PRISMA_ACCESS_KEY,
			SC_KEY,
			RN_KEY,
			MU_KEY,
			MU_EP_KEY,
			MU_GP_KEY,
			MU_GP_KEY_2,
		],
		'security-services/upstream-proxy-policy': [
			ALL,
			PRISMA_ACCESS_KEY,
			SC_KEY,
			RN_KEY,
			MU_KEY,
			MU_GP_KEY,
			MU_GP_KEY_2,
		],
		'/security-services/ai-security': [
			PRISMA_ACCESS_KEY,
			SC_KEY,
			RN_KEY,
			MU_KEY,
			MU_EP_KEY,
			MU_GP_KEY,
			MU_GP_KEY_2,
		],
		'/objects/traffic-objects': [PRISMA_ACCESS_KEY, SC_KEY, RN_KEY, MU_KEY, MU_EP_KEY, MU_GP_KEY, MU_GP_KEY_2],
	};
};

export const getFlattenNavigationList = (list: any) => {
	return list.reduce((r: any, { children, ...rest }: any) => {
		r.push(rest);
		if (children) r.push(...getFlattenNavigationList(children));
		return r;
	}, []);
};

export const getPathAvailMap = (list: any) =>
	list.reduce((map: any, { path, avail }: any) => {
		map[path] = avail;
		return map;
	}, {});
export const getPathTitleMap = (list: any) =>
	list.reduce((map: any, { path, title }: any) => {
		map[path] = title;
		return map;
	}, {});

export const getTargetRouteInfo = ({ containerKey, targetPath }: any) => {
	let foundPath: any;
	let isDisabled = false;
	const state = getStoreState();
	const pathRestrictionsMap: FAWKES.I_OBJECT = getPathRestrictionsMap(state);
	const paths = Object.keys(pathRestrictionsMap);

	if (containerKey) {
		// Use "containerKey" when we are not yet in the target scope (e.g. HLGridHeader)
		foundPath = paths.find((path) => window.location.pathname.indexOf(path) !== -1);
		const matchedPath = _.get(pathRestrictionsMap, foundPath);
		// isDisabled = _.get(pathRestrictionsMap, foundPath)?.indexOf(containerKey) > -1;
		if (matchedPath) {
			isDisabled = Pan.isFunction(matchedPath)
				? matchedPath({ scopeKey: containerKey })
				: Boolean(matchedPath.indexOf(containerKey) > -1);
		}
	} else if (targetPath) {
		// Use "targetPath" when we are already in the target scope (e.g. LeftNav)
		foundPath = paths.find((path) => targetPath.indexOf(path) !== -1);
		isDisabled = _.get(pathRestrictionsMap, foundPath)?.find((key: any) => targetPath.indexOf(key) > -1);
	} else {
		throw new Error('Method wrongly used');
	}
	return {
		isDisabled,
		foundPath,
	};
};
const appBaseURL = _.get(getStoreState(), 'main.appBaseURL', APP_BASE_URL_PREFIX);

const isCNGFWDeviceScope = ({ scopeKey }: any) => {
	return isCNGFWDevice(scopeKey);
};
const isSetupPageRestricted = ({ scopeKey, type }: any) => {
	return scopeKey !== NGFW_SHARED || type !== CONTAINER;
};

const getSnippetFolderPathRestrictionMap = () => {
	return {
		// '/manage/security-services/data-loss-prevention': [SNIPPET, CONTAINER, CONTAINER_ONPREM],
		'/manage/security-services/qos': isFullQoSParity() ? [SNIPPET] : [SNIPPET, CONTAINER, CONTAINER_ONPREM],
		'/manage/local-config/device-conflicts': [SNIPPET, CONTAINER],
		'/manage/local-config/folder-conflicts': [CONTAINER_ONPREM, SNIPPET],
		'/operations/troubleshooting': [SNIPPET],
		'/manage/security-services/web-security': isWebSecurityDisabled,
		'/security-posture/compliance-checks': isComplianceChecksDisabled,
		'/manage/security-services/http-header-insertion': isCNGFWDeviceScope,
		'/manage/security-services/pbf': isCNGFWDeviceScope,
		'/manage/security-services/nat': isCNGFWDeviceScope,
		'/manage/security-services/sdwan': isCNGFWDeviceScope,
		'/manage/objects/saas-application-management': isCNGFWDeviceScope,
		'/manage/objects/hip-objects': isCNGFWDeviceScope,
		'/manage/objects/hip-profiles': isCNGFWDeviceScope,
		'/manage/objects/devices': isCNGFWDeviceScope,
		'/manage/objects/device-quarantine-list': isCNGFWDeviceScope,
		'/manage/device-settings/interfaces': isCNGFWDeviceScope,
		'/manage/device-settings/zones': isCNGFWDeviceScope,
		'/manage/device-settings/virtual-wire': isCNGFWDeviceScope,
		'/manage/device-settings/routing': isCNGFWDeviceScope,
		'/manage/device-settings/ipsec-tunnel': isCNGFWDeviceScope,
		'/manage/device-settings/dhcp': isCNGFWDeviceScope,
		'/manage/device-settings/dns-proxy': ({ scopeKey }: any) => isCNGFWDeviceAWS(scopeKey),
		'/manage/device-settings/global-protect': isCNGFWDeviceScope,
		'/manage/device-settings/device-setup': isCNGFWDeviceScope,
		'/manage/device-settings/proxy': isCNGFWDeviceScope,
		'/manage/workflow/auto-vpn': isCNGFWDeviceScope,
		'/manage/saas-application-endpoints': isCNGFWDeviceScope,
		'/manage/identity-and-access-services/authentication': isCNGFWDeviceScope,
		'/manage/identity-and-access-services/directorySync': isCNGFWDeviceScope,
		'/manage/identity-and-access-services/identityRedistribution': isCNGFWDeviceScope,
		'/manage/identity-and-access-services/local-users-and-user-groups': isCNGFWDeviceScope,
		'/manage/notification-template': isCNGFWDeviceScope,
		'security-services/upstream-proxy-policy': [SNIPPET, CONTAINER, CONTAINER_ONPREM],
		'/manage/ngfw-setup': isSetupPageRestricted,
		'/workflows/prisma-access/setup': isSetupPageRestricted,
		'/workflows/mobile-users/setup': isSetupPageRestricted,
		'/workflows/mobile-users-gp/setup': isSetupPageRestricted,
		'/workflows/mu-device/setup': isSetupPageRestricted,
		'/workflows/mobile-users-ep/setup': isSetupPageRestricted,
		'/workflows/remote-networks/setup': isSetupPageRestricted,
		'/workflows/service-connections/setup': isSetupPageRestricted,
	};
};

export const getTargetRouteInfoForSnippetAndFolder = ({ containerKey, type }: any) => {
	let isDisabled = false;
	const state = getStoreState();
	const pathRestrictionsMap: FAWKES.I_OBJECT = getSnippetFolderPathRestrictionMap();
	const paths = Object.keys(pathRestrictionsMap);

	const foundPath = paths.find((path) => window.location.pathname.indexOf(path) !== -1);
	const matchedPath = _.get(getSnippetFolderPathRestrictionMap(), foundPath);
	if (matchedPath) {
		isDisabled = Pan.isFunction(matchedPath)
			? matchedPath({ scopeKey: containerKey, type })
			: Boolean(matchedPath.indexOf(type) > -1);
	}
	// isDisabled = Boolean(_.get(SNIPPET_FOLDER_PATH_RESTRICTION_MAP, foundPath)?.indexOf(type) > -1);

	return {
		isDisabled,
		foundPath,
	};
};

export const getNavItemByPath = (path: any) => {
	const flattenNavigationList = _.get(getStoreState(), 'main.flattenNavigationList', []);
	const res = flattenNavigationList
		.filter((nav: any) => path.startsWith(nav.path) && nav.renderer)
		.sort((a: any, b: any) => {
			return b.path.length - a.path.length;
		});
	if (res.length > 0) return res[0];
	return null;
};

export const webSecurityAvail = () => {
	// const storeState = getStoreState();
	// const storeVal = store.getState();
	// const webSecurityAvailSparky = _.get(storeVal, 'webSecurityAvail');
	// const isWebSecurityEnabled = _.get(storeState, 'dashboard.isEnabled.enableWebSecurity.result.enable', '');
	// return isWebSecurityEnabled === 'yes';
	return false;
};

export const getNavItemByRBAPath = (rbaPath: string) => {
	const flattenNavigationList = _.get(getStoreState(), 'main.flattenNavigationList', []);
	return flattenNavigationList.find((nav: any) => nav.rbaPath === rbaPath);
};

export const getGpEpEnabled = () => {
	//to decouple
	const fawkesMain = getFawkesMainFromSparkyStore();
	return _.get(fawkesMain, 'gpEpEnabled');
};

// The following mapping is supposed to be in sync with a file in another code base, so, should not change it directly.
export const routeKeyMap = {
	// Security Services
	'security-policy': 'security-policy',
	'anti-spyware': 'anti-spyware',
	'vulnerability-protection': 'vulnerability-protection',
	'wildfire-and-antivirus': 'wildfire-and-antivirus',
	'dns-security': 'dns-security',
	'url-access-management': 'url-access-management',
	'file-blocking': 'file-blocking',
	'http-header-insertion': 'http-header-insertion',
	'profile-groups': 'profile-groups',
	'web-security': 'web-security',
	decryption: 'decryption',
	'dos-protection': 'dos-protection',
	'ai-security': 'ai-security',
	// Network Policies
	qos: 'qos',
	'application-override': 'application-override',
	'policy-based-forwarding': 'policy-based-forwarding',
	nat: 'nat',
	'sdwan-policy': 'sdwan-policy',
	// Identity Services
	authentication: 'authentication',
	'cloud-identity-engine': 'cloud-identity-engine',
	'identity-redistribution': 'identity-redistribution',
	'local-users-groups': 'local-users-groups',
	// Objects
	'address-object-nav-group': 'address-object-nav-group',
	addresses: 'addresses',
	'address-groups': 'address-groups',
	regions: 'regions',
	'application-object-nav-group': 'application-object-nav-group',
	applications: 'applications',
	'application-groups': 'application-groups',
	'application-filters': 'application-filters',
	'traffic-objects': 'traffic-objects',
	'service-object-nav-group': 'service-object-nav-group',
	services: 'services',
	'service-groups': 'service-groups',
	saas_application_management: 'saas_application_management',
	'hip-object-nav-group': 'hip-object-nav-group',
	'hip-objects': 'hip-objects',
	'hip-profiles': 'hip-profiles',
	'dynamic-user-groups': 'dynamic-user-groups',
	tags: 'tags',
	'auto-tags': 'auto-tags',
	'log-forwarding': 'log-forwarding',
	devices: 'devices',
	'external-dynamic-lists': 'external-dynamic-lists',
	'certificate-management': 'certificate-management',
	schedules: 'schedules',
	'dynamic-privilege-access': 'dynamic-privilege-access',
	'device-quarantine-list': 'device-quarantine-list',
	// Device Settings
	interfaces: 'interfaces',
	zones: 'zones',
	vwire: 'vwire',
	routing: 'routing',
	'ipsec-tunnels': 'ipsec-tunnels',
	dhcp: 'dhcp',
	dns_proxy: 'dns_proxy',
	'global-protect': 'global-protect',
	'device-setup': 'device-setup',
	proxy: 'proxy',
	// Global Settings
	'auto-vpn': 'auto-vpn',
	'saas-application-management': 'saas-application-management',
	'device-admins': 'device-admins',
};

export const reduxRouteKeyMap = {
	// Security Services
	[SECURITY_POLICY_RULES]: routeKeyMap['security-policy'],
	[ANTI_SPYWARE_SECURITY_PROFILES]: routeKeyMap['anti-spyware'],
	[ANTI_SPYWARE_OVERRIDES]: routeKeyMap['anti-spyware'],
	[CUSTOM_SPYWARE_SIGNATURES]: routeKeyMap['anti-spyware'],
	[CUSTOM_VULNERABILITY_SIGNATURES]: routeKeyMap['vulnerability-protection'],
	[VULNERABILITY_PROTECTION_OVERRIDES]: routeKeyMap['vulnerability-protection'],
	[VULNERABILITY_PROTECTION_OBJECTS]: routeKeyMap['vulnerability-protection'],
	[WILDFIRE_AND_ANTIVIRUS_OBJECTS]: routeKeyMap['wildfire-and-antivirus'],
	[WILDFIRE_SETTINGS]: routeKeyMap['wildfire-and-antivirus'],
	[SESSION_INFO_SHARING]: routeKeyMap['wildfire-and-antivirus'],
	[DNS_SECURITY_PROFILES]: routeKeyMap['dns-security'],
	[ACCESS_CONTROL]: routeKeyMap['url-access-management'],
	[URL_CATEGORY_REDUX_ID]: routeKeyMap['url-access-management'],
	[CUSTOME_URL_CATEGORY]: routeKeyMap['url-access-management'],
	[URL_ADMIN_OVERRIDES]: routeKeyMap['url-access-management'],
	[URL_ADVANCED_SETTINGS]: routeKeyMap['url-access-management'],
	[FILE_BLOCKING_OBJECTS]: routeKeyMap['file-blocking'],
	[SAAS_SECURITY_PROFILES]: routeKeyMap['http-header-insertion'],
	[AI_SECURITY_PROFILES]: routeKeyMap['ai-security'],
	[SECURITY_PROFILE_GROUPS]: routeKeyMap['profile-groups'],
	[SWG_FILE_BLOCKING_SECURITY_PROFILES]: routeKeyMap['web-security'],
	[SWG_CUSTOM_WEB_POLICY]: routeKeyMap['web-security'],
	[SWG_DEFAULT_WEBPOLICY]: routeKeyMap['web-security'],
	[DEVICE_POSTURE_PROFILE_SWG]: routeKeyMap['web-security'],
	[DEVICE_IDENTIFICATION_SWG]: routeKeyMap['web-security'],
	[APPLICATION_GROUP_WITH_CONTROL_SWG]: routeKeyMap['web-security'],
	[DECRYPTION_PROFILE]: routeKeyMap['decryption'],
	[DECRYPTION_RULES]: routeKeyMap['decryption'],
	[DECRYPTION_EXCLUSION]: routeKeyMap['decryption'],
	[DOS_RULES]: routeKeyMap['dos-protection'],
	[DOS_PROTECTION_PROFILE_REDUX_ID]: routeKeyMap['dos-protection'],
	[ZONE_PROTECTION_PROFILE_REDUX_ID]: routeKeyMap['dos-protection'],
	// Network Policies
	[QO_RULES]: routeKeyMap['qos'],
	[QOS_PROFILE]: routeKeyMap['qos'],
	[QOS_INTERFACE]: routeKeyMap['qos'],
	[APPLICATION_OVERRIDE_RULES]: routeKeyMap['application-override'],
	[POLICY_BASED_FORWARDING_RULES]: routeKeyMap['policy-based-forwarding'],
	[NAT_RULES]: routeKeyMap['nat'],
	[SDWAN_RULES]: routeKeyMap['sdwan-policy'],
	[SDWAN_PATH_QUALITY]: routeKeyMap['sdwan-policy'],
	[SDWAN_SAAS_QUALITY]: routeKeyMap['sdwan-policy'],
	[SDWAN_TRAFFIC_DISTRIBUTION]: routeKeyMap['sdwan-policy'],
	[SDWAN_ERROR_CORRECTION]: routeKeyMap['sdwan-policy'],
	[SDWAN_INTERFACE_PROFILE]: routeKeyMap['sdwan-policy'],
	[SDWAN_LINK_TAGS]: routeKeyMap['sdwan-policy'],
	// Identity Services
	[AUTHENTICATION_RULES]: routeKeyMap['authentication'],
	[DELEGATION_PROFILE_REDUX_ID]: routeKeyMap['authentication'],
	[AUTHENTICATION_PORTAL_CONFIG]: routeKeyMap['authentication'],
	[AUTHENTICATION_PORTAL_CONFIG_]: routeKeyMap['authentication'],
	[MFA_SERVER]: routeKeyMap['authentication'],
	[AUTHENTICATION_PROFILE_GRID]: routeKeyMap['authentication'],
	[AUTHENTICATION_SEQUENCE]: routeKeyMap['authentication'],
	[SAML_PROFILE]: routeKeyMap['authentication'],
	[LDAP_PROFILE]: routeKeyMap['authentication'],
	[TACACS_PROFILE]: routeKeyMap['authentication'],
	[KERBEROS_PROFILE]: routeKeyMap['authentication'],
	[RADIUS_PROFILE]: routeKeyMap['authentication'],
	[DIR_SYNC]: routeKeyMap['cloud-identity-engine'],
	[REDISTRIBUTION_AGENTS]: routeKeyMap['identity-redistribution'],
	[LOCAL_USERS_REDUX_ID]: routeKeyMap['local-users-groups'],
	[LOCAL_USER_GROUPS_REDUX_ID]: routeKeyMap['local-users-groups'],
	// Objects
	[ADDRESS_REDUX_ID]: routeKeyMap['addresses'],
	[ADDRESS_GROUP_REDUX_ID]: routeKeyMap['address-groups'],
	[REGION_REDUX_ID]: routeKeyMap['regions'],
	[APPLICATIONS_REDUX_ID]: routeKeyMap['applications'],
	[APPLICATIONS_GROUPS_REDUX_ID]: routeKeyMap['application-groups'],
	[APPLICATION_FILTERS_REDUX_ID]: routeKeyMap['application-filters'],
	[TRAFFIC_OBJECTS_REDUX_ID]: routeKeyMap['traffic-objects'],
	[SERVICE_OBJECTS]: routeKeyMap['services'],
	[SERVICE_GROUP_REDUX_ID]: routeKeyMap['service-groups'],
	[HIP_OBJECTS]: routeKeyMap['hip-objects'],
	[HIP_PROFILES]: routeKeyMap['hip-profiles'],
	[DYNAMIC_USER_GROUP_OBJECTS]: routeKeyMap['dynamic-user-groups'],
	[TAGS_OBJECTS]: routeKeyMap['tags'],
	[AUTO_TAG_ACTIONS_OBJECTS]: routeKeyMap['auto-tags'],
	[LOG_FORWARDING_OBJECTS]: routeKeyMap['log-forwarding'],
	[SYS_LOG]: routeKeyMap['log-forwarding'],
	[HTTP_SERVER]: routeKeyMap['log-forwarding'],
	[SNMP_SERVER]: routeKeyMap['log-forwarding'],
	[EMAIL_SERVER]: routeKeyMap['log-forwarding'],
	[DEVICE_SUPPORT_REDUX_ID]: routeKeyMap['devices'],
	[EDL_REDUX_ID]: routeKeyMap['external-dynamic-lists'],
	[CUSTOM_CERTIFICATE]: routeKeyMap['certificate-management'],
	[CERTIFICATE_MANAGEMENT_PROFILE]: routeKeyMap['certificate-management'],
	[SCEP_REDUX_ID]: routeKeyMap['certificate-management'],
	[SSL_TLS_SERVICE_PROFILE]: routeKeyMap['certificate-management'],
	[OCSP_REPONDER]: routeKeyMap['certificate-management'],
	[SCHEDULES_OBJECTS]: routeKeyMap['schedules'],
	[ZTNA_AGENT_SETTINGS_REDUX_ID]: routeKeyMap['dynamic-privilege-access'],
	[PROJECT_BASED_ACCESS_REDUX_ID]: routeKeyMap['dynamic-privilege-access'],
	// Device Settings
	[ETHERNET_INTERFACE]: routeKeyMap['interfaces'],
	[AGGREGATE_ETHERNET]: routeKeyMap['interfaces'],
	[VLAN_REDUX_ID]: routeKeyMap['interfaces'],
	[LOOPBACK_REDUX_ID]: routeKeyMap['interfaces'],
	[TUNNEL_REDUX_ID]: routeKeyMap['interfaces'],
	[CELLULAR_REDUX_ID]: routeKeyMap['interfaces'],
	[ZONES_NETWORK]: routeKeyMap['zones'],
	[VIRTUAL_WIRE]: routeKeyMap['vwire'],
	[LOGICAL_ROUTERS_REDUX_ID]: routeKeyMap['routing'],
	[ADDRESS_FAMILY_PROFILE_REDUX_ID]: routeKeyMap['routing'],
	[AUTH_PROFILE_REDUX_ID]: routeKeyMap['routing'],
	[FILTERING_PROFILE_REDUX_ID]: routeKeyMap['routing'],
	[REDISTRIBUTION_PROFILE_REDUX_ID]: routeKeyMap['routing'],
	[VR_REDISTRIBUTION_PROFILE_REDUX_ID]: routeKeyMap['routing'],
	[ROUTE_ACCESS_LIST_ID]: routeKeyMap['routing'],
	[ROUTE_AS_PATH_ACCESS_LIST_ID]: routeKeyMap['routing'],
	[ROUTE_PREFIX_LIST_ID]: routeKeyMap['routing'],
	[ROUTE_MAP_BGP_LIST_ID]: routeKeyMap['routing'],
	[ROUTE_MAPS_DISTRIBUTION_LIST_ID]: routeKeyMap['routing'],
	[OSPF_AUTH_PROFILE_REDUX_ID]: routeKeyMap['routing'],
	[ROUTE_COMMUNITY_LIST_ID]: routeKeyMap['routing'],
	[IPSEC_TUNNEL]: routeKeyMap['ipsec-tunnels'],
	[DHCP_SERVER]: routeKeyMap['dhcp'],
	[DHCP_SERVER_RELAY]: routeKeyMap['dhcp'],
	[DNS_PROXIES]: routeKeyMap['dns_proxy'],
	[GP_PORTAL]: routeKeyMap['global-protect'],
	[GATEWAY_REDUX_ID]: routeKeyMap['global-protect'],
	[GP_APP_SETTINGS]: routeKeyMap['global-protect'],
	[GP_TUNNEL_SETTINGS]: routeKeyMap['global-protect'],
	[GP_AUTHENTICATION_PROFILE]: routeKeyMap['global-protect'],
	[DHCP_PROFILE]: routeKeyMap['global-protect'],
	[PORTAL_AGENT_PROFILE]: routeKeyMap['global-protect'],
	[GATEWAY_AGENT_PROFILE]: routeKeyMap['global-protect'],
	// Global Settings
	[VPN_CLUSTERS]: routeKeyMap['auto-vpn'],
	[AUTO_VPN_SETTING]: routeKeyMap['auto-vpn'],
	[DEVICE_SETTING_PASSWORD_PROFILES]: routeKeyMap['device-admins'],
	[DEVICE_SETTING_ADMINISTRATORS]: routeKeyMap['device-admins'],
};
