import { getRedirectURL } from 'ui-lib/core/services/CommonServices';
import { PAGINATION_QUERY_PARAM } from 'src/containers/main/constants';
import { FAWKES } from 'src/typings/type';
import { getAuthHeaders } from 'ui-lib/auth/lib';
import { RowNode } from 'ag-grid-community';

export interface IAM_Scope_Metadata {
	metadata_name: string;
	metadata_value: string;
}

export interface IAM_Scope_Resource {
	resource_type: string;
	resource_id: string;
	metadata?: IAM_Scope_Metadata[];
}

export interface IAM_Scope_Actions {
	// eslint-disable-next-line @typescript-eslint/ban-types
	addResource: Function;
	// eslint-disable-next-line @typescript-eslint/ban-types
	removeResource: Function;
}

export interface IAM_Scope_State {
	state: IAM_Scope;
	actions: IAM_Scope_Actions;
}

export interface IAM_Scope {
	id: string;
	tsg_id: string;
	name: string;
	description?: string; // optional
	resources: IAM_Scope_Resource[];
}

export interface SparkySbacScopeObj {
	id: string;
	name: string;
	description: string;
	resources: IAM_Scope_Resource[];
}

export interface SbacTreeNode {
	id: string;
	name: string;
	type: string;
}

interface Node {
	name: string;
	id: string;
	children?: Node[];
}

export interface SnippetType {
	name: string;
	id: string;
	display_name: string;
	type: string;
	description: string;
	donor_tenant_id: string;
	donor_tenant_name: string;
	recipient_tenant_id: string;
	recipient_tenant_name: string;
	scope: string;
	displayName: string;
	snippetType: string;
	displayType: string;
}

export const selectorTypeFolder = "folder"
export const selectorTypeSnippet = "snippet"
export const selectorTypeRemoteNetwork = "remote_network"
export const selectorTypeRemoteNetworkLocation = "remote_network_location"
// made up IDs since we need to show predefined and custom as a hierarchy
export const predefinedSnippetID = "aaaaaaaa-bbbb-cccc-dddd-426614174000"
export const customSnippetID = "eeeeeeee-ffff-1111-2222-426614174001"

export function flatChildrenRecursively(node, data, hierarchy, into) {
	const thisHierarchy = [...hierarchy, data.name];
	const dataForNode = {
		...data,
		hierarchy: thisHierarchy,
	};
	into.push(dataForNode);
	node.children?.forEach((childNode) => {
		const childData = {
			id: childNode.id,
			name: childNode.name,
			display_name: childNode.display_name,
		};
		flatChildrenRecursively(childNode, childData, thisHierarchy, into);
	});
}

export function flatChildrenCommon(data, extraFields = []) {
	const newDataAccumulator = [];
	data.forEach((parentNode) => {
		const parentData = {
			id: parentNode.id,
			name: parentNode.name,
			display_name: parentNode.display_name,
			...extraFields.reduce((acc, field) => {
				acc[field] = parentNode[field];
				return acc;
			}, {}),
		};
		const thisHierarchy = [];
		flatChildrenRecursively(parentNode, parentData, thisHierarchy, newDataAccumulator);
	});
	console.log('flat data', newDataAccumulator);
	return newDataAccumulator;
}

export function makeSnippetDataHierarchical(json: any) {
	const predefinedSnippets = {
		name: "Predefined",
		id: predefinedSnippetID,
		type: "predefined",
		display_name: "Predefined",
		children: []
	};

	const customSnippets = {
		name: "Custom",
		id: customSnippetID,
		type: "custom",
		display_name: "Custom",
		children: []
	};

	json.data.forEach((snippet: any) => {
		const snippetCopy = { ...snippet };
		if (snippet?.type === "predefined" || snippet?.type === "readonly") {
			predefinedSnippets.children.push(snippetCopy);
		} else {
			customSnippets.children.push(snippetCopy);
		}
	});

	return {
		data: [
			predefinedSnippets,
			customSnippets
		]
	};
}

export function getSnippetsByType(type: 'predefined' | 'custom', allSnippetsForStarSnippet: Record<string, SnippetType>): SnippetType[] {
	return Object.values(allSnippetsForStarSnippet).filter(snippet => {
		if (type === 'predefined') {
			return snippet?.snippetType === 'predefined' || snippet?.snippetType === 'readonly';
		} else {
			return snippet?.snippetType !== 'predefined' && snippet?.snippetType !== 'readonly';
		}
	});
}

export async function getCommonItems(selectorType: string) {
	let endpoint = '';
	switch (selectorType) {
		case selectorTypeFolder:
			endpoint = 'folders';
			break;
		case selectorTypeSnippet:
			endpoint = 'snippets';
			break;
		case selectorTypeRemoteNetwork:
			endpoint = 'remote-networks';
			break;
		default:
			throw new Error('Invalid selector type');
	}
	const url = `${getRedirectURL()}/sase/config/v1/${endpoint}?${PAGINATION_QUERY_PARAM}`;
	const param: FAWKES.I_FETCH_PARAM = {
		withCredentials: true,
		method: 'GET',
		headers: getAuthHeaders(),
		dataType: 'json',
		ContentType: 'application/json',
	};
	const response = await fetch(url, param);
	let json = await response.json();

	if (selectorType === selectorTypeFolder) {
		json.data = json.data.map(item => ({
			...item,
			name: item.display_name || item.name,
			children: item.children?.map(child => ({
				...child,
				name: child.name === 'ngfw-shared' ? 'All Firewalls' : (child.display_name || child.name),
				children: child.children?.filter(grandchild => grandchild.name !== "Colo Connect")
			}))
		}));
	} else if (selectorType === selectorTypeSnippet) {
		// filter snippets of name rbi or predefined-snippet or dlp-predefined-snippet
		const filteredJson = {
			...json,
			data: filterSnippets(json.data)
		};

		// Make data hierarchical based on type value.
		json = makeSnippetDataHierarchical(filteredJson);
	}

	console.table({
		url,
		param,
		json,
	});
	return json;
}

export function filterSnippets(snippets: any[]): any[] {
	const excludedNames = ["rbi", "predefined-snippet", "dlp-predefined-snippet"];

	return snippets.filter(snippet => !excludedNames.includes(snippet.name));
}


/*
 If a parent node and all its children are selected, the function will return only the parent node
 This is how we want to store to scopeState
 This function returns list of trimmed down IDs
 */
export function processSelectedIds(selectedIds: string[], inputObject) {
	function processNode(node) {
		if (!node.children || node.children.length === 0) {
			return selectedIds.includes(node.id) ? [node.id] : [];
		}
		const childrenIds = node.children.flatMap(processNode);
		const allChildrenSelected = node.children.every(
			(child) =>
				selectedIds.includes(child.id) ||
				(child.children && child.children.length > 0 && childrenIds.includes(child.id)),
		);
		if (allChildrenSelected) {
			return selectedIds.includes(node.id) ? [node.id] : [];
		} else {
			return childrenIds;
		}
	}
	return processNode(inputObject[0]);
}

/*
 we loop the hierarchy and for every node
- if its a leaf add it
- if its a parent and for this parent id, and parent toggle is on, then
  stop recursively checking lower and just add parent for that node.
- If parent toggle is off, then do not add parent but keep recursively keep
  checking down.
 */
export function getTrueSelections(
	selectedObjects: SbacTreeNode[],
	hierarchy,
	toggledNodes: Record<string, boolean>,
): SbacTreeNode[] {
	const result: SbacTreeNode[] = [];
	const selectedIdSet: Set<string> = new Set(selectedObjects.map((item) => item.id));

	function processNode(node): void {
		if (!node.children || node.children.length === 0) {
			// It's a leaf node, add it if selected
			if (selectedIdSet.has(node.id)) {
				result.push({ id: node.id, name: node.name, type: node.type });
			}
			return;
		}

		// It's a parent node
		if (toggledNodes[node.id]) {
			// Parent toggle is on, add parent and stop recursion
			if (selectedIdSet.has(node.id)) {
				result.push({ id: node.id, name: node.name, type: node.type });
			}
		} else {
			// Parent toggle is off, continue checking children
			node.children.forEach(processNode);
		}
	}

	if (hierarchy && hierarchy.length > 0) {
		hierarchy.forEach(processNode);
	}

	return result;
}

/*
This function takes an array of node IDs and an input object representing a hierarchical tree structure,
and returns an expanded array of IDs that includes all children of the initially provided IDs. This is used
when we read from state during load and state has only parents but we want to show both parent+children
on the grid
 */
export function expandIds(ids: string[], inputObject: Node[]): string[] {
	const result: Set<string> = new Set(ids);

	function traverse(node: Node) {
		if (result.has(node.id)) {
			addChildren(node);
		}
		node.children?.forEach(traverse);
	}

	function addChildren(node: Node) {
		node.children?.forEach((child) => {
			result.add(child.id);
			addChildren(child);
		});
	}

	inputObject.forEach(traverse);
	return Array.from(result);
}

export function findParentAndRecursiveDescendantIds(inputObject: Node[], parentId: string): Set<string> {
	const descendantIds: Set<string> = new Set();

	function traverse(node: Node) {
		if (node.id === parentId) {
			descendantIds.add(node.id);
			addAllDescendants(node);
			return;
		}
		node.children?.forEach(traverse);
	}

	function addAllDescendants(node: Node) {
		descendantIds.add(node.id);
		node.children?.forEach((child) => {
			addAllDescendants(child);
		});
	}

	inputObject.forEach(traverse);
	return descendantIds;
}

export function convertScopeResources(
	scopeResources: any[],
	globalFolderIdForStarFolder: string,
	allSnippetsForStarSnippet: any,
	randomId: string,
): any[] {
	return scopeResources.map((resource) => {
		// For each type of object, filter and then map resource_id
		// console.log('computed resource in convertScopeResources: ', resource);
		const folders = resource?.resources
			.filter((res) => res?.resource_type === 'cm_folder')
			.map((res) => (res?.resource_id === '*' ? globalFolderIdForStarFolder : res?.resource_id));
		// console.log('computed folders in convertScopeResources: ', folders);

		const snippets = resource?.resources
			.filter((res) => res?.resource_type === 'config_snippet')
			.flatMap((res) => {
				if (res?.resource_id === '*') {
					return Object.values(allSnippetsForStarSnippet).map((snippet: SnippetType) => snippet.id);
				} else if (res?.resource_id === predefinedSnippetID) {
					// Predefined snippet id found - so add all predefined snippets
					const predefinedSnippets = getSnippetsByType('predefined', allSnippetsForStarSnippet)
					return predefinedSnippets.map((snippet: SnippetType) => snippet.id);
				} else if (res?.resource_id === customSnippetID) {
					// Custom snippet id found - so add all custom snippets
					const customSnippets =  getSnippetsByType('custom', allSnippetsForStarSnippet)
					return customSnippets.map((snippet: SnippetType) => snippet.id);
				}
				return res?.resource_id;
			});
		// console.log('computed snippets in convertScopeResources: ', snippets);

		const remoteNetworks = resource.resources
			.filter((res) => res?.resource_type === 'remote_network')
			.map((res) => res?.resource_id);
		// console.log('computed remoteNetworks in convertScopeResources: ', remoteNetworks);

		const transformedObj = {
			id: randomId,
			folders,
			snippets,
			remoteNetworks,
			name: resource.name,
		};
		// console.log('computed transformedObj in convertScopeResources for resource: ', transformedObj, resource);

		return transformedObj;
	});
}

export function getChildIdsRecursivelyFromRowNode(node: RowNode): string[] {
	const result: string[] = [node?.data?.id]; // Include the input node's ID
	if (node?.childrenMapped) {
		Object.values(node.childrenMapped).forEach((child) => {
			result.push(...getChildIdsRecursivelyFromRowNode(child));
		});
	}
	return result;
}

// Handles errors
export const handleError = (error: any) => {
	console.log(error);
	// dispatch({
	// 	type: SERVER_ERROR,
	// 	errorMessage: parseError(error),
	// 	showMessage: true,
	// });
};

export function getCaptionText(selectorType) {
	let captionText = '';
	if (selectorType === selectorTypeFolder) {
		captionText = 'Folders';
	} else if (selectorType === selectorTypeSnippet) {
		captionText = 'Snippets';
	} else if (selectorType === selectorTypeRemoteNetwork) {
		captionText = 'Remote Networks';
	}
	return captionText;
}

export function getSelectorHubMapping() {
	return {
		[selectorTypeFolder]: 'cm_folder',
		[selectorTypeSnippet]: 'config_snippet',
		[selectorTypeRemoteNetwork]: 'remote_network',
		[selectorTypeRemoteNetworkLocation]: 'remote_network_location',
	};
}

export const getSiblingsById = (jsonData: any[], id: string): string[] => {
	const findParent = (data: any[], targetId: string): any => {
		for (const item of data) {
			if (item.id === targetId) {
				return null; // Found the item itself, no parent
			}
			if (item.children) {
				const parent = findParent(item.children, targetId);
				if (parent) return parent;
				if (item.children.some((child) => child.id === targetId)) {
					return item;
				}
			}
		}
		return null;
	};

	const parent = findParent(jsonData, id);
	if (!parent) return [];

	return parent.children.map((child) => child.id).filter((childId) => childId !== id);
};

export const findRemovedItems = (removeGridObjects: Set<string>, jsonData: any[]): any[] => {
	const removedItems: any[] = [];

	function traverse(node: any) {
		if (!removeGridObjects.has(node.id)) {
			removedItems.push(node);
		}
		if (node.children && Array.isArray(node.children)) {
			node.children.forEach(traverse);
		}
	}

	jsonData.forEach(traverse);
	return removedItems;
};

export function findAllParents(id: string, data: any[]): string[] {
	const parents: string[] = [];

	function traverse(nodes: any[]) {
		for (const node of nodes) {
			if (node.id === id) {
				return true;
			}
			if (node.children) {
				if (traverse(node.children)) {
					parents.push(node.id);
					return true;
				}
			}
		}
		return false;
	}

	traverse(data);
	return parents;
}
