export const SECURITY_POLICY_RULES = 'securityRules';
export const ANTI_SPYWARE_SECURITY_PROFILES = 'AntiSpywareSecurityProfiles';
export const ANTI_SPYWARE_OVERRIDES = 'antispywareOverrides';
export const CUSTOM_SPYWARE_SIGNATURES = 'CustomSpywareSignatures';
export const CUSTOM_VULNERABILITY_SIGNATURES = 'CustomVulnerabilitySignatures';
export const VULNERABILITY_PROTECTION_OVERRIDES = 'vulnerabilityProtectionOverrides';
export const VULNERABILITY_PROTECTION_OBJECTS = 'vulnerabilityProtectionObjects';
export const WILDFIRE_AND_ANTIVIRUS_OBJECTS = 'wildFireAndAntivirusObjects';
export const WILDFIRE_SETTINGS = 'wildFireSettings';
export const WILDFIRE_SETTINGS_CLOUD = 'wildFireSettingsCloud';
export const SESSION_INFO_SHARING = 'sessionInfoSharing';
export const DNS_SECURITY_PROFILES = 'DNSSecurityProfiles';
export const ACCESS_CONTROL = 'accessControl';
export const URL_CATEGORY_REDUX_ID = 'URLCategory';
export const CUSTOME_URL_CATEGORY = 'customeUrlCategory';
export const URL_ADMIN_OVERRIDES = 'urlAdminOverrides';
export const URL_ADVANCED_SETTINGS = 'urlAdvancedSettings';
export const URL_ADVANCED_SETTINGS_CLOUD = 'urlAdvancedSettingsCloud';
export const FILE_BLOCKING_OBJECTS = 'fileBlockingObjects';
export const SAAS_SECURITY_PROFILES = 'saasSecurityProfiles';
export const AI_SECURITY_PROFILES = 'aiSecurityProfiles';
export const SECURITY_PROFILE_GROUPS = 'securityProfileGroups';
export const SWG_FILE_BLOCKING_SECURITY_PROFILES = 'swgFileBlockingSecurityProfiles';
export const SWG_CUSTOM_WEB_POLICY = 'swgCustomWebPolicy';
export const SWG_DEFAULT_WEBPOLICY = 'swgDefaultWebPolicy';
export const DEVICE_POSTURE_PROFILE_SWG = 'DevicePostureProfileSwg';
export const DEVICE_IDENTIFICATION_SWG = 'DeviceIdentificationSwg';
export const APPLICATION_GROUP_WITH_CONTROL_SWG = 'ApplicationGroupWithControlSwg';
export const DECRYPTION_RULES = 'decryptionRules';
export const DECRYPTION_PROFILE = 'DecryptionProfile';
export const DOS_RULES = 'DosRules';
export const DOS_PROTECTION_PROFILE_REDUX_ID = 'DOS_PROTECTION_PROFILE_REDUX_ID';
export const ZONE_PROTECTION_PROFILE_REDUX_ID = 'zoneProtectionProfiles';
export const QO_RULES = 'qoRules';
export const QOS_PROFILE = 'qosProfile';
export const QOS_INTERFACE = 'qosInterface';
export const APPLICATION_OVERRIDE_RULES = 'applicationOverrideRules';
export const POLICY_BASED_FORWARDING_RULES = 'policyBasedForwardingRules';
export const NAT_RULES = 'natRules';
export const SDWAN_RULES = 'SDWAN-Rules';
export const SDWAN_PATH_QUALITY = 'sdwan-path-quality';
export const SDWAN_SAAS_QUALITY = 'sdwan-saas-quality';
export const SDWAN_TRAFFIC_DISTRIBUTION = 'sdwan-traffic-distribution';
export const SDWAN_ERROR_CORRECTION = 'sdwan-error-correction';
export const SDWAN_INTERFACE_PROFILE = 'sdwan-interface-profile';
export const SDWAN_LINK_TAGS = 'sdwan-link-tags';

export const AUTHENTICATION_RULES = 'authenticationRules';
export const AUTHENTICATION_PORTAL_CONFIG = 'authenticationPortalConfig';
export const AUTHENTICATION_PORTAL_CONFIG_ = 'AuthenticationPortalConfig';
export const MFA_SERVER = 'MFAServer';
export const AUTHENTICATION_PROFILE_GRID = 'AuthenticationProfileGrid';
export const AUTHENTICATION_SEQUENCE = 'authenticationSequence';
export const SAML_PROFILE = 'saml';
export const LDAP_PROFILE = 'ldap';
export const TACACS_PROFILE = 'tacacs';
export const KERBEROS_PROFILE = 'kerberos';
export const RADIUS_PROFILE = 'radius';
export const DIR_SYNC = 'dirSync';
export const REDISTRIBUTION_AGENTS = 'redistributionAgents';
export const LOCAL_USERS_REDUX_ID = 'localUsers';
export const LOCAL_USER_GROUPS_REDUX_ID = 'localUserGroups';
export const DELEGATION_PROFILE_REDUX_ID = 'delegationProfile';

export const ADDRESS_REDUX_ID = 'addressObjects';
export const ADDRESS_GROUP_REDUX_ID = 'addressGroups';
export const REGION_REDUX_ID = 'regionsObjects';
export const APPLICATIONS_REDUX_ID = 'applicationObjects';
export const APPLICATIONS_GROUPS_REDUX_ID = 'applicationGroupObjects';
export const APPLICATION_FILTERS_REDUX_ID = 'applicationFilterObjects';
export const TRAFFIC_OBJECTS_REDUX_ID = 'trafficObjects';
export const SERVICE_OBJECTS = 'serviceObjects';
export const SERVICE_GROUP_REDUX_ID = 'serviceGroupObject';
export const HIP_OBJECTS = 'hipObjects';
export const HIP_PROFILES = 'hipProfiles';
export const DYNAMIC_USER_GROUP_OBJECTS = 'dynamicUserGroupObjects';
export const TAGS_OBJECTS = 'tagsObjects';
export const AUTO_TAG_ACTIONS_OBJECTS = 'autoTagActionsObjects';
export const LOG_FORWARDING_OBJECTS = 'logForwardingObjects';
export const SYS_LOG = 'syslog';
export const HTTP_SERVER = 'http';
export const SNMP_SERVER = 'snmp';
export const EMAIL_SERVER = 'email';
export const DEVICE_SUPPORT_REDUX_ID = 'deviceSupport';
export const EDL_REDUX_ID = 'externalDynamicListObjects';
export const CUSTOM_CERTIFICATE = 'CustomCertificate';
export const CERTIFICATE_MANAGEMENT_PROFILE = 'certificateManagementProfile';
export const SCEP_REDUX_ID = 'scep';
export const SSL_TLS_SERVICE_PROFILE = 'sslTlsServiceProfile';
export const OCSP_REPONDER = 'ocspReponder';
export const SCHEDULES_OBJECTS = 'schedulesObjects';
export const ZTNA_AGENT_SETTINGS_REDUX_ID = 'jpAgentSettings';
export const PROJECT_BASED_ACCESS_REDUX_ID = 'ProjectBasedAccess';

export const ETHERNET_INTERFACE = 'ethernet-interface';
export const AGGREGATE_ETHERNET = 'aggregateEthernet';
export const VLAN_REDUX_ID = 'vlan';
export const LOOPBACK_REDUX_ID = 'loopback';
export const TUNNEL_REDUX_ID = 'tunnel';
export const CELLULAR_REDUX_ID = 'cellular';
export const ZONES_NETWORK = 'zonesNetwork';
export const VIRTUAL_WIRE = 'virtual-wire';
export const LOGICAL_ROUTERS_REDUX_ID = 'logicalRouters';
export const ADDRESS_FAMILY_PROFILE_REDUX_ID = 'AddressFamilyProfiles';
export const AUTH_PROFILE_REDUX_ID = 'BGPAuthProfiles';
export const FILTERING_PROFILE_REDUX_ID = 'FilteringProfiles';
export const REDISTRIBUTION_PROFILE_REDUX_ID = 'RedistributionProfiles';
export const VR_REDISTRIBUTION_PROFILE_REDUX_ID = 'VrRedistributionProfiles';
export const VR_REDISTRIBUTION_PROFILE_IPv6_REDUX_ID = 'VrRedistributionProfilesIpv6';
export const ROUTE_ACCESS_LIST_ID = 'ROUTE_ACCESS_LIST_ID';
export const ROUTE_AS_PATH_ACCESS_LIST_ID = 'ROUTE_AS_PATH_ACCESS_LIST_ID';
export const ROUTE_PREFIX_LIST_ID = 'ROUTE_PREFIX_LIST_ID';
export const ROUTE_MAP_BGP_LIST_ID = 'ROUTE_MAP_BGP_LIST_ID';
export const ROUTE_MAPS_DISTRIBUTION_LIST_ID = 'ROUTE_MAPS_DISTRIBUTION_LIST_ID';
export const OSPF_AUTH_PROFILE_REDUX_ID = 'OSPFAuthProfiles';
export const OSPFv3_AUTH_PROFILE_REDUX_ID = 'OSPFv3AuthProfiles';
export const ROUTE_COMMUNITY_LIST_ID = 'ROUTE_COMMUNITY_LIST_ID';
export const IPSEC_TUNNEL = 'ipsecTunnel';
export const IPSEC_CRYPTO_PROFILE = 'IpSec Crypto Profile';
export const DHCP_SERVER = 'dhcp-server';
export const DHCP_SERVER_RELAY = 'dhcp-server-relay';
export const DNS_PROXIES = 'dns-proxies';
export const GP_PORTAL = 'gpPortal';
export const GATEWAY_REDUX_ID = 'gateway';
export const GP_APP_SETTINGS = 'GPAppSettings';
export const GP_TUNNEL_SETTINGS = 'gpTunnelSettings';
export const GP_AUTHENTICATION_PROFILE = 'gpAuthenticationProfile';
export const GP_AGENT_SETTINGS_REDUX_ID = 'gpAgentSettings';
export const PORTAL_AGENT_PROFILE = 'portalAgentProfile';
export const GATEWAY_AGENT_PROFILE = 'gatewayAgentProfile';
export const VPN_CLUSTERS = 'vpnClusters';
export const AUTO_VPN_SETTING = 'autoVpnSetting';
export const DHCP_PROFILE = 'dhcpProfile';
export const OSPF_ROUTE_REDISTRIBUTION_REDUX_ID = 'OSPFRouteRedistributionProfiles';
export const DECRYPTION_EXCLUSION = 'decryptionExclusion';
export const OSPF_V3_ROUTE_REDISTRIBUTION_REDUX_ID = 'OSPFV3RouteRedistributionProfiles';
export const BFD_PROFILE_REDUX_ID = 'BfdProfiles';
export const DEVICE_SETTING_ADMINISTRATORS = 'deviceSettingAdministrators';
export const DEVICE_SETTING_PASSWORD_PROFILES = 'deviceSettingPasswordProfiles';
export const DEVICE_HA_CONFIG = 'haConfig';
export const QKD_PROFILE_REDUX_ID = 'qkdProfile';

export const OSPF_GLOBAL_TIMER_PROFILE_REDUX_ID = 'OspfGlobalTimerProfile';
export const OSPFV3_GLOBAL_TIMER_PROFILE_REDUX_ID = 'Ospfv3GlobalTimerProfile';
export const OSPF_INTERFACE_TIMER_PROFILE_REDUX_ID = 'OspfInterfaceTimerProfile';
export const OSPFV3_INTERFACE_TIMER_PROFILE_REDUX_ID = 'Ospfv3InterfaceTimerProfile';
export const BGP_TIMER_PROFILE_REDUX_ID = 'BGPTimerProfile';
export const BGP_DAMPENING_PROFILE_REDUX_ID = 'BGPDampeningProfile';
export const MULTICAST_INTERFACE_TIMER_PROFILE_REDUX_ID = 'MULTICAST_INTERFACE_TIMER_PROFILE_REDUX_ID';
export const MULTICAST_IGMP_QUERY_PROFILE_REDUX_ID = 'MULTICAST_IGMP_QUERY_PROFILE_REDUX_ID';
export const MULTICAST_MSDP_AUTH_PROFILE_REDUX_ID = 'MULTICAST_MSDP_AUTH_PROFILE_REDUX_ID';
export const MULTICAST_MSDP_TIMER_PROFILE_REDUX_ID = 'MULTICAST_MSDP_TIMER_PROFILE_REDUX_ID';
export const USER_AUTHENTICATION_CERTIFICATE_PROFILE_REDUX_ID = 'UserAuthenticationCertificate';
export const MU_GATEWAY_USER_AUTHENTICATION_PROFILE_REDUX_ID = 'gatewayUserAuthentication';
export const MU_GP_USER_AUTHENTICATION_PROFILE_REDUX_ID = 'userAuthentication';
export const MU_GP_USER_AUTHENTICATION_PROFILE_8843_REDUX_ID = 'userAuthentication_8443';
export const DEFAULT_PORTAL_REDUX_ID = 'GlobalProtect_Portal';
export const DEFAULT_GATEWAY_REDUX_ID = 'GlobalProtect_External_Gateway';
export const DEFAULT_GATEWAY_NETWORK_REDUX_ID = 'GlobalProtect_External_Gateway-N';
export const GP_TUNNEL_SETTINGS_V2 = 'gpTunnelSettingsV2';
export const GP_AGENT_SETTINGS_REDUX_ID_V2 = 'gpAgentSettingsV2';
export const MU_GP_USER_AUTHENTICATION_PROFILE_REDUX_ID_V2 = 'userAuthenticationV2';
export const MU_GP_USER_AUTHENTICATION_PROFILE_8843_REDUX_ID_V2 = 'userAuthentication_8443V2';
export const MU_GATEWAY_USER_AUTHENTICATION_PROFILE_REDUX_ID_V2 = 'gatewayUserAuthenticationV2';
